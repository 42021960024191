import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { ChildSidebarProps } from "../interfaces/ChildSidebarPops"

import sidebar from "../assets/images/icons/sidebar.svg"

import AdminSideBar from "../components/sidebar/AdminSideBar"
import MonitoringSideBar from "../components/sidebar/MonitoringSideBar"
import ConfigurationSideBar from "../components/sidebar/ConfigurationSideBar"
import VisualizationSideBar from "../components/sidebar/VisualizationSideBar"
import DocumentCategorySideBar from "../components/sidebar/DocumentCategorySideBar"

import type { AppDispatch } from "../store/store"
import { getSidebarOpen, setSidebarOpen } from "../store/counter/authSlice"

const Sidebar = () => {
  const location = useLocation()
  const getPath = (index = 1) => location.pathname.split("/")[index]

  const [showSidebar, setSidebar] = useState(useSelector(getSidebarOpen))

  const childProps: ChildSidebarProps = {
    getPath
  }

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    // update sidebar state to use in monitoring page
    dispatch(setSidebarOpen(showSidebar))
  }, [dispatch, showSidebar])

  const showMenu = () => {
    if (getPath() === "admin") return <AdminSideBar {...childProps} />
    else if (getPath() === "monitoring") return <MonitoringSideBar {...childProps} />
    else if (getPath() === "site-setup") return <ConfigurationSideBar {...childProps} />
    else if (getPath() === "visualization") return <VisualizationSideBar />
    else if (getPath() === "document") return <DocumentCategorySideBar />
    else return ""
  }

  return (
    <aside id="sidebarArea" className={showSidebar ? "show" : "collapsed"}>
      {showSidebar ? (
        <img
          src={sidebar}
          className="position-absolute"
          alt="sidebar"
          id="btnCollapse"
          role="button"
          onClick={() => setSidebar(!showSidebar)}
        />
      ) : (
        <img
          src={sidebar}
          className="position-absolute"
          alt="sidebar"
          id="btnSidebarShow"
          role="button"
          onClick={() => setSidebar(!showSidebar)}
        />
      )}
      <div className="sidebar-wrapper">{showMenu()}</div>
    </aside>
  )
}

export default Sidebar
