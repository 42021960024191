import Messages from "utils/messages"
import ActionButton from "components/button/ActionButton"
import { SITE_STATUS, USER_ROLES } from "utils/constants"

const LayoutActionButtons = (props: {
  configurationStatus?: string
  viewOnly: boolean
  userEditable: boolean
  isLoading: boolean
  onSaveLayout: () => void
  onLayoutSubmit: () => void
  updateStatus: (status: string) => void
  currentUserRole?: string
}) => {
  const {
    configurationStatus,
    viewOnly,
    userEditable,
    isLoading,
    onSaveLayout,
    onLayoutSubmit,
    updateStatus,
    currentUserRole
  } = props

  const superAdminButtons = () => (
    <div id="approvalButton">
      {(configurationStatus === SITE_STATUS.CONFIGURATION_IN_PROGRESS ||
        configurationStatus === SITE_STATUS.PENDING_FINAL_APPROVAL) && (
        <ActionButton
          className="btn-primary"
          onClick={() => updateStatus(SITE_STATUS.LAYOUT_IN_PROGRESS)}
          text={Messages.BTN_SITE_SETUP_EDIT_LAYOUT}
        />
      )}
      {!viewOnly && userEditable && (
        <>
          {(configurationStatus === SITE_STATUS.LAYOUT_IN_PROGRESS ||
            configurationStatus === SITE_STATUS.LAYOUT_PENDING_APPROVAL) && (
            <ActionButton
              className="btn-secondary"
              onClick={onSaveLayout}
              spinner={isLoading}
              text={Messages.BTN_SITE_SETUP_SAVE}
            />
          )}
          {configurationStatus === SITE_STATUS.LAYOUT_IN_PROGRESS && (
            <ActionButton
              className="btn-primary"
              onClick={onLayoutSubmit}
              text={Messages.BTN_SITE_SETUP_APPROVE}
            />
          )}
        </>
      )}
      {!viewOnly && configurationStatus === SITE_STATUS.LAYOUT_PENDING_APPROVAL && (
        <>
          <ActionButton
            className="btn-danger"
            onClick={() => updateStatus(SITE_STATUS.LAYOUT_IN_PROGRESS)}
            text={Messages.BTN_SITE_SETUP_REJECT}
          />
          <ActionButton
            className="btn-primary"
            onClick={() => updateStatus(SITE_STATUS.CONFIGURATION_IN_PROGRESS)}
            text={Messages.BTN_SITE_SETUP_APPROVE}
          />
        </>
      )}
    </div>
  )

  const tenantAdminButtons = () => (
    <div id="approvalButton">
      {configurationStatus &&
        [
          SITE_STATUS.LAYOUT_PENDING_APPROVAL,
          SITE_STATUS.LAYOUT_APPROVED,
          SITE_STATUS.CONFIGURATION_IN_PROGRESS,
          SITE_STATUS.PENDING_FINAL_APPROVAL
        ].includes(configurationStatus) && (
          <ActionButton
            className="btn-primary"
            onClick={() => updateStatus(SITE_STATUS.LAYOUT_IN_PROGRESS)}
            text={Messages.BTN_SITE_SETUP_CANCEL_SUBMISSION}
          />
        )}
      {configurationStatus === SITE_STATUS.LAYOUT_IN_PROGRESS && (
        <>
          <ActionButton
            className="btn-secondary"
            onClick={onSaveLayout}
            spinner={isLoading}
            text={Messages.BTN_SITE_SETUP_SAVE_TO_DRAFT}
          />
          <ActionButton
            className="btn-primary"
            onClick={onLayoutSubmit}
            text={Messages.BTN_SITE_SETUP_SUBMIT_APPROVAL}
          />
        </>
      )}
    </div>
  )

  return (
    <>
      {currentUserRole === USER_ROLES.AESI_SUPER_ADMIN.id
        ? superAdminButtons()
        : !viewOnly && tenantAdminButtons()}
    </>
  )
}

export default LayoutActionButtons
