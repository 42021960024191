import { useState, memo } from "react"
import { useSelector } from "react-redux"
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"
import {
  getViewBy,
  getSelectedTeraStors,
  getSiteEntity,
  getDataMode,
  getFetchingChartsStatus
} from "../../store/counter/visualizationSlice"

import MultipleDownloadModal from "../../components/modal/MultipleDownloadModal"

import { MultipleDownloadModalProps } from "../../interfaces/MultipleDownloadModalProps"

import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { ExportFileTypeEnum } from "../../enum/visualization/export-file-type"
import { DataMode } from "../../enum/visualization/data-mode"

import download from "../../assets/images/icons/visualization/download.svg"

import Messages from "../../utils/messages"

const VisualizationHeader = memo(() => {
  const currentSiteEntity = useSelector(getSiteEntity)
  const viewBy = useSelector(getViewBy)
  const dataMode = useSelector(getDataMode)
  const selectedTeraStors = useSelector(getSelectedTeraStors)
  const isFetchingCharts = useSelector(getFetchingChartsStatus)

  const [multipleDownload, setMultipleDownload] = useState<MultipleDownloadModalProps>({
    size: "md",
    modal: false,
    toggle: () => {
      setMultipleDownload({
        ...multipleDownload,
        modal: false
      })
    }
  })
  const handleMultiDownloadToggle = (fileType: ExportFileTypeEnum) => {
    setMultipleDownload({
      ...multipleDownload,
      modal: true,
      fileType
    })
  }

  // #region dropdown component
  const DownloadDropdown = memo(() => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} direction="down">
        <DropdownToggle caret className="bg-white px-2 text-break text-wrap">
          {Messages.LBL_VISUALIZATION_EXPORT_MULTIPLE}
          <img src={download} width={15} alt="download" className="ms-2" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleMultiDownloadToggle(ExportFileTypeEnum.PDF)}>
            PDF
          </DropdownItem>
          <DropdownItem onClick={() => handleMultiDownloadToggle(ExportFileTypeEnum.CSV)}>
            CSV
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  })
  DownloadDropdown.displayName = "DownloadDropdown"

  // #region render
  return (
    <>
      {currentSiteEntity && (
        <div className="d-flex flex-column flex-md-row justify-content-between mb-4 px-3 text-center">
          <div className="text-primary mb-3 mb-md-0 text-break text-wrap">
            <span className="fw-bold fs-4">{currentSiteEntity.siteName}</span>
            <span className="fs-4 ms-2">#{currentSiteEntity.siteUniqueId}</span>
          </div>
          {viewBy === VisualizationViewByEnum.Detail &&
            selectedTeraStors.length > 1 &&
            dataMode === DataMode.Average &&
            !isFetchingCharts && <DownloadDropdown />}
        </div>
      )}
      {multipleDownload.modal && <MultipleDownloadModal {...multipleDownload} />}
    </>
  )
})

VisualizationHeader.displayName = "VisualizationHeader"

export default VisualizationHeader
