import { useSelector } from "react-redux"

import { getFilters } from "../../../store/counter/visualizationSlice"

import { VisualizationFilterEnum } from "../../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../../enum/visualization/tab/battery-filter"
import { LoadFilterEnum } from "../../../enum/visualization/tab/load-filter"

import { getVisuaUnit } from "../../../utils/utils"
import Messages from "../../../utils/messages"
import { Color } from "../../../enum/visualization/color"

interface ViewBox {
  x: number
  y: number
  height: number
}
interface YAxisRightLabelProps {
  viewBox: ViewBox
  fontSizeSmall: string
  fontSizeLarge: string
  displayInSmallFontSize: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YAxisRightLabel = (props: YAxisRightLabelProps) => {
  const { x, y, height } = props.viewBox

  const filters = useSelector(getFilters)
  const isBattery = filters === VisualizationFilterEnum.Battery

  const Y_COORDINATE = -53

  return (
    <g transform={`translate(${x},${y})`}>
      {isBattery ? (
        <text
          x={height / 2}
          y={Y_COORDINATE}
          transform="rotate(90)"
          textAnchor="middle"
          fill={Color.Voltage}
          fontSize={props.displayInSmallFontSize ? props.fontSizeSmall : props.fontSizeLarge}
        >
          {Messages.LBL_VISUALIZATION_VOLTAGE} {getVisuaUnit(BatterFilterEnum.Voltage)}
        </text>
      ) : (
        <>
          <text
            x={height / 2 - 20}
            y={Y_COORDINATE}
            transform="rotate(90)"
            textAnchor="end"
            fill={Color.RealPower}
            fontSize={props.displayInSmallFontSize ? props.fontSizeSmall : props.fontSizeLarge}
          >
            {Messages.LBL_VISUALIZATION_REAL_POWER} {getVisuaUnit(LoadFilterEnum.RealPower)}
          </text>
          <text
            x={height / 2 + 5}
            y={Y_COORDINATE}
            transform="rotate(90)"
            textAnchor="start"
            fill={Color.ReactivePower}
            fontSize={props.displayInSmallFontSize ? props.fontSizeSmall : props.fontSizeLarge}
          >
            {Messages.LBL_VISUALIZATION_REACTIVE_POWER} {getVisuaUnit(LoadFilterEnum.ReactivePower)}
          </text>
        </>
      )}
    </g>
  )
}

export default YAxisRightLabel
