import "../../assets/scss/configuration/configuration.scss"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"

import { SiteConfiguration } from "../../interfaces/SiteConfiguration"
import AlertModal from "../../components/modal/AlertModal"
import { AlertModalProps } from "../../interfaces/AlertModalProps"
import { StepConfigurationProps } from "../../interfaces/StepConfigurationProps"
import {
  COMMISION_PAGE_SIZE,
  SITE_SETUP_STEP,
  SITE_SETUP_STEP_TITLE,
  SITE_STATUS,
  USER_ROLES
} from "../../utils/constants"
import { SiteEntity } from "../../interfaces/SiteEntity"
import StepLayout from "./StepLayout"
import StepConfigurationTeraStor from "./StepConfiguration-TeraStor"
import StepConfigurationSite from "./StepConfiguration-Site"
import { NetworkConfiguration } from "../../interfaces/NetworkConfiguration"
import ConfirmationModal from "../../components/modal/ConfirmationModal"

import { stringFormat, writeCodeLogEvent } from "utils/utils"
import messages from "../../utils/messages"

import { getCurrentUserState } from "../../store/counter/authSlice"
import {
  apiCheckSiteConfigurationPackageBySiteUniqueId,
  apiDownloadSiteConfigurationPackageBySiteUniqueId,
  apiGenerateConfig
} from "../../services/apiSiteConfiguration"

import { SiteCommission } from "interfaces/SiteCommission"
import { Tooltip as ReactTooltip } from "react-tooltip"
import ReviewSection from "components/configuration/ReviewSection"
import ActionButton from "components/button/ActionButton"

const SITE_CONFIG_PACKAGE_NAME = "site_config{0}.zip"

interface ApprovalStatus {
  disabled: boolean
  errMessage: string
}

const StepReviewSubmit: (props: StepConfigurationProps) => JSX.Element = (
  props: StepConfigurationProps
) => {
  const currentUser = useSelector(getCurrentUserState)
  const [siteConfiguration, setSiteConfiguration] = useState<SiteConfiguration>()
  const [site, setSite] = useState<SiteEntity>()
  const [submittable, setSubmittable] = useState(false)
  const [activeStep, setActiveStep] = useState<string>("")
  const [hasConfigPackage, setHasConfigPackage] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [finalApprove, setFinalApprove] = useState(false)
  const [submitReviewModalProps, setSubmitReviewModalProps] = useState({
    isShow: false,
    content: "",
    cancelButtonText: messages.BTN_SITE_SETUP_BACK,
    okButtonText: messages.BTN_SITE_SETUP_SUBMIT
  })

  const [alertProps, setAlertProps] = useState({
    message: messages.MSG_SAVING_SITE_CONFIGURATION,
    modal: false,
    status: "loading"
  })

  const [approvalStatus, setApprovalStatus] = useState<ApprovalStatus>()

  const alertToggle = () =>
    setAlertProps((prevProps) => ({ ...prevProps, modal: !prevProps.modal }))
  const alertModalProps: AlertModalProps = {
    size: "sm",
    modal: alertProps.modal,
    toggle: alertToggle,
    status: alertProps.status,
    message: alertProps.message
  }

  useEffect(() => {
    setSiteConfiguration(props.configuration)
    setSite(props.site)
    // Check data is submittable
    // status === SITE_STATUS.CONFIGURATION_IN_PROGRESS
    // layout not null
    // configuration
    setSubmittable(
      props.configuration?.status === SITE_STATUS.CONFIGURATION_IN_PROGRESS &&
        props.configuration?.layout !== null
    )
  }, [props.configuration, props.site])

  useEffect(() => {
    const errorMessage = []
    let disabled = false
    if (
      siteConfiguration?.status === SITE_STATUS.PENDING_FINAL_APPROVAL ||
      siteConfiguration?.status === SITE_STATUS.CONFIGURATION_IN_PROGRESS
    ) {
      if (!validNetwork(props.configuration?.network)) {
        errorMessage.push(messages.MSG_SITE_SETUP_WARNING_APPROVE)
        disabled = true
      }
      if (!validCommission(props.configuration?.commission)) {
        errorMessage.push(messages.MSG_SITE_SETUP_RING_MARSHAL_MISSING)
        disabled = true
      }
      if (!validEmsBoardSerial(props.configuration?.commission)) {
        errorMessage.push(messages.MSG_SITE_SETUP_EMS_MISSING)
        disabled = true
      }
    }
    const message = `<ul>${errorMessage.map((err) => `<li>${err}</li>`)}</ul>`.replaceAll(",", "")
    setApprovalStatus({ disabled: disabled, errMessage: message })
  }, [props.configuration])

  useEffect(() => {
    if (finalApprove) {
      updateStatus(SITE_STATUS.READY_FOR_INSTALLATION)
      setFinalApprove(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalApprove])

  const validNetwork = (network: NetworkConfiguration | undefined) => {
    // Site Information
    // Point of Interconnect Primary Meter
    // StorView Customer Network Settings
    if (
      network &&
      network.powerQualityMeterNetworks &&
      network.powerQualityMeterNetworks.length > 0 &&
      network.sitePowerInformation &&
      network.storViewNetwork &&
      network.dataServer &&
      network.domainMaster
    ) {
      // Site Information
      const sitePowerInfomation = network.sitePowerInformation
      const powerQualityMeter1 = network.powerQualityMeterNetworks[0]
      const storView = network.storViewNetwork
      if (
        sitePowerInfomation.acVoltage === undefined ||
        sitePowerInfomation.acVoltage === "" ||
        sitePowerInfomation.ratedRealPower === undefined ||
        sitePowerInfomation.ratedRealPower === "" ||
        sitePowerInfomation.ratedReactivePower === undefined ||
        sitePowerInfomation.ratedReactivePower === "" ||
        sitePowerInfomation.ratedEnergyCapacity === undefined ||
        sitePowerInfomation.ratedEnergyCapacity === ""
      ) {
        return false
      }
      if (
        powerQualityMeter1.hostName === undefined ||
        powerQualityMeter1.hostName === "" ||
        powerQualityMeter1.ipAddress === undefined ||
        powerQualityMeter1.ipAddress === "" ||
        powerQualityMeter1.port === undefined ||
        powerQualityMeter1.port === ""
      ) {
        return false
      }
      if (
        storView.ipAddress === undefined ||
        storView.ipAddress === "" ||
        storView.netmask === undefined ||
        storView.netmask === "" ||
        storView.gateway === undefined ||
        storView.gateway === ""
      ) {
        return false
      }
      return true
    }
    return false
  }

  const validCommission = (commission: SiteCommission[] | undefined) => {
    if (commission) {
      const requiredRingMarshal = Math.ceil(commission?.length / COMMISION_PAGE_SIZE)
      const currentRingMarshal = commission?.filter((c) => c.isRingMarshal).length
      return currentRingMarshal === requiredRingMarshal
    }
    return false
  }

  const validEmsBoardSerial = (commission?: SiteCommission[]) =>
    commission?.every((ems) => !!ems.emsBoardSerial) ?? false

  useEffect(() => {
    if (siteConfiguration?.status === SITE_STATUS.READY_FOR_INSTALLATION) {
      if (siteConfiguration?.siteId) {
        apiCheckSiteConfigurationPackageBySiteUniqueId(
          siteConfiguration?.siteId,
          props.site?.customerUniqueId
        )
          .then((res) => {
            if (res.status) {
              setHasConfigPackage(res.isAvailable || false)
            }
          })
          .catch((e) => {
            console.log("Code exception: handle check site configuration package =>", e.message)
            setAlertProps({
              message: messages.ERR_COMMON_SERVER_FAILED,
              modal: true,
              status: "error"
            })

            writeCodeLogEvent("handle check site configuration package", e, site?.siteUniqueId)
          })
      }
    }
  }, [props.site?.customerUniqueId, siteConfiguration?.siteId, siteConfiguration?.status])

  const toggle = (newStep: string) => {
    if (activeStep !== newStep) {
      setActiveStep(newStep)
    } else {
      setActiveStep("")
    }
  }

  const updateStatus = async (status: string) => {
    setAlertProps({
      message: messages.MSG_SAVING_SITE_CONFIGURATION,
      modal: true,
      status: "loading"
    })
    if (siteConfiguration?.siteId) {
      const configuration: SiteConfiguration = {
        siteId: siteConfiguration.siteId,
        status: status
      }
      await props.saveConfiguration(configuration)
      setAlertProps((prevProps) => ({ ...prevProps, modal: false }))
    }
  }

  const generateConfig = async () => {
    setAlertProps({
      message: messages.MSG_EXPORTING_SITE_CONFIGURATION,
      modal: true,
      status: "loading"
    })
    if (siteConfiguration?.siteId) {
      const result = await apiGenerateConfig(siteConfiguration?.siteId)
      if (!result.status) {
        setAlertProps({
          message: result.message,
          modal: true,
          status: "error"
        })
        return false
      } else {
        setAlertProps({
          message: messages.MSG_UPLOAD_SITE_CONFIGURATION_SUCCEED,
          modal: true,
          status: "success"
        })
        return true
      }
    } else {
      return false
    }
  }

  async function handleFinalApprove() {
    if (siteConfiguration?.siteId) {
      // Final approve
      const configuration: SiteConfiguration = { siteId: siteConfiguration.siteId }
      const res = await props.saveConfiguration(configuration)
      if (res?.status) {
        const genConfigRes = await generateConfig()
        if (genConfigRes) {
          setFinalApprove(true)
        }
      }
    }
  }

  const onFinalSubmit = () => {
    if (!validNetwork(props.configuration?.network)) {
      setSubmitReviewModalProps({
        ...submitReviewModalProps,
        ...{
          isShow: true,
          okButtonText: messages.BTN_SITE_SETUP_SUBMIT,
          content: messages.MSG_SITE_SETUP_WARNING_SUBMIT
        }
      })
    } else {
      updateStatus(SITE_STATUS.PENDING_FINAL_APPROVAL)
    }
  }

  const downloadConfig = () => {
    if (siteConfiguration?.siteId) {
      setIsDownloading(true)
      apiDownloadSiteConfigurationPackageBySiteUniqueId(
        siteConfiguration?.siteId,
        props.site?.customerUniqueId
      )
        .then((res) => {
          if (!res.status) {
            setAlertProps({
              message: res.message,
              modal: true,
              status: "error"
            })
          } else {
            const url = window.URL.createObjectURL(
              new Blob([res.data], { type: "application/zip" })
            )
            const siteConfigFileName = stringFormat(SITE_CONFIG_PACKAGE_NAME, [
              siteConfiguration?.siteId ? `_${siteConfiguration?.siteId}` : ""
            ])
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", siteConfigFileName)
            document.body.appendChild(link)
            link.click()
            if (link.parentNode) {
              link.parentNode.removeChild(link)
            }
            window.URL.revokeObjectURL(url)
          }
          setIsDownloading(false)
        })
        .catch((e) => {
          console.log("Code exception: handle download site configuration package =>", e.message)
          setAlertProps({
            message: messages.ERR_COMMON_SERVER_FAILED,
            modal: true,
            status: "error"
          })
          setIsDownloading(false)

          writeCodeLogEvent("handle download site configuration package", e, site?.siteUniqueId)
        })
    }
  }

  const viewOnlyConfigurationProps = {
    viewOnly: true,
    configuration: siteConfiguration,
    site: props.site,
    setConfiguration: props.setConfiguration,
    saveConfiguration: props.saveConfiguration
  }

  return (
    <>
      <div className="step-configuration review" data-testid="stepReview">
        <ReviewSection
          step={SITE_SETUP_STEP.LAYOUT}
          title={SITE_SETUP_STEP_TITLE.LAYOUT}
          activeStep={activeStep}
          userRole={currentUser?.role}
          configurationStatus={siteConfiguration?.status}
          toggle={toggle}
          updateStatus={updateStatus}
        >
          <StepLayout {...viewOnlyConfigurationProps} />
        </ReviewSection>
        <ReviewSection
          step={SITE_SETUP_STEP.CONFIGURATION}
          title={SITE_SETUP_STEP_TITLE.CONFIGURATION}
          activeStep={activeStep}
          userRole={currentUser?.role}
          configurationStatus={siteConfiguration?.status}
          toggle={toggle}
          updateStatus={updateStatus}
        >
          <StepConfigurationSite {...{ ...viewOnlyConfigurationProps }} />
        </ReviewSection>
        <ReviewSection
          step={SITE_SETUP_STEP.COMMISSIONING}
          title={SITE_SETUP_STEP_TITLE.COMMISIONING}
          activeStep={activeStep}
          userRole={currentUser?.role}
          configurationStatus={siteConfiguration?.status}
          toggle={toggle}
          updateStatus={updateStatus}
        >
          <StepConfigurationTeraStor {...{ ...viewOnlyConfigurationProps }} />
        </ReviewSection>
      </div>
      <Row className="mt-5">
        <Col className="d-flex justify-content-end">
          {currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id ? (
            <>
              {siteConfiguration?.status === SITE_STATUS.PENDING_FINAL_APPROVAL && (
                <ActionButton
                  className="btn-danger me-2 pe-4 px-4"
                  onClick={() => updateStatus(SITE_STATUS.LAYOUT_IN_PROGRESS)}
                  text={messages.BTN_SITE_SETUP_REJECT}
                />
              )}
              {siteConfiguration?.status === SITE_STATUS.READY_FOR_INSTALLATION && (
                <>
                  <ActionButton
                    className="btn-primary me-2 pe-4 px-4"
                    onClick={() => updateStatus(SITE_STATUS.LAYOUT_IN_PROGRESS)}
                    text={messages.BTN_SITE_SETUP_EDIT_SITE}
                  />
                  <ActionButton
                    className="btn-primary me-2 pe-4 px-4"
                    onClick={downloadConfig}
                    disabled={!hasConfigPackage || isDownloading}
                    spinner={isDownloading}
                    text={messages.BTN_SITE_SETUP_DOWNLOAD_JSON}
                  />
                </>
              )}
              {siteConfiguration?.status !== SITE_STATUS.READY_FOR_INSTALLATION && (
                <div className="justify-content-center">
                  <ActionButton
                    className="btn-primary me-2 pe-4 px-4"
                    onClick={handleFinalApprove}
                    disabled={
                      (siteConfiguration?.status !== SITE_STATUS.PENDING_FINAL_APPROVAL &&
                        siteConfiguration?.status !== SITE_STATUS.CONFIGURATION_IN_PROGRESS) ||
                      approvalStatus?.disabled
                    }
                    text={messages.BTN_SITE_SETUP_APPROVE}
                  />
                  {approvalStatus?.disabled && (
                    <i
                      data-tooltip-id="approvalAlert"
                      data-tooltip-html={approvalStatus?.errMessage}
                      data-tooltip-class-name="text-start"
                      className="bi bi-exclamation-triangle-fill fs-5 p-0 m-0"
                      style={{ color: "red" }}
                    ></i>
                  )}
                  <ReactTooltip id="approvalAlert" place="top-end" />
                </div>
              )}
            </>
          ) : (
            <>
              {siteConfiguration?.status === SITE_STATUS.CONFIGURATION_IN_PROGRESS && (
                <ActionButton
                  className="btn-primary me-2 pe-4 px-4"
                  onClick={onFinalSubmit}
                  disabled={!submittable}
                  text={messages.BTN_SITE_SETUP_SUBMIT}
                />
              )}
            </>
          )}
        </Col>
      </Row>
      {activeStep === "" &&
        siteConfiguration?.status === SITE_STATUS.READY_FOR_INSTALLATION &&
        currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id && (
          <Row>
            <div className="info-block card text-center">
              <div className="card-body">
                <p className="card-text mb-0 fs-5 p-4 text-primary">
                  {stringFormat(messages.MSG_SITE_SETUP_CONTACT_ADMIN, [
                    site?.siteUniqueId.toString() ?? ""
                  ])}
                </p>
                <div className="d-flex justify-content-center">
                  <ActionButton
                    className="btn-primary me-2 pe-4 px-4"
                    onClick={downloadConfig}
                    disabled={!hasConfigPackage || isDownloading}
                    text={messages.BTN_SITE_SETUP_DOWNLOAD_JSON}
                    spinner={isDownloading}
                  />
                </div>
              </div>
            </div>
          </Row>
        )}
      {alertProps.modal && <AlertModal {...alertModalProps} />}
      {submitReviewModalProps.isShow && (
        <ConfirmationModal
          size="md"
          modal={true}
          cancelButtonText={messages.BTN_SITE_SETUP_BACK}
          okButtonText={submitReviewModalProps.okButtonText}
          content={submitReviewModalProps.content}
          toggle={() => {
            setSubmitReviewModalProps({
              ...submitReviewModalProps,
              ...{ isShow: false }
            })
          }}
          onOK={() => {
            updateStatus(SITE_STATUS.PENDING_FINAL_APPROVAL)
          }}
        />
      )}
    </>
  )
}

export default StepReviewSubmit
