import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"

import {
  getSelectedTeraStors,
  setIsFetchingCharts
} from "../../../store/counter/visualizationSlice"

import { SiteTeraStorData } from "../../../interfaces/monitoring/maxnerva/SiteTeraStorData"

import Messages from "../../../utils/messages"
import { AppDispatch } from "store/store"

interface TerastorDropdownProps {
  tsId?: number
  setTsId?: (tsId?: number) => void
}
const TerastorDropdown = (props: TerastorDropdownProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const selectedTeraStors: SiteTeraStorData[] = useSelector(getSelectedTeraStors)
  const [selectTerastorToggle, setSelectTerastorToggle] = useState(false)

  const handleSetCurrentTerastorToggle = () => setSelectTerastorToggle(!selectTerastorToggle)
  const handleSetCurrentTerastor = (tsId: number) => {
    props.setTsId && props.setTsId(tsId)
    dispatch(setIsFetchingCharts(true))
  }

  return (
    <Dropdown
      isOpen={selectTerastorToggle}
      toggle={handleSetCurrentTerastorToggle}
      direction="down"
      id="drpSample"
    >
      <DropdownToggle
        caret
        className="fw-bold py-2 px-3 text-break text-wrap me-0 me-md-3 me-xl-0 mb-3 mb-md-0"
      >
        {Messages.LBL_VISUALIZATION_TERASTOR} #{props.tsId}
      </DropdownToggle>
      <DropdownMenu>
        {selectedTeraStors.map((teraStor: SiteTeraStorData, index: number) => (
          <DropdownItem key={index} onClick={() => handleSetCurrentTerastor(teraStor.tsId)}>
            {Messages.LBL_VISUALIZATION_TERASTOR} #{teraStor.tsId}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default TerastorDropdown
