import jsPDF from "jspdf"
import html2canvas from "html2canvas"

import { DropDown } from "../../interfaces/visualization/Dropdown"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { ChartData } from "../../interfaces/visualization/ChartData"

import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"
import { DataMode } from "../../enum/visualization/data-mode"

import Messages from "../messages"
import { getVisuaTooltipText, getVisuaUnit, _isShowDataLine } from "../utils"
import { PoppinsBold } from "../fonts-base64/Poppins-Bold"
import { PoppinsRegular } from "../fonts-base64/Poppins-Regular"
import { LogoBase64 } from "../fonts-base64/logo"

const centerX = (pageWidth: number, textWidth: number) => (pageWidth - textWidth) / 2
enum FontWeight {
  Normal = "normal",
  Bold = "bold"
}
const writeText = (
  pdf: jsPDF,
  text: string,
  fontSize: number,
  fontWeight: FontWeight,
  fontColor: string | number[],
  x: number,
  y: number
) => {
  pdf.setFont("Poppins", fontWeight)
  pdf.setFontSize(fontSize)
  if (typeof fontColor === "string") pdf.setTextColor(fontColor)
  else pdf.setTextColor(fontColor[0], fontColor[1], fontColor[2])
  pdf.text(text, x, y)
}
const drawTextBox = (
  pdf: jsPDF,
  text: string,
  fontSize: number,
  fontWeight: FontWeight,
  fontColor: string | number[],
  textWidth: number,
  textX: number,
  textY: number,
  boxBackgroundColor: string,
  boxBorderColor: string,
  boxWidth: number,
  boxHeight: number,
  boxBorderRadius: number
) => {
  // Draw text box with background and border radius
  pdf.setFillColor(boxBackgroundColor)
  pdf.setDrawColor(boxBorderColor)
  pdf.roundedRect(textX, textY, boxWidth, boxHeight, boxBorderRadius, boxBorderRadius, "FD")

  // write text in box
  writeText(pdf, text, fontSize, fontWeight, fontColor, textX + 1, textY + 2.7)
}
export const exportPdfFile = async (
  filter: VisualizationFilterEnum,
  fileName: string,
  selectedTeraStors: SiteTeraStorData[],
  badgeRef: HTMLElement | null,
  chartRef: HTMLElement | null,
  tsId?: number,
  isBlob?: boolean
) => {
  const pdf = new jsPDF()

  // Get the page dimensions
  const pageWidth = pdf.internal.pageSize.getWidth()

  // Add custom font (including bold style if available)
  pdf.addFileToVFS("Poppins-Regular.ttf", PoppinsRegular)
  pdf.addFont("Poppins-Regular.ttf", "Poppins", "normal")
  pdf.addFileToVFS("Poppins-Bold.ttf", PoppinsBold)
  pdf.addFont("Poppins-Bold.ttf", "Poppins", "bold") // Ensure the bold font is added

  // logo
  pdf.addImage(LogoBase64, "PNG", centerX(pageWidth, 40) - 3, 10, 40, 11)

  // title
  const text = tsId ? `${Messages.LBL_VISUALIZATION_TERASTOR} #${tsId}` : "Aggregated"
  writeText(
    pdf,
    text,
    6,
    FontWeight.Bold,
    [30, 70, 121],
    centerX(pageWidth, pdf.getTextWidth(text)) + 6,
    32
  )

  // badge
  if (badgeRef) {
    let canvas: HTMLCanvasElement | null = await html2canvas(badgeRef as HTMLElement)
    let imgData: string | null = canvas.toDataURL("image/png")

    pdf.addImage(imgData, "PNG", 15, 25, filter === VisualizationFilterEnum.Battery ? 45 : 55, 16)

    // Clean up
    canvas.remove()
    canvas = null
    imgData = null
  }

  // aggregated list teratstors
  let teraStorLineCount = 0
  const BOX_HEIGHT = 4
  const BOX_LINE_DISTANCE = BOX_HEIGHT + 1.5
  const AGGREGATED_X = 18
  const AGGREGATED_Y = 50

  if (!tsId) {
    writeText(
      pdf,
      `${Messages.LBL_VISUALIZATION_SHOWING_TERASTOR}:`,
      6,
      FontWeight.Bold,
      [0, 0, 0],
      AGGREGATED_X,
      AGGREGATED_Y
    )

    // list terastors
    const BOX_EXTEND_WIDTH = 2
    const BOX_GAP_WIDTH = 3
    const BOX_TO_BOX_DISTANCE = BOX_EXTEND_WIDTH + BOX_GAP_WIDTH
    const teraStorListY = AGGREGATED_Y + 5
    let lineWidth = 0
    let boxIndex = 0
    selectedTeraStors.forEach((teraStor: SiteTeraStorData) => {
      const text = `Terastor #${teraStor.tsId}`
      const textWidth = pdf.getTextWidth(text)

      if (
        (boxIndex > 0 && boxIndex % 10 === 0) ||
        lineWidth + textWidth + BOX_TO_BOX_DISTANCE > pageWidth - 32
      ) {
        teraStorLineCount++
        lineWidth = 0
        boxIndex = 0
      }

      const x = AGGREGATED_X - BOX_TO_BOX_DISTANCE + lineWidth + BOX_TO_BOX_DISTANCE // have no idea why we need to - BOX_TO_BOX_DISTANCE, it only works with that =))
      const y = teraStorListY + teraStorLineCount * BOX_LINE_DISTANCE

      drawTextBox(
        pdf,
        text,
        6,
        FontWeight.Normal,
        "#1E4679",
        textWidth,
        x,
        y,
        "#D7E2F0",
        "#D7E2F0",
        textWidth + BOX_EXTEND_WIDTH,
        BOX_HEIGHT,
        0.7
      )

      lineWidth += textWidth + BOX_TO_BOX_DISTANCE
      boxIndex++
    })
  }

  // chart
  let chartImgHeight = 0
  const chartY = !tsId ? AGGREGATED_Y + 20 + teraStorLineCount * BOX_LINE_DISTANCE : 45
  if (chartRef) {
    let canvas: HTMLCanvasElement | null = await html2canvas(chartRef as HTMLElement)
    let imgData: string | null = canvas.toDataURL("image/png")

    const imgWidth = pageWidth
    chartImgHeight = (canvas.height * imgWidth) / canvas.width

    pdf.addImage(imgData, "PNG", 0, chartY, imgWidth, chartImgHeight)

    // Clean up
    canvas.remove()
    canvas = null
    imgData = null
  }

  // footer note
  const footerNote = `${Messages.LBL_FOOTER_NOTE} © ${new Date().getFullYear()}`
  writeText(
    pdf,
    footerNote,
    5,
    FontWeight.Normal,
    [0, 0, 0],
    centerX(pageWidth, pdf.getTextWidth(footerNote)),
    chartY + chartImgHeight + 10
  )

  // save file
  if (isBlob) return pdf.output("blob")
  else pdf.save(`${fileName}.pdf`)
}

const csvHeaderText = (
  filter: VisualizationFilterEnum,
  dataKey: BatterFilterEnum | LoadFilterEnum,
  dataValue: BatterFilterDataEnum | LoadFilterDataEnum
) => `${getVisuaTooltipText(filter, dataKey, dataValue)} ${getVisuaUnit(dataKey)}`

const writeValue = (val: number | null | undefined) =>
  val === null || val === undefined ? "" : val
export const exportCSVFile = (
  tsId: number | null | undefined,
  filter: VisualizationFilterEnum,
  filters: DropDown[],
  chartData: ChartData[],
  viewMode: DataMode
) => {
  const data: string[][] = []
  // header
  data.push([
    tsId ? `TeraStor #${tsId}` : "Aggregated",

    ...(_isShowDataLine(filters, BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)
      ? [csvHeaderText(filter, BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)]
      : []),
    ...(_isShowDataLine(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Maximum)
      ? [csvHeaderText(filter, BatterFilterEnum.Temperature, BatterFilterDataEnum.Maximum)]
      : []),
    ...(_isShowDataLine(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Average)
      ? [csvHeaderText(filter, BatterFilterEnum.Temperature, BatterFilterDataEnum.Average)]
      : []),
    ...(_isShowDataLine(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Minimum)
      ? [csvHeaderText(filter, BatterFilterEnum.Temperature, BatterFilterDataEnum.Minimum)]
      : []),

    ...(_isShowDataLine(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Maximum)
      ? [csvHeaderText(filter, BatterFilterEnum.Voltage, BatterFilterDataEnum.Maximum)]
      : []),
    ...(_isShowDataLine(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Average)
      ? [csvHeaderText(filter, BatterFilterEnum.Voltage, BatterFilterDataEnum.Average)]
      : []),
    ...(_isShowDataLine(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Minimum)
      ? [csvHeaderText(filter, BatterFilterEnum.Voltage, BatterFilterDataEnum.Minimum)]
      : []),

    ...(_isShowDataLine(filters, LoadFilterEnum.Current, LoadFilterDataEnum.IA)
      ? [csvHeaderText(filter, LoadFilterEnum.Current, LoadFilterDataEnum.IA)]
      : []),
    ...(_isShowDataLine(filters, LoadFilterEnum.Current, LoadFilterDataEnum.IB)
      ? [csvHeaderText(filter, LoadFilterEnum.Current, LoadFilterDataEnum.IB)]
      : []),
    ...(_isShowDataLine(filters, LoadFilterEnum.Current, LoadFilterDataEnum.IC)
      ? [csvHeaderText(filter, LoadFilterEnum.Current, LoadFilterDataEnum.IC)]
      : []),

    ...(_isShowDataLine(filters, LoadFilterEnum.Voltage, LoadFilterDataEnum.VAB)
      ? [csvHeaderText(filter, LoadFilterEnum.Voltage, LoadFilterDataEnum.VAB)]
      : []),
    ...(_isShowDataLine(filters, LoadFilterEnum.Voltage, LoadFilterDataEnum.VBC)
      ? [csvHeaderText(filter, LoadFilterEnum.Voltage, LoadFilterDataEnum.VBC)]
      : []),
    ...(_isShowDataLine(filters, LoadFilterEnum.Voltage, LoadFilterDataEnum.VCA)
      ? [csvHeaderText(filter, LoadFilterEnum.Voltage, LoadFilterDataEnum.VCA)]
      : []),

    ...(_isShowDataLine(filters, LoadFilterEnum.RealPower, LoadFilterDataEnum.Commanded)
      ? [csvHeaderText(filter, LoadFilterEnum.RealPower, LoadFilterDataEnum.Commanded)]
      : []),
    ...(_isShowDataLine(filters, LoadFilterEnum.RealPower, LoadFilterDataEnum.Actual)
      ? [csvHeaderText(filter, LoadFilterEnum.RealPower, LoadFilterDataEnum.Actual)]
      : []),

    ...(_isShowDataLine(filters, LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Commanded)
      ? [csvHeaderText(filter, LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Commanded)]
      : []),
    ...(_isShowDataLine(filters, LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Actual)
      ? [csvHeaderText(filter, LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Actual)]
      : [])
  ])

  // body
  chartData.forEach((item: ChartData) => {
    data.push([
      viewMode === DataMode.Sample ? item.timestamp : item.timestamp.slice(0, 16),

      ...(_isShowDataLine(filters, BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)
        ? [`${writeValue(item.soc)}`]
        : []),
      ...(_isShowDataLine(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Maximum)
        ? [`${writeValue(item.maxTemp)}`]
        : []),
      ...(_isShowDataLine(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Average)
        ? [`${writeValue(item.avgTemp)}`]
        : []),
      ...(_isShowDataLine(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Minimum)
        ? [`${writeValue(item.minTemp)}`]
        : []),

      ...(_isShowDataLine(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Maximum)
        ? [`${writeValue(item.maxVoltage)}`]
        : []),
      ...(_isShowDataLine(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Average)
        ? [`${writeValue(item.avgVoltage)}`]
        : []),
      ...(_isShowDataLine(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Minimum)
        ? [`${writeValue(item.minVoltage)}`]
        : []),

      ...(_isShowDataLine(filters, LoadFilterEnum.Current, LoadFilterDataEnum.IA)
        ? [`${writeValue(item.ia)}`]
        : []),
      ...(_isShowDataLine(filters, LoadFilterEnum.Current, LoadFilterDataEnum.IB)
        ? [`${writeValue(item.ib)}`]
        : []),
      ...(_isShowDataLine(filters, LoadFilterEnum.Current, LoadFilterDataEnum.IC)
        ? [`${writeValue(item.ic)}`]
        : []),

      ...(_isShowDataLine(filters, LoadFilterEnum.Voltage, LoadFilterDataEnum.VAB)
        ? [`${writeValue(item.vab)}`]
        : []),
      ...(_isShowDataLine(filters, LoadFilterEnum.Voltage, LoadFilterDataEnum.VBC)
        ? [`${writeValue(item.vbc)}`]
        : []),
      ...(_isShowDataLine(filters, LoadFilterEnum.Voltage, LoadFilterDataEnum.VCA)
        ? [`${writeValue(item.vca)}`]
        : []),

      ...(_isShowDataLine(filters, LoadFilterEnum.RealPower, LoadFilterDataEnum.Commanded)
        ? [`${writeValue(item.commandRealPower)}`]
        : []),
      ...(_isShowDataLine(filters, LoadFilterEnum.RealPower, LoadFilterDataEnum.Actual)
        ? [`${writeValue(item.actualRealPower)}`]
        : []),

      ...(_isShowDataLine(filters, LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Commanded)
        ? [`${writeValue(item.commandReactivePower)}`]
        : []),
      ...(_isShowDataLine(filters, LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Actual)
        ? [`${writeValue(item.actualReactivePower)}`]
        : [])
    ])
  })

  return data
}
