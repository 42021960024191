import { useState, useEffect } from "react"
import { Nav, NavItem } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Messages from "../../utils/messages"
import Select from "react-select"

import allDocument from "../../assets/images/icons/document/category/all-documents.svg"
import legalWarrantlyDrawings from "../../assets/images/icons/document/category/legal-warrantly-drawings.svg"
import legalWarrantlyDrawingsActive from "../../assets/images/icons/document/category/legal-warrantly-drawings-active.svg"
import referenceDrawings from "../../assets/images/icons/document/category/reference-drawings.svg"
import referenceDrawingsActive from "../../assets/images/icons/document/category/reference-drawings-active.svg"
import siteEngineeringDrawings from "../../assets/images/icons/document/category/site-engineering-drawings.svg"
import siteEngineeringDrawingsActive from "../../assets/images/icons/document/category/site-engineering-drawings-active.svg"
import schedules from "../../assets/images/icons/document/category/schedules.svg"
import schedulesActive from "../../assets/images/icons/document/category/schedules-active.svg"
import userManualTroubleshootingGuides from "../../assets/images/icons/document/category/user-manual-troubleshooting-guides.svg"
import userManualTroubleshootingGuidesActive from "../../assets/images/icons/document/category/user-manual-troubleshooting-guides-active.svg"
import { getSidebarOpen } from "store/counter/authSlice"

// Components
import Loader from "components/Loader"
import { SiteEntity } from "interfaces/SiteEntity"
import { apiAdminListAllSites } from "services/apiSitesManagement"
import {
  fetchDocCategory,
  getCategoryList,
  setSelectedSites,
  setSelectedCategory,
  getSelectedCategory
} from "store/counter/docSlice"
import { DocCategory, DocCategoryEntity } from "interfaces/doc/DocCategory"
import { AppDispatch } from "store/store"
import { SelectOptions } from "interfaces/SelectOptions"

const DocumentCategorySideBar = () => {
  const dispatch = useDispatch<AppDispatch>()
  const sideBarOpen = useSelector(getSidebarOpen)
  const categoryList = useSelector(getCategoryList)
  const selectedCategory = useSelector(getSelectedCategory)

  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [listSites, setListSites] = useState<SiteEntity[] | null>(null)
  const [categories, setCategories] = useState<DocCategory[] | null>(null)
  const [siteOptionList, setSiteOptionList] = useState<SelectOptions[]>([])
  const [selectedSiteOption, setSelectedSiteOption] = useState<SelectOptions>()

  // TODO: Find a way to map category icons to category names dynamically
  const categoryIconsMap: Map<string, string> = new Map<string, string>([
    ["all_documents", allDocument],
    ["legal_warranty_drawings", legalWarrantlyDrawings],
    ["legal_warranty_drawings_active", legalWarrantlyDrawingsActive],
    ["reference_drawings", referenceDrawings],
    ["reference_drawings_active", referenceDrawingsActive],
    ["site_engineering_drawings", siteEngineeringDrawings],
    ["site_engineering_drawings_active", siteEngineeringDrawingsActive],
    ["schedules", schedules],
    ["schedules_active", schedulesActive],
    ["user_manual_troubleshooting_guides", userManualTroubleshootingGuides],
    ["user_manual_troubleshooting_guides_active", userManualTroubleshootingGuidesActive]
  ])

  /**
   * Fetching list assign site
   */
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    try {
      setLoading(true)
      await Promise.all([dispatch(fetchDocCategory()), fetchSitesData()])
    } catch (error) {
      console.error("Error fetching data:", error)
      // Handle error if needed
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (categoryList) {
      const docCategories: DocCategory[] = [
        {
          id: 0,
          code: "all_documents",
          name: "All documents",
          createdAt: "",
          updatedAt: "",
          deleted: false,
          icon: getCategoryIcon("all_documents", false)
        },
        ...categoryList.map((category: DocCategoryEntity) => ({
          ...category,
          icon: getCategoryIcon(category.code, false)
        }))
      ]

      setCategories(docCategories)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList])

  const fetchSitesData = async () => {
    const res = await apiAdminListAllSites()
    if (res.status) {
      const sites: SiteEntity[] = res.data ?? []
      setListSites(sites)
      const siteOptions = [
        { value: 0, label: "All Sites" },
        ...sites.map((site) => ({
          value: site.siteUniqueId,
          label: site.siteName
        }))
      ]

      if (siteOptions) {
        setSiteOptionList(siteOptions)
      }

      setError("")
    } else {
      setError(res.message || "")
    }
  }

  useEffect(() => {
    if (
      listSites &&
      !selectedSiteOption &&
      listSites.length > 0 &&
      siteOptionList &&
      siteOptionList.length > 0
    ) {
      setSelectedSiteOption(siteOptionList[0])
      dispatch(setSelectedSites(listSites))
    }
  }, [dispatch, listSites, siteOptionList, selectedSiteOption])

  const getCategoryIcon = (categoryName: string, isActive: boolean) => {
    let key = categoryName
    if (categoryName !== "all_documents") {
      key = isActive ? categoryName + "_active" : categoryName
    }
    return categoryIconsMap.get(key)
  }

  const onClickCategoryHandle = (category: DocCategory) => {
    if (category.code === "all_documents") {
      dispatch(setSelectedCategory(undefined))
    } else {
      dispatch(setSelectedCategory(category))
    }
  }

  const isSelectedCategory = (category: DocCategory) => {
    return category === selectedCategory || (category.code === "all_documents" && !selectedCategory)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (selectedOption: SelectOptions | null) => {
    if (selectedOption) {
      setSelectedSiteOption(selectedOption)
      if (selectedOption.value === 0) {
        dispatch(setSelectedSites(listSites))
      } else {
        const selectedSiteList = listSites?.find(
          (site) => site.siteUniqueId === selectedOption.value
        )
        dispatch(setSelectedSites([selectedSiteList]))
      }
    }
  }

  return (
    <div id="documentManagerSidebar" style={{ display: `${sideBarOpen ? "block" : "none"}` }}>
      {isLoading ? (
        <Loader status="loading" message={Messages.LBL_LOADING_CATEGORIES} />
      ) : error ? (
        <Loader status="error" message={error} />
      ) : (
        <>
          <Select
            id="selSiteDocument"
            className="p-1"
            styles={{ singleValue: (styles) => ({ ...styles, color: "#1F4679" }) }}
            value={selectedSiteOption}
            onChange={handleSelectChange}
            isMulti={false}
            options={siteOptionList}
            tabIndex={9}
            placeholder={isLoading ? "Loading sites..." : "Select site"}
          />
          <Nav>
            {categories?.map((category, index) => (
              <NavItem
                className={`${isSelectedCategory(category) ? "active" : ""}
                  ${category.code === "all_documents" ? "ms-1" : "ms-4"}`}
                key={index}
                onClick={() => onClickCategoryHandle(category)}
              >
                <img
                  src={getCategoryIcon(category.code, category === selectedCategory)}
                  alt={`${category.id}`}
                ></img>
                <span className="ms-2 mt-1">{category.name}</span>
              </NavItem>
            ))}
          </Nav>
        </>
      )}
    </div>
  )
}

export default DocumentCategorySideBar
