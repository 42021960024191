import React from "react"
import { Button, Modal, ModalBody, Row, Col } from "reactstrap"
import warning from "../../assets/images/configuration/warning-light.svg"

const ErrorModal: React.FC<{ isOpen: boolean; content: string; onClose: () => void }> = ({
  isOpen,
  content,
  onClose
}) => {
  if (!isOpen) return null

  return (
    <Modal className="modal-overlay" isOpen={isOpen} toggle={onClose} centered={true}>
      <ModalBody className="bg-white rounded p-3 pt-5">
        <Row>
          <Col md="2">
            <img src={warning} alt="icon"></img>
          </Col>
          <Col md="10">
            <p>{content}</p>
            <br></br>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "#F1F1F1",
            marginBottom: "-15px",
            padding: "15px"
          }}
        >
          <Col className="d-flex justify-content-end">
            <Button className="modal-btn fw-bold" onClick={onClose}>
              OK
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ErrorModal
