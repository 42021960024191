import { useEffect, useState } from "react"
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { useDispatch, useSelector } from "react-redux"
import Moment from "moment"

import {
  getDataMode,
  getDateRange,
  getFetchingChartsStatus,
  getFilters,
  getFromDate,
  getHourDate,
  getLoadUnit,
  getSelectedTeraStors,
  getSiteEntity,
  getToDate,
  getViewBy,
  setChartData
} from "../../store/counter/visualizationSlice"
import { AppDispatch } from "../../store/store"

import CustomTooltip from "./CustomTooltip"
import YAxisRightLabel from "./labels/YAxisRightLabel"
import YAxisLeftLabel from "./labels/YAxisLeftLabel"
import XAxisTick from "./labels/XAxisTick"
import Loader from "components/Loader"

import { apiMonitoringReport } from "../../services/apiSiteMonitoring"

import { DropDown } from "../../interfaces/visualization/Dropdown"
import { ChartActiveData } from "../../interfaces/visualization/ChartActiveData"
import { VisualizationChartData } from "../../interfaces/visualization/VisualizationChartData"
import { ChartData } from "../../interfaces/visualization/ChartData"
import { ReportApiBody } from "../../interfaces/visualization/ReportApiBody"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { AxisEdgeValues } from "../../interfaces/visualization/AxisEdgeValues"

import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { VisualizationDateRangeEnum } from "../../enum/visualization/tab/daterange-tab"
import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"
import { LineOpacityEnum } from "../../enum/visualization/chart-line-opacity"
import { ChartDataEnum } from "../../enum/visualization/chart-data"
import { Color } from "../../enum/visualization/color"
import { DataMode } from "../../enum/visualization/data-mode"

import Messages from "../../utils/messages"
import {
  _isShowDataLine,
  isValidValue,
  writeCodeLogEvent,
  countDatesApart
} from "../../utils/utils"
import { VISUALIZATION_DATE_FORMAT, XAXIS_TYPE } from "../../utils/constants"
import {
  convertAPIDataToChartData,
  appendMissingData,
  getHourLength,
  extractFromTimestamp,
  reduceDataSampleMode,
  calculateTicks
} from "../../utils/visualization/chart-data"
import {
  axisRound,
  calAxisMaxValue,
  calAxisMinValue,
  calGap,
  getAxisEdgeValues
} from "../../utils/visualization/data-range"

export interface ConvertData2 {
  data: ChartData[]
  laxisMax: number
  laxisMin: number
  raxisMax: number
  raxisMin: number
}

interface ChartProps {
  toggle?: () => void
  openInModal?: boolean
  filters: DropDown[]
  setTooltipData?: (data: ChartActiveData) => void
  tsId?: number

  setChartData?: (data: ChartData[]) => void

  onChartLoaded?: () => void
}

const Chart = (props: ChartProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const siteEntity = useSelector(getSiteEntity)
  const selectedTeraStors = useSelector(getSelectedTeraStors)
  const dataMode = useSelector(getDataMode)
  const viewBy = useSelector(getViewBy)
  const dateRange = useSelector(getDateRange)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const hourDate = useSelector(getHourDate)
  const filters = useSelector(getFilters)
  const loadUnit = useSelector(getLoadUnit)
  const isFetchingCharts = useSelector(getFetchingChartsStatus)
  const isBattery = filters === VisualizationFilterEnum.Battery
  const isDateRange = dateRange === VisualizationDateRangeEnum.Date
  const isAggregatedView = viewBy === VisualizationViewByEnum.Aggregated
  const isCurrent = loadUnit === LoadFilterEnum.Current
  const [xAxisType, setXAxisType] = useState<string>(XAXIS_TYPE.CATEGORY)
  const isSample = dataMode === DataMode.Sample
  const curOrVolColor = isCurrent ? Color.Temperature : Color.Voltage

  const [apiData, setApiData] = useState<VisualizationChartData[]>([])
  const [noData, setNoData] = useState(false)
  const [isLoading, setLoading] = useState(false)

  // fetching report data
  useEffect(() => {
    if (isFetchingCharts || props.openInModal) {
      setData(initData)
      setLoading(true)
    }
  }, [isFetchingCharts, props.openInModal])

  /**
   * Update no data status
   * @param {VisualizationChartData[]} resData api response data
   * @param {ChartData[]} tempData data after adding missing data
   * @returns {any}
   */
  const updateNoDataStatus = (resData: VisualizationChartData[], tempData: ChartData[]) => {
    // check if no display data
    if (resData.length <= 0) setNoData(true)
    else {
      const temp = tempData.filter(
        (data: ChartData) =>
          !isValidValue(data.soc) &&
          !isValidValue(data.maxTemp) &&
          !isValidValue(data.avgTemp) &&
          !isValidValue(data.minTemp) &&
          !isValidValue(data.maxVoltage) &&
          !isValidValue(data.avgTemp) &&
          !isValidValue(data.minTemp) &&
          !isValidValue(data.ia) &&
          !isValidValue(data.ib) &&
          !isValidValue(data.ic) &&
          !isValidValue(data.vab) &&
          !isValidValue(data.vbc) &&
          !isValidValue(data.vca) &&
          !isValidValue(data.commandReactivePower) &&
          !isValidValue(data.actualReactivePower) &&
          !isValidValue(data.commandRealPower) &&
          !isValidValue(data.actualRealPower)
      )

      if (temp.length === tempData.length) setNoData(true)
      else setNoData(false)
    }
  }

  /**
   * Compare maximum value from api to default maximum value
   * @param {number|null} newVal maximum value from api
   * @param {number} defaultVal default maximum value
   * @returns {number} bigger value
   */
  const getMaxEdgeValue = (newVal: number | null, defaultVal: number): number =>
    newVal !== null ? newVal : defaultVal

  /**
   * Compare minimum value from api to default minium value
   * @param {number|null} newVal minium value from api
   * @param {number} defaultVal default minium value
   * @returns {number} smaller value
   */
  const getMinEdgeValue = (newVal: number | null, defaultVal: number): number =>
    newVal !== null ? newVal : defaultVal

  /**
   * Update chart data
   * @param {ChartData[]} tempData data after adding missing data
   * @returns {void}
   */
  const updateChartData = (tempData: ChartData[]) => {
    const edgeValues: AxisEdgeValues = getAxisEdgeValues(tempData, isBattery, isCurrent)
    const ROWS = 5
    const DEFAULT_GAP = 1
    const VOLTAGE_DEFAULT_GAP = 0.25

    // left axis
    const leftDefaultMax = isBattery
      ? BATTERY_LAXIS_MAX_VALUE
      : isCurrent
        ? LOAD_LAXIS_MAX_VALUE_CURRENT
        : LOAD_LAXIS_MAX_VALUE
    const leftDefaultMin = isBattery
      ? BATTERY_LAXIS_MIN_VALUE
      : isCurrent
        ? LOAD_LAXIS_MIN_VALUE_CURRENT
        : LOAD_LAXIS_MIN_VALUE
    let leftGap = calGap(
      getMaxEdgeValue(edgeValues.laxisMax, leftDefaultMax),
      getMinEdgeValue(edgeValues.laxisMin, leftDefaultMin),
      ROWS
    )
    leftGap = leftGap < DEFAULT_GAP ? DEFAULT_GAP : leftGap
    const laxisMax = calAxisMaxValue(edgeValues.laxisMax, leftGap, leftDefaultMax)
    const laxisMin = calAxisMinValue(edgeValues.laxisMin, leftGap, leftDefaultMin)

    // right axis
    const rightDefaultMax = isBattery ? BATTERY_RAXIS_MAX_VALUE : LOAD_RAXIS_MAX_VALUE
    const rightDefaultMin = isBattery ? BATTERY_RAXIS_MIN_VALUE : LOAD_RAXIS_MIN_VALUE
    let rightGap = calGap(
      getMaxEdgeValue(edgeValues.raxisMax, rightDefaultMax),
      getMinEdgeValue(edgeValues.raxisMin, rightDefaultMin),
      ROWS,
      isBattery ? false : true
    )
    rightGap =
      rightGap < (isBattery ? VOLTAGE_DEFAULT_GAP : DEFAULT_GAP)
        ? isBattery
          ? VOLTAGE_DEFAULT_GAP
          : DEFAULT_GAP
        : rightGap
    const raxisMax = calAxisMaxValue(
      edgeValues.raxisMax,
      rightGap,
      rightDefaultMax,
      isBattery ? false : true
    )
    const raxisMin = calAxisMinValue(
      edgeValues.raxisMin,
      rightGap,
      rightDefaultMin,
      isBattery ? false : true
    )

    setData({
      data: tempData,
      laxisMax,
      laxisMin,
      raxisMax: isBattery ? parseFloat(raxisMax.toFixed(3)) : axisRound(raxisMax),
      raxisMin: isBattery ? parseFloat(raxisMin.toFixed(3)) : axisRound(raxisMin)
    })
    dispatch(setChartData({ tsId: props.tsId, data: tempData }))

    if (props.setChartData) props.setChartData(tempData)
  }
  useEffect(() => {
    // Issue: when change Mode: (Average, Aggregated) => (Sample, Aggregated)
    // Delay set XAxisType when load data complete
    setXAxisType(isSample ? XAXIS_TYPE.NUMBER : XAXIS_TYPE.CATEGORY)
  }, [isSample])

  useEffect(() => {
    if (siteEntity && isLoading) {
      const reportType: VisualizationFilterEnum = filters
      const siteId: number = siteEntity.siteUniqueId
      const listIds: number[] = selectedTeraStors.map((item: SiteTeraStorData) => item.tsId)
      const body: ReportApiBody = {
        selectedTsIDs: isAggregatedView ? listIds : [props.tsId || 0],
        ...(isDateRange && {
          dateRange: {
            start: fromDate,
            end: toDate
          }
        }),
        ...(!isDateRange && {
          hourRange: {
            date: hourDate
          }
        }),
        ...(!isDateRange && {
          mode: dataMode
        })
      }

      const callbackUpdate = () => {
        setLoading(false)
        if (props.onChartLoaded) {
          props.onChartLoaded()
        }
      }

      apiMonitoringReport(reportType, siteId, body)
        .then((res) => {
          let resData = res.status ? (res.data as VisualizationChartData[]) : []
          if (dataMode === DataMode.Sample) {
            resData = reduceDataSampleMode(resData, body.selectedTsIDs)
          }
          setApiData(resData)

          let tempData: ChartData[] = convertAPIDataToChartData(
            resData,
            viewBy,
            isBattery,
            isCurrent,
            isSample
          )

          // matching to the display data
          if (dataMode === DataMode.Average) {
            tempData = appendMissingData(
              tempData,
              resData,
              dateRange,
              hourDate,
              fromDate,
              toDate,
              isBattery,
              isCurrent,
              isSample,
              isAggregatedView
            )
          } else if (isAggregatedView && selectedTeraStors.length > 1) {
            tempData = tempData.sort((a: ChartData, b: ChartData) => {
              return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            })
          }

          // check if no display data
          updateNoDataStatus(resData, tempData)

          // update chart data
          updateChartData(tempData)

          callbackUpdate()
        })
        .catch((e) => {
          console.error("Code exception: handle get visualization report =>", e.message)
          callbackUpdate()

          writeCodeLogEvent("handle get visualization report", e, siteEntity?.siteUniqueId)
        })
    }
  }, [isLoading])

  const BATTERY_LAXIS_MAX_VALUE = 60
  const BATTERY_LAXIS_MIN_VALUE = 0
  const BATTERY_RAXIS_MAX_VALUE = 4
  const BATTERY_RAXIS_MIN_VALUE = 0
  const BATTERY_YAXIS_TICK_COUNT = 5

  const LOAD_LAXIS_MAX_VALUE = 2400
  const LOAD_LAXIS_MIN_VALUE = 0
  const LOAD_LAXIS_MAX_VALUE_CURRENT = 1600
  const LOAD_LAXIS_MIN_VALUE_CURRENT = -1700
  const LOAD_RAXIS_MAX_VALUE = 4000
  const LOAD_RAXIS_MIN_VALUE = -4000
  const LOAD_YAXIS_TICK_COUNT = 9

  const initData: ConvertData2 = {
    data: [],
    laxisMax: isBattery ? BATTERY_LAXIS_MAX_VALUE : LOAD_LAXIS_MAX_VALUE_CURRENT,
    laxisMin: isBattery ? BATTERY_LAXIS_MIN_VALUE : LOAD_LAXIS_MIN_VALUE_CURRENT,
    raxisMax: isBattery ? BATTERY_RAXIS_MAX_VALUE : LOAD_RAXIS_MAX_VALUE,
    raxisMin: isBattery ? BATTERY_RAXIS_MIN_VALUE : LOAD_RAXIS_MIN_VALUE
  }
  const [data, setData] = useState<ConvertData2>(initData)

  const axisLineColor = "#E5E5EF"
  const axisTickColor = "#615E83"

  const leftYAxisId = "temp-axis"
  const rightYAxisId = "volt-axis"

  const fontSizeSmall = "0.75rem"
  const fontSizeLarge = "0.9rem"
  const displayInSmallFontSize = !(
    viewBy === VisualizationViewByEnum.Aggregated || props.openInModal
  )

  const dotStyle = (color: Color) => {
    return {
      stroke: color,
      strokeWidth: 1,
      fill: color,
      r: 2,
      strokeDasharray: ""
    }
  }
  const isShowDataLine = (
    dataKey: BatterFilterEnum | LoadFilterEnum,
    dataValue: BatterFilterDataEnum | LoadFilterDataEnum
  ) => {
    return _isShowDataLine(props.filters, dataKey, dataValue)
  }
  const isShowCurrVoltDataLine = (
    currDataKey: BatterFilterEnum | LoadFilterEnum,
    currDataValue: BatterFilterDataEnum | LoadFilterDataEnum,
    voltDataKey: BatterFilterEnum | LoadFilterEnum,
    voltDataValue: BatterFilterDataEnum | LoadFilterDataEnum
  ) => {
    return (
      (isCurrent && isShowDataLine(currDataKey, currDataValue)) ||
      (!isCurrent && isShowDataLine(voltDataKey, voltDataValue))
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChartClick = (tdata: any) => {
    const activeTooltipIndex = tdata.activeTooltipIndex
    const activePayload = tdata.activePayload
    const selectedData = data.data[activeTooltipIndex]
    if (
      props.setTooltipData &&
      props.toggle &&
      activeTooltipIndex !== undefined &&
      activeTooltipIndex !== null &&
      selectedData &&
      tdata.activePayload &&
      tdata.activeLabel
    ) {
      const dateCount = countDatesApart(fromDate, toDate)
      const hours = getHourLength(dateCount)

      const modalData = apiData.filter((item: VisualizationChartData) => {
        const extractDate = extractFromTimestamp(item.timestamp)

        return (
          extractDate.date === selectedData.date &&
          ((!isDateRange &&
            (isSample
              ? item.timestamp === selectedData.timestamp // sample mode get explicit timestamp
              : extractDate.hour === parseInt(`${selectedData.xAxis}`))) || // regular hour mode just compare hour
            (isDateRange && // date mode get date and hour range
              extractDate.hour >= parseInt(`${selectedData.xAxis}`) &&
              parseInt(`${extractDate.hour}`) <= parseInt(`${selectedData.xAxis}`) + hours))
        )
      })

      if (modalData.length > 0) {
        props.setTooltipData({
          activePayload,
          apiData: modalData,
          activeLabel: tdata.activeLabel
        })
        props.toggle()
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader status="loading" message="Preparing data..." />
      ) : (
        <ResponsiveContainer
          width="100%"
          height={
            viewBy === VisualizationViewByEnum.Aggregated || dataMode === DataMode.Sample
              ? 600
              : props.openInModal
                ? 600
                : 350
          }
          className="chart-wrapper px-5"
          id={`chart${props.tsId}`}
        >
          <LineChart
            data={data.data}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(tdata: any) => handleChartClick(tdata)}
            margin={{
              top: 60,
              right: 5,
              left: 10
            }}
          >
            <CartesianGrid
              vertical={false}
              strokeWidth={1}
              style={{ opacity: LineOpacityEnum.Min }}
              color={axisLineColor}
            />

            <XAxis
              type={xAxisType === XAXIS_TYPE.NUMBER ? XAXIS_TYPE.NUMBER : XAXIS_TYPE.CATEGORY}
              domain={xAxisType === XAXIS_TYPE.NUMBER ? ["dataMin", "dataMax"] : ["auto", "auto"]}
              ticks={xAxisType === XAXIS_TYPE.NUMBER ? calculateTicks(data.data) : undefined}
              dataKey={
                xAxisType === XAXIS_TYPE.NUMBER
                  ? ChartDataEnum.timestampNumber
                  : ChartDataEnum.timestamp
              }
              tickLine={false}
              axisLine={{ stroke: axisLineColor }}
              strokeWidth={1}
              tick={{ fill: axisTickColor }}
              tickFormatter={(tick: string) => XAxisTick(tick, dataMode)}
              {...(!isSample
                ? {
                    label: (
                      <Label
                        value={Messages.LBL_VISUALIZATION_HOURS}
                        textAnchor="right"
                        position="right"
                        style={{
                          fontSize: displayInSmallFontSize ? fontSizeSmall : fontSizeLarge,
                          fill: axisTickColor,
                          marginRight: "10px",
                          marginTop: "50px"
                        }}
                        dy={5}
                        dx={26}
                      />
                    )
                  }
                : {})}
              tickMargin={10}
            />
            <Tooltip
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              content={(tprops: any) => (
                <CustomTooltip
                  active={tprops.active}
                  payload={tprops.payload}
                  label={tprops.label}
                  filters={props.filters}
                />
              )}
              isAnimationActive={false}
            />
            <YAxis
              yAxisId={leftYAxisId}
              orientation="left"
              dataKey={ChartDataEnum.leftYaxis}
              axisLine={false}
              strokeWidth={2}
              tick={{ fill: axisTickColor, dx: 5 }}
              tickLine={false}
              tickCount={isBattery ? BATTERY_YAXIS_TICK_COUNT : LOAD_YAXIS_TICK_COUNT}
              domain={[data.laxisMin, data.laxisMax]}
              allowDataOverflow={true}
              tickMargin={15}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              label={(props: any) => (
                <YAxisLeftLabel
                  viewBox={props.viewBox}
                  displayInSmallFontSize={displayInSmallFontSize}
                  fontSizeSmall={fontSizeSmall}
                  fontSizeLarge={fontSizeLarge}
                />
              )}
            />
            <YAxis
              yAxisId={rightYAxisId}
              orientation="right"
              axisLine={false}
              strokeWidth={2}
              dataKey={ChartDataEnum.rightYAxis}
              tick={{ fill: axisTickColor }}
              tickLine={false}
              tickCount={isBattery ? BATTERY_YAXIS_TICK_COUNT : LOAD_YAXIS_TICK_COUNT}
              domain={[data.raxisMin, data.raxisMax]}
              allowDataOverflow={true}
              tickMargin={5}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              label={(props: any) => (
                <YAxisRightLabel
                  viewBox={props.viewBox}
                  displayInSmallFontSize={displayInSmallFontSize}
                  fontSizeSmall={fontSizeSmall}
                  fontSizeLarge={fontSizeLarge}
                />
              )}
            />

            {filters === VisualizationFilterEnum.Battery ? (
              <>
                {/* SOC */}
                {isShowDataLine(BatterFilterEnum.SOC, BatterFilterDataEnum.SOC) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.soc}
                    stroke={Color.ReactivePower}
                    activeDot={true}
                    dot={dotStyle(Color.ReactivePower)}
                    strokeWidth={2}
                    connectNulls
                    isAnimationActive={isSample ? false : true}
                  />
                )}

                {/* Tempeture */}
                {isShowDataLine(BatterFilterEnum.Temperature, BatterFilterDataEnum.Maximum) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.maxTemp}
                    stroke={Color.Temperature}
                    activeDot={true}
                    dot={dotStyle(Color.Temperature)}
                    strokeWidth={2}
                    connectNulls
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowDataLine(BatterFilterEnum.Temperature, BatterFilterDataEnum.Average) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.avgTemp}
                    stroke={Color.Temperature}
                    activeDot={true}
                    dot={dotStyle(Color.Temperature)}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Ave
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowDataLine(BatterFilterEnum.Temperature, BatterFilterDataEnum.Minimum) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.minTemp}
                    stroke={Color.Temperature}
                    activeDot={true}
                    dot={dotStyle(Color.Temperature)}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Min
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}

                {/* Voltage */}
                {isShowDataLine(BatterFilterEnum.Voltage, BatterFilterDataEnum.Maximum) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.maxVoltage}
                    dot={dotStyle(Color.Voltage)}
                    activeDot={true}
                    stroke={Color.Voltage}
                    strokeWidth={2}
                    connectNulls
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowDataLine(BatterFilterEnum.Voltage, BatterFilterDataEnum.Average) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.avgVoltage}
                    dot={dotStyle(Color.Voltage)}
                    activeDot={true}
                    stroke={Color.Voltage}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Ave
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowDataLine(BatterFilterEnum.Voltage, BatterFilterDataEnum.Minimum) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.minVoltage}
                    dot={dotStyle(Color.Voltage)}
                    activeDot={true}
                    stroke={Color.Voltage}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Min
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}
              </>
            ) : (
              <>
                {/* Real Power */}
                {isShowDataLine(LoadFilterEnum.RealPower, LoadFilterDataEnum.Commanded) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.commandRealPower}
                    dot={dotStyle(Color.RealPower)}
                    activeDot={true}
                    stroke={Color.RealPower}
                    strokeWidth={2}
                    connectNulls
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowDataLine(LoadFilterEnum.RealPower, LoadFilterDataEnum.Actual) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.actualRealPower}
                    dot={dotStyle(Color.RealPower)}
                    activeDot={true}
                    stroke={Color.RealPower}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Ave
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}

                {/* Reactive Power */}
                {isShowDataLine(LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Commanded) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.commandReactivePower}
                    dot={dotStyle(Color.ReactivePower)}
                    activeDot={true}
                    stroke={Color.ReactivePower}
                    strokeWidth={2}
                    connectNulls
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowDataLine(LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Actual) && (
                  <Line
                    yAxisId={rightYAxisId}
                    type="linear"
                    dataKey={ChartDataEnum.actualReactivePower}
                    dot={dotStyle(Color.ReactivePower)}
                    activeDot={true}
                    stroke={Color.ReactivePower}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Ave
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}

                {/* Voltage / Current */}
                {isShowCurrVoltDataLine(
                  LoadFilterEnum.Current,
                  LoadFilterDataEnum.IA,
                  LoadFilterEnum.Voltage,
                  LoadFilterDataEnum.VAB
                ) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={isCurrent ? ChartDataEnum.ia : ChartDataEnum.vab}
                    stroke={curOrVolColor}
                    activeDot={true}
                    dot={dotStyle(curOrVolColor)}
                    strokeWidth={2}
                    connectNulls
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowCurrVoltDataLine(
                  LoadFilterEnum.Current,
                  LoadFilterDataEnum.IB,
                  LoadFilterEnum.Voltage,
                  LoadFilterDataEnum.VBC
                ) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={isCurrent ? ChartDataEnum.ib : ChartDataEnum.vbc}
                    stroke={curOrVolColor}
                    activeDot={true}
                    dot={dotStyle(curOrVolColor)}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Ave
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}
                {isShowCurrVoltDataLine(
                  LoadFilterEnum.Current,
                  LoadFilterDataEnum.IC,
                  LoadFilterEnum.Voltage,
                  LoadFilterDataEnum.VCA
                ) && (
                  <Line
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={isCurrent ? ChartDataEnum.ic : ChartDataEnum.vca}
                    stroke={curOrVolColor}
                    activeDot={true}
                    dot={dotStyle(curOrVolColor)}
                    strokeWidth={2}
                    connectNulls
                    style={{
                      opacity: LineOpacityEnum.Min
                    }}
                    isAnimationActive={isSample ? false : true}
                  />
                )}
              </>
            )}

            {dateRange === VisualizationDateRangeEnum.Date && (
              <>
                {data.data.map(
                  (item: ChartData, index: number) =>
                    (item.xAxis === 0 || item.xAxis === 24) &&
                    index < data.data.length - 1 && (
                      <ReferenceLine
                        yAxisId={leftYAxisId}
                        x={item.timestamp}
                        strokeWidth={1}
                        label={
                          <Label
                            value={Moment(item.date).format(VISUALIZATION_DATE_FORMAT)}
                            angle={-40}
                            textAnchor="middle"
                            dy={
                              viewBy === VisualizationViewByEnum.Aggregated
                                ? -285
                                : props.openInModal
                                  ? -285
                                  : -160
                            }
                            dx={25}
                            style={{
                              fontSize: "12px"
                            }}
                          />
                        }
                        style={{
                          filter: "drop-shadow( 0px 0px 6px rgba(0, 0, 0, 0.1))"
                        }}
                        key={index}
                      />
                    )
                )}
              </>
            )}

            {noData && (
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                className="recharts-text recharts-label"
                style={{
                  fontSize: displayInSmallFontSize ? fontSizeSmall : fontSizeLarge,
                  fill: axisTickColor
                }}
              >
                {Messages.LBL_VISUALIZATION_NO_DATA}
              </text>
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default Chart
