import { useLocation } from "react-router-dom"

import { isAESISuperAdminRoute } from "../../utils/utils"

import { HeaderMenuProps } from "../../interfaces/HeadMenuProps"
import { LogoProps } from "../../interfaces/LogoProps"

import HeaderMenu from "./HeaderMenu"
import HeaderActions from "./HeaderActions"
import Logo from "../Logo"

const HeaderDesktop = () => {
  const location = useLocation()
  const getPath = (index = 1) => location.pathname.split("/")[index]
  const headerMenuPops: HeaderMenuProps = {
    class: ""
  }
  const logoProps: LogoProps = {
    class: "pt-0"
  }
  return (
    <div className="header d-none d-xl-flex justify-content-between px-3">
      <Logo {...logoProps} />
      {!isAESISuperAdminRoute(getPath(2)) && <HeaderMenu {...headerMenuPops} />}
      <HeaderActions />
    </div>
  )
}

export default HeaderDesktop
