import { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import DOMPurify from "dompurify"

import { DeleteModalProps } from "../../interfaces/DeleteModalProps"
import { DeleteApiRes } from "../../interfaces/DeleteApiRes"

import { writeCodeLogEvent } from "../../utils/utils"

const DeleteModal = (props: DeleteModalProps) => {
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)

    // make request
    props
      .request(props.id)
      .then((res: DeleteApiRes) => {
        setLoading(false)
        props.toggle()

        // response back to parent
        props.response(res.status, props.id, res.message || "")
      })
      .catch((e) => {
        setLoading(false)
        props.toggle()

        // response back to parent
        props.response(false, props.id, e.message)

        console.error("Code exception: handle delete request =>", e.message)

        writeCodeLogEvent("handle delete request", e)
      })
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      id="alertModal"
      backdrop={"static"}
      onClosed={props.onClose}
    >
      <ModalBody className="p-4">
        <p
          className="mb-0 ms-3 text-break"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${props.text}?`) }}
        ></p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button
          color="outline-primary"
          className="me-2 fw-bold"
          onClick={props.toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit} disabled={isLoading} className="fw-bold">
          {isLoading && (
            <Spinner color="light" size="sm" className="me-1">
              Loading...
            </Spinner>
          )}
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal
