import { Spinner } from "reactstrap"
import { TableStatusProps } from "../interfaces/TableStatusProps"

import error from "../assets/images/icons/error.svg"

const TableStatus = (props: TableStatusProps) => {
  return (
    <tr className="loading-tr">
      <td colSpan={props.colCount}>
        {props.status === "loading" && (
          <Spinner color="primary" className="mt-2">
            Loading...
          </Spinner>
        )}
        {!["loading", "empty"].includes(props.status) && (
          <img src={error} alt="table status error" width="50px" className="mt-2" />
        )}
        <p className="my-2">{props.message}</p>
      </td>
    </tr>
  )
}

export default TableStatus
