import { AdminCreateUser } from "../interfaces/AdminCreateUser"
import { AdminUpdateUser } from "../interfaces/AdminUpdateUser"
import { UserEntity } from "../interfaces/UserEntity"
import { getCurrentUser } from "../store/counter/authSlice"
import api from "../utils/axios"
import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import { AXIOS_ALLOWANCE_STATUS } from "../utils/constants"

export const apiAdminListUsers = async (
  paginationToken?: string | null,
  customerUniqueId?: number
) => {
  try {
    const response = await api.get(
      `api/users?customerUniqueId=${
        customerUniqueId || getCurrentUser().customerUniqueId
      }&pageSize=${process.env.REACT_APP_PAGE_SIZE}${
        paginationToken ? `&paginationToken=${paginationToken}` : ""
      }`
    )

    return {
      status: true,
      data: response.data.users as UserEntity[],
      paginationToken: response.data.paginationToken
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list users =>", e.message)

    writeApiLogEvent("get list users", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_USER_GET_LIST_FAILED)
    }
  }
}

export const apiAdminListSiteAdminUsers = async (customerUniqueId?: number) => {
  try {
    const response = await api.get(
      `api/users/roles/TENANT_SITE_ADMIN?customerUniqueId=${customerUniqueId}`
    )

    return {
      status: true,
      data: response.data.users as UserEntity[],
      paginationToken: response.data.paginationToken
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list site admin users =>", e.message)

    writeApiLogEvent("get list site admin user", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_USER_GET_LIST_SITE_ADMIN_FAILED)
    }
  }
}

interface ErrorList {
  errorField: string
  errorMsg: string
}

export const apiAdminModifyUser = async (
  body: AdminCreateUser | AdminUpdateUser,
  username?: string
) => {
  try {
    let response
    if (username)
      response = await api.put(`api/users/${username}`, body, {
        validateStatus: function (status: number) {
          return [...AXIOS_ALLOWANCE_STATUS, 409].includes(status)
        }
      })
    else
      response = await api.post(`api/users`, body, {
        validateStatus: function (status: number) {
          return [...AXIOS_ALLOWANCE_STATUS, 409].includes(status)
        }
      })

    const data = response.data
    const status = [200, 201].includes(response.status)
    const errorLists = data.errorLists ? data.errorLists.map((obj: ErrorList) => obj.errorMsg) : []

    // user exists
    if (response.status === 409 && errorLists.length <= 0) {
      errorLists.push(data.msg)
    }

    return {
      status,
      errorLists
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: modify users =>", e.message)

    writeApiLogEvent("modify user", e)

    return {
      status: false,
      message: responseErrorMessage(
        e.code,
        username ? Message.ERR_USER_MODIFY_FAILED : Message.ERR_USER_CREATE_FAILED
      )
    }
  }
}

export const apiAdminDeleteUser = async (username: string) => {
  try {
    const response = await api.delete(`api/users/${username}`)

    return {
      status: [204, 200].includes(response.status),
      message: response.data.msg || ""
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: delete users =>", e.message)

    writeApiLogEvent("API exception: delete user", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_USER_DELETE_FAILED)
    }
  }
}

export const apiAdminSoftDeleteUserOfCustomer = async (customerUniqueId: number) => {
  try {
    await api.delete(`api/users/customers/${customerUniqueId}`)

    return {
      status: true,
      message: Message.MSG_USER_SOFT_DELETE_SUCCESS
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: soft delete users of customers =>", e.message)

    writeApiLogEvent("soft delete users of customers", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_USER_SOFT_DELETE_FAILED)
    }
  }
}

export const apiAdminUploadUserAvatar = async (username: string, extension: string, file: File) => {
  try {
    const fileName = `${encodeURIComponent(username)}.${Math.random()}.${extension}`
    await api.put(`api/resources/${process.env.REACT_APP_AVATAR_BUCKET}/${fileName}`, file, {
      headers: {
        "Content-Type": `${file.type}`
      }
    })
    return {
      status: true,
      imageUrl: `${process.env.REACT_APP_AVATAR_BUCKET_HOST}${fileName}`
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: upload user avatar =>", e.message)

    writeApiLogEvent("upload user avatar", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_USER_UPLOAD_AVATAR_FAILED)
    }
  }
}
