import { useLocation } from "react-router-dom"

import CustomerManagement from "./customer-management/CustomerManagement"
import CustomerDetail from "./customer-management/CustomerDetail"

const CustomerManagementWrapper = () => {
  const location = useLocation()
  const customerUniqeuId = location.pathname.split("/")[3]

  if (customerUniqeuId) return <CustomerDetail />
  else return <CustomerManagement />
}

export default CustomerManagementWrapper
