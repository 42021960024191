interface RoundImageButtonProps {
  onClick: () => void
  tooltipTitle: string
  disabled?: boolean
  src?: string
  children?: React.ReactNode
}

const RoundImageButton: React.FC<RoundImageButtonProps> = (props?: RoundImageButtonProps) => (
  <button
    className="p-0 m-1 rounded-circle toolbar-button"
    onClick={props?.onClick}
    data-toggle="tooltip"
    title={props?.tooltipTitle}
    disabled={props?.disabled}
  >
    {props?.src ? (
      <img
        style={{
          opacity: props?.disabled ? 0.2 : 1
        }}
        src={props?.src}
        alt=""
      />
    ) : (
      props?.children
    )}
  </button>
)
export default RoundImageButton
