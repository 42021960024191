import { ReactNode, useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import { useSelector } from "react-redux"

import type { RootState } from "../../store/store"
import moment from "moment"
import cookies from "../../utils/cookies"
import { AUTHORITY_CONSTANTS, COOKIE_NAME } from "../../utils/constants"
import { getRateLimitExceeded } from "../../store/counter/authSlice"
import { useNavigate } from "react-router-dom"
import { stringFormat } from "../../utils/utils"
import Messages from "../../utils/messages"

interface WrapperProps {
  children: ReactNode
  className?: string
  id: string
}
function Wrapper({ children, className, id }: WrapperProps) {
  const navigate = useNavigate()
  const errorMessage = useSelector((state: RootState) => state.auth.errorMessage)
  const errorColor = useSelector((state: RootState) => state.auth.errorColor)
  const rateLimitExceeded = useSelector(getRateLimitExceeded)
  const [lockTime, setLockTime] = useState(AUTHORITY_CONSTANTS.TIME_LOCK_USER)
  const errorClass = `alert alert-${errorColor} mb-4`

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined
    if (rateLimitExceeded >= AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT) {
      intervalId = setInterval(() => {
        const timeCount =
          parseInt(localStorage.getItem(COOKIE_NAME.LOCK_TIME) || "0") - new Date().getTime()
        setLockTime(timeCount)
        if (timeCount < 0) {
          setLockTime(0)
          cookies.set(COOKIE_NAME.RATE_LIMIT_EXCEEDED, 0)
          navigate(0)
        }
      }, 1000)
    }

    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateLimitExceeded])

  return (
    <div id={id} className={className || ""}>
      {errorMessage && rateLimitExceeded < AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT && (
        <div data-testid="error" className={errorClass}>
          {errorMessage}
        </div>
      )}
      {rateLimitExceeded >= AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT && (
        <div data-testid="error" className={errorClass}>
          {stringFormat(Messages.ERR_MSG_RATE_LIMIT_EXCEEDED, [moment(lockTime).format("mm:ss")])}
        </div>
      )}
      <Row className="mb-3">
        <Col lg="2" className="col-1"></Col>
        <Col lg="8" className="col-10 px-4 px-md-5 px-lg-3">
          {rateLimitExceeded < AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT && children}
        </Col>
        <Col lg="2" className="col-1"></Col>
      </Row>
    </div>
  )
}

export default Wrapper
