const Error = () => {
  return (
    <>
      <div id="error-page" className="text-center px-5">
        <h1>Not Found</h1>
        <p>
          The requested page was not found!{" "}
          <b className="text-danger">{window.location.pathname}</b>
        </p>
      </div>
    </>
  )
}

export default Error
