import { Suspense, lazy } from "react"
import { useLocation } from "react-router-dom"

import "../assets/scss/monitoring/monitoring.scss"

const MonitorParent = lazy(() => import("./monitoring/MonitorParent"))
const MoniteringSepTeraStor = lazy(() => import("./monitoring/MonitoringSepTeraStor"))
import Loader from "../components/Loader"

const MonitoringWrapper = () => {
  const location = useLocation()
  const tsId = location.pathname.split("/")[4]

  return (
    <Suspense fallback={<Loader />}>
      {tsId ? <MoniteringSepTeraStor /> : <MonitorParent />}
    </Suspense>
  )
}

export default MonitoringWrapper
