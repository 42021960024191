import React, { useRef, useState, useEffect } from "react"
import { SiteDrawingResizerProps } from "../../interfaces/SiteDrawingResizerProps"
import Moveable from "react-moveable"
import { Row } from "reactstrap"
import { resizeFile } from "utils/imageResizer"

const SiteDrawingResizer: React.FC<SiteDrawingResizerProps> = (props) => {
  const [originalDrawing, setOriginalDrawing] = useState({ url: "", width: 0, height: 0 })
  const [moveableTarget, setMoveableTarget] = useState<Array<string>>([".resizeable-image"])
  const resizedImageRef = useRef<HTMLImageElement>(null)
  const moveableRef = React.useRef<Moveable>(null)

  useEffect(() => {
    if (props.drawingFile) {
      const image = new Image()
      const imageUrl = URL.createObjectURL(props.drawingFile)
      image.src = imageUrl

      image.onload = () => {
        const width = image.width
        const height = image.height
        const url = image.src
        setOriginalDrawing({ url, width, height })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResizeComplete = async () => {
    if (resizedImageRef.current && props.drawingFile) {
      setMoveableTarget([])

      const resizedFile = await resizeFile(
        props.drawingFile,
        resizedImageRef.current?.width,
        resizedImageRef.current?.height,
        props.drawingFile?.type.split("/")[1]
      )
      props.onResizeComplete(resizedFile as File)
    }
  }

  const onCancelResize = () => {
    props.onCancelResize()
  }

  return (
    <div className="image-container p-4">
      {originalDrawing.width !== 0 && originalDrawing.height !== 0 && (
        <>
          <Moveable
            ref={moveableRef}
            scrollable={true}
            scrollOptions={{
              container: ".image-container",
              threshold: 30,
              checkScrollEvent: false,
              throttleTime: 0
            }}
            target={moveableTarget}
            origin={false}
            resizable={true}
            keepRatio={true}
            throttleResize={1}
            renderDirections={["nw", "ne", "sw", "se", "e", "s"]}
            onResize={(e) => {
              e.target.style.width = `${e.width}px`
              e.target.style.height = `${e.height}px`
              e.target.style.transform = e.drag.transform
            }}
            onResizeEnd={(e) => {
              e.target.style.transform = ""
            }}
          />
          <Row>
            <div
              className="col-md-auto align-self-start"
              style={{
                position: "relative",
                zIndex: 3
              }}
            >
              <button
                className="bi bi-x fs-4 text-danger p-0 m-1 rounded-circle toolbar-button"
                onClick={onCancelResize}
                data-toggle="tooltip"
                title="Cancel"
              ></button>
              <button
                className="bi bi-check fs-4 text-success p-0 m-1 rounded-circle toolbar-button"
                onClick={onResizeComplete}
                data-toggle="tooltip"
                title="Accept"
              ></button>
            </div>
          </Row>
          <Row
            className="m-0"
            style={{
              width: `${originalDrawing.width}px`,
              height: `${originalDrawing.height}px`
            }}
          >
            <img
              ref={resizedImageRef}
              className="resizeable-image"
              src={`${originalDrawing.url}`}
            ></img>
          </Row>
        </>
      )}
    </div>
  )
}

export default SiteDrawingResizer
