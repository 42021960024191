import { useState, Suspense, lazy, useEffect } from "react"
import { Button } from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"

import prev from "../../assets/images/icons/admin/prev.svg"
import site from "../../assets/images/icons/admin/site.svg"
import siteActive from "../../assets/images/icons/admin/site-active.svg"
import users from "../../assets/images/icons/admin/users.svg"
import usersActive from "../../assets/images/icons/admin/users-active.svg"
import { SiteManagementProps } from "../../interfaces/SiteManagementProps"

import { apiAdminGetCustomerInfo } from "../../services/apiCustomerManagement"

import { AESI_ADMIN_ROUTE } from "../../utils/constants"

const SiteManagement = lazy(() => import("../site-management/SiteManagement"))
const UserManagement = lazy(() => import("./UserManagement"))
import Loader from "../../components/Loader"

import { writeCodeLogEvent } from "../../utils/utils"

const CustomerDetail = () => {
  // get customerUniqueId and customerName
  const location = useLocation()
  const decoded = decodeURIComponent(location.pathname)
  const customerUniqueId = parseInt(decoded.split("/")[3])

  const [customerName, setCustomerName] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    if (customerUniqueId) {
      apiAdminGetCustomerInfo(customerUniqueId)
        .then((res) => {
          if (res.status) {
            setCustomerName(res.data?.name || "")
          } else {
            navigate("/aesi-admin/customer-management")
          }
        })
        .catch((e) => {
          console.error("Code exception: handle get customer info =>", e.message)
          writeCodeLogEvent("handle get customer info", e)
          navigate("/aesi-admin/customer-management")
        })
    } else {
      navigate("/aesi-admin/customer-management")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUniqueId])

  // user modal
  const [userModal, setUserModal] = useState(false)

  // site modal
  const [siteModal, setSiteModal] = useState(false)
  const siteManagementProps: SiteManagementProps = {
    customerUniqueId,
    customerName,
    siteModal,
    setSiteModal: setSiteModal
  }

  // tabs
  const [activeTab, setActiveTab] = useState("site-management")
  const SITE_MANAGEMENT = "site-management"
  const USERS_MANAGEMENT = "users-management"
  const toggleTab = (tab: string) => setActiveTab(tab)

  return (
    <div id="customerDetail" className="admin-wrapper">
      <div className="text-center d-md-flex justify-content-between mb-5">
        <Link
          to={`${AESI_ADMIN_ROUTE}/customer-management`}
          className="text-under text-decoration-none pt-2 mt-1"
          id="achBack"
        >
          <img src={prev} alt="back" className="me-4" />
          Back
        </Link>
      </div>
      <h4 className="mb-3 mb-md-4 title text-primary text-center text-md-start">
        <span className="fw-bold me-2 text-break">{customerName}</span>
        <span>#{customerUniqueId}</span>
      </h4>
      <div className="d-md-flex justify-content-between mb-4">
        <div className="d-flex flex-column flex-md-row bd-highlight">
          <Button
            color="link"
            className={
              activeTab === SITE_MANAGEMENT
                ? "tab-item selected me-0 me-dm-3 mb-2 test-center test-md-start"
                : "tab-item me-0 me-dm-3 mb-2 test-center test-md-start"
            }
            onClick={() => toggleTab(SITE_MANAGEMENT)}
          >
            {activeTab === SITE_MANAGEMENT ? (
              <img src={siteActive} alt="Site Management" className="me-2 selected" />
            ) : (
              <img src={site} alt="Site Management" className="me-2 none-selected" />
            )}
            Site Management
          </Button>
          <Button
            color="link"
            className={
              activeTab === USERS_MANAGEMENT
                ? "tab-item selected mb-2 test-center test-md-start"
                : "tab-item mb-2 test-center test-md-start"
            }
            onClick={() => toggleTab(USERS_MANAGEMENT)}
          >
            {activeTab === USERS_MANAGEMENT ? (
              <img src={usersActive} alt="Users Management" className="me-2 selected" />
            ) : (
              <img src={users} alt="Users Management" className="me-2 none-selected" />
            )}
            User Management
          </Button>
        </div>
        <div id="btnWrapper">
          {activeTab === SITE_MANAGEMENT && (
            <Button
              color="primary"
              id="btnAddUser"
              className="px-5 btn-actions"
              onClick={() => setSiteModal(true)}
            >
              Create Site
            </Button>
          )}
          {activeTab === USERS_MANAGEMENT && (
            <Button
              color="primary"
              id="btnAddUser"
              className="px-5 btn-actions"
              onClick={() => setUserModal(!userModal)}
            >
              Create User
            </Button>
          )}
        </div>
      </div>

      <div className="mb-5">
        <Suspense fallback={<Loader />}>
          {activeTab === SITE_MANAGEMENT ? (
            <SiteManagement {...siteManagementProps} />
          ) : (
            <UserManagement
              customerUniqueId={customerUniqueId}
              userModal={userModal}
              setUserModal={() => setUserModal(!userModal)}
            />
          )}
        </Suspense>
      </div>
    </div>
  )
}

export default CustomerDetail
