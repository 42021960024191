//#region React imports
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
//#endregion

import { AppDispatch } from "store/store"
import { getSiteEntity, setSiteEntity } from "store/counter/monitoringSlice"

import "../../assets/scss/configuration/configuration.scss"

import prev from "../../assets/images/icons/admin/prev.svg"

import { apiGetSiteBySiteUniqueId } from "../../services/apiSitesManagement"

import AlertModal from "../../components/modal/AlertModal"
import Configuration from "pages/configuration/Configuration"

import { AlertModalProps } from "../../interfaces/AlertModalProps"
import { getSiteStatusAsString, getSiteStatusCSS, writeCodeLogEvent } from "../../utils/utils"
// constants
import { AESI_ADMIN_ROUTE } from "../../utils/constants"
import Messages from "../../utils/messages"

const ReviewConfiguration = () => {
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()

  const site = useSelector(getSiteEntity)
  const [lastAdminPage, setLastAdminPage] = useState("") // store the last admin page if navigated from all sites management
  const [alertModal, setAlertModal] = useState(false)
  const [alertStatus, setAlertStatus] = useState("loading")
  const [error, setError] = useState(Messages.LBL_SITE_SETUP_FETCHING)

  const alertToggle = () => setAlertModal(!alertModal)
  const alertModalProps: AlertModalProps = {
    size: "sm",
    modal: alertModal,
    toggle: alertToggle,
    status: alertStatus,
    message: error
  }

  const getSiteUniqueIdFromUri = () => {
    const decoded = decodeURIComponent(location.pathname)
    return Number(decoded.split("/")[3])
  }
  useEffect(() => {
    const newSiteUniqueId = getSiteUniqueIdFromUri()
    if (location.state && location.state.from) {
      setLastAdminPage(location.state.from)
    }
    if (location.state && location.state.site) {
      dispatch(setSiteEntity(location.state.site))
    } else if (newSiteUniqueId) {
      // handle navigate from url outside the app
      fetchSiteData(newSiteUniqueId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const fetchSiteData = async (siteUniqueId: number) => {
    try {
      dispatch(setSiteEntity(undefined))
      const siteRes = await apiGetSiteBySiteUniqueId(siteUniqueId)
      // handle error
      if (!siteRes.status) {
        setAlertStatus("error")
        setError(Messages.ERR_SITE_GET_INFO_FAILED)
        setAlertModal(true)
      }
      const newState = {
        from: lastAdminPage,
        site: siteRes.data
      }
      location.state = newState
      dispatch(setSiteEntity(siteRes.data))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // handle error
      setAlertStatus("error")
      setError(Messages.ERR_COMMON_SERVER_FAILED)
      setAlertModal(true)

      console.error("Code exception: handle fetch site data =>", e.message)

      writeCodeLogEvent("handle fetch site data", e, getSiteUniqueIdFromUri())
    }
  }

  return (
    <div className="h-100 overflow-x-hidden overflow-y-auto">
      <div
        id="review-configuration"
        className="pt-0 px-4 text-center d-flex justify-content-between"
      >
        <Link
          to={lastAdminPage || `${AESI_ADMIN_ROUTE}/customer-management/${site?.customerUniqueId}`}
          className="pt-4 text-under text-decoration-none"
          id="achBack"
        >
          <img src={prev} alt="back" className="me-4" />
          {Messages.LBL_BACK_SUPER_ADMIN}
        </Link>
        <h4 className="title text-primary text-end site-setup position-relative">
          <span className={`badge d-inline-block p-1 ${getSiteStatusCSS(site?.status)}`}>
            {getSiteStatusAsString(site?.status)}
          </span>
          <br></br>
          <span className="fw-bold">
            {site?.customerName} - {site?.siteName}
          </span>
        </h4>
      </div>
      <Configuration />
      {alertModal && <AlertModal {...alertModalProps} />}
    </div>
  )
}

export default ReviewConfiguration
