import Resizer from "react-image-file-resizer"

export const resizeFile = (
  file: File,
  maxWidth: number,
  maxHeight: number,
  compressFormat: string
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      80,
      0,
      (uri) => {
        resolve(uri)
      },
      "file"
    )
  })
