export enum LoadFilterDataEnum {
  VAB = "VAB",
  VBC = "VBC",
  VCA = "VCA",
  IA = "IA",
  IB = "IB",
  IC = "IC",
  Commanded = "Commanded",
  Actual = "Actual"
}
