import { PaginationV2Props } from "../interfaces/PaginationV2Props"
import ReactPaginate from "react-paginate"

import prev from "../assets/images/icons/admin/prev.svg"
import next from "../assets/images/icons/admin/next.svg"

const Previous = () => {
  return (
    <>
      <img src={prev} alt="prev" className="me-3" />
      Previous
    </>
  )
}

const Next = () => {
  return (
    <>
      Next
      <img src={next} alt="next" className="ms-3" />
    </>
  )
}

interface SelectedPage {
  selected: number
}
const PaginationV2 = (props: PaginationV2Props) => {
  const pageInfo = props.pageInfo

  const handlePageChange = (event: SelectedPage) => props.setCurrentPage(event.selected)

  const paginationText = () => {
    if (pageInfo) {
      const prevPage = pageInfo.pageNumber === pageInfo.pagePrev ? 0 : pageInfo.pagePrev + 1
      const currentPage = pageInfo.pageNumber + 1
      const pageSize = pageInfo.pageSize
      const totalElements = pageInfo.totalElements

      if (totalElements === 0)
        return (
          <>
            Showing <b data-testid="count">0</b>
          </>
        )
      return (
        <>
          Showing{" "}
          <b data-testid="count">
            {prevPage * pageSize + 1} to{" "}
            {currentPage * pageSize > totalElements ? totalElements : currentPage * pageSize}
          </b>{" "}
          of <span data-testid="total">{totalElements}</span>
        </>
      )
    }

    return ""
  }

  return (
    <div
      className="d-md-flex justify-content-between align-items-center paginationv2 text-center text-md-start"
      data-testid="paginationWrapper"
    >
      <span id="pagingText" data-testid="wrapper">
        {paginationText()}
      </span>
      <ReactPaginate
        previousLabel={<Previous />}
        nextLabel={<Next />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageInfo?.totalPages || 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName="pagination d-flex justify-content-center justify-content-md-end"
        activeClassName="active"
        forcePage={props.forcePage}
      />
    </div>
  )
}

export default PaginationV2
