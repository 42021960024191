import { Outlet, useRouteError, Navigate } from "react-router-dom"
import { Container } from "reactstrap"
import { useSelector } from "react-redux"
import { Row, Col } from "reactstrap"

import "../assets/scss/auth.scss"

import { getIdTokenState, getCurrentUserState } from "../store/counter/authSlice"

import logo from "../assets/images/logo.svg"

const AuthLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError()
  const idToken = useSelector(getIdTokenState)
  const currentUser = useSelector(getCurrentUserState)
  if (idToken && currentUser) {
    return <Navigate to="/" />
  } else
    return (
      <Container id="contentWrapper" className="p-4 auth-wrapper d-flex" fluid>
        <Row className="flex-grow-1 align-self-center px-3 px-md-5">
          <Col md="2" lg="3" xl="3" xxl="4"></Col>
          <Col
            md="8"
            lg="6"
            xl="6"
            xxl="4"
            className="px-0 px-md-4 px-lg-3 px-xl-5"
            id="authWrapper"
          >
            <div className="bg-white py-5 rounded-4">
              <div className="my-5">
                <Row className="mt-3">
                  <Col xl="2" className="col-1"></Col>
                  <Col xl="8" className="col-10">
                    <div className="mb-5 text-center px-4 px-md-5 px-xl-0">
                      <img src={logo} alt="logo" className="mb-2 w-100"></img>
                    </div>
                  </Col>
                  <Col xl="2" className="col-1"></Col>
                </Row>
                {!error ? (
                  <Outlet />
                ) : (
                  <div id="error-page" className="text-center px-5">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p className="text-danger">
                      <i>{error.statusText || error.message}</i>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md="2" lg="3" xl="3" xxl="4"></Col>
        </Row>
      </Container>
    )
}

export default AuthLayout
