import { lazy, useEffect, useState } from "react"
import { Outlet, useRouteError, Navigate } from "react-router-dom"
import { Container } from "reactstrap"
import { useSelector } from "react-redux"
import { getIdTokenState, getCurrentUserState, isSigninExpired } from "../store/counter/authSlice"
import { writeCrashedLogEvent } from "../utils/utils"

import "../assets/scss/admin.scss"

import { USER_ROLES } from "../utils/constants"

import Header from "./Header"

import imgError from "../assets/images/icons/error.png"

const ExpiredModal = lazy(() => import("../components/modal/ExpiredModal"))

const FullLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError()

  const [expiredModal, setExpiredModal] = useState(false)
  useEffect(() => {
    const handleStorageChange = () => setExpiredModal(isSigninExpired())
    // Listen for changes in sessionStorage
    window.addEventListener("storage", handleStorageChange)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      console.error("Layout exception: app crash =>", error.statusText || error.message)
      writeCrashedLogEvent("", error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const idToken = useSelector(getIdTokenState)
  const currentUser = useSelector(getCurrentUserState)
  if (!idToken || !currentUser) {
    return <Navigate to="/auth/signin" />
  } else if (currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id) {
    return <Navigate to="/monitoring" />
  } else
    return (
      <>
        {/********header**********/}
        <Header />
        {/********Middle Content**********/}
        <Container id="contentWrapper" className="p-0" fluid>
          {!error ? (
            <Outlet />
          ) : (
            <div id="errorPage" className="d-flex justify-content-center align-self-center w-100">
              <div className="text-center align-self-center">
                <img src={imgError} alt="error" width={100} className="mb-2"></img>
                <h1 className="text-danger">Oops!</h1>
                <h4 className="text-danger mb-4">Something went wrong. Please try again.</h4>
              </div>
            </div>
          )}
        </Container>
        <ExpiredModal modal={expiredModal} />
      </>
    )
}

export default FullLayout
