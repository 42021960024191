import React, { useEffect, useRef, useState } from "react"
import { Button, Input } from "reactstrap"

import Messages from "utils/messages"
import UploadProgresModal from "components/modal/UploadProgresModal"
import { AlertModalProps } from "interfaces/AlertModalProps"
import AlertModal from "components/modal/AlertModal"
import { AxiosProgressEvent } from "axios"
import { ACCEPTED_DOC_EXTENSIONS, MAX_FW_FILE_SIZE } from "utils/constants"
import { DocManagerAdminProps } from "interfaces/DocManagerAdminProps"
import AllDocumentsTable from "./AllDocumentsTable"
import { DocEntity } from "interfaces/doc/DocEntity"
import { apiUploadDoc } from "services/apiDocumentManagement"
import { ProgressType } from "interfaces/UploadProgresModalProps"
import { stringFormat } from "utils/utils"
import { SelectOptions } from "interfaces/SelectOptions"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "store/store"
import { fetchDocCategory, getCategoryList } from "store/counter/docSlice"
import { SiteEntity } from "interfaces/SiteEntity"

const DocManagerSuperAdmin = (props: DocManagerAdminProps) => {
  const { site } = props

  const dispatch = useDispatch<AppDispatch>()
  const categoryList = useSelector(getCategoryList)

  const docFileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [needUpdateList, setNeedUpdateList] = useState(false)
  const [selectOptionList, setSelectOptionList] = useState<SelectOptions[]>([])
  const [selectedCategory, setSelectedCategory] = useState<SelectOptions>()
  const [siteList, setSiteList] = useState<SiteEntity[]>([])

  useEffect(() => {
    dispatch(fetchDocCategory())
  }, [dispatch])

  useEffect(() => {
    setSiteList(site ? [site] : [])
  }, [site])

  useEffect(() => {
    if (categoryList) {
      const arr: SelectOptions[] = []
      categoryList.forEach((category) => {
        const item: SelectOptions = {
          value: category.id,
          label: category.name
        }
        arr.push(item)
      })
      setSelectOptionList(arr)
      if (arr.length > 0) {
        setSelectedCategory(arr[0])
      }
    }
  }, [categoryList])

  const browseFile = () => {
    if (docFileInputRef.current) {
      docFileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 1. handle file validation
    // 2. set selected file
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const OnUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const progres = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setUploadProgress(progres)
    }
  }

  const handleUpload = async () => {
    if (!props.site) {
      // Show error message
      return
    }
    if (selectedFile && selectedCategory) {
      if (selectedFile.size > MAX_FW_FILE_SIZE) {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: Messages.ERR_FW_UPDATE_FILE_SIZE_EXCEEDED,
          modal: true
        })
        return
      }

      setIsProgressModalOpen(true)
      setUploadProgress(0)

      const doc: DocEntity = {
        customerId: props.site?.customerUniqueId,
        siteId: props.site?.siteUniqueId,
        fileName: selectedFile.name,
        categoryId: selectedCategory.value
      }
      const res = await apiUploadDoc(doc, selectedFile, OnUploadProgress)

      if (res.status) {
        handleNewDocAdded()
        setAlertModalProps({
          ...alertModalProps,
          status: "success",
          message: stringFormat(Messages.MSG_DOCUMENTS_UPLOAD_SUCCESSFULLY, [
            doc.siteId.toString()
          ]),
          modal: true
        })

        // clean up
        setSelectedFile(null)
        if (docFileInputRef.current) {
          docFileInputRef.current.value = ""
        }
      } else {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: res.errorLists || res.message,
          modal: true
        })
      }
      setIsProgressModalOpen(false)
    }
  }

  const handleNewDocAdded = () => {
    // Trigger the update
    setNeedUpdateList((prev) => !prev)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const selected = selectOptionList.find((item) => item.value.toString() === value)
    if (selected) {
      setSelectedCategory(selected)
    }
  }

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  return (
    <div id="documentManager" className="pt-2">
      <p className="title pt-0 mt-0">{Messages.LBL_DOCUMENTS_All_DOCUMENTS}</p>

      {/* Upload document snippet: */}
      <div className="pt-0">
        <div className="d-flex align-items-baseline">
          <h3>{Messages.LBL_DOCUMENTS_UPLOAD_NEW_DOCUMENT}</h3>
        </div>
        <div className="d-flex flex-wrap align-items-baseline">
          <div className="col-12 col-md-6 col-xl-3 me-3 text-nowrap align-items-center">
            <Input
              id="docCategory"
              className="form-control low-opacity-placeholder f-14 col-3"
              disabled={selectOptionList.length === 0}
              type="select"
              placeholder={Messages.LBL_DOCUMENTS_HEADER_DOCUMENT_CATEGORY}
              value={selectedCategory?.value?.toString() ?? ""}
              onChange={handleChange}
              tabIndex={1}
              required={true}
            >
              {selectOptionList?.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))}
            </Input>
          </div>
          <input
            ref={docFileInputRef}
            className="d-none"
            type="file"
            accept={ACCEPTED_DOC_EXTENSIONS}
            onChange={handleFileChange}
          />
          <div className="d-flex align-items-center">
            <input
              type="text"
              disabled={true}
              className="low-opacity-placeholder readonly-textbox"
              value={selectedFile?.name || ""}
              title={selectedFile?.name}
              placeholder={Messages.LBL_FW_UPDATE_BROWSE_FILE}
            ></input>
            <Button
              color="primary"
              className="right-radius-button"
              onClick={browseFile}
              disabled={!selectedCategory}
            >
              {Messages.LBL_FW_UPDATE_BROWSE}
            </Button>
          </div>
          <Button
            color="primary"
            className="mx-3"
            onClick={handleUpload}
            disabled={selectedFile === null || !selectedCategory}
          >
            {Messages.LBL_FW_UPDATE_UPLOAD}
          </Button>
        </div>
      </div>

      {/* All Documents Table snippet: */}
      <AllDocumentsTable
        isTenantAdmin={false}
        needUpdateList={needUpdateList}
        siteList={siteList}
      />

      {/* Modal snippet: */}
      {isProgressModalOpen && (
        <UploadProgresModal
          isOpen={isProgressModalOpen}
          progress={uploadProgress}
          type={ProgressType.DETERMINATE}
        />
      )}
      {alertModalProps.modal && <AlertModal {...alertModalProps} />}
    </div>
  )
}

export default DocManagerSuperAdmin
