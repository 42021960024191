import { memo } from "react"
import { useSelector } from "react-redux"
import { Modal, ModalBody } from "reactstrap"

import {
  getFilters,
  getSelectedTeraStors,
  getDataMode,
  getDateRange
} from "../../store/counter/visualizationSlice"

import { ModalProps } from "../../interfaces/ModalProps"
import { DropDown } from "../../interfaces/visualization/Dropdown"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { ChartActiveData } from "../../interfaces/visualization/ChartActiveData"
import { VisualizationChartData } from "../../interfaces/visualization/VisualizationChartData"

import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"
import { DataMode } from "../../enum/visualization/data-mode"
import { VisualizationDateRangeEnum } from "../../enum/visualization/tab/daterange-tab"

import close from "../../assets/images/icons/admin/close.svg"

import { getInfoFromTimestamp, decimalVal } from "../../utils/visualization/chart-data"
import Messages from "../../utils/messages"

import {
  getVisuaTooltipText,
  getVisuaTooltipData,
  getVisuaTooltipValue,
  getVisuaTooltipAttributes,
  getVisuaUnit,
  celsiusToFahrenheit
} from "../../utils/utils"

interface ChartDetailModalProps extends ModalProps {
  filters: DropDown[]
  data?: ChartActiveData
}

const ChartDetailModal = memo((props: ChartDetailModalProps) => {
  const filters = useSelector(getFilters)
  const selectedTeraStors = useSelector(getSelectedTeraStors)
  const dataMode = useSelector(getDataMode)
  const dateRange = useSelector(getDateRange)
  const data = getVisuaTooltipData(props.data ? props.data?.activePayload : [])

  type VisualizationDataType = string | number | null | undefined
  const getAttributeDataArray = (
    arr: VisualizationChartData[],
    key: keyof VisualizationChartData
  ): VisualizationDataType[] =>
    arr
      .filter(
        (item: VisualizationChartData) =>
          typeof item[key] !== "string" && item[key] !== null && item[key] !== undefined
      )
      .map((obj: VisualizationChartData) => obj[key])

  const isAttributeHasData = (
    data: Record<string, number>,
    dataKey: BatterFilterEnum | LoadFilterEnum,
    dataValue: BatterFilterDataEnum | LoadFilterDataEnum
  ) => {
    const value = getVisuaTooltipValue(data, dataKey, dataValue)
    return typeof value !== "string" && value !== null && value !== undefined
  }

  const isDataAvailable = (
    tsId: number,
    dataKey: BatterFilterEnum | LoadFilterEnum,
    dataValue: BatterFilterDataEnum | LoadFilterDataEnum
  ): boolean => {
    if (!props.data) return false
    const temp = props.data.apiData.filter(
      (item: VisualizationChartData) => item.terastorID === tsId
    )

    if (temp.length === 0) return false

    // get attribute key
    const key: keyof VisualizationChartData = getVisuaTooltipAttributes(dataKey, dataValue)

    // get attribute data array and check validity of data
    const arr: VisualizationDataType[] = getAttributeDataArray(temp, key)
    if (arr.length > 0) return true

    return false
  }

  const getTeraStorData = (
    tsId: number,
    dataKey: BatterFilterEnum | LoadFilterEnum,
    dataValue: BatterFilterDataEnum | LoadFilterDataEnum
  ) => {
    if (props.data) {
      const temp = props.data.apiData.filter(
        (item: VisualizationChartData) => item.terastorID === tsId
      )
      if (temp.length > 0) {
        // get attribute key
        const key: keyof VisualizationChartData = getVisuaTooltipAttributes(dataKey, dataValue)

        // get attribute data array and calculating average value
        const arr: VisualizationDataType[] = getAttributeDataArray(temp, key)

        const decimal = dataKey === BatterFilterEnum.Voltage ? 3 : 1

        if (arr.length > 0) {
          // calculating total value
          let total = 0
          arr.forEach((val: VisualizationDataType) => {
            if (typeof val !== "string" && val !== null && val !== undefined) {
              const tempVal =
                dataKey === BatterFilterEnum.Temperature ? celsiusToFahrenheit(val) : val
              total = total + decimalVal(tempVal, decimal)
            }
          })

          // calculating average value
          const calVal = total / arr.length

          return decimalVal(calVal, decimal)
        }
      }
    }

    return 0
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      onClosed={props.onClose}
      unmountOnClose={true}
      id="chartDetailModal"
      className="my-0 bg-white h-100"
    >
      <ModalBody className="py-4 px-5">
        <div className="d-flex justify-content-between mb-5">
          <h4 className="fw-bold text-primary mb-0">
            {Messages.LBL_VISUALIZATION_DETAIL_MODAL_SUMMARY}
          </h4>
          <img src={close} alt="Close" onClick={() => props.toggle()} role="button" />
        </div>
        <div className="mb-5 text-center fw-bold">
          {getInfoFromTimestamp(
            props.data?.activeLabel || "",
            dataMode === DataMode.Sample,
            dateRange === VisualizationDateRangeEnum.Date
          )}
        </div>
        {/* Summary */}
        {props.filters.map(
          (item: DropDown, index: number) =>
            isAttributeHasData(data, item.dataKey, item.dataValue) && (
              <div className="block mb-5" key={index}>
                <p className="p-2 d-flex justify-content-between text-primary fw-normal title">
                  <span className="me-3">
                    {getVisuaTooltipText(filters, item.dataKey, item.dataValue)}
                  </span>
                  <span>
                    {getVisuaTooltipValue(data, item.dataKey, item.dataValue)}{" "}
                    {getVisuaUnit(item.dataKey)}
                  </span>
                </p>
                {selectedTeraStors.map(
                  (teraStor: SiteTeraStorData, index: number) =>
                    isDataAvailable(teraStor.tsId, item.dataKey, item.dataValue) && (
                      <p className="p-2 d-flex justify-content-between" key={index}>
                        <span className="me-3">
                          {Messages.LBL_VISUALIZATION_TERASTOR} #{teraStor.tsId}
                        </span>
                        <span>
                          {getTeraStorData(teraStor.tsId, item.dataKey, item.dataValue)}{" "}
                          {getVisuaUnit(item.dataKey)}
                        </span>
                      </p>
                    )
                )}
              </div>
            )
        )}
      </ModalBody>
    </Modal>
  )
})

ChartDetailModal.displayName = "ChartDetailModal"

export default ChartDetailModal
