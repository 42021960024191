import { Button, Input, FormText } from "reactstrap"

import info from "../../assets/images/icons/admin/info.svg"

const SystemUpdates = () => {
  return (
    <div id="systemUpdates" className="admin-wrapper">
      <h4 className="mb-4 text-primary fw-bold">System Updates</h4>

      <div className="mb-3">
        <img src={info} alt="Info" className="me-2" />
        <span>First you need to select TeraStor then upload configuration.</span>
      </div>

      <Button color="primary" id="btnSelectTeraStor" className="mb-4">
        Select TeraStor
      </Button>

      <div className="block">
        <p className="title">
          <b>BMS Firmware Update</b>
        </p>
        <div className="d-flex justify-content-between mb-4">
          <span>
            Current Firmware Version - <b>0.01.01</b>
          </span>
          <span>
            Last update on : <b>12 May 2022</b>
          </span>
        </div>

        <p>
          <b>Upload Firmware</b>
        </p>
        <ul className="list-group list-group-horizontal">
          <li className="list-group-item border-0 p-0">
            <Input
              id="exampleFile"
              name="file"
              type="text"
              readOnly={true}
              placeholder="Browse file"
              role="button"
              className="inp-upload"
            />
            <FormText className="d-none d-md-block">
              Allowed file type: bin, fw, firm, ozip, zip only
            </FormText>
          </li>
          <li className="list-group-item border-0 p-0 pe-5">
            <Button color="primary" id="btnSelectTeraStor" className="btn-upload">
              Browse
            </Button>
          </li>
          <li className="list-group-item border-0 p-0">
            <Button color="primary" id="btnSelectTeraStor">
              Upload
            </Button>
          </li>
        </ul>
        <FormText className="d-block d-md-none">
          Allowed file type: bin, fw, firm, ozip, zip only
        </FormText>
      </div>

      <hr></hr>

      <div className="block">
        <p className="title">
          <b>ESS StorView Update</b>
        </p>
        <div className="d-flex justify-content-between mb-4">
          <span>
            Current Firmware Version - <b>0.01.01</b>
          </span>
          <span>
            Last update on : <b>12 May 2022</b>
          </span>
        </div>

        <p>
          <b>Upload Firmware</b>
        </p>
        <ul className="list-group list-group-horizontal">
          <li className="list-group-item border-0 p-0">
            <Input
              id="exampleFile"
              name="file"
              type="text"
              readOnly={true}
              placeholder="Browse file"
              role="button"
              className="inp-upload"
            />
            <FormText className="d-none d-md-block">
              Allowed file type: bin, fw, firm, ozip, zip only
            </FormText>
          </li>
          <li className="list-group-item border-0 p-0 pe-5">
            <Button color="primary" id="btnSelectTeraStor" className="btn-upload">
              Browse
            </Button>
          </li>
          <li className="list-group-item border-0 p-0">
            <Button color="primary" id="btnSelectTeraStor">
              Upload
            </Button>
          </li>
        </ul>
        <FormText className="d-block d-md-none">
          Allowed file type: bin, fw, firm, ozip, zip only
        </FormText>
      </div>
    </div>
  )
}

export default SystemUpdates
