import { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import DOMPurify from "dompurify"

import { DeleteTeraStorModalProps } from "interfaces/DeleteTeraStorModalProps"
import { DeleteApiRes } from "../../interfaces/DeleteApiRes"

import { writeCodeLogEvent } from "../../utils/utils"
import messages from "utils/messages"

const DeleteTeraStorModal = (props: DeleteTeraStorModalProps) => {
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)

    // make request
    props
      .request(props.terastorUUIDs)
      .then((res: DeleteApiRes) => {
        setLoading(false)
        props.toggle()

        // response back to parent
        if (props.response) props.response(res.status, res.message || "")
      })
      .catch((e) => {
        setLoading(false)
        props.toggle()

        // response back to parent
        if (props.response) props.response(false, e.message)

        console.error("Code exception: handle delete request =>", e.message)

        writeCodeLogEvent("handle delete request", e)
      })
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      id="alertModal"
      backdrop={"static"}
      onClosed={props.onClose}
    >
      <ModalBody className="mt-3 modal-body-scroll">
        <p
          className="mb-0 text-break"
          dangerouslySetInnerHTML={{
            __html: `<ul>${props.msgArr.map((msg) => `<li>${DOMPurify.sanitize(msg)}</li>`).join("")}</ul>`
          }}
        ></p>
        <p>{messages.MSG_SITE_SETUP_DELETE_CONFIRM}</p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button
          color="outline-primary"
          className="me-2 fw-bold"
          onClick={props.toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit} disabled={isLoading} className="fw-bold">
          {isLoading && (
            <Spinner color="light" size="sm" className="me-1">
              Loading...
            </Spinner>
          )}
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteTeraStorModal
