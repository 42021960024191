import { useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalBody,
  Form,
  Label,
  Input,
  Row,
  Col,
  Alert,
  Spinner,
  FormText,
  FormFeedback,
  FormGroup
} from "reactstrap"
import PhoneInput, { parsePhoneNumber, Country } from "react-phone-number-input"
import { Value as E164Number, isValidPhoneNumber } from "react-phone-number-input"

import "react-phone-number-input/style.css"

import { CustomerModalProps } from "../../interfaces/CustomerModalProps"
import { AdminCreateCustomer } from "../../interfaces/AdminCreateCustomer"
import { AdminUpdateCustomer } from "../../interfaces/AdminUpdateCustomer"
import { DeleteModalProps } from "../../interfaces/DeleteModalProps"
import { DeleteApiRes } from "../../interfaces/DeleteApiRes"

import { SubscriptionPlan } from "../../enum/subscription-plan"

import DeleteModal from "../../components/modal/DeleteModal"

import {
  apiAdminModifyCustomer,
  apiAdminDeleteCustomer
} from "../../services/apiCustomerManagement"
import { apiAdminSoftDeleteUserOfCustomer } from "../../services/apiUserManagement"

import { DEFAULT_PHONE_COUNTRY_CODE } from "../../utils/constants"
import { writeCodeLogEvent, convertTierString } from "../../utils/utils"
import Message from "../../utils/messages"
import { CustomerEntity } from "../../interfaces/CustomerEntity"

const CustomerModal = (props: CustomerModalProps) => {
  const [error, setError] = useState("")
  const [errorLists, setErrorLists] = useState([])

  const [customerUniqueID, setCustomerUniqueID] = useState("")
  const [name, setCustomerName] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [stateProvince, setStateProvince] = useState("")
  const [country, setCountry] = useState("")
  const [adminFirstName, setFirstname] = useState<string | undefined>("")
  const [adminLastName, setLastname] = useState<string | undefined>("")
  const [primaryContactEmail, setEmail] = useState<string | undefined>("")
  const [phone, setPhoneNumber] = useState<E164Number | undefined>()
  const [phoneCountry, setPhoneCountry] = useState<Country>(DEFAULT_PHONE_COUNTRY_CODE)
  const [subscriptionPlan, setSubscriptionPlan] = useState("")
  const [requiredErrors, setRequiredErrors] = useState<
    Partial<Record<keyof CustomerEntity, string>>
  >({})
  const COUNTRY_REGEX = new RegExp("^(?!\\s)[a-zA-Z\\s]*$")
  const [validFiels, setValidFiels] = useState<boolean>(true)

  // update edit data
  useEffect(() => {
    const data = props.data
    setCustomerUniqueID(data && data.customerUniqueID ? `${data.customerUniqueID}` : "")
    setCustomerName(data ? data.name : "")
    setAddress(data ? data.address : "")
    setCity(data ? data.city : "")
    setZipCode(data ? data.zipCode : "")
    setStateProvince(data ? data.stateProvince : "")
    setCountry(data ? data.country : "")
    setFirstname(data ? data.adminFirstName : "")
    setLastname(data ? data.adminLastName : "")
    setEmail(data ? data.primaryContactEmail : "")
    setPhoneNumber(data ? data.phone : "")
    setSubscriptionPlan(
      data ? data.subscriptionPlan || SubscriptionPlan.TIER_1 : SubscriptionPlan.TIER_1
    )

    if (data) {
      const parsePhone = parsePhoneNumber(data.phone || "")
      if (parsePhone) {
        setPhoneCountry(parsePhone.country || DEFAULT_PHONE_COUNTRY_CODE)
      } else {
        setPhoneCountry(DEFAULT_PHONE_COUNTRY_CODE)
      }
    }

    setRequiredErrors({})
    setError("")
    setErrorLists([])
  }, [props.data])

  const inputOnchange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.name === "name") {
      setCustomerName(event.currentTarget.value)
    } else if (event.currentTarget.name === "address") {
      setAddress(event.currentTarget.value)
    } else if (event.currentTarget.name === "stateProvince") {
      setStateProvince(event.currentTarget.value)
    } else if (event.currentTarget.name === "city") {
      setCity(event.currentTarget.value)
    } else if (event.currentTarget.name === "zipCode") {
      setZipCode(event.currentTarget.value)
    } else if (event.currentTarget.name === "country") {
      setCountry(event.currentTarget.value)
    } else if (event.currentTarget.name === "adminFirstName") {
      setFirstname(event.currentTarget.value)
    } else if (event.currentTarget.name === "adminLastName") {
      setLastname(event.currentTarget.value)
    } else if (event.currentTarget.name === "primaryContactEmail") {
      setEmail(event.currentTarget.value)
    } else if (event.currentTarget.name === "subscriptionPlan") {
      setSubscriptionPlan(event.currentTarget.value)
    }
  }

  const getStartDate = () => {
    const toDay = new Date()
    const date = toDay.getDate()
    const month = toDay.getMonth() + 1
    const year = toDay.getFullYear()

    return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}T00:00:00Z`
  }

  const [isSubmitting, setSubmit] = useState(false)
  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmit(true)

    if ((phone && !isValidPhoneNumber(phone)) || !validateCustomerRequired()) {
      setSubmit(false)
      return false
    }

    // get form
    let body: AdminCreateCustomer | AdminUpdateCustomer

    if (props.data && props.data.customerUniqueID) {
      body = {
        address,
        adminFirstName: adminFirstName || undefined,
        adminLastName: adminLastName || undefined,
        name,
        city,
        zipCode,
        stateProvince,
        country,
        phone: phone ? `${phone}` : undefined,
        primaryContactEmail: primaryContactEmail || undefined,
        subscriptionPlan
      }
    } else {
      body = {
        address,
        adminFirstName: adminFirstName || undefined,
        adminLastName: adminLastName || undefined,
        name,
        city,
        zipCode,
        stateProvince,
        country,
        phone: phone ? `${phone}` : undefined,
        primaryContactEmail: primaryContactEmail || undefined,
        startDate: getStartDate(),
        subscriptionPlan
      }
    }

    apiAdminModifyCustomer(body, props.data?.customerUniqueID)
      .then((res) => {
        if (res.status) {
          props.toggle()
          if (props.data?.customerUniqueID) props.editCallback(body, props.data?.customerUniqueID)
          else props.addCallback()
        } else {
          setError(res.message || "")
          setErrorLists(res.errorLists || [])
        }
        setSubmit(false)
      })
      .catch((e) => {
        setSubmit(false)
        setError(
          props.data && props.data.customerUniqueID
            ? Message.ERR_CUSTOMER_MODIFY_FAILED
            : Message.ERR_CUSTOMER_CREATE_FAILED
        )

        console.error("Code exception: handle save modify customer =>", e.message)

        writeCodeLogEvent("handle save modify customer", e)
      })
  }

  // ================================
  // ======= Delete customer ========
  // ================================
  const callDeleteRequests = async (customerUniqueID: string): Promise<DeleteApiRes> => {
    try {
      const resCustomer = await apiAdminDeleteCustomer(parseInt(customerUniqueID))

      if (resCustomer.status) {
        const softDelete = await apiAdminSoftDeleteUserOfCustomer(parseInt(customerUniqueID))

        return softDelete
      }
      return resCustomer
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error("Code exception: handle delete customer =>", e.message)

      writeCodeLogEvent("handle delete customer", e)

      return {
        status: false,
        message: Message.ERR_CUSTOMER_DELETE_FAILED
      }
    }
  }

  const [deleteUser, setDeleteUser] = useState<number | null>(null)
  const [deletemodal, setDeleteModal] = useState(false)
  const deleteToggle = () => setDeleteModal(!deletemodal)
  const deleteResponse = (status: boolean, username?: string, message?: string) => {
    if (status) {
      props.toggle()
      props.deleteCallback(props.data?.name || "")
    } else setError(message || Message.ERR_CUSTOMER_DELETE_FAILED)
  }
  const deleteModalProps: DeleteModalProps = {
    size: "sm",
    modal: deletemodal,
    toggle: deleteToggle,
    id: deleteUser ? `${deleteUser}` : "",
    text: Message.MSG_CUSTOMER_DELETE,
    request: callDeleteRequests,
    response: deleteResponse,
    onClose: () => {
      setDeleteUser(null)
    }
  }
  useEffect(() => {
    if (deleteUser) {
      deleteToggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUser])

  const validateCustomerRequired = (): boolean => {
    let valid = true
    const newErrors: Partial<Record<keyof CustomerEntity, string>> = {}

    if (!name) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newErrors.name = Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("name")!
      valid = false
    }

    if (!address) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newErrors.address = Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("address")!
      valid = false
    }

    if (!city) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newErrors.city = Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("city")!
      valid = false
    }

    if (!zipCode) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newErrors.zipCode = Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("zipCode")!
      valid = false
    }

    if (!stateProvince) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newErrors.stateProvince = Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("stateProvince")!
      valid = false
    }

    if (!country) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      newErrors.country = Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("country")!
      valid = false
    } else if (!checkValidCountry(country)) {
      valid = false
    }

    if (!subscriptionPlan) {
      newErrors.subscriptionPlan =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Message.VALIDATE_CUSTOMER_REQUIRED_FIELDS.get("subscriptionPlan")!
      valid = false
    }

    setRequiredErrors(newErrors)
    setValidFiels(valid)
    return valid
  }

  const checkValidCountry = (country: string) => {
    const regexValid = !!country && COUNTRY_REGEX.test(country)
    return regexValid
  }

  return (
    <>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        size={props.size}
        centered={true}
        backdrop={"static"}
        onClosed={props.onClose}
        unmountOnClose={true}
      >
        <ModalBody className="p-5">
          <h4 className="mb-2 text-primary fw-bold">
            {props.data && props.data.customerUniqueID
              ? Message.LBL_CUSTOMER_MODAL_TITLE_EDIT
              : Message.LBL_CUSTOMER_MODAL_TITLE_ADD}
          </h4>
          <hr></hr>
          {props.data && props.data.customerUniqueID ? (
            <p className="fw-bold text-primary" data-testid="lblEdit">
              {Message.LBL_CUSTOMER_HEADER_ID}: #{customerUniqueID}
            </p>
          ) : (
            ""
          )}
          <Form onSubmit={onSubmit}>
            {error && (
              <Alert color="danger" className="mb-3">
                <p className="mb-0">{error}</p>
              </Alert>
            )}
            {errorLists.length > 0 && (
              <Alert color="danger" className="mb-3">
                <ul className="mb-0">
                  {errorLists.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}
            <Row className="bg-secondary p-4 rounded mb-4 border">
              <Col md="6">
                <FormGroup className="mb-4">
                  <Label for="inpCustomerName">{Message.LABEL_CUSTOMER_NAME} *</Label>
                  <Input
                    id="inpCustomerName"
                    name="name"
                    type="text"
                    defaultValue={name}
                    placeholder={Message.LABEL_CUSTOMER_NAME}
                    tabIndex={1}
                    invalid={!name && !!requiredErrors.name}
                    onChange={inputOnchange}
                    data-testid="inpCustomerName"
                  />
                  <FormFeedback>{requiredErrors.name}</FormFeedback>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="mb-4">
                  <Label for="inpSubscriptionPlan">{Message.LABEL_SUBSCRIPTION_TYPE} *</Label>
                  {props.data?.customerUniqueID ? (
                    <Input
                      id="inpSubscriptionPlan"
                      name="subscriptionPlan"
                      type="text"
                      defaultValue={convertTierString(subscriptionPlan)}
                      placeholder={Message.LABEL_SUBSCRIPTION_TYPE}
                      tabIndex={2}
                      disabled
                      data-testid="inpSubscriptionPlan"
                    />
                  ) : (
                    <Input
                      id="sleSubscriptionPlan"
                      name="subscriptionPlan"
                      type="select"
                      tabIndex={2}
                      value={subscriptionPlan}
                      invalid={!subscriptionPlan && !!requiredErrors.subscriptionPlan}
                      onChange={inputOnchange}
                      data-testid="sleSubscriptionPlan"
                    >
                      <option value={SubscriptionPlan.TIER_0}>Tier 0</option>
                      <option value={SubscriptionPlan.TIER_1}>Tier 1</option>
                      <option value={SubscriptionPlan.TIER_2}>Tier 2</option>
                      <option value={SubscriptionPlan.TIER_3}>Tier 3</option>
                    </Input>
                  )}
                  <FormFeedback>{requiredErrors.subscriptionPlan}</FormFeedback>
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup className="mb-4">
                  <Label for="inpAddress">{Message.LABEL_BUSINESS_ADDRESS} *</Label>
                  <Input
                    id="inpAddress"
                    name="address"
                    type="text"
                    defaultValue={address}
                    placeholder={Message.LABEL_BUSINESS_ADDRESS}
                    tabIndex={3}
                    invalid={!address && !!requiredErrors.address}
                    onChange={inputOnchange}
                    data-testid="inpAddress"
                  />
                  <FormFeedback>{requiredErrors.address}</FormFeedback>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="mb-4">
                  <Label for="inpCity">{Message.LABEL_CITY} *</Label>
                  <Input
                    id="inpCity"
                    name="city"
                    type="text"
                    defaultValue={city}
                    placeholder={Message.LABEL_CITY}
                    tabIndex={4}
                    invalid={!city && !!requiredErrors.city}
                    onChange={inputOnchange}
                    data-testid="inpCity"
                  />
                  <FormFeedback>{requiredErrors.city}</FormFeedback>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="mb-4">
                  <Label for="inpStateProvince">{Message.LABEL_STATE_PROVINCE} *</Label>
                  <Input
                    id="inpStateProvince"
                    name="stateProvince"
                    type="text"
                    defaultValue={stateProvince}
                    placeholder={Message.LABEL_STATE_PROVINCE}
                    tabIndex={5}
                    invalid={!stateProvince && !!requiredErrors.stateProvince}
                    onChange={inputOnchange}
                    data-testid="inpStateProvince"
                  />
                  <FormFeedback>{requiredErrors.stateProvince}</FormFeedback>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="mb-4">
                  <Label for="inpZipcode">{Message.LABEL_ZIPCODE} *</Label>
                  <Input
                    id="inpZipcode"
                    name="zipCode"
                    type="text"
                    defaultValue={zipCode}
                    placeholder={Message.LABEL_ZIPCODE}
                    tabIndex={6}
                    invalid={!zipCode && !!requiredErrors.zipCode}
                    onChange={inputOnchange}
                    data-testid="inpZipcode"
                  />
                  <FormFeedback>{requiredErrors.zipCode}</FormFeedback>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup className="mb-4">
                  <Label for="inpCountry">{Message.LABEL_COUNTRY} *</Label>
                  <Input
                    id="inpCountry"
                    name="country"
                    type="text"
                    defaultValue={country}
                    placeholder={Message.LABEL_COUNTRY}
                    tabIndex={7}
                    invalid={
                      !validFiels &&
                      ((!country && !!requiredErrors.country) ||
                        (!!country && !checkValidCountry(country)))
                    }
                    onChange={inputOnchange}
                    data-testid="inpCountry"
                  />
                  <FormFeedback>
                    {!country && !validFiels && requiredErrors.country
                      ? requiredErrors.country
                      : country && !validFiels
                        ? Message.ERR_COUNTRY_INVALID
                        : ""}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row className="bg-secondary p-4 rounded mb-4 border">
              <h5 className="fw-bold mb-3">{Message.LBL_BUSINESS_POINT_OF_CONTACT}</h5>
              <Col md="6">
                <div className="mb-4">
                  <Label for="inpFirstname">{Message.LBL_USER_HEADER_FIRSTNAME}</Label>
                  <Input
                    id="inpFirstname"
                    name="adminFirstName"
                    type="text"
                    defaultValue={adminFirstName}
                    placeholder={Message.LBL_USER_HEADER_FIRSTNAME}
                    tabIndex={8}
                    onChange={inputOnchange}
                    data-testid="inpFirstname"
                  />
                </div>
                <div className="mb-4">
                  <Label for="inpEmail">Email</Label>
                  <Input
                    id="inpEmail"
                    name="primaryContactEmail"
                    type="email"
                    defaultValue={primaryContactEmail}
                    placeholder="Email address"
                    tabIndex={10}
                    onChange={inputOnchange}
                    data-testid="inpEmail"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-4">
                  <Label for="inpLastname">{Message.LBL_USER_HEADER_LASTNAME}</Label>
                  <Input
                    id="inpLastname"
                    name="adminLastName"
                    type="text"
                    defaultValue={adminLastName}
                    placeholder={Message.LBL_USER_HEADER_LASTNAME}
                    tabIndex={9}
                    onChange={inputOnchange}
                    data-testid="inpLastname"
                  />
                </div>
                <div>
                  <Label for="inpPhonenumber">{Message.LBL_PHONE_NUMBER_NAME}</Label>
                  <PhoneInput
                    id="inpPhonenumber"
                    name="phone"
                    className={
                      phone
                        ? isValidPhoneNumber(phone)
                          ? "form-control phone-wrapper"
                          : "is-invalid form-control phone-wrapper"
                        : "form-control phone-wrapper"
                    }
                    value={phone}
                    onChange={setPhoneNumber}
                    placeholder={Message.LBL_PHONE_NUMBER_NAME}
                    tabIndex={11}
                    defaultCountry={phoneCountry}
                    data-testid="inpPhonenumber"
                  />
                  {phone && !isValidPhoneNumber(phone) && (
                    <FormText className="text-danger">
                      {Message.ERR_CUSTOMER_MODAL_PHONE_INVALID}
                    </FormText>
                  )}
                </div>
              </Col>
            </Row>
            <hr></hr>
            <div className="d-lg-flex justify-content-between text-center text-lg-start">
              <p className="mb-3 fw-bold">* {Message.REQUIRED_QUESTION_MESSAGE}</p>
              <div>
                <Button
                  color="light"
                  type="button"
                  className="me-3 py-2 px-4 btn-cancel"
                  onClick={props.toggle}
                  disabled={!!deleteUser || isSubmitting}
                  data-testid="btnCancel"
                  tabIndex={1000}
                >
                  Cancel
                </Button>
                {props.data?.customerUniqueID !== 0 && (
                  <Button
                    color="danger"
                    type="button"
                    className="me-3 py-2 px-4 btn-delete"
                    onClick={() => setDeleteUser(props.data?.customerUniqueID || 0)}
                    disabled={!!deleteUser || isSubmitting}
                    data-testid="btnDelete"
                    tabIndex={1001}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  color="primary"
                  className="py-2 px-4"
                  type="submit"
                  disabled={!!deleteUser || isSubmitting}
                  data-testid="btnSubmit"
                  tabIndex={1002}
                >
                  {isSubmitting && (
                    <Spinner color="light" size="sm" className="me-1">
                      Loading...
                    </Spinner>
                  )}
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <DeleteModal {...deleteModalProps} />
    </>
  )
}

export default CustomerModal
