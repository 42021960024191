import { memo } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  getBatteryFilter,
  getLoadUnit,
  getLoadFilter,
  addFilterData,
  removeFilterData,
  setLoadUnit
} from "../../store/counter/visualizationSlice"
import { AppDispatch } from "../../store/store"

import Messages from "../../utils/messages"

import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"

interface VisualizationFilterTab {
  filter: VisualizationFilterEnum
}

const VisualizationFilterTab = (props: VisualizationFilterTab) => {
  const dispatch = useDispatch<AppDispatch>()
  const batteryFilter = useSelector(getBatteryFilter)
  const loadUnit = useSelector(getLoadUnit)
  const loadFilter = useSelector(getLoadFilter)

  const isVoltage = loadUnit === LoadFilterEnum.Voltage

  const isTemperatureChecked = (index: BatterFilterEnum, value: BatterFilterDataEnum) => {
    return batteryFilter[index].filter((item: BatterFilterDataEnum) => item === value).length > 0
  }

  const isLoadChecked = (index: LoadFilterEnum, value: LoadFilterDataEnum) => {
    return loadFilter[index].filter((item: LoadFilterDataEnum) => item === value).length > 0
  }

  const handleFiltersChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filters: VisualizationFilterEnum,
    dataKey: BatterFilterEnum | LoadFilterEnum,
    data: BatterFilterDataEnum | LoadFilterDataEnum
  ) => {
    if (event.target.checked) {
      dispatch(
        addFilterData({
          filters,
          dataKey,
          data
        })
      )
    } else {
      dispatch(
        removeFilterData({
          filters,
          dataKey,
          data
        })
      )
    }
  }

  const handleSetLoadUnit = (value: LoadFilterEnum) => {
    dispatch(setLoadUnit(value))
  }

  return (
    <>
      {props.filter === VisualizationFilterEnum.Battery ? (
        <>
          <div className="row mb-3">
            <div className="col-6 text-break">{Messages.LBL_VISUALIZATION_SOC}</div>
            <div className="col-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkSoc"
                  checked={isTemperatureChecked(BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.SOC,
                      BatterFilterDataEnum.SOC
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkSoc">
                  {Messages.LBL_VISUALIZATION_SOC}
                </label>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-6 text-break">{Messages.LBL_VISUALIZATION_TEMPERATURE}</div>
            <div className="col-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkTempMaximum"
                  checked={isTemperatureChecked(
                    BatterFilterEnum.Temperature,
                    BatterFilterDataEnum.Maximum
                  )}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.Temperature,
                      BatterFilterDataEnum.Maximum
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkTempMaximum">
                  {Messages.LBL_VISUALIZATION_MAXIMUM}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkTempAverage"
                  checked={isTemperatureChecked(
                    BatterFilterEnum.Temperature,
                    BatterFilterDataEnum.Average
                  )}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.Temperature,
                      BatterFilterDataEnum.Average
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkTempAverage">
                  {Messages.LBL_VISUALIZATION_AVERAGE}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkTempMinimum"
                  checked={isTemperatureChecked(
                    BatterFilterEnum.Temperature,
                    BatterFilterDataEnum.Minimum
                  )}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.Temperature,
                      BatterFilterDataEnum.Minimum
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkTempMinimum">
                  {Messages.LBL_VISUALIZATION_MINIMUM}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">{Messages.LBL_VISUALIZATION_VOLTAGE}</div>
            <div className="col-6">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkVolMaximum"
                  checked={isTemperatureChecked(
                    BatterFilterEnum.Voltage,
                    BatterFilterDataEnum.Maximum
                  )}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.Voltage,
                      BatterFilterDataEnum.Maximum
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkVolMaximum">
                  {Messages.LBL_VISUALIZATION_MAXIMUM}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkVolAverage"
                  checked={isTemperatureChecked(
                    BatterFilterEnum.Voltage,
                    BatterFilterDataEnum.Average
                  )}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.Voltage,
                      BatterFilterDataEnum.Average
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkVolAverage">
                  {Messages.LBL_VISUALIZATION_AVERAGE}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkVolMinimum"
                  checked={isTemperatureChecked(
                    BatterFilterEnum.Voltage,
                    BatterFilterDataEnum.Minimum
                  )}
                  onChange={(event) =>
                    handleFiltersChange(
                      event,
                      VisualizationFilterEnum.Battery,
                      BatterFilterEnum.Voltage,
                      BatterFilterDataEnum.Minimum
                    )
                  }
                />
                <label className="form-check-label" htmlFor="chkVolMinimum">
                  {Messages.LBL_VISUALIZATION_MINIMUM}
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-3">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="radCurrent"
                value="option1"
                checked={!isVoltage}
                onChange={() => handleSetLoadUnit(LoadFilterEnum.Current)}
              />
              <label className="form-check-label" htmlFor="radCurrent">
                {Messages.LBL_VISUALIZATION_CURRENT}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="readVoltage"
                value="option2"
                checked={isVoltage}
                onChange={() => handleSetLoadUnit(LoadFilterEnum.Voltage)}
              />
              <label className="form-check-label" htmlFor="readVoltage">
                {Messages.LBL_VISUALIZATION_VOLTAGE}
              </label>
            </div>
          </div>

          <div className="mb-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="chkVAB"
                checked={isLoadChecked(
                  loadUnit,
                  isVoltage ? LoadFilterDataEnum.VAB : LoadFilterDataEnum.IA
                )}
                onChange={(event) =>
                  handleFiltersChange(
                    event,
                    VisualizationFilterEnum.Load,
                    loadUnit,
                    isVoltage ? LoadFilterDataEnum.VAB : LoadFilterDataEnum.IA
                  )
                }
              />
              <label className="form-check-label" htmlFor="chkVAB">
                {isVoltage ? (
                  <>
                    V<small>AB</small>(V)
                  </>
                ) : (
                  <>
                    I<small>A</small>(A)
                  </>
                )}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="chkVBC"
                checked={isLoadChecked(
                  loadUnit,
                  isVoltage ? LoadFilterDataEnum.VBC : LoadFilterDataEnum.IB
                )}
                onChange={(event) =>
                  handleFiltersChange(
                    event,
                    VisualizationFilterEnum.Load,
                    loadUnit,
                    isVoltage ? LoadFilterDataEnum.VBC : LoadFilterDataEnum.IB
                  )
                }
              />
              <label className="form-check-label" htmlFor="chkVBC">
                {isVoltage ? (
                  <>
                    V<small>BC</small>(V)
                  </>
                ) : (
                  <>
                    I<small>B</small>(A)
                  </>
                )}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="chkVCA"
                checked={isLoadChecked(
                  loadUnit,
                  isVoltage ? LoadFilterDataEnum.VCA : LoadFilterDataEnum.IC
                )}
                onChange={(event) =>
                  handleFiltersChange(
                    event,
                    VisualizationFilterEnum.Load,
                    loadUnit,
                    isVoltage ? LoadFilterDataEnum.VCA : LoadFilterDataEnum.IC
                  )
                }
              />
              <label className="form-check-label" htmlFor="chkVCA">
                {isVoltage ? (
                  <>
                    V<small>CA</small>(V)
                  </>
                ) : (
                  <>
                    I<small>C</small>(A)
                  </>
                )}
              </label>
            </div>
          </div>

          <p className="fw-bold">{Messages.LBL_VISUALIZATION_REAL_POWER}</p>
          <div className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="chkRlPCommanded"
                checked={isLoadChecked(LoadFilterEnum.RealPower, LoadFilterDataEnum.Commanded)}
                onChange={(event) =>
                  handleFiltersChange(
                    event,
                    VisualizationFilterEnum.Load,
                    LoadFilterEnum.RealPower,
                    LoadFilterDataEnum.Commanded
                  )
                }
              />
              <label className="form-check-label" htmlFor="chkRlPCommanded">
                {Messages.LBL_VISUALIZATION_COMMANDED}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="chkRlPActual"
                checked={isLoadChecked(LoadFilterEnum.RealPower, LoadFilterDataEnum.Actual)}
                onChange={(event) =>
                  handleFiltersChange(
                    event,
                    VisualizationFilterEnum.Load,
                    LoadFilterEnum.RealPower,
                    LoadFilterDataEnum.Actual
                  )
                }
              />
              <label className="form-check-label" htmlFor="chkRlPActual">
                {Messages.LBL_VISUALIZATION_ACTUAL}
              </label>
            </div>
          </div>

          <p className="fw-bold">{Messages.LBL_VISUALIZATION_REACTIVE_POWER}</p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="chkRtPCommanded"
              checked={isLoadChecked(LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Commanded)}
              onChange={(event) =>
                handleFiltersChange(
                  event,
                  VisualizationFilterEnum.Load,
                  LoadFilterEnum.ReactivePower,
                  LoadFilterDataEnum.Commanded
                )
              }
            />
            <label className="form-check-label" htmlFor="chkRtPCommanded">
              {Messages.LBL_VISUALIZATION_COMMANDED}
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="chkRtPActual"
              checked={isLoadChecked(LoadFilterEnum.ReactivePower, LoadFilterDataEnum.Actual)}
              onChange={(event) =>
                handleFiltersChange(
                  event,
                  VisualizationFilterEnum.Load,
                  LoadFilterEnum.ReactivePower,
                  LoadFilterDataEnum.Actual
                )
              }
            />
            <label className="form-check-label" htmlFor="chkRtPActual">
              {Messages.LBL_VISUALIZATION_ACTUAL}
            </label>
          </div>
        </>
      )}
    </>
  )
}

export default memo(VisualizationFilterTab)
