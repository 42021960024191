import api from "../utils/axios"
import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import axios, { AxiosProgressEvent, AxiosResponse } from "axios"
import { ErrorItem } from "interfaces/ErrorItem"
import { PresignUploadDocRes } from "interfaces/doc/PresignDoc"
import { DocEntity } from "interfaces/doc/DocEntity"
import { PageInfo } from "interfaces/PageInfo"

const docRoute = "/api/doc"
const categoryRoute = "/api/doc/category"

export const apiGetDoc = async (
  customerId?: number,
  siteIds?: number[],
  categoryId?: number,
  pageNumber = 0,
  sortString = "",
  pageSize = process.env.REACT_APP_PAGE_SIZE,
  searchText?: string
) => {
  try {
    let endpoint = customerId ? `${docRoute}?customerId=${customerId}&` : `${docRoute}?`
    endpoint += siteIds && siteIds.length !== 0 ? `siteIds=${siteIds}&` : ""
    endpoint += categoryId ? `categoryId=${categoryId}&` : ""
    endpoint += `page=${pageNumber}&size=${pageSize}${sortString}${searchText ? "&name=" + searchText : ""}`
    const response = await api.get(`${endpoint}`)

    return {
      status: response.status === 200,
      data: response.data.data,
      message: Message.ERR_DOCUMENTS_GET_LIST_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : [],
      pageInfo: response.data.pageInfo as PageInfo
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list document =>", e.message)

    writeApiLogEvent("get list document", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_GET_LIST_FAIL)
    }
  }
}

export const apiGetDocByCatgory = async (
  pageNumber = 0,
  sortString = "",
  pageSize = process.env.REACT_APP_PAGE_SIZE,
  searchText?: string
) => {
  try {
    const endpoint = `${categoryRoute}?page=${pageNumber}&size=${pageSize}${sortString}&${searchText ? "&name=" + searchText : ""}`
    const response = await api.get(`${endpoint}`)

    return {
      status: response.status === 200,
      data: response.data.data,
      message: Message.ERR_DOCUMENTS_GET_LIST_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : [],
      pageInfo: response.data.pageInfo as PageInfo
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list document =>", e.message)

    writeApiLogEvent("get list document", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_GET_LIST_FAIL)
    }
  }
}

const apiCreateDoc = async (uploadRequest: DocEntity) => {
  try {
    const response = await api.post(`${docRoute}`, uploadRequest)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_DOCUMENTS_UPLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: create doc =>", e.message)
    writeApiLogEvent("create doc", e)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_UPLOAD_FAIL)
    }
  }
}

export const apiUpdateDoc = async (doc: DocEntity) => {
  try {
    const response = await api.put(`${docRoute}/${doc.id}`, doc)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_DOCUMENTS_UPDATE_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: update doc =>", e.message)
    writeApiLogEvent("update doc", e)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_UPDATE_FAIL)
    }
  }
}

export const apiDeleteDoc = async (id: number) => {
  try {
    const response = await api.delete(`${docRoute}/${id}`)
    return {
      status: response.status === 204 || response.status === 200
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: delete doc =>", e.message)

    writeApiLogEvent("delete doc", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiDownloadDoc = async (customerId: number, docIds: number[]) => {
  try {
    const response = await api.post(`${docRoute}/retrieval?customerId=${customerId}`, { docIds })

    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_DOCUMENTS_DOWNLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : [],
      pageInfo: response.data.pageInfo as PageInfo
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list doc download link  =>", e.message)

    writeApiLogEvent("get list doc download link", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_DOWNLOAD_FAIL)
    }
  }
}

export const apiUploadDoc = async (
  doc: DocEntity,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    return await apiSinglePartUpload(doc, file, OnUploadProgress)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: upload doc =>", e.message)
    writeApiLogEvent("upload doc", e)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_UPLOAD_FAIL)
    }
  }
}

const apiSinglePartUpload = async (
  doc: DocEntity,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    // const checksum = await calculateFileChecksum(file)
    let response = await apiGetUploadUrl(doc)
    if (!response.status) {
      throw new Error(response.errorLists)
    }

    // TODO: check if the response.data is correct type of PresignUploadDocRes
    const docUpload: PresignUploadDocRes = response.data
    response = await apiSingleUpload(docUpload.preSignedUrl, file, OnUploadProgress)
    if (!response.status) {
      throw new Error(response.message)
    }
    // TODO: compare etag with checksum

    const requestDTO: DocEntity = {
      ...doc,
      filePath: docUpload.filePath,
      fileId: docUpload.fileId,
      fileType: `.${file.name.split(".").pop() || ""}`
    }
    response = await apiCreateDoc(requestDTO)

    return {
      status: response.status,
      data: response.data,
      message: Message.ERR_DOCUMENTS_UPLOAD_FAIL,
      errorLists: response.errorLists || []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: single part upload =>", e.message)
    writeApiLogEvent("single part upload doc", e)
    return {
      status: false,
      message: e.message || responseErrorMessage(e.code, Message.ERR_DOCUMENTS_UPLOAD_FAIL)
    }
  }
}

const apiGetUploadUrl = async (doc: DocEntity) => {
  try {
    const response = await api.post(`${docRoute}/presigned-upload`, doc)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_DOCUMENTS_UPLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get doc single upload url =>", e.message)

    writeApiLogEvent("get doc single upload url", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_UPLOAD_FAIL)
    }
  }
}

const apiSingleUpload = async (
  url: string,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const formData = new FormData()
    formData.append("file", file)
    const response = await axios.put(url, file, {
      onUploadProgress: OnUploadProgress,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 600000 // 10 minutes in milliseconds
    })
    return {
      status: response.status === 200,
      data: getEtag(response),
      message: Message.ERR_DOCUMENTS_UPLOAD_FAIL,
      errorLists: []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: single upload doc to s3 =>", e.message)
    writeApiLogEvent("single upload doc to s3", e)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_UPLOAD_FAIL)
    }
  }
}

export const apiGetCategory = async () => {
  try {
    const response = await api.get(`${categoryRoute}`)

    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_DOCUMENTS_CATEGORY_GET_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : [],
      pageInfo: response.data.pageInfo as PageInfo
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list category =>", e.message)

    writeApiLogEvent("get list category", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOCUMENTS_CATEGORY_GET_FAIL)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEtag = (response: AxiosResponse<any, any>) => {
  let eTag = ""
  if (response.headers && typeof response.headers.get === "function") {
    eTag = response.headers.get("ETag")?.toString()?.replaceAll('"', "") || ""
  }
  return eTag
}
