import React, { useState, useEffect } from "react"
import { Collapse } from "reactstrap"
import arrowOpen from "../assets/images/icons/admin/arrow-open.svg"
import arrowClosed from "../assets/images/icons/admin/arrow-closed.svg"

type CollapsibleContainerProps = {
  header: React.ReactNode
  content: React.ReactNode
  isActive: boolean
  onToggleCollapse?: () => void
}

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = (
  props: CollapsibleContainerProps
) => {
  const { isActive } = props
  const [isCollapsed, setIsCollapsed] = useState(!isActive)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
    props.onToggleCollapse && props.onToggleCollapse()
  }

  useEffect(() => {
    setIsCollapsed(!isActive)
  }, [isActive])

  const header = (
    <div
      className={`d-flex justify-content-between cursor-p ${isCollapsed ? "content-collapsing" : "content-expanding"}`}
      onClick={handleToggleCollapse}
    >
      {props.header}
      <img
        src={isCollapsed ? arrowClosed : arrowOpen}
        alt="open"
        className="arrow-open arrow cursor-p"
      />
    </div>
  )

  const content = !isCollapsed && props.content

  return (
    <>
      {header}
      <Collapse isOpen={!isCollapsed}>{content}</Collapse>
    </>
  )
}

export default CollapsibleContainer
