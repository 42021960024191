import { useState, useEffect } from "react"
import { Collapse, Nav } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import bateryGroup from "../../assets/images/monitoring/img/batery-group.svg"
import batteryOnline from "../../assets/images/monitoring/img/batery-online.svg"
import batteryIdle from "../../assets/images/monitoring/img/batery-idle.svg"
import batteryFault from "../../assets/images/monitoring/img/batery-fault.svg"

import { apiMonitoringDataConnectedCheck } from "services/apiSiteMonitoring"
import { getCurrentUserState, getSidebarOpen } from "store/counter/authSlice"

import { apiAdminListAllSites } from "../../services/apiSitesManagement"

import { SiteEntity } from "../../interfaces/SiteEntity"
import { TsDataOverview } from "interfaces/monitoring/maxnerva/SiteOverviewData"
import { ChildSidebarProps } from "interfaces/ChildSidebarPops"

import { SITE_STATUS } from "../../utils/constants"
import Messages from "../../utils/messages"
import constants from "../../utils/monitoring/en"

import { AppDispatch } from "../../store/store"

import {
  setSiteEntity,
  fetchSummaryData,
  fechTeraStorData,
  setIsFetchingSiteList,
  getSitesOverviewData,
  getOverviewGroupedData,
  fetchSitesOverviewData,
  setSiteEntityList,
  getSiteEntityList,
  getIsFetchingSiteList,
  getShowSoftwareStatus,
  setIsFetchingSummary
} from "store/counter/monitoringSlice"

// Components
import Loader from "components/Loader"
import SiteNavItem from "./SidebarNavItem"
import CollapsibleContainer from "components/CollapsibleContainer"
import { SiteEntityMonitoring, SiteMonitoringDataCheck } from "interfaces/SiteEntityMonitoring"
import MonitoringSearch from "./MonirtoringSearch"
import { SearchObject } from "interfaces/monitoring/SearchProps"

const MonitoringSidebar = (props: ChildSidebarProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const currentUser = useSelector(getCurrentUserState)
  const sideBarOpen = useSelector(getSidebarOpen)
  const sitesOverviewData = useSelector(getSitesOverviewData)
  const listSites = useSelector(getSiteEntityList)
  const isFetchingSiteList = useSelector(getIsFetchingSiteList)
  const showSoftwareStatus = useSelector(getShowSoftwareStatus)

  const { siteUniqueId: siteId } = useParams()
  const siteUniqueId = siteId ? parseInt(siteId) : 0
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [groupedData, setGroupedData] = useState<Record<string, TsDataOverview[]>>({})
  const [searchGroupName, setSearchGroupName] = useState("")

  /**
   * Constructor
   * Fetching list assign site
   * Redirect to first site
   * Fetching site status
   */
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    setLoading(true)
    dispatch(setIsFetchingSiteList(true))
    let listSiteEntitesMonitoring: SiteEntityMonitoring[] = []

    // get list sites
    const res = await apiAdminListAllSites()
    if (res.status && res.data) {
      // get site overview data
      const siteUniqueIds = res.data.map((site) => site.siteUniqueId) ?? []
      if (siteUniqueIds.length > 0) {
        dispatch(
          fetchSitesOverviewData({
            siteUniqueIds: siteUniqueIds,
            customerUniqueId: currentUser?.customerUniqueId || 0
          })
        )
      }

      // get monitoring data connected check
      const dataConnectedRes = await apiMonitoringDataConnectedCheck()
      if (dataConnectedRes.status) {
        const siteMonitoringDataCheckList: SiteMonitoringDataCheck = dataConnectedRes.data ?? []

        listSiteEntitesMonitoring = res.data.map((site) => ({
          ...site,
          dataConnected: siteMonitoringDataCheckList[site.siteUniqueId] ?? false
        }))
      }
      setError("")
    } else {
      setError(res.message || "")
    }

    dispatch(setSiteEntityList(listSiteEntitesMonitoring))
    dispatch(setIsFetchingSiteList(false))
    setLoading(false)
  }

  useEffect(() => {
    if (!isFetchingSiteList) {
      selectDefaultSite()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingSiteList])

  const selectDefaultSite = () => {
    if (listSites && listSites.length > 0) {
      let siteIdParam = props.getPath(2)
      if (!siteIdParam) {
        siteIdParam = listSites[0].siteUniqueId.toString()
      }
      const siteEntity = listSites?.find((site) => site.siteUniqueId.toString() === siteIdParam)
      if (siteEntity) {
        navigate(`/monitoring/` + siteIdParam + `/all`)
        dispatch(setSiteEntity(siteEntity))
        dispatch(fetchSummaryData({ siteUniqueId: siteEntity.siteUniqueId }))
        dispatch(
          fechTeraStorData({
            siteUniqueId: siteEntity.siteUniqueId,
            showSoftwareStatus: showSoftwareStatus
          })
        )
        setIsExpanded(true)
      } else {
        // Set site null when user does not have access to the site
        dispatch(setSiteEntity(null))
      }
    } else {
      if (listSites) {
        dispatch(setSiteEntity(null))
      }
    }
  }

  // Catch click site, redirect to new route, fetching new data
  useEffect(() => {
    if (siteUniqueId !== 0 && checkSiteStatus(siteUniqueId)) {
      dispatch(setIsFetchingSummary(true))
      dispatch(fetchSummaryData({ siteUniqueId: siteUniqueId }))
      dispatch(
        fechTeraStorData({ siteUniqueId: siteUniqueId, showSoftwareStatus: showSoftwareStatus })
      )
      setIsExpanded(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteUniqueId])

  useEffect(() => {
    const selectedSite = sitesOverviewData.find((site) => site.siteUniqueId === siteUniqueId)
    if (selectedSite) {
      const groupedData = getOverviewGroupedData(selectedSite.groups)
      setGroupedData(groupedData)
    } else {
      setGroupedData({})
    }
  }, [siteUniqueId, sitesOverviewData])

  // click site redirect to new route, update site entity
  const navigate = useNavigate()
  const onSiteSelected = (siteEntity: SiteEntity, searchData?: SearchObject) => {
    if (siteUniqueId !== siteEntity.siteUniqueId) {
      navigate(`/monitoring/` + siteEntity.siteUniqueId + `/all`)
      dispatch(setSiteEntity(siteEntity))
      setIsExpanded(true)
    } else if (!searchData) {
      toggle()
    }
    if (searchData && searchData.groupId) {
      setSearchGroupName(searchData.name)
      setIsExpanded(true)
    } else {
      setSearchGroupName("")
    }
  }

  const isSiteSelected = (siteUniqueId: string) => {
    const param = props.getPath(2)
    if (!param) return false
    return param.toString() === `${siteUniqueId}`
  }

  const checkSiteStatus = (siteUniqueId: number) => {
    const siteStatus = listSites?.find((status) => status.siteUniqueId === siteUniqueId)
    return siteStatus?.status === SITE_STATUS.READY_FOR_INSTALLATION || siteStatus?.dataConnected
  }

  const [isExpanded, setIsExpanded] = useState(false)
  const toggle = () => {
    setIsExpanded((oldValue) => !oldValue)
  }

  const isDangerStatus = (status: number | null) => {
    return status === constants.TS_STATE_FAULT || status === constants.TS_STATE_EPO
  }

  return (
    <div className="monitoring" style={{ display: `${sideBarOpen ? "block" : "none"}` }}>
      {/* search input */}
      <MonitoringSearch
        listSites={listSites}
        sitesOverviewData={sitesOverviewData}
        onSiteSelected={onSiteSelected}
      />

      <hr className="mx-3 mb-4 mt-0" />

      {/* list site */}
      {isLoading ? (
        <Loader status="loading" message={Messages.LBL_LOADING_SITES} />
      ) : error ? (
        <Loader status="error" message={error} />
      ) : (
        <Nav vertical className="sidebarNav" tag="div">
          {listSites?.map((site, index) => (
            <div className="nav-item w-100" key={index}>
              <SiteNavItem
                site={site}
                isSelected={isSiteSelected(`${site.siteUniqueId}`)}
                onSiteSelected={onSiteSelected}
                isOpen={isExpanded}
              ></SiteNavItem>

              {/* TeraStor group view */}
              {checkSiteStatus(site.siteUniqueId) && isSiteSelected(`${site.siteUniqueId}`) && (
                <Collapse isOpen={isExpanded}>
                  <div className="nav-dropdown">
                    <ul className="list-group">
                      {Object.keys(groupedData).map((groupname) => (
                        <li className="list-group-item border-0" key={groupname}>
                          <CollapsibleContainer
                            header={
                              <a className="list-group-link btn d-flex justify-content-between border-0">
                                <span>
                                  <img
                                    src={bateryGroup}
                                    alt="site group"
                                    className="me-2 batery-group-icon mb-1"
                                  />
                                  {groupname} ({groupedData[groupname].length})
                                </span>
                              </a>
                            }
                            content={
                              <div className="nav-sub-dropdown px-2">
                                <ul className="list-group">
                                  {groupedData[groupname].map((item, index) => {
                                    let imgSrc
                                    switch (item.status) {
                                      case constants.TS_STATE_ONLINE:
                                        imgSrc = batteryOnline
                                        break
                                      case constants.TS_STATE_MAINTENANCE:
                                        imgSrc = batteryFault
                                        break
                                      default:
                                        imgSrc = batteryIdle
                                    }
                                    return (
                                      <li className={`list-group-item border-0`} key={index}>
                                        <img src={imgSrc} alt="batery" className="me-2 mb-1" />
                                        <span
                                          className={`px-1
                                            ${isDangerStatus(item.status) ? "ts-danger" : ""}
                                          `}
                                        >
                                          {constants.TERASTOR}
                                          <span
                                            className={`
                                            ${isDangerStatus(item.status) ? "text-red" : ""}
                                          `}
                                          >
                                            {" "}
                                            #{item.teraStorID}
                                          </span>
                                        </span>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            }
                            isActive={searchGroupName === groupname}
                          ></CollapsibleContainer>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              )}
            </div>
          ))}
        </Nav>
      )}
    </div>
  )
}

export default MonitoringSidebar
