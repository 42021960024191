import { useState, memo } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  getViewBy,
  getSelectedTeraStors,
  setSelectedTeraStors
} from "../../store/counter/visualizationSlice"
import { AppDispatch } from "../../store/store"

import close from "../../assets/images/icons/visualization/close.svg"

import Chart from "./Chart"
import Desktop from "./chart-head/Desktop"
import Mobile from "./chart-head/Mobile"

import { DropDown } from "../../interfaces/visualization/Dropdown"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { ChartActiveData } from "../../interfaces/visualization/ChartActiveData"
import { ChartData } from "../../interfaces/visualization/ChartData"

import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import Messages from "../../utils/messages"

interface ChartWrapperProps {
  status?: boolean
  class?: string
  expandToggle?: () => void

  detailToggle?: () => void
  setTooltipData?: (data: ChartActiveData) => void

  setFilters?: (items: DropDown[]) => void

  openInModal?: boolean
  tsId?: number
  setTsId?: (tsId?: number) => void

  setDownloadProcess: (isOpen: boolean, progress: number) => void
  setDownloadSucceed: (modal: boolean) => void

  onChartLoaded?: () => void
  wrapper?: string
}

const ChartWrapper = memo((props: ChartWrapperProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const viewBy = useSelector(getViewBy)
  const selectedTeraStors: SiteTeraStorData[] = useSelector(getSelectedTeraStors)
  const [filters, setFilters] = useState<DropDown[]>([])
  const isAggregated = viewBy === VisualizationViewByEnum.Aggregated
  const [chartData, setChartData] = useState<ChartData[]>([])

  const handleRemoveTeraStor = (teraStor: SiteTeraStorData) => {
    dispatch(
      setSelectedTeraStors(
        selectedTeraStors.filter((item: SiteTeraStorData) => item.tsId !== teraStor.tsId)
      )
    )
  }

  const handleClearAllTeraStor = () => {
    dispatch(setSelectedTeraStors([]))
  }

  return (
    <div className={`chart-wrapper shadow px-3 pt-2 pb-3 rounded ${props.class}`}>
      <Desktop
        status={props.status}
        expandToggle={props.expandToggle}
        tsId={props.tsId}
        setTsId={props.setTsId}
        filters={filters}
        setFilters={(filters: DropDown[]) => {
          setFilters(filters)
          if (props.setFilters) props.setFilters(filters)
        }}
        chartData={chartData}
        setDownloadProcess={(isOpen: boolean, progress: number) =>
          props.setDownloadProcess(isOpen, progress)
        }
        setDownloadSucceed={(modal: boolean) => props.setDownloadSucceed(modal)}
        wrapper={props.wrapper}
      />
      <Mobile
        status={props.status}
        expandToggle={props.expandToggle}
        tsId={props.tsId}
        setTsId={props.setTsId}
        filters={filters}
        setFilters={(filters: DropDown[]) => {
          setFilters(filters)
          if (props.setFilters) props.setFilters(filters)
        }}
        chartData={chartData}
        setDownloadProcess={(isOpen: boolean, progress: number) =>
          props.setDownloadProcess(isOpen, progress)
        }
        setDownloadSucceed={(modal: boolean) => props.setDownloadSucceed(modal)}
        wrapper={props.wrapper}
      />

      {isAggregated && (
        <div className="mb-3 m-md-5 select-terastor px-md-5">
          <h5 className="mb-4 fw-bold ms-md-3">{Messages.LBL_VISUALIZATION_SHOWING_TERASTOR}</h5>
          <ul className="nav ms-md-3">
            {selectedTeraStors.map((teraStor: SiteTeraStorData, index: number) => (
              <li className="nav-item mb-2" key={index}>
                <p className="nav-link mb-0 d-flex justify-content-between">
                  <span className="text-primary me-2 text-break text-wrap">
                    {Messages.LBL_VISUALIZATION_TERASTOR} #{teraStor.tsId}
                  </span>
                  <img
                    src={close}
                    alt="Close"
                    width={15}
                    role="button"
                    onClick={() => handleRemoveTeraStor(teraStor)}
                  />
                </p>
              </li>
            ))}
            <li className="nav-item mb-2">
              <p
                className="nav-link text-decoration-underline mb-0 text-primary bg-white"
                role="button"
                onClick={handleClearAllTeraStor}
              >
                {Messages.BTN_VISUALIZATION_CLEAR_ALL}
              </p>
            </li>
          </ul>
        </div>
      )}

      <Chart
        toggle={() => isAggregated && props.detailToggle && props.detailToggle()}
        openInModal={props.openInModal}
        filters={filters}
        setTooltipData={props.setTooltipData}
        tsId={props.tsId}
        setChartData={setChartData}
        onChartLoaded={props.onChartLoaded}
      />
    </div>
  )
})

ChartWrapper.displayName = "ChartWrapper"
export default ChartWrapper
