import { useState, RefObject } from "react"
import { useSelector } from "react-redux"

import { getViewBy, getDataMode } from "../../../store/counter/visualizationSlice"

import Actions from "./Actions"
import Badge from "./Badge"
import TerastorDropdown from "./TerastorDropdown"

import { ChartHeadProps } from "../../../interfaces/visualization/ChartHeadProps"

import { VisualizationViewByEnum } from "../../../enum/visualization/tab/viewby-tab"
import { DataMode } from "../../../enum/visualization/data-mode"

const Desktop = (props: ChartHeadProps) => {
  const viewBy = useSelector(getViewBy)
  const dataMode = useSelector(getDataMode)
  const [badgeRef, setBadgeRef] = useState<RefObject<HTMLDivElement> | null>(null)

  return (
    <div className="head d-none d-xl-flex justify-content-between">
      <Badge filters={props.filters} setBadgeRef={setBadgeRef} tsId={props.tsId} />
      {viewBy === VisualizationViewByEnum.Detail && dataMode === DataMode.Average && (
        <h5 className="text-primary fw-bold">TeraStor #{props.tsId}</h5>
      )}
      {viewBy === VisualizationViewByEnum.Detail && dataMode === DataMode.Sample && (
        <TerastorDropdown tsId={props.tsId} setTsId={props.setTsId} />
      )}
      <Actions
        status={props.status}
        expandToggle={props.expandToggle}
        tsId={props.tsId}
        setTsId={props.setTsId}
        wrapper={`Desktop${props.wrapper ?? ""}`}
        filters={props.filters}
        setFilters={props.setFilters}
        chartData={props.chartData}
        badgeRef={badgeRef}
        setDownloadProcess={(isOpen: boolean, progress: number) =>
          props.setDownloadProcess(isOpen, progress)
        }
        setDownloadSucceed={(modal: boolean) => props.setDownloadSucceed(modal)}
      />
    </div>
  )
}

export default Desktop
