import { memo } from "react"
import { Spinner } from "reactstrap"

import "../assets/scss/loader.scss"
import error from "../assets/images/icons/error.svg"

interface LoaderProps {
  status?: string
  message?: string | boolean
}
const Loader = memo((props?: LoaderProps) => (
  <div className="text-center">
    {!props || !props.status || props.status === "loading" ? (
      <Spinner color="primary" className="my-2">
        Loading...
      </Spinner>
    ) : (
      <img src={error} alt="table status error" width="50px" className="my-2" />
    )}
    <p>{props && props.message ? props.message : "Loading ..."}</p>
  </div>
))

Loader.displayName = "Loader"
export default Loader
