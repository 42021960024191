import { useState, useEffect } from "react"
import { Nav, NavItem } from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { AppDispatch } from "store/store"

import monitoring from "../../assets/images/icons/monitoring.svg"
import monitoring_active from "../../assets/images/icons/monitoring-active.svg"
import visualization from "../../assets/images/icons/visualization.svg"
import visualization_active from "../../assets/images/icons/visualization-active.svg"
import admin from "../../assets/images/icons/admin.svg"
import admin_active from "../../assets/images/icons/admin-active.svg"
import sitesetup from "../../assets/images/icons/sitesetup.svg"
import sitesetup_active from "../../assets/images/icons/sitesetup-active.svg"
import document from "../../assets/images/icons/document-header.svg"
import document_active from "../../assets/images/icons/document-header-active.svg"

import { HeaderMenuProps } from "../../interfaces/HeadMenuProps"
import {
  setSiteEntity,
  getIsFetchingSiteList,
  getSiteEntityList
} from "store/counter/monitoringSlice"

const HeaderMenu = (props: HeaderMenuProps | undefined) => {
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const getPath = (index = 1) => location.pathname.split("/")[index]
  const navClass = "nav-link rounded-4 px-3"

  const isFetchingSiteList = useSelector(getIsFetchingSiteList)
  const siteEntityList = useSelector(getSiteEntityList)
  const [disableClass, setDisableClass] = useState("")

  useEffect(() => {
    if (!isFetchingSiteList && siteEntityList.length > 0) setDisableClass("")
    else setDisableClass("disabled")
  }, [isFetchingSiteList, siteEntityList])

  const onPageChange = () => {
    dispatch(setSiteEntity(undefined))
  }
  return (
    <Nav className={`header-menu my-3 mb-4 ${props?.class || ""}`}>
      <NavItem>
        <Link
          to="/monitoring"
          className={getPath() === "monitoring" ? navClass + " active" : navClass}
          onClick={onPageChange}
        >
          <img
            src={getPath() === "monitoring" ? monitoring_active : monitoring}
            alt="monitoring"
            className="me-2"
          ></img>
          Monitoring
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to={!disableClass ? "/visualization" : "#"}
          className={
            getPath() === "visualization"
              ? `${navClass} active ${disableClass}`
              : `${navClass} ${disableClass}`
          }
        >
          <img
            src={getPath() === "visualization" ? visualization_active : visualization}
            alt="visualization"
            className="me-2"
          ></img>
          Visualization
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to={!disableClass ? "/site-setup" : "#"}
          className={
            getPath() === "site-setup"
              ? `${navClass} active ${disableClass}`
              : `${navClass} ${disableClass}`
          }
          onClick={onPageChange}
        >
          <img
            src={getPath() === "site-setup" ? sitesetup_active : sitesetup}
            alt="site-setup"
            className="me-2"
          ></img>
          Site Setup
        </Link>
      </NavItem>
      <NavItem>
        <Link
          to="/document"
          className={getPath() === "document" ? navClass + " active" : navClass}
          onClick={onPageChange}
        >
          <img
            src={getPath() === "document" ? document_active : document}
            alt="document"
            className="me-2"
          ></img>
          Documents
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/admin" className={getPath() === "admin" ? navClass + " active" : navClass}>
          <img
            src={getPath() === "admin" ? admin_active : admin}
            alt="admin"
            className="me-2"
          ></img>
          Admin
        </Link>
      </NavItem>
    </Nav>
  )
}

export default HeaderMenu
