import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Input, Button, Label, FormGroup, Spinner } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Reaptcha from "reaptcha"

import { SigninBody } from "interfaces/SigninBody"

import { getChallenge, getRateLimitExceeded, signin } from "store/counter/authSlice"

import { AUTHORITY_CONSTANTS } from "utils/constants"

import eyeopen from "assets/images/icons/auth/eye-open.svg"
import eyeoclosed from "assets/images/icons/auth/eye-closed.svg"

import Messages from "utils/messages"

import Wrapper from "./Wrapper"

import {
  getIdTokenState,
  getRefreshTokenState,
  getIsLoadingState,
  getCurrentUserState,
  getUsername,
  getRememberMe
} from "store/counter/authSlice"
import { AppDispatch } from "store/store"

const Signin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const isLoading = useSelector(getIsLoadingState)
  const idToken = useSelector(getIdTokenState)
  const refreshToken = useSelector(getRefreshTokenState)
  const currentUser = useSelector(getCurrentUserState)
  const username = getUsername()
  const rememberMe = getRememberMe()

  const [checkbox, setCheckbox] = useState(rememberMe === "1")
  const [inpUsername, setInpUsername] = useState(rememberMe === "1" ? username : "")
  const [inpPassword, setInpPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const challenge = useSelector(getChallenge)
  const rateLimitExceeded = useSelector(getRateLimitExceeded)
  const [recaptcha, setRecaptcha] = useState<Reaptcha | null>()
  const [captchaReady, setCaptchaReady] = useState(false)
  const [verified, setVerified] = useState(false)
  const [captcha, setCaptcha] = useState<string | undefined>()

  useEffect(() => {
    if (rememberMe && (!idToken || !currentUser) && refreshToken) {
      dispatch(signin(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      inpUsername &&
      inpPassword &&
      challenge === AUTHORITY_CONSTANTS.ERR_KEY_NEW_PASSWORD_REQUIRED
    ) {
      navigate(`/auth/signup/${encodeURI(inpUsername)}/${encodeURI(inpPassword)}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (
      !isLoading &&
      rateLimitExceeded >= AUTHORITY_CONSTANTS.CAPTCHA_LOGIN_ATTEMPT &&
      rateLimitExceeded < AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT
    ) {
      if (captchaReady) {
        if (!recaptcha?.state.rendered) {
          recaptcha?.renderExplicitly()
        } else {
          setVerified(false)
          if (rateLimitExceeded < AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT) {
            recaptcha?.reset()
          }
        }
      }
    } else if (!isLoading) {
      setVerified(false)
      setCaptcha(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaReady, isLoading, rateLimitExceeded, recaptcha])

  const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
    setCheckbox(event.currentTarget.checked)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // get form
    const signinBody: SigninBody = {
      username: inpUsername || e.currentTarget.username.value,
      password: inpPassword || e.currentTarget.password.value,
      rememberMe: checkbox,
      captcha: captcha
    }

    // login
    dispatch(signin(signinBody))
  }

  const onVerify = (recaptchaResponse: string) => {
    setVerified(true)
    setCaptcha(recaptchaResponse)
  }

  if (rememberMe && !idToken && refreshToken) {
    return (
      <Wrapper id="signinWrapper" className="text-center">
        <Spinner color="primary" className="mb-3">
          Loading...
        </Spinner>
        <p className="mb-0">{Messages.MSG_SIGNIN_VERIFY}</p>
      </Wrapper>
    )
  }

  if (idToken && refreshToken) {
    return (
      <Wrapper id="signinWrapper" className="text-center">
        <Spinner color="primary" className="mb-3">
          Loading...
        </Spinner>
        <p className="mb-0" data-testid="succeed">
          {Messages.MSG_SIGNIN_SUCCEED}
        </p>
      </Wrapper>
    )
  }

  return (
    <Wrapper id="signinWrapper">
      <Form type="post" onSubmit={onSubmit}>
        <FormGroup floating>
          <Input
            name="username"
            type="text"
            placeholder="Username or email"
            className="mb-3"
            id="inpUsername"
            data-testid="username"
            disabled={isLoading}
            required={true}
            defaultValue={inpUsername}
            onChange={(event) => setInpUsername(event.target.value)}
          />
          <Label for="inpUsername">Username or email</Label>
        </FormGroup>
        <FormGroup floating className="rear-icon-wrapper">
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className="mb-3"
            id="inpPassword"
            data-testid="password"
            disabled={isLoading}
            required={true}
            onChange={(event) => setInpPassword(event.target.value)}
          />
          <Label for="inpPassword">Password</Label>
          <img
            src={showPassword ? eyeopen : eyeoclosed}
            className="rear-icon"
            onClick={handleShowPassword}
          />
        </FormGroup>
        <div className="d-flex justify-content-between mb-5" id="rememberMe">
          <div>
            <Input
              type="checkbox"
              className="me-2"
              name="rememeberMe"
              defaultChecked={checkbox}
              onChange={handleCheckboxChange}
              data-testid="rememeber-me"
            />{" "}
            <Label check>Remember me</Label>
          </div>
          <Link to="/auth/forgot-password" className="text-end pt-1" data-testid="forgot-password">
            Forgot password?
          </Link>
        </div>
        <FormGroup id="captcha-holder" className="d-flex justify-content-between mx-2">
          <Reaptcha
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            ref={(e) => setRecaptcha(e)}
            onVerify={onVerify}
            onLoad={() => setCaptchaReady(true)}
            explicit
          />
        </FormGroup>
        <Button
          color="primary"
          className="w-100 mt-5"
          disabled={
            isLoading ||
            (rateLimitExceeded >= AUTHORITY_CONSTANTS.CAPTCHA_LOGIN_ATTEMPT && !verified)
          }
          data-testid="signin"
        >
          {isLoading && (
            <Spinner color="light" size="sm" className="me-1">
              Loading...
            </Spinner>
          )}
          Sign In
        </Button>
        <FormGroup className="d-flex justify-content-between text-center mt-5">
          <small>
            By Signing in and creating an account, you are agreeing to our&nbsp;
            <a href="https://www.aesi-ess.com/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </small>
        </FormGroup>
      </Form>
    </Wrapper>
  )
}

export default Signin
