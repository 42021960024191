import { createAsyncThunk } from "@reduxjs/toolkit"

import { SiteEntity } from "../../interfaces/SiteEntity"
import { SiteOverviewData } from "../../interfaces/monitoring/maxnerva/SiteOverviewData"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"

import { apiMonitoringGetSitesOverview } from "../../services/apiSiteMonitoring"
import { apiAdminListAllSites } from "../../services/apiSitesManagement"

import { writeCodeLogEvent } from "../../utils/utils"
import Message from "../../utils/messages"

import { fechTeraStorData } from "./visualizationSlice"

export const fetchingListAllSites = createAsyncThunk(
  "visualization/fetchingListAllSites",
  async (customerUniqueId: number) => {
    try {
      // get list all sites
      const response = await apiAdminListAllSites()
      const data = response.data as SiteEntity[]
      let sitesOverviewData: SiteOverviewData[] = []
      let teraStorGroupData: Record<string, SiteTeraStorData[]> | null = null
      let selectedTeraStors: SiteTeraStorData[] = []
      let currentSiteEntity: SiteEntity | null = null

      if (data.length > 0) {
        // get list site overview data
        const requestBody = { siteUniqueIds: data.map((site) => site.siteUniqueId) }
        const resSiteOverviewData = await apiMonitoringGetSitesOverview(
          customerUniqueId,
          requestBody
        )
        if (resSiteOverviewData.status)
          sitesOverviewData = resSiteOverviewData.data as SiteOverviewData[]

        // if only one site then get list terastor of that site
        if (data.length === 1) {
          currentSiteEntity = data[0]
          const siteUniqueId = currentSiteEntity.siteUniqueId
          const siteOverview = sitesOverviewData.find((site) => site.siteUniqueId === siteUniqueId)

          const resTeraStorData = await fechTeraStorData(siteUniqueId, siteOverview?.groups ?? [])
          if (resTeraStorData.status) {
            const tempData = resTeraStorData.data
            const temp: SiteTeraStorData[] = []

            Object.keys(tempData).forEach((name: string) => {
              tempData[name].forEach((teraStor: SiteTeraStorData) => {
                temp.push(teraStor)
              })
            })

            selectedTeraStors = temp
            teraStorGroupData = tempData
          }
        }
      }

      return {
        status: response.status,
        message: response.message,
        data: {
          allSites: data,
          sitesOverviewData,
          teraStorGroupData,
          selectedTeraStors,
          currentSiteEntity
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(
        "Code exception: thunk get list all site, site overview data and list terastor =>",
        err.message
      )

      writeCodeLogEvent("thunk get list all site, site overview data and list terastor", err)

      return {
        status: false,
        message: Message.ERR_SITE_GET_LIST_FAILED
      }
    }
  }
)
