import { FWData } from "./FWData"

export interface FWUploadTableProps {
  title: string
  fwList: FWData[]
  type: FWType
  removeFW: (deletingFW: FWData) => void
  addNewFW: (newFW: FWData) => void
  onReleaseNotesClick: (fwData: FWData) => void
  setSelectedFW: (fwData: FWData | null) => void
  isLoading: boolean
  selectedFw: FWData | null // Change to ID for unique
}

export enum FWType {
  BMS = "BMS",
  EMS = "EMS"
}
