import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import { UpdateFWModalProps } from "interfaces/UpdateFWModalProps"
import Messages from "utils/messages"
import { useEffect, useState } from "react"
import ExpandableWidthCard from "components/cards/ExpandableWidthCard"
import FWUploadTable from "components/fw-update/FWUploadTable"
import close from "../../assets/images/icons/admin/close.svg"
import { FWType } from "interfaces/FWUploadTableProps"
import FWReleasesNoteModal from "components/modal/FWReleasesNoteModal"
import { FWReleasesNoteModalProps } from "interfaces/FWReleasesNoteModalProps"
import { apiDeleteFirmware, apiGetFirmware, apiUpdateFirmware } from "services/apiFWUpdate"
import { FWData, ReleaseNotes } from "interfaces/FWData"
import { SiteEntity } from "interfaces/SiteEntity"
import AlertModal from "components/modal/AlertModal"
import { AlertModalProps } from "interfaces/AlertModalProps"
import { apiAdminStageFirmware } from "services/apiSitesManagement"
import { stringFormat, writeCodeLogEvent } from "utils/utils"
import { DeleteModalProps } from "interfaces/DeleteModalProps"
import DeleteModal from "components/modal/DeleteModal"
import { getFWTypeString } from "utils/utils"

export type GroupedSites = Record<number, SiteEntity[]>

const UpdateFWModal = (props: UpdateFWModalProps) => {
  const [groupedSites, setGroupedSites] = useState<GroupedSites>()
  const [bmsFWList, setBMSFWList] = useState<FWData[]>([])
  const [emsFWList, setEMSFWList] = useState<FWData[]>([])
  const [selectedBMSFW, setSelectedBMSFW] = useState<FWData | null>(null)
  const [selectedEMSFW, setSelectedEMSFW] = useState<FWData | null>(null)
  const [loadingStates, setLoadingStates] = useState({ isLoadingEMS: true, isLoadingBMS: true })
  const [stagingFW, setStagingFW] = useState(false)

  useEffect(() => {
    const groupedSites = props.listSite.reduce((grouped: GroupedSites, site: SiteEntity) => {
      const key = site.customerUniqueId
      if (!grouped[key]) {
        grouped[key] = []
      }
      grouped[key].push(site)
      return grouped
    }, {})
    setGroupedSites(groupedSites)
  }, [props.listSite])

  useEffect(() => {
    apiGetFirmware(FWType.BMS)
      .then((res) => {
        if (res.status) {
          const data = res.data || []
          setBMSFWList(data)
          setLoadingStates((oldStates) => ({ ...oldStates, isLoadingBMS: false }))
        } else {
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: res.errorLists || res.message,
            modal: true
          })
        }
      })
      .catch((e) => {
        console.error("Code exception: handle list fw =>", e.message)

        writeCodeLogEvent("handle list fw", e)
      })
    apiGetFirmware(FWType.EMS)
      .then((res) => {
        if (res.status) {
          const data = res.data || []
          setEMSFWList(data)
          setLoadingStates((oldStates) => ({ ...oldStates, isLoadingEMS: false }))
        } else {
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: res.errorLists || res.message,
            modal: true
          })
        }
      })
      .catch((e) => {
        console.error("Code exception: handle list fw =>", e.message)

        writeCodeLogEvent("handle list fw", e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeGroupCustomer = (customerUniqueId: number) => {
    const updatedGroupedSites = { ...groupedSites }
    delete updatedGroupedSites[customerUniqueId]
    setGroupedSites(updatedGroupedSites)
  }

  const removeSite = (customerUniqueId: number, siteUniqueId: number) => {
    const updatedGroupedSites = { ...groupedSites }
    updatedGroupedSites[customerUniqueId] = updatedGroupedSites[customerUniqueId].filter(
      (site) => site.siteUniqueId !== siteUniqueId
    )
    if (updatedGroupedSites[customerUniqueId].length === 0) {
      delete updatedGroupedSites[customerUniqueId]
    }
    setGroupedSites(updatedGroupedSites)
  }

  const removeFirmware = (deletingFW: FWData) => {
    setDeletingFW(deletingFW)
    setDeleteModal(true)
  }

  const addNewBMSFW = (newFW: FWData) => {
    const updatedFWList = [newFW, ...bmsFWList]
    setBMSFWList(updatedFWList)
    setSelectedBMSFW(null)
  }

  const addNewEMSFW = (newFW: FWData) => {
    const updatedFWList = [newFW, ...emsFWList]
    setEMSFWList(updatedFWList)
    setSelectedEMSFW(null)
  }

  const selectBMSFWToStage = (fwData: FWData | null) => {
    setSelectedBMSFW(fwData)
  }

  const selectEMSFWToStage = (fwData: FWData | null) => {
    setSelectedEMSFW(fwData)
  }

  const showReleaseNoteModal = (fwData: FWData) => {
    setFWReleasesNoteModalProps((oldProps) => ({
      ...oldProps,
      modal: true,
      fwData: fwData,
      type: fwData.type || FWType.BMS,
      onDone: saveReleaseNotes
    }))
  }

  const saveReleaseNotes = (releaseNotes: ReleaseNotes, fwData: FWData) => {
    const updatedNote = { ...fwData, releaseNote: JSON.stringify(releaseNotes) }
    apiUpdateFirmware(fwData.type, updatedNote).then((res) => {
      if (res.status) {
        if (fwData.type === FWType.BMS) {
          setBMSFWList((oldList) =>
            oldList.map((fw) =>
              fw.id === fwData.id ? { ...fw, releaseNote: JSON.stringify(releaseNotes) } : fw
            )
          )
        } else if (fwData.type === FWType.EMS) {
          setEMSFWList((oldList) =>
            oldList.map((fw) =>
              fw.id === fwData.id ? { ...fw, releaseNote: JSON.stringify(releaseNotes) } : fw
            )
          )
        }
      } else {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: res.errorLists || res.message,
          modal: true
        })
      }
    })
  }

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  // =========================================
  // ========== Release notes modal ==========
  // =========================================
  const [fwReleasesNoteModalProps, setFWReleasesNoteModalProps] =
    useState<FWReleasesNoteModalProps>({
      size: "lg",
      modal: false,
      toggle: () => {
        setFWReleasesNoteModalProps({
          ...fwReleasesNoteModalProps,
          modal: false
        })
      },
      fwData: null,
      onDone: saveReleaseNotes,
      type: FWType.BMS
    })

  // =========================================
  // ============= Delete modal ==============
  // =========================================
  const [deletingFW, setDeletingFW] = useState<FWData | null>(null)
  const [deletemodal, setDeleteModal] = useState(false)
  const deleteToggle = () => setDeleteModal(!deletemodal)
  const deleteResponse = (status: boolean) => {
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: status ? "success" : "error",
      message: status
        ? stringFormat(Messages.MSG_FW_UPDATE_DELETE_FW_SUCCESSFULLY, [
            getFWTypeString(deletingFW?.type),
            deletingFW?.version || ""
          ])
        : stringFormat(Messages.MSG_FW_UPDATE_DELETE_FW_FAIL, [
            getFWTypeString(deletingFW?.type),
            deletingFW?.version || ""
          ])
    })

    if (status) {
      if (deletingFW?.type === FWType.BMS) {
        setBMSFWList((oldList) => oldList.filter((fw) => fw.id !== deletingFW.id))
        if (selectedBMSFW?.id === deletingFW.id) {
          setSelectedBMSFW(null)
        }
      } else if (deletingFW?.type === FWType.EMS) {
        setEMSFWList((oldList) => oldList.filter((fw) => fw.id !== deletingFW.id))
        if (selectedEMSFW?.id === deletingFW.id) {
          setSelectedEMSFW(null)
        }
      }
    }
    setDeletingFW(null)
  }
  const deleteFirmware = async (type: FWType, id: number) => await apiDeleteFirmware(type, id)
  const deleteModalProps: DeleteModalProps = {
    size: "sm",
    modal: deletemodal,
    toggle: deleteToggle,
    id: deletingFW ? `${deletingFW.id}` : "",
    text: stringFormat(Messages.MSG_FW_UPDATE_DELETE_FW_CONFIRMATION, [
      deletingFW?.type || "",
      deletingFW?.version || ""
    ]),
    request: async () => {
      const res = await deleteFirmware(deletingFW?.type || FWType.BMS, deletingFW?.id || 0)
      return {
        status: res.status,
        message: res.message || Messages.ERR_COMMON_SERVER_FAILED
      }
    },
    response: deleteResponse
  }

  const stageFW = async () => {
    setStagingFW(true)
    const siteIds = Object.values(groupedSites || {}).reduce(
      (acc: number[], sites: SiteEntity[]) => {
        return acc.concat(sites.map((site) => site.siteUniqueId))
      },
      []
    )
    let showSuccessfulMsg = false
    let successfulMsg = ""
    try {
      if (selectedBMSFW) {
        const res = await apiAdminStageFirmware(FWType.BMS, siteIds, selectedBMSFW?.id || 0)
        if (res.status) {
          showSuccessfulMsg = true
          successfulMsg = stringFormat(Messages.MSG_FW_UPDATE_STAGE_FW_SUCCESSFULLY, [
            getFWTypeString(FWType.BMS)
          ])
        } else {
          showSuccessfulMsg = false
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: stringFormat(Messages.ERR_FW_UPDATE_STAGE_FW_FAILED, [
              getFWTypeString(FWType.BMS)
            ]),
            modal: true
          })
        }
      }

      if (selectedEMSFW) {
        const res = await apiAdminStageFirmware(FWType.EMS, siteIds, selectedEMSFW?.id || 0)
        if (res.status) {
          showSuccessfulMsg = true
          successfulMsg = stringFormat(Messages.MSG_FW_UPDATE_STAGE_FW_SUCCESSFULLY, [
            `${selectedBMSFW ? FWType.BMS + " and " + getFWTypeString(FWType.EMS) : getFWTypeString(FWType.EMS)}`
          ])
        } else {
          showSuccessfulMsg = false
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: stringFormat(Messages.ERR_FW_UPDATE_STAGE_FW_FAILED, [
              getFWTypeString(FWType.EMS)
            ]),
            modal: true
          })
        }
      }
      if (showSuccessfulMsg) {
        setAlertModalProps({
          ...alertModalProps,
          status: "success",
          message: successfulMsg,
          modal: true,
          onClose: () => {
            props.toggle()
            if (props.onClose) {
              props.onClose()
            }
          }
        })
      }
    } catch (error) {
      console.log("Error when staging firmware", error)
      setAlertModalProps({
        ...alertModalProps,
        status: "error",
        message: Messages.ERR_COMMON_SERVER_FAILED,
        modal: true
      })
    } finally {
      setStagingFW(false)
    }
  }

  return (
    <>
      <Modal id="updateFWModal" size="xl" backdrop={"static"} isOpen={props.modal}>
        <ModalHeader className="pb-1">
          <p className="title">{Messages.LBL_MANAGEMENT_BTN_UPDATE_FW}</p>
          <button
            className="border-0 position-absolute bg-transparent"
            style={{ right: "22px", top: "24px", width: "25px" }}
            onClick={props.toggle}
          >
            <img src={close} alt="Close" role="button" />
          </button>
        </ModalHeader>
        <ModalBody>
          <h2>{Messages.LBL_FW_UPDATE_SELECTED_SITES}</h2>
          <div className="card-wrapper">
            {groupedSites &&
              Object.keys(groupedSites).map((customerId: string) => (
                <ExpandableWidthCard
                  key={customerId}
                  customerUniqueId={parseInt(customerId)}
                  siteList={groupedSites[parseInt(customerId)]}
                  removeGroupCustomer={removeGroupCustomer}
                  removeSite={removeSite}
                />
              ))}
          </div>
          <FWUploadTable
            title={Messages.LBL_FW_UPDATE_BMS_MODAL}
            type={FWType.BMS}
            fwList={bmsFWList}
            removeFW={removeFirmware}
            onReleaseNotesClick={showReleaseNoteModal}
            addNewFW={addNewBMSFW}
            setSelectedFW={selectBMSFWToStage}
            isLoading={loadingStates.isLoadingBMS}
            selectedFw={selectedBMSFW}
          />
          <FWUploadTable
            title={Messages.LBL_FW_UPDATE_EMS_MODAL}
            type={FWType.EMS}
            fwList={emsFWList}
            removeFW={removeFirmware}
            onReleaseNotesClick={showReleaseNoteModal}
            addNewFW={addNewEMSFW}
            setSelectedFW={selectEMSFWToStage}
            isLoading={loadingStates.isLoadingEMS}
            selectedFw={selectedEMSFW}
          />
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            className="border-0"
            onClick={() => {
              props.toggle()
            }}
            disabled={stagingFW}
          >
            {Messages.BTN_SITE_SETUP_CANCEL}
          </Button>
          <Button
            color="primary"
            className="ms-3"
            onClick={() => {
              stageFW()
            }}
            disabled={
              stagingFW ||
              (!selectedBMSFW && !selectedEMSFW) ||
              !groupedSites ||
              Object.keys(groupedSites).length === 0
            }
          >
            {stagingFW && (
              <Spinner color="light" size="sm" className="me-1">
                Loading...
              </Spinner>
            )}
            {Messages.LBL_FW_UPDATE_STAGE}
          </Button>
        </ModalFooter>
      </Modal>
      {fwReleasesNoteModalProps.modal && <FWReleasesNoteModal {...fwReleasesNoteModalProps} />}
      {alertModalProps.modal && <AlertModal {...alertModalProps} />}
      <DeleteModal {...deleteModalProps} />
    </>
  )
}

export default UpdateFWModal
