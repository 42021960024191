import { VisualizationChartDataEnum } from "../../enum/visualization/visualization-chart-data"
import { VisualizationChartData } from "../../interfaces/visualization/VisualizationChartData"
import { AxisEdgeValues } from "../../interfaces/visualization/AxisEdgeValues"

import { isValidValue } from "../utils"

// Math operator
export type Operator = "<" | ">"
export const operators = {
  "<": (a: number, b: number) => a < b,
  ">": (a: number, b: number) => a > b
}

/**
 * Check if value is valid
 * @param {string|number|null|undefined} num compare value
 * @returns {boolean} true if value is valid and false otherwise
 */
const _isValidValue = (num: string | number | null | undefined) =>
  typeof num !== "string" ? isValidValue(num) : false

/**
 * Check and update axis values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {Operator} operator compare operator
 * @param {VisualizationChartDataEnum} attr attribute enum to get data from item
 * @returns {boolean} true if item value is valid and satisfied comparing condition
 */
const compareValues = (
  oldVal: number | null,
  item: VisualizationChartData,
  operator: Operator,
  newVal: Data
) =>
  _isValidValue(newVal) && (oldVal === null || operators[operator](parseFloat(`${newVal}`), oldVal))

type Data = number | null | undefined
const removeInvalidValue = (arr: Data[]): Data[] =>
  arr.filter((item: Data) => item !== undefined && item !== null)
/**
 * Update left axis values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {boolean} isBattery sidebar filters
 * @param {boolean} isCurrent load filters current or voltage
 * @param {Operator} operator compare operator
 * @param {VisualizationChartDataEnum} batterAttr battery attribute enum to get data from item
 * @param {VisualizationChartDataEnum} loadCurrentAttr load current battery attribute enum to get data from item
 * @param {VisualizationChartDataEnum} loadVoltageAttr load voltage battery attribute enum to get data from item
 * @returns {number|null} new value if condition is satisfied or oldVal if condition is not satisfied
 */
const updateLaxis = (
  oldVal: number | null,
  item: VisualizationChartData,
  isBattery: boolean,
  isCurrent: boolean,
  operator: Operator,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValFunc: any,
  batteryAttrs: Data[],
  loadCurrentAttrs: Data[],
  loadVoltageAttrs: Data[]
): number | null => {
  // remove undefined
  batteryAttrs = removeInvalidValue(batteryAttrs)
  loadCurrentAttrs = removeInvalidValue(loadCurrentAttrs)
  loadVoltageAttrs = removeInvalidValue(loadVoltageAttrs)

  // get edges value
  const batteryValue: Data = batteryAttrs.length > 0 ? getValFunc(...batteryAttrs) : null
  const loadCurrentValue: Data =
    loadCurrentAttrs.length > 0 ? getValFunc(...loadCurrentAttrs) : null
  const loadVoltageValue: Data =
    loadVoltageAttrs.length > 0 ? getValFunc(...loadVoltageAttrs) : null

  // battery
  if (isBattery) {
    if (compareValues(oldVal, item, operator, batteryValue)) return batteryValue as number
  } else {
    if (isCurrent) {
      if (compareValues(oldVal, item, operator, loadCurrentValue)) return loadCurrentValue as number
    } else if (compareValues(oldVal, item, operator, loadVoltageValue))
      return loadVoltageValue as number
  }

  return oldVal
}

/**
 * Update left axis min values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {boolean} isBattery sidebar filters
 * @param {boolean} isCurrent load filters current or voltage
 * @returns {number|null} new value if condition is satisfied or oldVal if condition is not satisfied
 */
const updateLaxisMin = (
  oldVal: number | null,
  item: VisualizationChartData,
  isBattery: boolean,
  isCurrent: boolean,
  batteryTemp: Data[],
  loadCurrent: Data[],
  loadVoltage: Data[]
): number | null =>
  updateLaxis(
    oldVal,
    item,
    isBattery,
    isCurrent,
    "<",
    Math.min,
    batteryTemp,
    loadCurrent,
    loadVoltage
  )

/**
 * Update left axis max values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {boolean} isBattery sidebar filters
 * @param {boolean} isCurrent load filters current or voltage
 * @returns {number|null} new value if condition is satisfied or oldVal if condition is not satisfied
 */
const updateLaxisMax = (
  oldVal: number | null,
  item: VisualizationChartData,
  isBattery: boolean,
  isCurrent: boolean,
  batteryTemp: Data[],
  loadCurrent: Data[],
  loadVoltage: Data[]
): number | null =>
  updateLaxis(
    oldVal,
    item,
    isBattery,
    isCurrent,
    ">",
    Math.max,
    batteryTemp,
    loadCurrent,
    loadVoltage
  )

/**
 * Update right axis values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {boolean} isBattery sidebar filters
 * @param {Operator} operator compare operator
 * @param {VisualizationChartDataEnum} batterAttr battery attribute enum to get data from item
 * @param {VisualizationChartDataEnum} loadAttr load attribute enum to get data from item
 * @returns {number|null} new value if condition is satisfied or oldVal if condition is not satisfied
 */
const updateRaxis = (
  oldVal: number | null,
  item: VisualizationChartData,
  isBattery: boolean,
  operator: Operator,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValFunc: any,
  batterAttrs: Data[],
  loadAttrs: Data[]
): number | null => {
  // remove undefined
  batterAttrs = removeInvalidValue(batterAttrs)
  loadAttrs = removeInvalidValue(loadAttrs)

  // get edges value
  const batteryValue: Data = batterAttrs.length > 0 ? getValFunc(...batterAttrs) : null
  const loadValue: Data = loadAttrs.length > 0 ? getValFunc(...loadAttrs) : null

  // battery
  if (isBattery) {
    if (compareValues(oldVal, item, operator, batteryValue)) return batteryValue as number
  } else if (compareValues(oldVal, item, operator, loadValue)) return loadValue as number

  return oldVal
}

/**
 * Update right axis min values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {boolean} isBattery sidebar filters
 * @returns {number|null} new value if condition is satisfied or oldVal if condition is not satisfied
 */
const updateRaxisMin = (
  oldVal: number | null,
  item: VisualizationChartData,
  isBattery: boolean,
  batteryVoltage: Data[],
  loadReactivePower: Data[]
): number | null =>
  updateRaxis(oldVal, item, isBattery, "<", Math.min, batteryVoltage, loadReactivePower)

/**
 * Update right axis max values
 * @param {number|null} oldVal root value
 * @param {VisualizationChartData} item current item data
 * @param {boolean} isBattery sidebar filters
 * @returns {number|null} new value if condition is satisfied or oldVal if condition is not satisfied
 */
const updateRaxisMax = (
  oldVal: number | null,
  item: VisualizationChartData,
  isBattery: boolean,
  batteryVoltage: Data[],
  loadRealPower: Data[]
): number | null =>
  updateRaxis(oldVal, item, isBattery, ">", Math.max, batteryVoltage, loadRealPower)

/**
 * Calculating gap between axis values
 * @param {number} max axis maximum value
 * @param {number} min axis minimum value
 * @param {number} rows number of axis lines
 * @returns {number} gap between axis values (rounded)
 */
export const calGap = (max: number, min: number, rows: number, isRound = true): number => {
  // cal gap
  const val = (max - min) / rows

  // round it up, 1.1 -> 1, 1.5 -> 2
  return isRound ? Math.round(val) : val
}

/**
 * Round value
 * @param {number} num value to round
 * @example 1.1 -> 1, 1.5 -> 2
 * @example -1.1 -> -1, -1.5 -> -2
 * @returns {number} round value
 */
export const axisRound = (num: number): number => {
  if (num > 0) {
    return Math.round(num) // positive, round up
  } else {
    return -Math.round(-num) // negative, round down, this case is for -1.5 -> 2 to match with 1.5 -> 2
  }
}

/**
 * Update new max axis edge value
 * @param {number|null} newVal new edge value from chart data
 * @param {number} newGap gap between edge value and data edge value
 * @param {number} defaultVal default edge value
 * @returns {number} new axis edge value
 */
export const calAxisMaxValue = (
  newVal: number | null,
  newGap: number,
  defaultVal: number,
  isRound = true
): number => {
  if (newVal !== null) {
    const roundedVal = isRound ? axisRound(newVal) : newVal
    const temp = roundedVal + newGap

    // max
    return temp
  }

  return defaultVal
}

/**
 * Update new min axis edge value
 * @param {number|null} newVal new edge value from chart data
 * @param {number} newGap gap between edge value and data edge value
 * @param {number} defaultVal default edge value
 * @returns {number} new axis edge value
 */
export const calAxisMinValue = (
  newVal: number | null,
  newGap: number,
  defaultVal: number,
  isRound = true
): number => {
  if (newVal !== null) {
    const roundedVal = isRound ? axisRound(newVal) : newVal
    const temp = roundedVal - newGap

    // min
    return temp
  }

  return defaultVal
}

/**
 * Calculating axis edge values from api data
 * @param {VisualizationChartData[]} resData api data
 * @param {boolean} isBattery variable indicating battery or load mode
 * @param {boolean} isCurrent variable indicating current or voltage mode
 * @returns {AxisEdgeValues} left and right axis edge values
 */
export const getAxisEdgeValues = (
  resData: VisualizationChartData[],
  isBattery: boolean,
  isCurrent: boolean
): AxisEdgeValues => {
  let laxisMax: number | null = null
  let laxisMin: number | null = null
  let raxisMax: number | null = null
  let raxisMin: number | null = null

  resData.forEach((item: VisualizationChartData) => {
    const batteryTemp = [
      item[VisualizationChartDataEnum.soc],
      item[VisualizationChartDataEnum.maxTemp],
      item[VisualizationChartDataEnum.avgTemp],
      item[VisualizationChartDataEnum.minTemp]
    ]
    const loadCurrent = [
      item[VisualizationChartDataEnum.ia],
      item[VisualizationChartDataEnum.ib],
      item[VisualizationChartDataEnum.ic]
    ]
    const loadVoltage = [
      item[VisualizationChartDataEnum.vab],
      item[VisualizationChartDataEnum.vbc],
      item[VisualizationChartDataEnum.vca]
    ]
    const batteryVoltage = [
      item[VisualizationChartDataEnum.maxVoltage],
      item[VisualizationChartDataEnum.avgVoltage],
      item[VisualizationChartDataEnum.minVoltage]
    ]
    const loadRealPower = [
      item[VisualizationChartDataEnum.actualRealPower],
      item[VisualizationChartDataEnum.commandRealPower],
      item[VisualizationChartDataEnum.actualReactivePower],
      item[VisualizationChartDataEnum.commandReactivePower]
    ]
    const loadReactivePower = [
      item[VisualizationChartDataEnum.actualRealPower],
      item[VisualizationChartDataEnum.commandRealPower],
      item[VisualizationChartDataEnum.actualReactivePower],
      item[VisualizationChartDataEnum.commandReactivePower]
    ]
    // update axis values
    laxisMax = updateLaxisMax(
      laxisMax,
      item,
      isBattery,
      isCurrent,
      batteryTemp,
      loadCurrent,
      loadVoltage
    )
    laxisMin = updateLaxisMin(
      laxisMin,
      item,
      isBattery,
      isCurrent,
      batteryTemp,
      loadCurrent,
      loadVoltage
    )
    raxisMax = updateRaxisMax(raxisMax, item, isBattery, batteryVoltage, loadRealPower)
    raxisMin = updateRaxisMin(raxisMin, item, isBattery, batteryVoltage, loadReactivePower)
  })

  return {
    laxisMax,
    laxisMin,
    raxisMax,
    raxisMin
  }
}
