import { useState, useEffect } from "react"
import {
  Modal,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import {
  getSiteEntity,
  setSiteEntity as rSetSiteEntity,
  getSelectedTeraStors,
  getTeraStorGroupData,
  setSelectedTeraStors as rSetSelectedTeraStors,
  fechTeraStorData as rFechTeraStorData,
  setTeraStorGroupData as rSetTeraStorGroupData,
  setViewBy,
  getAllSites,
  getSitesOverviewData,
  setDataMode,
  setIsFetchingCharts
} from "../../store/counter/visualizationSlice"
import { AppDispatch } from "../../store/store"

import search from "../../assets/images/icons/admin/search.svg"

import Loader from "components/Loader"

import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { DataMode } from "../../enum/visualization/data-mode"

import { ModalProps } from "../../interfaces/ModalProps"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { SiteEntity } from "../../interfaces/SiteEntity"

import { writeCodeLogEvent } from "../../utils/utils"

import Messages from "../../utils/messages"

const SelectTeraStorModal = (props: ModalProps) => {
  // redux data
  const dispatch = useDispatch<AppDispatch>()
  const rAllSites: SiteEntity[] = useSelector(getAllSites)
  const rSelectedTeraStors: SiteTeraStorData[] = useSelector(getSelectedTeraStors)
  const rTeraStorGroupData: Record<string, SiteTeraStorData[]> | null =
    useSelector(getTeraStorGroupData)
  const rSiteEntity: SiteEntity | null = useSelector(getSiteEntity)
  const sitesOverviewData = useSelector(getSitesOverviewData)

  // local data
  const [siteEntity, setSiteEntity] = useState<SiteEntity | null>(null)
  const [selectedTeraStors, setSelectedTeraStors] = useState<SiteTeraStorData[]>([])
  const [teraStorGroupData, setTeraStorGroupData] = useState<Record<
    string,
    SiteTeraStorData[]
  > | null>(null)

  const [listsites, setListSites] = useState<SiteEntity[]>([])
  const [teraStorLoading, setTeraStorLoading] = useState(false)

  const [selectAll, setSelectAll] = useState(false)
  const [enableApplyButton, setEnableApplyButton] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [sitesToggle, setSitesToggle] = useState(false)

  // Reset selected teraStor list at initialization
  useEffect(() => {
    setSiteEntity(rSiteEntity)
    setListSites(rAllSites)

    if (rSiteEntity) {
      setSiteEntity(rSiteEntity)
      setTeraStorLoading(true)
    } else if (rAllSites.length > 0) {
      setSiteEntity(rAllSites[0])
      setTeraStorLoading(true)
    }

    setSelectedTeraStors(rSelectedTeraStors)
    setTeraStorGroupData(rTeraStorGroupData)

    setEnableApplyButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (teraStorLoading && siteEntity) {
      const siteOverview = sitesOverviewData.find(
        (site) => site.siteUniqueId === siteEntity.siteUniqueId
      )
      rFechTeraStorData(siteEntity?.siteUniqueId, siteOverview?.groups ?? [])
        .then((res) => {
          if (res.status) setTeraStorGroupData(res.data)
          else console.error("Code exception: handle teraStor list =>", res.message)
          setTeraStorLoading(false)
        })
        .catch((e) => {
          console.error("Code exception: handle teraStor list =>", e.message)
          setTeraStorLoading(false)

          writeCodeLogEvent("handle teraStor list", e, siteEntity?.siteUniqueId)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teraStorLoading])

  // Update select all checkbox if selected list is changed
  useEffect(() => {
    if (selectedTeraStors && teraStorGroupData) {
      let selectAllStatus = true
      Object.keys(teraStorGroupData).forEach((name: string) => {
        if (isMatchSearchText(name)) {
          teraStorGroupData[name].forEach((teraStor: SiteTeraStorData) => {
            if (isMatchSearchText(teraStor.tsId, false)) {
              const isSelected = isSelectedTeraStor(teraStor.tsId)
              if (!isSelected) selectAllStatus = isSelected
            }
          })
        }
      })
      if (selectedTeraStors.length === 0) {
        setSelectAll(false)
      } else if (selectAllStatus !== selectAll) {
        setSelectAll(selectAllStatus)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeraStors])

  const isSelectedTeraStor = (tsId: number): boolean => {
    const filter = selectedTeraStors.filter((teraStor: SiteTeraStorData) => teraStor.tsId === tsId)
    return filter.length > 0
  }

  const sortSelectedTeraStors = (data: SiteTeraStorData[]) => {
    data.sort((a: SiteTeraStorData, b: SiteTeraStorData) => a.tsId - b.tsId)
    return data
  }
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    teraStor: SiteTeraStorData
  ) => {
    if (event.target.checked) {
      setSelectedTeraStors([...selectedTeraStors, teraStor])
      setEnableApplyButton(true)
    } else {
      const selectedList = selectedTeraStors.filter(
        (item: SiteTeraStorData) => item.tsId !== teraStor.tsId
      )
      setSelectedTeraStors(sortSelectedTeraStors(selectedList))

      if (selectedList.length <= 0) setEnableApplyButton(false)
      else setEnableApplyButton(true)
    }
  }

  const handleClearAllSelectedTeraStor = () => {
    setSelectAll(false)
    setSelectedTeraStors([])
    setEnableApplyButton(false)
  }

  const handleSelectAllTeraStor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      if (teraStorGroupData) {
        const selectedTeraStor: SiteTeraStorData[] = []
        Object.keys(teraStorGroupData).forEach((name: string) => {
          if (isMatchSearchText(name)) {
            teraStorGroupData[name].forEach((teraStor: SiteTeraStorData) => {
              if (isMatchSearchText(teraStor.tsId, false)) selectedTeraStor.push(teraStor)
            })
          }
        })
        setSelectedTeraStors(sortSelectedTeraStors(selectedTeraStor))
        if (selectedTeraStor.length > 0) setEnableApplyButton(true)
        else setEnableApplyButton(false)
      }
    } else {
      setSelectedTeraStors([])
      setEnableApplyButton(false)
    }
  }

  const handleClickApply = () => {
    dispatch(rSetSiteEntity(siteEntity))
    dispatch(rSetSelectedTeraStors(sortSelectedTeraStors(selectedTeraStors)))
    dispatch(rSetTeraStorGroupData(teraStorGroupData))
    dispatch(setIsFetchingCharts(true))

    dispatch(setDataMode(DataMode.Average))

    if (selectedTeraStors.length >= 5) {
      dispatch(setViewBy(VisualizationViewByEnum.Aggregated))
    } else dispatch(setViewBy(VisualizationViewByEnum.Detail))

    props.toggle()
  }

  const handleSearchInput = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value)
  }

  const handleSelectSite = (siteEntity: SiteEntity) => {
    setSiteEntity(siteEntity)
    setSelectAll(false)
    setSelectedTeraStors([])
    setTeraStorGroupData(null)
    setTeraStorLoading(true)
  }

  const isMatchSearchText = (value: string | number, isGroup = true) => {
    if (!searchText) return true

    // only search group is search text is not number
    if (isGroup && Number(searchText)) return true

    // only search terastor list is search text is number
    if (!isGroup && !Number(searchText)) return true

    if (`${value}`.toLowerCase().includes(searchText.toLowerCase())) return true
    else return false
  }

  const hasTerastorData = () => {
    return teraStorGroupData !== null && Object.keys(teraStorGroupData).length > 0
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      backdrop={"static"}
      onClosed={props.onClose}
      unmountOnClose={true}
      id="selectTeraStorModal"
    >
      <ModalBody className="p-4 d-flex flex-column">
        {/* Topbar */}
        <div className="d-flex flex-column flex-lg-row justify-content-between mb-4">
          <div className="d-flex justify-content-between justify-content-lg-start mb-3 mb-lg-0">
            <div className="d-flex justify-content-between search-wrapper me-2 me-lg-0">
              <img src={search} alt="map" role="button" />
              <input
                type="text"
                className="form-control"
                placeholder={Messages.LBL_VISUALIZATION_SEARCH}
                onChange={handleSearchInput}
              />
            </div>
            <label
              htmlFor="selSelectedSite"
              className="form-label ms-3 me-2 pt-2 d-none d-lg-block"
            >
              {Messages.LBL_VISUALIZATION_SELECTED_SITE}
            </label>
            <Dropdown
              isOpen={sitesToggle}
              toggle={() => setSitesToggle(!sitesToggle)}
              direction="down"
              id="selSelectedSite"
              disabled={teraStorLoading}
            >
              <DropdownToggle
                caret
                color="outline-primary"
                className="bg-white px-3 text-break text-wrap"
              >
                {siteEntity
                  ? `Site ${siteEntity.siteName}`
                  : Messages.ERR_VISUALIZATION_SITES_NOTSET}
              </DropdownToggle>
              <DropdownMenu>
                {listsites.map((item: SiteEntity, index: number) => (
                  <DropdownItem key={index} onClick={() => handleSelectSite(item)}>
                    Site {item.siteName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          {hasTerastorData() && (
            <div className="d-flex flex-row bd-highlight justify-content-center justify-content-lg-end">
              <div className="form-check me-3 pt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="chkSelectAll"
                  checked={selectAll}
                  onChange={handleSelectAllTeraStor}
                />
                <label className="form-check-label" htmlFor="chkSelectAll">
                  {Messages.LBL_VISUALIZATION_SELECT_ALL}
                </label>
              </div>
              <Button color="outline-primary" onClick={handleClearAllSelectedTeraStor}>
                {Messages.BTN_VISUALIZATION_CLEAR}
              </Button>
            </div>
          )}
        </div>

        {/* Body */}
        <div className="row my-auto">
          {teraStorLoading ? (
            <Loader status="loading" message={Messages.ERR_VISUALIZATION_FETCHING_TERASTOR} />
          ) : teraStorGroupData === null || Object.keys(teraStorGroupData).length === 0 ? (
            <p className="col mb-0 text-center py-5">{Messages.MSG_EMPTY_DATA}</p>
          ) : (
            Object.keys(teraStorGroupData).map(
              (name: string) =>
                isMatchSearchText(name) && (
                  <div className="col-6 col-lg-3 col-xl-2 mb-3" key={name}>
                    <p className="title text-center p-2 rounded">{name}</p>
                    <div className="px-2">
                      {teraStorGroupData[name].map(
                        (teraStor: SiteTeraStorData, index: number) =>
                          isMatchSearchText(teraStor.tsId, false) && (
                            <div className="form-check mb-3" key={index}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`chkTeraStor${teraStor.tsId}`}
                                checked={isSelectedTeraStor(teraStor.tsId)}
                                onChange={(event) => handleCheckboxChange(event, teraStor)}
                              />
                              <label
                                className="form-check-label text-break text-wrap"
                                htmlFor={`chkTeraStor${teraStor.tsId}`}
                              >
                                TeraStor #{teraStor.tsId}
                              </label>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )
            )
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button
          color="light"
          type="button"
          className="me-3 py-2 px-4 btn-cancel"
          onClick={props.toggle}
        >
          {Messages.BTN_VISUALIZATION_CANCEL}
        </Button>
        <Button
          color="primary"
          className="py-2 px-4"
          type="submit"
          onClick={handleClickApply}
          disabled={!enableApplyButton}
        >
          {Messages.BTN_VISUALIZATION_APPLY}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectTeraStorModal
