import { createAsyncThunk } from "@reduxjs/toolkit"

import { SigninBody } from "../../interfaces/SigninBody"

import { signin } from "../../services/cognito"
import { AUTHORITY_CONSTANTS, COOKIE_NAME } from "../../utils/constants"

import Message from "../../utils/messages"
import cookies from "../../utils/cookies"

export const signinThunk = createAsyncThunk("cognito/signin", async (body: SigninBody | null) => {
  try {
    const response = await signin(body || undefined)
    if (response.status) {
      return {
        status: true,
        username: !body ? localStorage.getItem(COOKIE_NAME.USERNAME) : body.username,
        rememberMe: !body ? true : body.rememberMe,
        idToken: response.idToken,
        currentUser: response.currentUser,
        refreshToken: response.refreshToken,
        challenge: response.challenge,
        rateLimitExceeded: response.rateLimitExceeded
      }
    } else {
      let message
      switch (response.message) {
        case AUTHORITY_CONSTANTS.ERR_KEY_TOO_MANY_REQUESTS:
          message = Message.ERR_COGNITO_SIGNIN_TOO_MANY_REQUESTS
          break
        case AUTHORITY_CONSTANTS.ERR_KEY_PASSWORD_INCORRECT:
          message = Message.ERR_COGNITO_SIGNIN_INVALID_USERNAME_PASSWORD
          break
        case AUTHORITY_CONSTANTS.ERR_KEY_USER_DISABLED:
          message = Message.ERR_MSG_USER_DISABLED
          break
        case AUTHORITY_CONSTANTS.ERR_KEY_FORCE_CHANGE_PASSWORD:
          message = Message.ERR_MSG_FORCE_CHANGE_PASSWORD
          break
        case AUTHORITY_CONSTANTS.ERR_KEY_PENDING_CONFIRMATION:
          message = Message.ERR_MSG_PENDING_CONFIRMATION
          break
        default:
          message = Message.ERR_COGNITO_SIGNIN_INVALID_USERNAME_PASSWORD
          break
      }
      return {
        status: false,
        message,
        rateLimitExceeded: response.rateLimitExceeded
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error("API exception: signin failed =>", err.message)
    let message, rateLimitExceeded
    // Error from Auth.currentAuthenticatedUser() is String
    if (err?.response?.status == 429) {
      // Lock 5 minutes
      localStorage.setItem(
        COOKIE_NAME.LOCK_TIME,
        String(new Date().getTime() + AUTHORITY_CONSTANTS.TIME_LOCK_USER)
      )
      cookies.set(COOKIE_NAME.RATE_LIMIT_EXCEEDED, AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT)
      message = ""
      rateLimitExceeded = AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT
    } else if (err?.message) {
      switch (err.message) {
        case Message.ERR_COGNITO_SIGNIN_NOT_ALLOWED:
          message = Message.ERR_COGNITO_SIGNIN_NOT_ALLOWED
          break
        case undefined:
          message = Message.ERR_COGNITO_SIGNIN_EXPIRED
          break
        case Message.ERR_COGNITO_GET_CUSTOMERUNIQUEID_FAILED:
          message = Message.ERR_COGNITO_GET_CUSTOMERUNIQUEID_FAILED
          break
        default:
          message = Message.ERR_COGNITO_SIGNIN_INVALID_USERNAME_PASSWORD
          break
      }
    } else {
      message = Message.ERR_COMMON_SERVER_FAILED
    }
    return {
      status: false,
      message,
      rateLimitExceeded
    }
  }
})
