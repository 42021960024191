import { useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import close from "../../assets/images/icons/admin/close.svg"
import { AdminNoteModalProps } from "../../interfaces/AdminNoteModalProps"
import { SiteConfiguration } from "../../interfaces/SiteConfiguration"

const NoteModal = (props: AdminNoteModalProps) => {
  const [siteConfiguration, setSiteConfiguration] = useState<SiteConfiguration>()
  useEffect(() => {
    setSiteConfiguration(props.siteConfiguration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteConfiguration])

  const handleClose = () => {
    props.close()
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      className="admin-note h-100 w-100"
    >
      <ModalBody className="p-3">
        <div className="d-flex justify-content-between align-items-baseline cursor-p">
          <h4 className="title">Super Admin Notes</h4>
          <img src={close} alt="Close" onClick={handleClose} />
        </div>
        <hr></hr>
        <div className="admin-wrapper">
          <div className="overflow-x-hidden">
            <h4 className="fw-bold">Site Layout Notes</h4>
            {siteConfiguration?.note?.layout && (
              <span className="px-1">&#8226;&nbsp;{siteConfiguration?.note?.layout}</span>
            )}
            <br />
            <br />
            <br />
            <h4 className="fw-bold">Configuration Notes</h4>
            {siteConfiguration?.note?.network && (
              <span className="px-1">&#8226;&nbsp;{siteConfiguration?.note?.network}</span>
            )}
            <br />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default NoteModal
