import { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Spinner } from "reactstrap"

import {
  getViewBy,
  getSelectedTeraStors,
  fetchingListAllSites,
  getFetchingListAllSitesStatus,
  getDataMode,
  setDataMode,
  setIsFetchingCharts
} from "../../store/counter/visualizationSlice"
import { getCurrentUserState } from "../../store/counter/authSlice"

import "../../assets/scss/visualization.scss"

import info from "../../assets/images/icons/visualization/info.svg"

import ChartModal from "../../components/modal/ChartModal"
import ChartDetailModal from "../../components/modal/ChartDetailModal"
import UploadProgresModal from "../../components/modal/UploadProgresModal"
import AlertModal from "../../components/modal/AlertModal"
import ChartWrapper from "./ChartWrapper"
import Loader from "../../components/Loader"
import VisualizationHeader from "./VisualizationHeader"

import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { ChartActiveData } from "../../interfaces/visualization/ChartActiveData"
import { DropDown } from "../../interfaces/visualization/Dropdown"
import { UploadProgresModalProps } from "../../interfaces/UploadProgresModalProps"
import { AlertModalProps } from "../../interfaces/AlertModalProps"

import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { DataMode } from "../../enum/visualization/data-mode"

import { AppDispatch } from "../../store/store"

import Messages from "../../utils/messages"

const Visualization = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [modal, setModal] = useState(false)
  const modalToggle = useCallback(() => setModal(!modal), [modal])

  const [detailModal, setDetailModal] = useState(false)
  const detailToggle = useCallback(() => setDetailModal(!detailModal), [detailModal])
  const [tooltipData, setTooltipData] = useState<ChartActiveData>()
  const [filters, setFilters] = useState<DropDown[]>([])

  const [downloadProcess, setDownloadProcess] = useState<UploadProgresModalProps>({
    isOpen: false,
    progress: 0
  })
  const [downloadSucceed, setDownloadSucceed] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setDownloadSucceed({
        ...downloadSucceed,
        modal: false
      })
    },
    status: "success",
    message: Messages.LBL_UPLOAD_PROCESS_DOWNLOAD_SUCCEED
  })

  const dataMode = useSelector(getDataMode)
  const viewBy = useSelector(getViewBy)
  const currentUser = useSelector(getCurrentUserState)
  const fetchingAllSites = useSelector(getFetchingListAllSitesStatus)
  const selectedTeraStors = useSelector(getSelectedTeraStors)
  const [selectedTsId, setSelectedTsId] = useState(0)
  const isMultipleChart = viewBy === VisualizationViewByEnum.Detail && dataMode === DataMode.Average

  // #region constructor
  useEffect(() => {
    dispatch(fetchingListAllSites(currentUser?.customerUniqueId || 0))
    dispatch(setDataMode(DataMode.Average))

    // reset sequential list
    if (sequentialList.length > 0) setSequentialList([])

    // reset selected tsid
    if (selectedTsId !== 0) setSelectedTsId(0)

    // reset chart status
    if (
      chartStatuses.length !== 1 ||
      (chartStatuses.length === 1 && !chartStatuses.every((status) => !status))
    ) {
      setChartStatuses(new Array(1).fill(false))
    }

    // hide block div
    return () => {
      const blockWrapper = document.getElementById("exportBlock")
      if (blockWrapper) {
        blockWrapper.classList.add("d-none")
      }
    }
  }, [])

  // #region chart status and sequential loading
  const [chartStatuses, setChartStatuses] = useState<boolean[]>([false])
  const [sequentialList, setSequentialList] = useState<SiteTeraStorData[]>([])

  // whenever list of selected terastors, datamode (average, sample), view mode (detail, aggregated) and chart mode (battery, load)
  // we need to reset sequential list and chart status list to re-render the list over-again
  // we need to reset selected tsid
  useEffect(() => {
    if (selectedTeraStors.length > 0) {
      // reset sequential list
      setSequentialList([selectedTeraStors[0]])

      // reset selected tsid
      // if sample mode then set first terastor as selected terastor
      if (dataMode === DataMode.Sample && viewBy === VisualizationViewByEnum.Detail)
        setSelectedTsId(selectedTeraStors[0].tsId)

      // reset chart status
      if (
        isMultipleChart &&
        (chartStatuses.length !== selectedTeraStors.length ||
          (chartStatuses.length === selectedTeraStors.length &&
            !chartStatuses.every((status) => !status)))
      ) {
        setChartStatuses(new Array(selectedTeraStors.length).fill(false))
      } else if (
        chartStatuses.length !== 1 ||
        (chartStatuses.length === 1 && !chartStatuses.every((status) => !status))
      ) {
        setChartStatuses(new Array(1).fill(false))
      }
    }
  }, [selectedTeraStors, dataMode, viewBy])

  // Callback function when a chart is loaded
  // index is current chart index
  const handleChartLoaded = (index: number) =>
    setChartStatuses((prevStatuses) => {
      const updatedStatuses = [...prevStatuses]
      updatedStatuses[index] = true
      // update sequential list with next chart index
      if (index + 1 <= selectedTeraStors.length - 1) {
        setSequentialList([...sequentialList, selectedTeraStors[index + 1]])
      }
      return updatedStatuses
    })

  useEffect(() => {
    if (
      chartStatuses.length > 0 &&
      chartStatuses.every((status) => status === true) &&
      !fetchingAllSites &&
      (!isMultipleChart || (isMultipleChart && chartStatuses.length === selectedTeraStors.length))
    ) {
      setTimeout(() => dispatch(setIsFetchingCharts(false)), 500)
    }
  }, [chartStatuses])
  // #endregion chart status and sequential loading

  // #region chart functions
  const chartSetTsId = useCallback((tsId?: number) => setSelectedTsId(tsId || 0), [])
  const chartSetDownloadProcess = useCallback(
    (isOpen: boolean, progress: number) =>
      setDownloadProcess({
        isOpen,
        progress
      }),
    []
  )
  const chartSetDownloadSucceed = useCallback(
    (modal: boolean) => setDownloadSucceed({ ...downloadSucceed, modal }),
    []
  )
  const chartSetFilter = useCallback((items: DropDown[]) => setFilters(items), [])
  const chartSetTooltipData = useCallback((data: ChartActiveData) => setTooltipData(data), [])

  // #region render
  return (
    <div id="visualizationWrapper" className="admin-wrapper">
      <VisualizationHeader />
      {fetchingAllSites ? (
        <div className="d-flex justify-content-center text-primary fw-bold align-items-stretch h-100">
          <img src={info} width={45} alt="info" className="me-2" />
          <div className="align-self-center select-text text-break mb-0 d-md-none fw-normal">
            {fetchingAllSites
              ? Messages.ERR_VISUALIZATION_FETCHING_SITES
              : Messages.ERR_VISUALIZATION_FETCHING_TERASTOR}
            <Spinner color="primary" className="ms-2" size="sm">
              Loading...
            </Spinner>
          </div>
          <h4 className="align-self-center select-text text-break d-none d-md-block">
            {fetchingAllSites
              ? Messages.ERR_VISUALIZATION_FETCHING_SITES
              : Messages.ERR_VISUALIZATION_FETCHING_TERASTOR}
            <Spinner color="primary" className="ms-2" size="sm">
              Loading...
            </Spinner>
          </h4>
        </div>
      ) : selectedTeraStors.length === 0 ? (
        <div className="d-flex justify-content-center text-primary fw-bold align-items-stretch h-100">
          <img src={info} width={45} alt="info" className="me-2" />
          <p className="align-self-center select-text text-break mb-0 d-md-none fw-normal">
            {Messages.MSG_VISUALIZATION_SELECT_TERASTOR}
          </p>
          <h4 className="align-self-center select-text text-break d-none d-md-block">
            {Messages.MSG_VISUALIZATION_SELECT_TERASTOR}
          </h4>
        </div>
      ) : isMultipleChart ? (
        <>
          {sequentialList.map((teraStor: SiteTeraStorData, index: number) => (
            <ChartWrapper
              class="mb-4"
              expandToggle={modalToggle}
              key={index}
              tsId={teraStor.tsId}
              setTsId={chartSetTsId}
              setDownloadProcess={chartSetDownloadProcess}
              setDownloadSucceed={chartSetDownloadSucceed}
              onChartLoaded={() => handleChartLoaded(index)}
            />
          ))}
          {sequentialList.length < selectedTeraStors.length && (
            <Loader status="loading" message="Loading next chart ..." />
          )}
        </>
      ) : (
        <ChartWrapper
          class="mb-4"
          detailToggle={detailToggle}
          setTooltipData={chartSetTooltipData}
          setFilters={chartSetFilter}
          setDownloadProcess={chartSetDownloadProcess}
          setDownloadSucceed={chartSetDownloadSucceed}
          tsId={selectedTsId}
          setTsId={chartSetTsId}
          onChartLoaded={() => handleChartLoaded(0)}
        />
      )}

      {modal && (
        <ChartModal
          modal={modal}
          size="xl"
          toggle={modalToggle}
          tsId={selectedTsId}
          setDownloadProcess={chartSetDownloadProcess}
          setDownloadSucceed={chartSetDownloadSucceed}
        />
      )}

      {detailModal && (
        <ChartDetailModal
          modal={detailModal}
          size="md"
          toggle={() => setDetailModal(!detailModal)}
          filters={filters}
          data={tooltipData}
        />
      )}
      {downloadProcess.isOpen && (
        <UploadProgresModal {...downloadProcess} text={Messages.LBL_UPLOAD_PROCESS_DOWNLOADING} />
      )}
      <AlertModal {...downloadSucceed} />

      {/* TODO: this is temporary, remove this after improve chart rendering performance */}
      <div
        id="exportBlock"
        className="text-primary container-fluid full-height d-flex justify-content-center align-items-center d-none"
      >
        <div className="text-center rounded-3 bg-white p-4">
          <div className="mb-4">
            <Spinner color="primary" />
          </div>
          <h4 className="mb-3">{Messages.BTN_VISUALIZATION_EXPORT_BLOCK_TITLE}</h4>
          <p className="mb-0">{Messages.BTN_VISUALIZATION_EXPORT_BLOCK_DESCRIPTION}</p>
        </div>
      </div>
    </div>
  )
}

export default Visualization
