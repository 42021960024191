import { DataMode } from "../../../enum/visualization/data-mode"
import Moment from "moment"
import { VISUALIZATION_TIMESTAMP_REGULAR } from "../../../utils/constants"

const XAxisTick = (tick: string, dataMode: DataMode) => {
  if (dataMode === DataMode.Sample) {
    return Moment(tick).utc().format("HH:mm")
  }
  if (VISUALIZATION_TIMESTAMP_REGULAR.test(tick)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [datePart, timePart] = tick.split(" ")
    const [hours] = timePart.split(":").map(Number) // Split and convert to numbers
    return hours.toString()
  }
  return tick
}

export default XAxisTick
