import React from "react"
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Nav, NavItem } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Tooltip as ReactTooltip } from "react-tooltip"

import user from "../../assets/images/icons/user.svg"

import type { AppDispatch } from "../../store/store"
import { logout, getCurrentUserState } from "../../store/counter/authSlice"

import { USER_ROLES } from "../../utils/constants"
import Messages from "../../utils/messages"

const HeaderActions = () => {
  const currentUser = useSelector(getCurrentUserState)
  const roleName = USER_ROLES[currentUser?.role || ""]
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const clickLogout = () => {
    navigate("/auth/signin")
    dispatch(logout())
  }

  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const toggle = () => setDropdownOpen(!dropdownOpen)

  const MAX_LENGTH = 20
  const CUT_LENGTH = 18
  const truncateText = (text: string) =>
    text.length > MAX_LENGTH ? `${text.substring(0, CUT_LENGTH)}...` : text
  let fullName = `${currentUser?.firstName} ${currentUser?.lastName}`
  if (!currentUser?.firstName && !currentUser?.lastName) fullName = currentUser?.email || ""

  return (
    <Nav className="header-actions nav my-3 d-flex justify-content-center justify-content-md-end">
      <NavItem>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="user-dropdown">
          <DropdownToggle color="transparent" className="p-0 d-flex justify-content-between">
            <span
              className="btn position-relative border-0 rounded-circle circle-background py-2 me-3 avatar"
              style={{ backgroundImage: `url(${currentUser?.avatar})` }}
            >
              {!currentUser?.avatar && <img src={user} alt="user" id="icoUser"></img>}
            </span>
            <div className="text-start me-3">
              <p className="mb-0" data-tooltip-id="fullName">
                {truncateText(fullName)}
              </p>
              {fullName.length > MAX_LENGTH && (
                <ReactTooltip id="fullName" place="bottom" content={fullName} />
              )}
              <p className="mb-0 role">{roleName ? roleName.name : ""}</p>
            </div>
            <i className="bi bi-chevron-down ms-3"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{Messages.LBL_HEADER_WELCOME}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>
              <span data-tooltip-id="username">
                <b>Username:</b> {truncateText(currentUser?.username || "")}
              </span>
              {(currentUser?.username || "").length > MAX_LENGTH && (
                <ReactTooltip id="username" place="bottom" content={currentUser?.username} />
              )}
            </DropdownItem>
            <DropdownItem header>
              <span data-tooltip-id="email">
                <b>Email:</b> {truncateText(currentUser?.email || "")}
              </span>
              {(currentUser?.email || "").length > MAX_LENGTH && (
                <ReactTooltip id="email" place="bottom" content={currentUser?.email} />
              )}
            </DropdownItem>
            <DropdownItem header>
              <b>Role:</b> {currentUser?.role ? currentUser?.role.replace(/_/g, " ") : ""}
            </DropdownItem>
            {currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id && (
              <DropdownItem header>
                <b>Customer Id:</b> {currentUser?.customerUniqueId}
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={clickLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavItem>
    </Nav>
  )
}

export default HeaderActions
