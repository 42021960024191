import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"

import site from "../assets/images/icons/admin/site.svg"
import siteActive from "../assets/images/icons/admin/site-active.svg"
import users from "../assets/images/icons/admin/users.svg"
import usersActive from "../assets/images/icons/admin/users-active.svg"

import { AdminTabNavigatorProps } from "interfaces/AdminTabNavigatorProps"

import Messages from "../utils/messages"
import { AESI_ADMIN_ROUTE, CUSTOMER_MANAGEMENT, ALL_SITES_MANAGEMENT } from "../utils/constants"

const AdminTabNavigator = (props: AdminTabNavigatorProps) => {
  const navigate = useNavigate()
  return (
    <div className="d-md-flex justify-content-between mb-3 text-center text-md-start">
      <div className="d-flex flex-column flex-md-row bd-highlight">
        <Button
          color="link"
          className={`
              tab-item thin py-0 test-center test-md-start
              ${props.selectedTab === CUSTOMER_MANAGEMENT ? "selected" : ""}`}
          onClick={() => navigate(`${AESI_ADMIN_ROUTE}/${CUSTOMER_MANAGEMENT}`)}
        >
          {props.selectedTab === CUSTOMER_MANAGEMENT ? (
            <img
              src={usersActive}
              alt={Messages.LBL_MANAGEMENT_CUSTOMER_LIST}
              className="me-2 selected"
            />
          ) : (
            <img
              src={users}
              alt={Messages.LBL_MANAGEMENT_CUSTOMER_LIST}
              className="me-2 none-selected"
            />
          )}
          {Messages.LBL_MANAGEMENT_CUSTOMER_LIST}
        </Button>
        <Button
          color="link"
          className={`
              tab-item thin py-0 test-center test-md-start
              ${props.selectedTab === ALL_SITES_MANAGEMENT ? "selected" : ""}`}
          onClick={() => {
            navigate(`${AESI_ADMIN_ROUTE}/${ALL_SITES_MANAGEMENT}`)
          }}
        >
          {props.selectedTab === ALL_SITES_MANAGEMENT ? (
            <img
              src={siteActive}
              alt={Messages.LBL_MANAGEMENT_ALL_SITES}
              className="me-2 selected"
            />
          ) : (
            <img
              src={site}
              alt={Messages.LBL_MANAGEMENT_ALL_SITES}
              className="me-2 none-selected"
            />
          )}
          {Messages.LBL_MANAGEMENT_ALL_SITES}
        </Button>
      </div>
      <div>
        <Button
          color="primary"
          id="btnAddCustomer"
          className="px-5 btn-actions"
          onClick={props.actionButtonClick}
          disabled={props.actionButtonDisabled}
        >
          {props.actionButtonLabel}
        </Button>
      </div>
    </div>
  )
}

export default AdminTabNavigator
