import { useState, RefObject } from "react"
import { useSelector } from "react-redux"

import { getViewBy, getDataMode } from "../../../store/counter/visualizationSlice"

import Actions from "./Actions"
import Badge from "./Badge"
import TerastorDropdown from "./TerastorDropdown"

import { ChartHeadProps } from "../../../interfaces/visualization/ChartHeadProps"

import { VisualizationViewByEnum } from "../../../enum/visualization/tab/viewby-tab"
import { DataMode } from "../../../enum/visualization/data-mode"

const Mobile = (props: ChartHeadProps) => {
  const viewBy = useSelector(getViewBy)
  const dataMode = useSelector(getDataMode)
  const [badgeRef, setBadgeRef] = useState<RefObject<HTMLDivElement> | null>(null)

  return (
    <div className="head head-mobile d-xl-none">
      <div className="d-md-flex justify-content-center mb-3 text-center">
        {viewBy === VisualizationViewByEnum.Detail && dataMode === DataMode.Average && (
          <h5 className="text-primary fw-bold me-4 pt-2">TeraStor #{props.tsId}</h5>
        )}
        {viewBy === VisualizationViewByEnum.Detail && dataMode === DataMode.Sample && (
          <TerastorDropdown tsId={props.tsId} setTsId={props.setTsId} />
        )}
        <Actions
          status={props.status}
          expandToggle={props.expandToggle}
          tsId={props.tsId}
          setTsId={props.setTsId}
          wrapper={`Mobile${props.wrapper ?? ""}`}
          filters={props.filters}
          setFilters={props.setFilters}
          chartData={props.chartData}
          badgeRef={badgeRef}
          setDownloadProcess={(isOpen: boolean, progress: number) =>
            props.setDownloadProcess(isOpen, progress)
          }
          setDownloadSucceed={(modal: boolean) => props.setDownloadSucceed(modal)}
        />
      </div>
      <Badge filters={props.filters} setBadgeRef={setBadgeRef} tsId={props.tsId} />
    </div>
  )
}

export default Mobile
