export interface UploadProgresModalProps {
  isOpen: boolean
  progress: number
  text?: string
  type?: ProgressType
}

export enum ProgressType {
  DETERMINATE = 1,
  INDETERMINATE = 2
}
