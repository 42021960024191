import "../../assets/scss/configuration/configuration.scss"
import { Row, TabContent, TabPane } from "reactstrap"
import edit from "../../assets/images/icons/configuration/edit.svg"
import card from "../../assets/images/icons/configuration/card.svg"
import list from "../../assets/images/icons/configuration/list.svg"
import { StepConfigurationProps } from "../../interfaces/StepConfigurationProps"
import { useSelector } from "react-redux"
import { getCurrentUserState } from "../../store/counter/authSlice"
import {
  COMMISION_PAGE_SIZE,
  SITE_SETUP_VIEW_MODE,
  SITE_STATUS,
  USER_ROLES
} from "../../utils/constants"
import { useEffect, useState } from "react"
import { CommissionModalProps } from "../../interfaces/CommissionModalProps"
import CommissionModal from "./CommissionModal"
import { SiteCommission } from "../../interfaces/SiteCommission"
import TableTooltipColumn from "../../components/TableTooltipColumn"
import Messages from "utils/messages"
import PaginationV2 from "components/PaginationV2"
import { PageInfo } from "interfaces/PageInfo"
import { PaginationV2Props } from "interfaces/PaginationV2Props"
import { SiteConfiguration } from "interfaces/SiteConfiguration"
import AlertModal from "components/modal/AlertModal"
import { AlertModalProps } from "interfaces/AlertModalProps"
import { ErrorItem } from "interfaces/ErrorItem"
import { writeCodeLogEvent } from "../../utils/utils"

const StepConfigurationTeraStor = (props: StepConfigurationProps) => {
  const currentUser = useSelector(getCurrentUserState)
  const [userEditable, setUserEditable] = useState(false)
  const [commissionModal, setCommissionModal] = useState(false)
  const [currentCommission, setCurrentCommission] = useState<SiteCommission>()
  const [commissions, setCommissions] = useState<SiteCommission[]>()
  const [partNumber, setPartNumber] = useState<string>()
  const [viewMode, setViewMode] = useState(SITE_SETUP_VIEW_MODE.LIST)
  const [currentPage, setCurrentPage] = useState(0)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const [updating, setUpdating] = useState(false)

  const commissionModalProps: CommissionModalProps = {
    size: "lg",
    modal: commissionModal,
    siteId: props.site?.siteUniqueId,
    saveConfiguration: props.saveConfiguration,
    selectCommission: currentCommission,
    commissions: commissions,
    partNumber: partNumber,
    toggle: () => setCommissionModal(!commissionModal),
    onClose: () => {
      setCurrentCommission(undefined)
    }
  }

  useEffect(() => {
    let editable = false
    if (currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id) {
      editable = props.configuration?.status !== SITE_STATUS.READY_FOR_INSTALLATION
    } else {
      editable =
        props.configuration?.status === SITE_STATUS.LAYOUT_IN_PROGRESS ||
        props.configuration?.status === SITE_STATUS.CONFIGURATION_IN_PROGRESS ||
        props.configuration?.status === SITE_STATUS.LAYOUT_PENDING_APPROVAL
    }
    setUserEditable(editable)
  }, [currentUser?.role, props.configuration?.status])

  useEffect(() => {
    setCommissions(
      props.configuration?.layout?.map((layout) => {
        let commission = props.configuration?.commission?.find((c) => c.uuid === layout.uuid)
        if (!commission) {
          commission = {
            id: layout.id,
            uuid: layout.uuid,
            partNumber: partNumber
          }
        } else {
          commission.id = layout.id
        }
        return commission
      })
    )
  }, [partNumber, props.configuration?.commission, props.configuration?.layout])

  useEffect(() => {
    setPageInfo({
      totalElements: props.configuration?.layout ? props.configuration.layout.length : 0,
      totalPages: props.configuration?.layout
        ? Math.ceil(props.configuration.layout.length / COMMISION_PAGE_SIZE)
        : 0,
      pageNumber: currentPage,
      pageSize: COMMISION_PAGE_SIZE,
      pagePrev: currentPage - 1
    })
  }, [currentPage, props.configuration?.layout])

  useEffect(() => {
    setStartIndex(currentPage * COMMISION_PAGE_SIZE)
    setEndIndex(currentPage * COMMISION_PAGE_SIZE + COMMISION_PAGE_SIZE)
  }, [currentPage])

  useEffect(() => {
    setPartNumber(props.site?.teraStorPartNumber)
  }, [props.site?.teraStorPartNumber])

  useEffect(() => {
    const currStartIndex = currentPage * COMMISION_PAGE_SIZE
    if (props.configuration?.layout && props.configuration?.layout.length < currStartIndex) {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1)
      } else {
        setCurrentPage(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configuration?.layout])

  const editCommissionActions = (commission: SiteCommission) => {
    setCurrentCommission(commission)
    setCommissionModal(true)
  }

  const handleRingMarshalSelection = async (
    commission: SiteCommission,
    startIndex: number,
    endIndex: number,
    checked: boolean
  ) => {
    if (commissions) {
      const oldCommissions = [...commissions]
      const updatedCommissions: SiteCommission[] = commissions.map((c, index) => {
        if (c.uuid === commission.uuid) {
          return { ...c, isRingMarshal: checked }
        } else if (index >= startIndex && index < endIndex) {
          return { ...c, isRingMarshal: false }
        }
        return c
      })
      const result = await saveConfiguration(updatedCommissions)
      if (result) {
        setCommissions(updatedCommissions)
      } else {
        setCommissions(oldCommissions) // Revert to original state on failure
      }
    }
  }

  const saveConfiguration = async (newCommissions: SiteCommission[] | undefined) => {
    if (props.configuration?.siteId) {
      const configuration: SiteConfiguration = {
        siteId: props.configuration?.siteId,
        commission: newCommissions
      }
      try {
        setUpdating(true)
        const res = await props.saveConfiguration(configuration)
        if (res) {
          if (!res.status) {
            setAlertModalProps({
              ...alertModalProps,
              status: "error",
              message: res.errorLists
                ? res.errorLists.map((obj: ErrorItem) => obj.errorMsg).join("\n")
                : res.message,
              modal: true
            })
            return false
          }
        }
        return true
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: Messages.ERR_SAVE_CONFIGURATION,
          modal: true
        })
        console.error("Code exception: save commission =>", e.message)

        writeCodeLogEvent("save commission", e, props.site?.siteUniqueId)
        return false
      } finally {
        setUpdating(false)
      }
    } else {
      return false
    }
  }

  // ================================
  // ========== Pagination ==========
  // ================================
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const paginationV2Props: PaginationV2Props = {
    setCurrentPage: (pageNumber: number) => setCurrentPage(pageNumber),
    pageInfo,
    showingCount: props.configuration?.commission ? props.configuration?.commission.length : 0,
    forcePage: currentPage
  }

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  return (
    <div id="commissioning" className="p-3">
      <div className="d-md-flex justify-content-between pb-2">
        <h5 className="fw-bold d-flex flex-column flex-md-row">
          {Messages.LBL_AVAILABLE_TERASTORS}
        </h5>
        <ul className="nav justify-content-end">
          <li
            className={viewMode === SITE_SETUP_VIEW_MODE.LIST ? "nav-item selected " : "nav-item "}
            role="button"
          >
            <img src={list} width="16px" onClick={() => setViewMode(SITE_SETUP_VIEW_MODE.LIST)} />
          </li>
          <li
            className={viewMode === SITE_SETUP_VIEW_MODE.CARD ? "nav-item selected " : "nav-item "}
            role="button"
          >
            <img
              src={card}
              width="16px"
              onClick={() => {
                setViewMode(SITE_SETUP_VIEW_MODE.CARD)
              }}
            />
          </li>
        </ul>
      </div>
      {commissions?.length === 0 ? (
        <p className="commission-list text-center p-5 fw-bold">{Messages.MSG_NO_TERASTOR_ALERT}</p>
      ) : (
        <TabContent activeTab={viewMode}>
          <TabPane tabId={SITE_SETUP_VIEW_MODE.LIST} className="commission-list">
            <div className="table-responsive py-3 mb-1">
              <table className="table table-hover data-table mb-0">
                <thead>
                  <tr>
                    <th>{Messages.TERASTOR_NO}</th>
                    <th>{Messages.NICKNAME}</th>
                    {userEditable && !props.viewOnly && <th></th>}
                    <th className="text-wrap text-center">{Messages.RING_MARSHAL}</th>
                    <th>{Messages.PART_NO}</th>
                    <th className="text-center">
                      {Messages.QUARTER_BLOCK_A}
                      <p>{Messages.SERIAL_NO}</p>
                    </th>
                    <th className="text-center">
                      {Messages.QUARTER_BLOCK_B}
                      <p>{Messages.SERIAL_NO}</p>
                    </th>
                    <th className="text-center">
                      {Messages.QUARTER_BLOCK_C}
                      <p>{Messages.SERIAL_NO}</p>
                    </th>
                    <th className="text-center">
                      {Messages.QUARTER_BLOCK_D}
                      <p>{Messages.SERIAL_NO}</p>
                    </th>
                    <th className="text-center">
                      {Messages.EMS_BOARD}
                      <p>{Messages.SERIAL_NO}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {commissions?.slice(startIndex, endIndex).map((commission, index) => (
                    <tr key={index}>
                      <td>
                        {Messages.TERASTOR_NO}
                        {commission?.id}
                      </td>
                      {commission?.nickName ? (
                        <TableTooltipColumn
                          id={`nickName${commission?.id}`}
                          text={commission?.nickName}
                          maxLength={175}
                        />
                      ) : (
                        <td>
                          <span className="nick-name-disable">{Messages.NICKNAME}</span>
                        </td>
                      )}
                      {userEditable && !props.viewOnly && (
                        <td className="edit-button">
                          <img
                            src={edit}
                            className="p-1"
                            width="20px"
                            onClick={() => editCommissionActions(commission)}
                          />
                        </td>
                      )}
                      <td>
                        <input
                          id={`checkbox-ringmarshal-${commission.uuid}`}
                          disabled={!userEditable || props.viewOnly || updating}
                          type="checkbox"
                          className="marshal-checkbox"
                          onChange={(event) =>
                            handleRingMarshalSelection(
                              commission,
                              startIndex,
                              endIndex,
                              event.target.checked
                            )
                          }
                          checked={commission.isRingMarshal || false}
                        />
                      </td>
                      <TableTooltipColumn
                        id={`partNumber${commission?.id}`}
                        text={commission?.partNumber}
                        maxLength={200}
                      />
                      <TableTooltipColumn
                        id={`blockASerial${commission?.id}`}
                        text={commission?.blockASerial}
                        maxLength={200}
                      />
                      <TableTooltipColumn
                        id={`blockBSerial${commission?.id}`}
                        text={commission?.blockBSerial}
                        maxLength={200}
                      />
                      <TableTooltipColumn
                        id={`blockCSerial${commission?.id}`}
                        text={commission?.blockCSerial}
                        maxLength={200}
                      />
                      <TableTooltipColumn
                        id={`blockDSerial${commission?.id}`}
                        text={commission?.blockDSerial}
                        maxLength={200}
                      />
                      <TableTooltipColumn
                        id={`emsSerial${commission?.id}`}
                        text={commission?.emsBoardSerial}
                        maxLength={200}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <PaginationV2 {...paginationV2Props} />
          </TabPane>
          <TabPane tabId={SITE_SETUP_VIEW_MODE.CARD} className="commission-card">
            <Row className="mb-1">
              {commissions?.slice(startIndex, endIndex).map((commission, index) => (
                <div className="tera-stor col-3" key={index}>
                  <div className="row px-3">
                    <div className="nick-name justify-content-between d-flex p-0">
                      <p className="m-0 pt-1 ps-1 tera-id d-flex">
                        <input
                          id={`checkbox-ringmarshal-${commission.uuid}`}
                          disabled={!userEditable || props.viewOnly || updating}
                          type="checkbox"
                          className="marshal-checkbox me-1"
                          onChange={(event) =>
                            handleRingMarshalSelection(
                              commission,
                              startIndex,
                              endIndex,
                              event.target.checked
                            )
                          }
                          checked={commission.isRingMarshal || false}
                        />
                        {Messages.TERASTOR_NO}
                        {commission?.id}
                        {commission?.nickName ? (
                          <span className="fw-bold tera-nick-name">{commission?.nickName}</span>
                        ) : (
                          <span className="tera-nick-name nick-name-disable">
                            {Messages.NICKNAME}
                          </span>
                        )}
                      </p>
                      {userEditable && !props.viewOnly && (
                        <img
                          src={edit}
                          className="p-1"
                          width="20px"
                          onClick={() => editCommissionActions(commission)}
                        />
                      )}
                    </div>
                    <div className="col-6 p-0 pt-1 ps-1">
                      <p className="card-header fw-bold">{Messages.PART_NO}</p>
                      <p className="d-flex">
                        <span
                          className="label"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={commission?.partNumber}
                        >
                          {commission?.partNumber}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 p-0 pt-1 ps-1">
                      <p className="card-header fw-bold">{Messages.EMS_BOARD}</p>
                      <p className="d-flex">
                        <span className="label">{Messages.SERIAL_NO}</span>
                        <span
                          className="value"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={commission?.emsBoardSerial}
                        >
                          {commission?.emsBoardSerial}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 p-0 ps-1">
                      <p className="card-header fw-bold">{Messages.QUARTER_BLOCK_A}</p>
                      <p className="d-flex">
                        <span className="label">{Messages.SERIAL_NO}</span>
                        <span
                          className="value"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={commission?.blockASerial}
                        >
                          {commission?.blockASerial}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 p-0 ps-1">
                      <p className="card-header fw-bold">{Messages.QUARTER_BLOCK_B}</p>
                      <p className="d-flex">
                        <span className="label">{Messages.SERIAL_NO}</span>
                        <span
                          className="value"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={commission?.blockBSerial}
                        >
                          {commission?.blockBSerial}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 p-0 ps-1">
                      <p className="card-header fw-bold">{Messages.QUARTER_BLOCK_C}</p>
                      <p className="d-flex">
                        <span className="label">{Messages.SERIAL_NO}</span>
                        <span
                          className="value"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={commission?.blockCSerial}
                        >
                          {commission?.blockCSerial}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 p-0 ps-1">
                      <p className="card-header fw-bold">{Messages.QUARTER_BLOCK_D}</p>
                      <p className="d-flex">
                        <span className="label">{Messages.SERIAL_NO}</span>
                        <span
                          className="value"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={commission?.blockDSerial}
                        >
                          {commission?.blockDSerial}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
            <PaginationV2 {...paginationV2Props} />
          </TabPane>
        </TabContent>
      )}

      {commissionModal && <CommissionModal {...commissionModalProps} />}
      {alertModalProps.modal && <AlertModal {...alertModalProps} />}
    </div>
  )
}

export default StepConfigurationTeraStor
