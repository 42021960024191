import { createAsyncThunk } from "@reduxjs/toolkit"
import Message from "../../utils/messages"
import { apiGetCategory } from "services/apiDocumentManagement"
import { writeCodeLogEvent } from "utils/utils"

export const fetchCategoryThunk = createAsyncThunk("doc/category", async () => {
  try {
    const response = await apiGetCategory()

    return {
      status: response.status,
      message: response.message,
      data: response.data
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error("Code exception: thunk get doc category =>", err.message)
    writeCodeLogEvent("[Document Center] thunk get doc category", err)
    return {
      status: false,
      message: Message.ERR_DOCUMENTS_CATEGORY_GET_FAIL
    }
  }
})
