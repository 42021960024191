import arrow from "../../assets/images/icons/admin/arrow-open.svg"
import { USER_ROLES, SITE_STATUS, SITE_SETUP_STEP } from "utils/constants"
import messages from "utils/messages"

const ReviewSection = (props: {
  step: string
  title: string
  activeStep: string
  userRole?: string
  configurationStatus?: string
  toggle: (step: string) => void
  updateStatus: (status: string) => void
  children: React.ReactNode
}) => {
  const {
    step,
    title,
    activeStep,
    userRole: userRole,
    configurationStatus,
    toggle,
    updateStatus,
    children
  } = props
  const isActive = activeStep === step
  const canEdit =
    userRole !== USER_ROLES.AESI_SUPER_ADMIN.id &&
    configurationStatus === SITE_STATUS.PENDING_FINAL_APPROVAL

  const handleEditClick = () => {
    const status =
      step === SITE_SETUP_STEP.LAYOUT
        ? SITE_STATUS.LAYOUT_IN_PROGRESS
        : SITE_STATUS.CONFIGURATION_IN_PROGRESS
    updateStatus(status)
  }

  return (
    <div className="px-4 pe-4">
      <div className="justify-content-between d-md-flex mt-2 px-2 mb-2">
        <h4 className="fw-bold pt-2">{title}</h4>
        <div>
          {isActive && canEdit && (
            <button
              className="btn-actions btn btn-light rounded border-info fw-bold"
              onClick={handleEditClick}
            >
              {messages.BTN_SITE_SETUP_EDIT}
            </button>
          )}
          <img
            src={arrow}
            className={`p-3 ${isActive ? "" : "rotate-180"}`}
            width="50x"
            onClick={() => toggle(step)}
          />
        </div>
      </div>
      {isActive && children}
    </div>
  )
}

export default ReviewSection
