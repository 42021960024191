import "../../assets/scss/configuration/configuration.scss"
import React, { useEffect } from "react"
import { Button, Col, Row, Spinner } from "reactstrap"
import { useRef, useState } from "react"
import SiteMap from "../../components/configuration/SiteMap"
import ElementSelection from "../../components/configuration/ElementSelection"
import { ElementSelectionProps } from "../../interfaces/ElementSelectionProps"
import { SiteMapProps } from "../../interfaces/SiteMapProps"
import {
  apiCreateSiteConfiguration,
  apiResourceDownload,
  apiResourceUpload
} from "../../services/apiSiteConfiguration"
import { AxiosProgressEvent } from "axios"
import UploadProgresModal from "../../components/modal/UploadProgresModal"
import { SiteConfiguration } from "../../interfaces/SiteConfiguration"
import { imageValidator } from "utils/imageValidator"
import { SiteDrawingResizerProps } from "interfaces/SiteDrawingResizerProps"
import SiteDrawingResizer from "components/configuration/SiteDrawingResizer"
import { StepConfigurationProps } from "../../interfaces/StepConfigurationProps"
import { useSelector } from "react-redux"
import { getCurrentUserState } from "../../store/counter/authSlice"
import {
  blankNetwork,
  defaultPOIPrimaryMeter,
  SITE_STATUS,
  USER_ROLES
} from "../../utils/constants"
import AlertModal from "../../components/modal/AlertModal"
import { AlertModalProps } from "../../interfaces/AlertModalProps"
import { ElementProperties } from "../../interfaces/ElementProperties"
import ConfirmationModal from "components/modal/ConfirmationModal"
import { stringFormat, writeCodeLogEvent } from "../../utils/utils"
import Messages from "../../utils/messages"
import { NetworkConfiguration } from "interfaces/NetworkConfiguration"
import { SitePowerInformation } from "interfaces/ConfigurationSiteInformation"
import { ProgressType } from "interfaces/UploadProgresModalProps"
import LayoutActionButtons from "components/configuration/LayoutActionButtons"

enum StepName {
  DrawingUpload,
  ResizeDrawing,
  SiteLayout
}

enum LayoutStatus {
  NO_TERASTOR,
  EXCEED_NUMBER_TERASTOR,
  SUBCEED_NUMBER_TERASTOR,
  OK
}

export type SaveConfigurationHandle = {
  saveConfiguration: () => Promise<void>
  getConfiguration: () => ElementProperties[]
}

const StepLayout: (props: StepConfigurationProps) => JSX.Element = (
  props: StepConfigurationProps
) => {
  const configurationStatus = props.configuration?.status

  const defaultSitePowerInformation: SitePowerInformation = {
    acVoltage: "690",
    ratedReactivePower: props.site?.numTeraStors
      ? (2520 * props.site?.numTeraStors).toString()
      : "",
    ratedRealPower: props.site?.numTeraStors ? (3600 * props.site?.numTeraStors).toString() : "",
    ratedEnergyCapacity: props.site?.numTeraStors
      ? (7200 * props.site?.numTeraStors).toString()
      : ""
  }

  const defaultNetwork: NetworkConfiguration = {
    powerQualityMeterNetworks: [defaultPOIPrimaryMeter, blankNetwork, blankNetwork, blankNetwork],
    sitePowerInformation: defaultSitePowerInformation,
    storViewNetwork: blankNetwork
  }
  const currentUser = useSelector(getCurrentUserState)
  const siteMapRef = useRef<SaveConfigurationHandle>(null)
  const prevSiteUniqueIdRef = useRef<number | undefined>(undefined)
  const backgroundFileInputRef = useRef<HTMLInputElement | null>(null)

  const [isBackgroundUploaded, setIsBackgroundUploaded] = useState<boolean>(false)
  const [backgroundImage, setBackgroundImage] = useState<string>("")
  const [draggedElement, setDraggedElement] = useState({
    element: "",
    offsetX: 0,
    offsetY: 0,
    elementWidth: 0,
    elementHeight: 0
  })
  const [layoutStep, setLayoutStep] = useState<StepName>(StepName.DrawingUpload)
  const [backgroundDimension, setBackgroundDimension] = useState({ width: 0, height: 0 })
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [drawingFile, setDrawingFile] = useState<File>()
  const [userEditable, setUserEditable] = useState(false)
  const [savingLayout, setSavingLayout] = useState(false)

  const [submitReviewModalProps, setSubmitReviewModalProps] = useState({
    isShow: false,
    content: ""
  })

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  const getLayoutStatus = () => {
    if (
      !props.configuration ||
      !props.configuration.layout ||
      props.configuration.layout.length === 0
    ) {
      return LayoutStatus.NO_TERASTOR
    } else if (props.configuration && props.configuration?.layout && props.site) {
      if (props.configuration.layout.length > props.site.numTeraStors) {
        return LayoutStatus.EXCEED_NUMBER_TERASTOR
      } else if (props.configuration.layout.length < props.site.numTeraStors) {
        return LayoutStatus.SUBCEED_NUMBER_TERASTOR
      }
    }
    return LayoutStatus.OK
  }

  useEffect(() => {
    if (
      isSiteIdValid(props.site?.siteUniqueId) &&
      props.site?.siteUniqueId !== prevSiteUniqueIdRef.current
    ) {
      setIsLoading(true)
      apiResourceDownload(`site-images/site_${props.site?.siteUniqueId}/drawing.png`)
        .then((res) => {
          if (res.status === 200) {
            // parse image and display
            const backgroundUrl = getBase64ImageUrl(res.data)
            onSiteImageLoaded(backgroundUrl)
          } else if (res.status === 404) {
            // new site configuration flow
            setIsLoading(false)
            setLayoutStep(StepName.DrawingUpload)
          } else {
            setAlertModalProps({
              ...alertModalProps,
              status: "error",
              message: res.message,
              modal: true
            })
            setIsLoading(false)
          }
        })
        .catch((e) => {
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: `Can not get the site image. Please try again (${e.message})`,
            modal: true
          })
          setIsLoading(false)

          console.error("Code exception: handle download site image =>", e.message)

          writeCodeLogEvent("handle download site image", e, props.site?.siteUniqueId)
        })
      // Update the ref with the current siteUniqueId
      prevSiteUniqueIdRef.current = props.site?.siteUniqueId
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site?.siteUniqueId])

  useEffect(() => {
    if (
      isBackgroundUploaded &&
      (props.configuration === undefined ||
        configurationStatus === undefined ||
        configurationStatus === SITE_STATUS.START_SITE_LAYOUT)
    ) {
      createSiteConfiguration()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBackgroundUploaded])

  useEffect(() => {
    if (currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id) {
      setUserEditable(
        !props.configuration ||
          configurationStatus === SITE_STATUS.LAYOUT_IN_PROGRESS ||
          configurationStatus === SITE_STATUS.LAYOUT_PENDING_APPROVAL
      )
    } else {
      setUserEditable(
        !props.configuration || configurationStatus === SITE_STATUS.LAYOUT_IN_PROGRESS
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, configurationStatus])

  const onSiteImageLoaded = (imageUrl: string) => {
    const image = new Image()
    image.src = imageUrl

    image.onload = () => {
      const width = image.width
      const height = image.height
      setBackgroundDimension({ width, height })

      // process to next screen when load image successfully
      setBackgroundImage(imageUrl)
      setIsBackgroundUploaded(true)
      setLayoutStep(StepName.SiteLayout)
      setIsLoading(false)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    image.onerror = (e: any) => {
      setAlertModalProps({
        ...alertModalProps,
        status: "error",
        message: e.message,
        modal: true
      })
      setIsLoading(false)
    }
  }

  const OnUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const progres = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setUploadProgress(progres)
    }
  }

  const elementDragged = (event: React.MouseEvent, element: string) => {
    const rect = event.currentTarget.getBoundingClientRect()
    const offsetX = event.clientX - rect.left
    const offsetY = event.clientY - rect.top
    setDraggedElement({
      element: element,
      offsetX: offsetX,
      offsetY: offsetY,
      elementWidth: rect.width,
      elementHeight: rect.height
    })
  }

  const elementSelectionProps: ElementSelectionProps = {
    elementDragged: elementDragged,
    totalElements: props.site?.numTeraStors || 0,
    placedElements: props.configuration?.layout?.length || 0
  }

  const handleFileSelect = () => {
    if (backgroundFileInputRef.current) {
      backgroundFileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]

    if (selectedFile) {
      handleSiteDrawingFileChange(selectedFile)
    }
    event.target.value = "" // Clear the file input field
  }

  const handleChangeBackground = () => {
    handleFileSelect()
  }

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault()
    const droppedFile = e.dataTransfer.files[0]

    if (droppedFile) {
      handleSiteDrawingFileChange(droppedFile)
    }
  }

  const handleSiteDrawingFileChange = (file: File) => {
    const validateResult = imageValidator(file)
    if (validateResult.valid) {
      setDrawingFile(file)
      setLayoutStep(StepName.ResizeDrawing)
    } else {
      setAlertModalProps({
        ...alertModalProps,
        status: "error",
        message: validateResult.message,
        modal: true
      })
    }
  }

  const onResizeComplete = async (resizedFile: File) => {
    if (resizedFile) {
      handleSiteMapUpload(resizedFile)
    }
  }

  const onCancelResize = async () => {
    if (isBackgroundUploaded) {
      setLayoutStep(StepName.SiteLayout)
    } else {
      setLayoutStep(StepName.DrawingUpload)
    }
  }

  const handleSiteMapUpload = (file: File) => {
    setAlertModalProps({
      ...alertModalProps,
      status: "loading",
      message: ""
    })
    setUploadProgress(0)
    setIsProgressModalOpen(true)
    // Upload file to s3
    apiResourceUpload(
      `site-images/site_${props.site?.siteUniqueId}/drawing.png`,
      file,
      OnUploadProgress
    ).then((res) => {
      if (res.status) {
        const imageUrl = URL.createObjectURL(file)
        onSiteImageLoaded(imageUrl)
      } else {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: res.message,
          modal: true
        })
      }
      setIsProgressModalOpen(false)
      setLayoutStep(StepName.SiteLayout)
    })
  }

  const createSiteConfiguration = () => {
    const configuration: SiteConfiguration = {
      siteId: props.site?.siteUniqueId,
      layout: [],
      network: defaultNetwork,
      status: SITE_STATUS.LAYOUT_IN_PROGRESS
    }

    apiCreateSiteConfiguration(configuration)
      .then((res) => {
        if (res.status) {
          props.setConfiguration(res.data, true)
        } else {
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: res.message,
            modal: true
          })
        }
      })
      .catch((e) => {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: e.message,
          modal: true
        })

        console.error("Code exception: handle create site configuration =>", e.message)

        writeCodeLogEvent("handle create site configuration", e, props.site?.siteUniqueId)
      })
  }

  const handleDrawingDragOver = (e: React.DragEvent) => {
    e.preventDefault()
  }

  const siteMapProps: SiteMapProps = {
    siteUniqueId: props.configuration?.siteId,
    draggedElement: draggedElement,
    backgroundUrl: backgroundImage,
    backgroundWidth: backgroundDimension.width,
    backgroundHeight: backgroundDimension.height,
    configuration: props.configuration,
    setConfiguration: props.setConfiguration,
    saveConfiguration: props.saveConfiguration,
    handleChangeBackground: handleChangeBackground,
    viewOnly: props.viewOnly || !userEditable,
    syncState: props.syncState,
    setSyncState: props.setSyncState,
    contentHeight: props.contentHeight,
    contentWidth: props.contentWidth
  }

  const siteDrawingResizerProps: SiteDrawingResizerProps = {
    drawingFile: drawingFile,
    onResizeComplete: onResizeComplete,
    onCancelResize: onCancelResize
  }

  const submitForReview = () => {
    let layout = undefined
    if (siteMapRef.current) {
      layout = siteMapRef.current.getConfiguration()
    }
    if (currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id) {
      updateStatus(SITE_STATUS.CONFIGURATION_IN_PROGRESS, layout)
    } else {
      updateStatus(SITE_STATUS.LAYOUT_PENDING_APPROVAL, layout)
    }
  }

  const updateStatus = async (
    status: string,
    layout: ElementProperties[] | undefined = undefined
  ) => {
    setAlertModalProps({
      ...alertModalProps,
      status: "loading",
      message: "",
      modal: true
    })
    if (props.configuration?.siteId) {
      const configuration: SiteConfiguration = {
        siteId: props.configuration?.siteId,
        status: status,
        layout: layout
      }

      try {
        const res = await props.saveConfiguration(configuration)
        if (res) {
          if (!res.status) {
            setAlertModalProps({
              ...alertModalProps,
              status: "error",
              message: res.message,
              modal: true
            })
          } else {
            if (SITE_STATUS.LAYOUT_PENDING_APPROVAL === status) {
              setAlertModalProps({
                ...alertModalProps,
                status: "success",
                message: "The Site layout was successfully submitted for approval.",
                modal: true
              })
            }
          }
        }
        setAlertModalProps({
          ...alertModalProps,
          modal: false
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: e.message,
          modal: true
        })
        console.error("Code exception: handle save configuration =>", e.message)

        writeCodeLogEvent("handle save configuration", e, props.site?.siteUniqueId)
      }
    }
  }

  const onSaveLayout = async () => {
    if (siteMapRef.current) {
      setSavingLayout(true)
      await siteMapRef.current.saveConfiguration()
      setSavingLayout(false)
    }
  }

  const onLayoutSubmit = () => {
    const layoutStatus = getLayoutStatus()
    const messageExceedAlert =
      currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id
        ? Messages.MSG_EXCEED_SUCCEED_NUMBER_TERASTOR_ALERT_SUPPER_ADMIN
        : Messages.MSG_EXCEED_SUCCEED_NUMBER_TERASTOR_ALERT
    switch (layoutStatus) {
      case LayoutStatus.NO_TERASTOR:
        setSubmitReviewModalProps({
          isShow: true,
          content: Messages.MSG_NO_TERASTOR_ALERT
        })
        break
      case LayoutStatus.EXCEED_NUMBER_TERASTOR:
      case LayoutStatus.SUBCEED_NUMBER_TERASTOR:
        setSubmitReviewModalProps({
          isShow: true,
          content: stringFormat(messageExceedAlert, [
            props.site?.numTeraStors.toString() || "0",
            props.site?.numTeraStors === 1 ? "" : "s",
            props.configuration?.layout?.length.toString() || "0",
            props.configuration?.layout?.length === 1 ? "" : "s"
          ])
        })
        break
      case LayoutStatus.OK:
      default:
        submitForReview()
        break
    }
  }

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: props.contentHeight }}
      >
        <Spinner color="primary" className="mb-3">
          {Messages.MSG_LOADING}
        </Spinner>
        <p className="mb-0 fs-4">{Messages.MSG_LOADING_SITE_MAP}</p>
      </div>
    )
  }

  // #region JSX
  return (
    <div className="h-100 w-100">
      {isProgressModalOpen && (
        <UploadProgresModal
          isOpen={isProgressModalOpen}
          progress={uploadProgress}
          type={ProgressType.DETERMINATE}
        />
      )}
      {layoutStep === StepName.DrawingUpload &&
        (props.viewOnly || !userEditable ? (
          <div
            className="h-100 d-flex flex-column align-items-center justify-content-center text-center"
            style={{ height: props.contentHeight }}
          >
            <p className="fs-5 text-primary">{Messages.MSG_NO_SITE_DRAWING_UPLOADED}</p>
          </div>
        ) : (
          <div
            className="d-flex flex-column align-items-center justify-content-center text-center"
            style={{ height: props.contentHeight }}
            onDrop={handleDrop}
            onDragOver={handleDrawingDragOver}
          >
            <p className="fs-5 text-primary">{Messages.LBL_SITE_SETUP_UPLOAD_BACKGROUND}</p>
            <Button onClick={handleFileSelect} className="text-common-style round-button">
              {Messages.LBL_FW_UPDATE_UPLOAD}
            </Button>
          </div>
        ))}
      {layoutStep !== StepName.DrawingUpload && (
        <>
          <Row>
            <Col md={!siteMapProps.viewOnly ? "10" : "12"} className="h-100">
              {layoutStep === StepName.SiteLayout && <SiteMap ref={siteMapRef} {...siteMapProps} />}

              {layoutStep === StepName.ResizeDrawing && (
                <SiteDrawingResizer {...siteDrawingResizerProps} />
              )}
            </Col>
            {!siteMapProps.viewOnly && (
              <Col md="2">
                <ElementSelection {...elementSelectionProps} />
              </Col>
            )}
          </Row>
          {layoutStep === StepName.SiteLayout && (
            <Row>
              <Col className="d-flex justify-content-end pt-4">
                <LayoutActionButtons
                  viewOnly={props.viewOnly}
                  userEditable={userEditable}
                  isLoading={savingLayout}
                  currentUserRole={currentUser?.role}
                  configurationStatus={configurationStatus}
                  onSaveLayout={onSaveLayout}
                  onLayoutSubmit={onLayoutSubmit}
                  updateStatus={updateStatus}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      <input
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={backgroundFileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <AlertModal {...alertModalProps} />

      {submitReviewModalProps.isShow && (
        <ConfirmationModal
          size="md"
          modal={true}
          content={submitReviewModalProps.content}
          cancelButtonText={Messages.BTN_SITE_SETUP_BACK}
          okButtonText={
            currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id
              ? Messages.BTN_SITE_SETUP_APPROVE
              : Messages.BTN_SITE_SETUP_SUBMIT
          }
          toggle={() => setSubmitReviewModalProps({ isShow: false, content: "" })}
          onOK={() => {
            submitForReview()
          }}
        />
      )}
    </div>
  )
  // #endregion JSX
}

export default StepLayout

const signatures: { [key: string]: string } = {
  JVBERi0: "pdf",
  R0lGODdh: "gif",
  R0lGODlh: "gif",
  iVBORw0KGgo: "png",
  "/9j/": "jpg"
}

const getBase64Extension = (b64: string): string | undefined => {
  for (const s in signatures) {
    if (Object.prototype.hasOwnProperty.call(signatures, s)) {
      if (b64.indexOf(s) === 0) {
        return signatures[s]
      }
    }
  }
  return undefined
}

function getBase64ImageUrl(base64Str: string): string {
  const extension = getBase64Extension(base64Str) || "jpg"
  return `data:image/${extension};base64,` + base64Str
}

function isSiteIdValid(siteUniqueId: number | undefined): boolean {
  if (!siteUniqueId || isNaN(siteUniqueId)) {
    return false
  }
  return true
}
