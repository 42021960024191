import React, { memo } from "react"
import { Modal, ModalBody, Progress, Row, Spinner } from "reactstrap"

import "../../assets/scss/configuration/configuration.scss"

import { UploadProgresModalProps, ProgressType } from "../../interfaces/UploadProgresModalProps"

import Messages from "../../utils/messages"

const UploadProgresModal = memo((props: UploadProgresModalProps) => {
  if (!props.isOpen) return null

  return (
    <Modal className="modal-overlay" isOpen={props.isOpen} centered={true}>
      <ModalBody className="bg-white rounded p-3 pt-5">
        <Row className="text-center text-common-style">
          <p
            style={{
              fontWeight: 600
            }}
          >
            {props.text || Messages.LBL_UPLOAD_PROCESS_UPLOADING}
          </p>
        </Row>
        {props.type === ProgressType.DETERMINATE ? (
          <>
            <Row className="justify-content-center align-items-center p-3">
              <Progress
                className="w-75 p-0"
                barClassName="custom-progress"
                variant="determinate"
                value={props.progress}
                style={{
                  height: "20px",
                  backgroundColor: "#D9D9D9"
                }}
              />
            </Row>
            <Row className="text-center">
              <p>{props.progress}%</p>
            </Row>
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center p-3">
            <Spinner color="primary" size="md" />
          </div>
        )}
      </ModalBody>
    </Modal>
  )
})

UploadProgresModal.displayName = "UploadProgresModal"

export default UploadProgresModal
