import type { RootState } from "../store"
import { createSlice } from "@reduxjs/toolkit"
import Moment from "moment"

import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"
import { SiteEntity } from "../../interfaces/SiteEntity"

import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { VisualizationDateRangeEnum } from "../../enum/visualization/tab/daterange-tab"
import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"
import { ExportFileTypeEnum } from "../../enum/visualization/export-file-type"
import { DataMode } from "../../enum/visualization/data-mode"

import { fetchingListAllSites } from "./visualizationThunk"
import { _fetchTeraStorData, sitesOverviewThunk } from "./monitoringThunk"

import { SERVER_DATE_FORMAT, VISUALIZATION_DATE_RANGE } from "../../utils/constants"
import { genDateApart } from "../../utils/utils"

import { getGroupedTeraStorData } from "./monitoringSlice"
import {
  SiteOverviewData,
  TeraStorGroup
} from "../../interfaces/monitoring/maxnerva/SiteOverviewData"
import { ChartData } from "interfaces/visualization/ChartData"
import { DropDown } from "interfaces/visualization/Dropdown"

interface DatePayload {
  payload: string
}
interface LoadUnitPayload {
  payload: LoadFilterEnum
}
interface TeraStorPayload {
  payload: SiteTeraStorData[]
}
interface ChartDataPayload {
  payload: {
    tsId?: number
    data: ChartData[]
  }
}
interface TeraStorFilterPayload {
  payload: {
    tsId?: number
    data: DropDown[]
  }
}
interface FiltersPayload {
  payload: {
    filters: VisualizationFilterEnum
    dataKey: BatterFilterEnum | LoadFilterEnum
    data: BatterFilterDataEnum | LoadFilterDataEnum
  }
}
interface VisualizationState {
  currentSiteEntity: SiteEntity | null
  selectedTeraStors: SiteTeraStorData[]
  sitesOverviewData: SiteOverviewData[]
  teraStorGroupData: Record<string, SiteTeraStorData[]> | null

  isFetchingListAllSites: boolean
  isFetchingCharts: boolean
  allSites: SiteEntity[]

  filters: VisualizationFilterEnum
  batteryFilter: Record<BatterFilterEnum, BatterFilterDataEnum[]>
  loadUnit: LoadFilterEnum
  loadFilter: Record<LoadFilterEnum, LoadFilterDataEnum[]>
  dateRange: VisualizationDateRangeEnum
  hourDate: string
  fromDate: string
  toDate: string
  viewBy: VisualizationViewByEnum

  isMultipleDownload: boolean
  multiDownloadFileType?: ExportFileTypeEnum
  chartData: Record<number, ChartData[]>
  teraStorFilters: Record<number, DropDown[]>

  dataMode: DataMode
}

const toDay = new Date()
const initDate = genDateApart(toDay, -VISUALIZATION_DATE_RANGE, SERVER_DATE_FORMAT)
const toDate = Moment(toDay).format(SERVER_DATE_FORMAT)

const initialState: VisualizationState = {
  currentSiteEntity: null,
  selectedTeraStors: [],
  sitesOverviewData: [],
  teraStorGroupData: null,

  isFetchingListAllSites: false,
  isFetchingCharts: false,
  allSites: [],

  filters: VisualizationFilterEnum.Battery,
  batteryFilter: {
    [BatterFilterEnum.SOC]: [BatterFilterDataEnum.SOC],
    [BatterFilterEnum.Temperature]: [
      BatterFilterDataEnum.Maximum,
      BatterFilterDataEnum.Average,
      BatterFilterDataEnum.Minimum
    ],
    [BatterFilterEnum.Voltage]: [
      BatterFilterDataEnum.Maximum,
      BatterFilterDataEnum.Average,
      BatterFilterDataEnum.Minimum
    ]
  },
  loadUnit: LoadFilterEnum.Current,
  loadFilter: {
    [LoadFilterEnum.Current]: [LoadFilterDataEnum.IA, LoadFilterDataEnum.IB, LoadFilterDataEnum.IC],
    [LoadFilterEnum.Voltage]: [
      LoadFilterDataEnum.VAB,
      LoadFilterDataEnum.VBC,
      LoadFilterDataEnum.VCA
    ],
    [LoadFilterEnum.RealPower]: [LoadFilterDataEnum.Commanded, LoadFilterDataEnum.Actual],
    [LoadFilterEnum.ReactivePower]: [LoadFilterDataEnum.Commanded, LoadFilterDataEnum.Actual]
  },
  dateRange: VisualizationDateRangeEnum.Hours,
  hourDate: toDate,
  fromDate: initDate,
  toDate: toDate,
  viewBy: VisualizationViewByEnum.Detail,

  isMultipleDownload: false,
  chartData: {},
  teraStorFilters: {},

  dataMode: DataMode.Average
}

export const visualizationSlice = createSlice({
  name: "visualization",
  initialState,
  reducers: {
    reset: () => {
      // reset state to initial state
      return initialState
    },
    setSiteEntity: (state, action) => {
      state.currentSiteEntity = action.payload
      state.teraStorGroupData = null
      state.selectedTeraStors = []
    },
    setTeraStorGroupData: (state, action) => {
      state.teraStorGroupData = action.payload
    },
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setFromDate: (state, action: DatePayload) => {
      state.fromDate = action.payload
    },
    setToDate: (state, action: DatePayload) => {
      state.toDate = action.payload
    },
    setHourDate: (state, action: DatePayload) => {
      state.hourDate = action.payload
    },
    setViewBy: (state, action) => {
      state.viewBy = action.payload
    },
    setSelectedTeraStors: (state, action: TeraStorPayload) => {
      state.selectedTeraStors = action.payload
    },
    setChartData: (state, action: ChartDataPayload) => {
      if (action.payload.tsId) {
        state.chartData[action.payload.tsId] = action.payload.data
      }
    },
    setTeraStorFilter: (state, action: TeraStorFilterPayload) => {
      if (action.payload.tsId) {
        state.teraStorFilters[action.payload.tsId] = action.payload.data
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addFilterData: (state: any, action: FiltersPayload) => {
      const { filters, dataKey, data } = action.payload

      const stateKey = filters === VisualizationFilterEnum.Battery ? "batteryFilter" : "loadFilter"

      const arrData = state[stateKey][dataKey]
      arrData.push(data)
      state[stateKey][dataKey] = arrData
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeFilterData: (state: any, action: FiltersPayload) => {
      const { filters, dataKey, data } = action.payload

      const stateKey = filters === VisualizationFilterEnum.Battery ? "batteryFilter" : "loadFilter"

      const arrData = state[stateKey][dataKey]
      state[stateKey][dataKey] = arrData.filter(
        (item: BatterFilterDataEnum | LoadFilterDataEnum) => item !== data
      )
    },
    setLoadUnit: (state, action: LoadUnitPayload) => {
      state.loadUnit = action.payload
    },
    setMultipleDownload: (state, action) => {
      state.isMultipleDownload = action.payload
    },
    setMultiDownloadFileType: (state, action) => {
      state.multiDownloadFileType = action.payload
    },
    setDataMode: (state, action) => {
      state.dataMode = action.payload
    },
    setIsFetchingCharts: (state, action) => {
      state.isFetchingCharts = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchingListAllSites.pending, (state: VisualizationState) => {
      state.isFetchingListAllSites = true
      state.allSites = []
      state.isFetchingCharts = true
      state.teraStorGroupData = null
      state.currentSiteEntity = null
      state.selectedTeraStors = []
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(fetchingListAllSites.fulfilled, (state: VisualizationState, action: any) => {
      const payload = action.payload
      state.isFetchingListAllSites = false
      if (payload.status) {
        const {
          allSites,
          sitesOverviewData,
          teraStorGroupData,
          selectedTeraStors,
          currentSiteEntity
        } = payload.data

        state.allSites = allSites
        // no site or more than 1 site -> reset setching chart status
        // with 1 site -> keep fetching chart status for rendering chart
        if (allSites.length === 0 || allSites.length > 1) state.isFetchingCharts = false
        if (sitesOverviewData.length > 0) state.sitesOverviewData = sitesOverviewData
        if (teraStorGroupData) state.teraStorGroupData = teraStorGroupData
        if (selectedTeraStors.length > 0) state.selectedTeraStors = selectedTeraStors
        if (currentSiteEntity) state.currentSiteEntity = currentSiteEntity
      }
    })

    builder.addCase(
      sitesOverviewThunk.fulfilled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state: VisualizationState, action: any) => {
        const payload = action.payload
        if (payload.status) {
          state.sitesOverviewData = payload.data
        }
      }
    )
  }
})

// Action creators are generated for each case reducer function
export const {
  setSiteEntity,
  setSelectedTeraStors,
  setTeraStorGroupData,
  setFilters,
  setViewBy,
  setDateRange,
  addFilterData,
  removeFilterData,
  setLoadUnit,
  setFromDate,
  setToDate,
  setHourDate,
  setMultipleDownload,
  setMultiDownloadFileType,
  setChartData,
  setTeraStorFilter,
  setDataMode,
  setIsFetchingCharts
} = visualizationSlice.actions

export { fetchingListAllSites }

export const fechTeraStorData = async (siteUniqueId: number, teraStorGroup: TeraStorGroup[]) => {
  const res = await _fetchTeraStorData(siteUniqueId)
  if (res.status) {
    res.data = getGroupedTeraStorData(teraStorGroup, res.data) as Record<string, SiteTeraStorData[]>
  }
  return res
}

export const getFilters = (state: RootState) => state.visualization.filters
export const getBatteryFilter = (state: RootState) => state.visualization.batteryFilter
export const getLoadUnit = (state: RootState) => state.visualization.loadUnit
export const getLoadFilter = (state: RootState) => state.visualization.loadFilter
export const getDateRange = (state: RootState) => state.visualization.dateRange
export const getFromDate = (state: RootState) => state.visualization.fromDate
export const getToDate = (state: RootState) => state.visualization.toDate
export const getHourDate = (state: RootState) => state.visualization.hourDate
export const getViewBy = (state: RootState) => state.visualization.viewBy
export const getMultipleDownload = (state: RootState) => state.visualization.isMultipleDownload
export const getMultiDownloadFileType = (state: RootState) =>
  state.visualization.multiDownloadFileType
export const getChartData = (state: RootState) => state.visualization.chartData
export const getTeraStorFilters = (state: RootState) => state.visualization.teraStorFilters

export const getSiteEntity = (state: RootState) => state.visualization.currentSiteEntity
export const getSelectedTeraStors = (state: RootState) => state.visualization.selectedTeraStors
export const getTeraStorGroupData = (state: RootState) => state.visualization.teraStorGroupData
export const getSitesOverviewData = (state: RootState) => state.visualization.sitesOverviewData
export const getDataMode = (state: RootState) => state.visualization.dataMode

export const getFetchingListAllSitesStatus = (state: RootState) =>
  state.visualization.isFetchingListAllSites
export const getAllSites = (state: RootState) => state.visualization.allSites
export const getFetchingChartsStatus = (state: RootState) => state.visualization.isFetchingCharts

export default visualizationSlice.reducer
