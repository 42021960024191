import { createAsyncThunk } from "@reduxjs/toolkit"
import Message from "../../utils/messages"

import {
  apiMonitoringGetSiteSummary,
  apiMonitoringGetSitesOverview,
  apiMonitoringGetTeraStorsData
} from "../../services/apiSiteMonitoring"

export const summaryDataThunk = createAsyncThunk(
  "monitoring/summaryData",
  async ({
    siteUniqueId,
    customerUniqueId
  }: {
    siteUniqueId: number
    customerUniqueId?: number
  }) => {
    try {
      const response = await apiMonitoringGetSiteSummary(siteUniqueId, customerUniqueId)

      return {
        status: response.status,
        message: response.message,
        data: response.data
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error("Code exception: thunk get site summary =>", err.message)
      return {
        status: false,
        message: Message.ERR_MONITORING_GET_SITE_SUMMARY_FAILED
      }
    }
  }
)

export const sitesOverviewThunk = createAsyncThunk(
  "monitoring/sitesOverview",
  async ({
    siteUniqueIds,
    customerUniqueId
  }: {
    siteUniqueIds: number[]
    customerUniqueId: number
  }) => {
    try {
      const requestBody = { siteUniqueIds: siteUniqueIds }
      const response = await apiMonitoringGetSitesOverview(customerUniqueId, requestBody)

      return {
        status: response.status,
        message: response.message,
        data: response.data
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error("Code exception: thunk get site summary =>", err.message)
      return {
        status: false,
        message: Message.ERR_MONITORING_GET_SITE_SUMMARY_FAILED
      }
    }
  }
)

export const _fetchTeraStorData = async (siteUniqueId: number, showSoftwareStatus = false) => {
  try {
    const response = await apiMonitoringGetTeraStorsData(siteUniqueId, showSoftwareStatus)

    return {
      status: response.status,
      message: response.message,
      data: response.data
        ? response.data.sort((a: { tsId: number }, b: { tsId: number }) => {
            const aTsId = Number(a.tsId)
            const bTsId = Number(b.tsId)

            if (isNaN(aTsId) || isNaN(bTsId)) {
              return 0 // ignore invalid tsIds and leave them at their current indices
            }

            return aTsId - bTsId
          })
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error("Code exception: thunk get site list terastor =>", err.message)
    return {
      status: false,
      message: Message.ERR_MONITORING_GET_SITE_TERASTOR_FAILED
    }
  }
}

export const teraStorDataThunk = createAsyncThunk(
  "monitoring/teraStorData",
  async ({
    siteUniqueId,
    showSoftwareStatus = false
  }: {
    siteUniqueId: number
    showSoftwareStatus?: boolean
  }) => await _fetchTeraStorData(siteUniqueId, showSoftwareStatus)
)
