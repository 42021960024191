import { useEffect, useRef, RefObject } from "react"
import { useSelector } from "react-redux"

import { getFilters, getLoadUnit } from "../../../store/counter/visualizationSlice"

import ChartBadge from "../ChartBadge"

import { DropDown } from "../../../interfaces/visualization/Dropdown"

import { VisualizationFilterEnum } from "../../../enum/visualization/tab/filter-tab"
import { Color } from "../../../enum/visualization/color"
import { BatterFilterEnum } from "../../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../../enum/visualization/tab/load-filter-data"

import Messages from "../../../utils/messages"
import { getVisuaUnit } from "../../../utils/utils"

interface BadgeProps {
  filters: DropDown[]

  tsId?: number

  setBadgeRef: (ref: RefObject<HTMLDivElement>) => void
}
const Badge = (props: BadgeProps) => {
  const filters = useSelector(getFilters)
  const loadUnit = useSelector(getLoadUnit)

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    props.setBadgeRef(ref)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {filters === VisualizationFilterEnum.Battery ? (
        <div
          className="badge-wrapper d-flex justify-content-start justify-content-md-center pb-3 pe-1"
          ref={ref}
          id={`badge${props.tsId}`}
        >
          <ChartBadge
            title={`${Messages.LBL_VISUALIZATION_SOC} ${getVisuaUnit(BatterFilterEnum.SOC)}`}
            color={Color.ReactivePower}
            filter={VisualizationFilterEnum.Battery}
            dataKey={BatterFilterEnum.SOC}
            filters={props.filters}
            desiredOrder={[BatterFilterDataEnum.SOC]}
          />
          <ChartBadge
            title={`${Messages.LBL_VISUALIZATION_TEMPERATURE} ${getVisuaUnit(BatterFilterEnum.Temperature)}`}
            color={Color.Temperature}
            filter={VisualizationFilterEnum.Battery}
            dataKey={BatterFilterEnum.Temperature}
            filters={props.filters}
            desiredOrder={[
              BatterFilterDataEnum.Maximum,
              BatterFilterDataEnum.Average,
              BatterFilterDataEnum.Minimum
            ]}
          />
          <ChartBadge
            title={`${Messages.LBL_VISUALIZATION_VOLTAGE} ${getVisuaUnit(BatterFilterEnum.Voltage)}`}
            color={Color.Voltage}
            filter={VisualizationFilterEnum.Battery}
            dataKey={BatterFilterEnum.Voltage}
            filters={props.filters}
            desiredOrder={[
              BatterFilterDataEnum.Maximum,
              BatterFilterDataEnum.Average,
              BatterFilterDataEnum.Minimum
            ]}
          />
        </div>
      ) : (
        <div
          className="badge-wrapper d-flex justify-content-start justify-content-md-center pb-3 pe-1"
          ref={ref}
          id={`badge${props.tsId}`}
        >
          <ChartBadge
            title={
              loadUnit === LoadFilterEnum.Current
                ? `${Messages.LBL_VISUALIZATION_CURRENT} ${getVisuaUnit(LoadFilterEnum.Current)}`
                : `${Messages.LBL_VISUALIZATION_VOLTAGE} ${getVisuaUnit(LoadFilterEnum.Voltage)}`
            }
            color={loadUnit === LoadFilterEnum.Current ? Color.Temperature : Color.Voltage}
            filter={VisualizationFilterEnum.Load}
            dataKey={loadUnit}
            filters={props.filters}
            desiredOrder={
              loadUnit === LoadFilterEnum.Current
                ? [LoadFilterDataEnum.IA, LoadFilterDataEnum.IB, LoadFilterDataEnum.IC]
                : [LoadFilterDataEnum.VAB, LoadFilterDataEnum.VBC, LoadFilterDataEnum.VCA]
            }
          />
          <ChartBadge
            title={`${Messages.LBL_VISUALIZATION_REAL_POWER} ${getVisuaUnit(LoadFilterEnum.RealPower)}`}
            color={Color.RealPower}
            filter={VisualizationFilterEnum.Load}
            dataKey={LoadFilterEnum.RealPower}
            filters={props.filters}
            desiredOrder={[LoadFilterDataEnum.Commanded, LoadFilterDataEnum.Actual]}
          />
          <ChartBadge
            title={`${Messages.LBL_VISUALIZATION_REACTIVE_POWER} ${getVisuaUnit(LoadFilterEnum.ReactivePower)}`}
            color={Color.ReactivePower}
            filter={VisualizationFilterEnum.Load}
            dataKey={LoadFilterEnum.ReactivePower}
            filters={props.filters}
            desiredOrder={[LoadFilterDataEnum.Commanded, LoadFilterDataEnum.Actual]}
          />
        </div>
      )}
    </>
  )
}

export default Badge
