import { ActivationKeyEntity } from "../interfaces/ActivationKeyEntity"

import api from "../utils/axios"
import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import { AXIOS_ALLOWANCE_STATUS } from "../utils/constants"

export const apiGenerateActivationKey = async (siteUniqueId: number) => {
  try {
    const response = await api.post(`api/sites/activation-code/${siteUniqueId}`)

    return {
      status: response.status === 201,
      data: response.data as ActivationKeyEntity
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: generate activation key =>", e.message)

    writeApiLogEvent("generate activation key", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_ACTIVATION_KEY_GENERATE_FAILED)
    }
  }
}

export const apiGetActivationKey = async (siteUniqueId: number) => {
  try {
    const response = await api.get(`api/sites/activation-code/${siteUniqueId}`, {
      validateStatus: function (status: number) {
        return [...AXIOS_ALLOWANCE_STATUS, 404].includes(status)
      }
    })

    return {
      status: [200, 404].includes(response.status),
      data: response.status === 404 ? [] : (response.data as ActivationKeyEntity[])
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get activation key =>", e.message)

    writeApiLogEvent("get activation key", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_ACTIVATION_KEY_GET_FAILED)
    }
  }
}
