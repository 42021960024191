import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Moment from "moment"

import edit from "../../assets/images/icons/admin/edit.svg"
import editDisabled from "../../assets/images/icons/admin/edit-disabled.svg"

import { apiAdminListCustomers } from "../../services/apiCustomerManagement"

// loading props
import { AlertModalProps } from "../../interfaces/AlertModalProps"
import { CustomerEntity } from "../../interfaces/CustomerEntity"
import { AdminCreateCustomer } from "../../interfaces/AdminCreateCustomer"
import { AdminUpdateCustomer } from "../../interfaces/AdminUpdateCustomer"
import { CustomerModalProps } from "../../interfaces/CustomerModalProps"
import { TableStatusProps } from "../../interfaces/TableStatusProps"
import { PaginationV2Props } from "../../interfaces/PaginationV2Props"
import { PageInfo } from "../../interfaces/PageInfo"

import { SubscriptionPlan } from "../../enum/subscription-plan"

import { DATE_FORMAT, AESI_ADMIN_ROUTE, CUSTOMER_MANAGEMENT } from "../../utils/constants"

// loading components
import CustomerModal from "../../components/modal/CustomerModal"
import AlertModal from "../../components/modal/AlertModal"
import TableStatus from "../../components/TableStatus"
import TableHeader from "../../components/TableHeader"
import PaginationV2 from "../../components/PaginationV2"
import TableTooltipColumn from "../../components/TableTooltipColumn"
import SearchInput from "../../components/form/SearchInput"

import Message from "../../utils/messages"
import { writeCodeLogEvent } from "../../utils/utils"
import AdminTabNavigator from "components/AdminTabNavigator"

const CustomerManagement = () => {
  const [includeDelete, setIncludeDelete] = useState(false)

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  // ================================
  // =========== Sorting ============
  // ================================
  const headerColumns = [
    {
      alias: "customerUniqueID",
      title: Message.LBL_CUSTOMER_HEADER_ID
    },
    {
      alias: "name",
      title: Message.LBL_CUSTOMER_HEADER_NAME
    },
    {
      alias: "numberOfSites",
      title: Message.LBL_CUSTOMER_HEADER_NUMBER_OF_SITES
    },
    {
      alias: "primaryContactEmail",
      title: Message.LBL_CUSTOMER_HEADER_EMAIL
    },
    {
      alias: "address",
      title: Message.LBL_CUSTOMER_HEADER_ADDRESS
    },
    {
      alias: "startDate",
      title: Message.LBL_CUSTOMER_HEADER_DATE
    }
  ]
  const [sortingHeader, setSortingHeader] = useState("")

  // ================================
  // ========= Modify ==========
  // ================================
  const [modal, setModal] = useState(false)
  const emptyCustomer: CustomerEntity = {
    address: "",
    adminFirstName: "",
    adminLastName: "",
    customerUniqueID: 0,
    name: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    country: "",
    numberOfSites: 0,
    phone: "",
    primaryContactEmail: "",
    startDate: "",
    subscriptionPlan: SubscriptionPlan.TIER_1
  }
  const [editCustomer, setEditCustomer] = useState<CustomerEntity | null>(null)
  const toggle = () => setModal(!modal)
  useEffect(() => {
    if (editCustomer) {
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCustomer])
  const showEditModal = (customerUniqueID?: number) => {
    if (customerUniqueID) {
      const customer: CustomerEntity[] = listData.filter(
        (customer) => customer.customerUniqueID === customerUniqueID
      )
      if (!customer || customer.length === 0) {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: "Could not get customer detail!. Please try again"
        })
        return
      }

      setEditCustomer(customer[0])
    } else {
      setEditCustomer(emptyCustomer)
    }
  }
  const updateListData = (
    arr: CustomerEntity[],
    body: AdminCreateCustomer | AdminUpdateCustomer | null,
    customerUniqueID?: number
  ) => {
    if (!body) return arr
    return arr.map((item) => {
      if (item.customerUniqueID === customerUniqueID) {
        item.address = body.address
        item.adminFirstName = body.adminFirstName
        item.adminLastName = body.adminLastName
        item.name = body.name
        item.city = body.city
        item.stateProvince = body.stateProvince
        item.zipCode = body.zipCode
        item.country = body.country
        item.phone = body.phone
        item.primaryContactEmail = body.primaryContactEmail
        item.subscriptionPlan = body.subscriptionPlan
      }
      return item
    })
  }
  const addNewCustomerToListData = () => {
    // show success dialog
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: "success",
      message: Message.MSG_CUSTOMER_CREATE_SUCCESS
    })

    // refresh list data
    fetchListData()
  }
  const updateCustomerToListData = (body: AdminUpdateCustomer, customerUniqueID: number) => {
    // show success dialog
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: "success",
      message: Message.MSG_CUSTOMER_MODIY_SUCCESS
    })

    // refresh list data
    setListData(updateListData(listData, body, customerUniqueID))
  }
  const removeCustomerToListData = () => {
    // show success dialog
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: "success",
      message: Message.MSG_CUSTOMER_DELETE_SUCCESS
    })

    // remove from list
    fetchListData()
  }
  const customerModalProps: CustomerModalProps = {
    size: "lg",
    modal,
    toggle,
    onClose: () => {
      setEditCustomer(null)
    },
    addCallback: addNewCustomerToListData,
    editCallback: updateCustomerToListData,
    deleteCallback: removeCustomerToListData,
    data: editCustomer
  }

  // ================================
  // ========= Loading data ========
  // ================================
  const [currentPage, setCurrentPage] = useState(0)
  const [listData, setListData] = useState<CustomerEntity[]>([])
  const [isLoading, setLoading] = useState(false)
  const [tableStatus, setTableStatus] = useState("loading")
  const [tableStatusMsg, setTableStatusMsg] = useState("Fetching the customer list... Please wait")
  const tablestatusProps: TableStatusProps = {
    status: tableStatus,
    message: tableStatusMsg,
    colCount: 7
  }
  const [searchText, setSearchText] = useState("")
  const fetchListData = () => {
    setTableStatusMsg("Fetching the customer list... Please wait")
    setTableStatus("loading")
    setLoading(true)

    apiAdminListCustomers(currentPage, sortingHeader, includeDelete, searchText)
      .then((res) => {
        if (res.status) {
          const data = res.data || []
          setListData(data)
          setPageInfo(res.pageInfo)

          if (data.length <= 0) {
            setTableStatus("empty")
            setTableStatusMsg(Message.MSG_EMPTY_DATA)
          } else {
            setTableStatus("")
            setLoading(false)
          }
        } else {
          setTableStatus("error")
          setTableStatusMsg(res.message || "")
        }
      })
      .catch((e) => {
        console.error("Code exception: handle list customer =>", e.message)
        setTableStatus("error")
        setTableStatusMsg(Message.ERR_CUSTOMER_GET_LIST_FAILED)

        writeCodeLogEvent("handle list customer", e)
      })
  }

  useEffect(() => {
    fetchListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortingHeader])

  useEffect(() => {
    if (currentPage === 0) {
      fetchListData()
    } else {
      setCurrentPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeDelete, searchText])

  // ================================
  // ========== Pagination ==========
  // ================================
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const paginationV2Props: PaginationV2Props = {
    setCurrentPage: (pageNumber: number) => setCurrentPage(pageNumber),
    pageInfo,
    showingCount: listData.length,
    forcePage: currentPage
  }

  // ================================
  // ========== Rendering ===========
  // ================================
  return (
    <div id="customerManagement" className="admin-wrapper">
      <AdminTabNavigator
        selectedTab={CUSTOMER_MANAGEMENT}
        actionButtonClick={() => showEditModal()}
        actionButtonDisabled={false}
        actionButtonLabel={Message.LBL_MANAGEMENT_BTN_CREATE_CUSTOMER}
      />

      <div className="text-center d-md-flex justify-content-between my-4">
        <SearchInput
          name="search"
          placeHolder="Search"
          onChange={(text: string) => setSearchText(text)}
          testId="inpSearch"
        />
        <div role="button" className="pt-2 mt-1">
          <label
            className="form-check-label me-2 text-primary fw-bold"
            htmlFor="chkIncludeDeleteCustomer"
          >
            {Message.LBL_CUSTOMER_HIDE_DELETE_CUSTOMER}
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="chkIncludeDeleteCustomer"
            checked={includeDelete}
            onChange={() => setIncludeDelete((oldState) => !oldState)}
            data-testid="chkDeleteCustomer"
          />
        </div>
      </div>

      <div className="overflow-auto mb-5">
        <table className="table table-hover data-table mb-0" data-testid="dataTable">
          <thead>
            <tr>
              {headerColumns.map((item, index) => (
                <TableHeader
                  key={index}
                  alias={item.alias}
                  title={item.title}
                  isLoading={isLoading}
                  sortingHeader={sortingHeader}
                  setSortingHeader={setSortingHeader}
                />
              ))}
              <th scope="col">{Message.LBL_CUSTOMER_HEADER_ACTIONS}</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && tableStatus && <TableStatus {...tablestatusProps} />}
            {!isLoading &&
              listData.map((item, index) => (
                <tr key={index} className={item.deleted ? "disabled-row" : ""}>
                  <TableTooltipColumn
                    id={`customerUniqueID${item.customerUniqueID}`}
                    text={`${item.customerUniqueID}`}
                    maxLength={140}
                  />
                  <TableTooltipColumn
                    id={`customerName${item.customerUniqueID}`}
                    text={item.name}
                    maxLength={168}
                  />
                  <td>{item.numberOfSites}</td>
                  <TableTooltipColumn
                    id={`primaryContactEmail${item.customerUniqueID}`}
                    text={item.primaryContactEmail || ""}
                    maxLength={335}
                  />
                  <TableTooltipColumn
                    id={`address${item.customerUniqueID}`}
                    text={item.address}
                    maxLength={366}
                  />
                  <td>{Moment(item.startDate).format(DATE_FORMAT)}</td>
                  <td>
                    <Link
                      to={
                        item.deleted
                          ? "#"
                          : `${AESI_ADMIN_ROUTE}/customer-management/${item.customerUniqueID}`
                      }
                      className="badge bg-white text-primary border me-1 link-offset-2 link-underline link-underline-opacity-0"
                      role="button"
                    >
                      {Message.LBL_CUSTOMER_HEADER_MANAGE}
                    </Link>
                    <span
                      className="btn btn-light border-0 p-1 py-0 btn-edit"
                      role="button"
                      onClick={() => !item.deleted && showEditModal(item.customerUniqueID)}
                    >
                      <img src={item.deleted ? editDisabled : edit} alt="Edit" />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaginationV2 {...paginationV2Props} />

      <CustomerModal {...customerModalProps} />
      <AlertModal {...alertModalProps} />
    </div>
  )
}

export default CustomerManagement
