export enum VisualizationChartDataEnum {
  terastorID = "terastorID",
  timestamp = "timestamp",
  groupId = "groupId",
  groupName = "groupName",

  avgVoltage = "avgVoltage",
  minVoltage = "minVoltage",
  maxVoltage = "maxVoltage",

  soc = "soc",

  avgTemp = "avgTemp",
  minTemp = "minTemp",
  maxTemp = "maxTemp",

  commandReactivePower = "commandReactivePower",
  actualReactivePower = "actualReactivePower",
  commandRealPower = "commandRealPower",
  actualRealPower = "actualRealPower",

  ia = "ia",
  ib = "ib",
  ic = "ic",

  vab = "vab",
  vbc = "vbc",
  vca = "vca"
}
