import { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Messages from "utils/messages"

import close from "../../assets/images/icons/admin/close.svg"
import { RELEASE_NOTES_MAX_LENGTH } from "utils/constants"
import { DocumentEditModalProps } from "interfaces/doc/DocumentEditModalProps"
import InputField from "components/InputField"
import { DocEntity } from "interfaces/doc/DocEntity"
import moment from "moment"
import { SiteEntity } from "interfaces/SiteEntity"
import { apiAdminListAllSites } from "services/apiSitesManagement"
import { SelectOptions } from "interfaces/SelectOptions"
import { writeCodeLogEvent, getFileNameWithoutExtension } from "utils/utils"

const DocumentEditModal = (props: DocumentEditModalProps) => {
  const { docData, onDone, toggle } = props
  const [newDocData, setNewDocData] = useState<DocEntity | null>()
  const [select, setSelect] = useState<SelectOptions[]>([])
  const [isLoading, setLoading] = useState(true)

  const formattedDate = docData?.updatedAt
    ? moment.unix(parseInt(docData?.updatedAt)).format("DD MMM YYYY")
    : ""

  useEffect(() => {
    if (docData) {
      setNewDocData({ ...docData, fileName: getFileNameWithoutExtension(docData.fileName) })
    }
  }, [docData])

  useEffect(() => {
    setLoading(true)
    apiAdminListAllSites(docData?.customerId)
      .then((res) => {
        if (res.status) {
          const arr: SelectOptions[] = []
          res.data?.forEach((site: SiteEntity) => {
            const item: SelectOptions = {
              value: site.siteUniqueId,
              label: site.siteName
            }
            arr.push(item)
          })
          setSelect(arr)
        }
      })
      .catch((e) => {
        console.error("Code exception: [Document Center] handle get list site =>", e.message)
        writeCodeLogEvent("[Document Center] handle get list site", e)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.id && event.currentTarget.value) {
      switch (event.currentTarget.id) {
        case "docFileName": {
          const newValue = event.currentTarget.value
          setNewDocData((prevValue) => (prevValue ? { ...prevValue, fileName: newValue } : null))
          break
        }
        case "docSite": {
          const newSiteId = parseInt(event.currentTarget.value)
          setNewDocData((prevValue) => (prevValue ? { ...prevValue, siteId: newSiteId } : null))
          break
        }
      }
    }
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      backdrop={"static"}
      id="fwReleasesNoteModal"
    >
      <ModalHeader>
        <p className="title">{Messages.LBL_DOCUMENTS_EDIT_FILE}</p>
        <button
          className="border-0 position-absolute bg-transparent"
          style={{ right: "31px", top: "15px", width: "25px" }}
          onClick={props.toggle}
        >
          <img src={close} alt="Close" role="button" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <p className="title">
              <span className="fw-700 emphasize">{docData?.fileName}</span>
            </p>
            <p>
              {Messages.LBL_FW_UPDATE_LAST_UPDATE_ON}
              <span className="fw-500 ms-3">{formattedDate}</span>
            </p>
          </div>
          <div className="col-12">
            <div className="input-field-container row ps-0">
              <div className="col-12 col-md-6">
                <InputField
                  id="docFileName"
                  label={Messages.LBL_DOCUMENTS_HEADER_FILE_NAME}
                  placeholder={Messages.LBL_DOCUMENTS_HEADER_FILE_NAME}
                  value={newDocData?.fileName ?? ""}
                  disabled={false}
                  onChange={(e) => handleChange(e)}
                  tabIndex={17}
                  col={true}
                  required={true}
                />
              </div>
              <div className="col-12 col-md-6">
                <InputField
                  id="docSite"
                  label={Messages.LBL_DOCUMENTS_SITE}
                  placeholder={Messages.LBL_DOCUMENTS_SITE}
                  value={newDocData?.siteId.toString() ?? ""}
                  disabled={isLoading}
                  onChange={(e) => handleChange(e)}
                  tabIndex={17}
                  col={true}
                  required={true}
                  type="select"
                  options={select}
                />
              </div>
            </div>
          </div>

          <p className="pt-2">{Messages.LBL_DOCUMENTS_FILE_DESCRIPTION}</p>
          <>
            <textarea
              id="docDescription"
              value={newDocData?.description ?? ""}
              placeholder={Messages.LBL_FW_UPDATE_TEXTAREA_PLACEHOLDER}
              maxLength={RELEASE_NOTES_MAX_LENGTH}
              onChange={(e) => {
                setNewDocData((prevValue) =>
                  prevValue ? { ...prevValue, description: e.target.value } : null
                )
              }}
            />
            <p className="d-flex justify-content-end word-count">
              {newDocData?.description ? newDocData?.description.length : 0}/
              {RELEASE_NOTES_MAX_LENGTH} characters
            </p>
          </>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button
          className="border-0"
          onClick={() => {
            toggle()
          }}
        >
          {Messages.BTN_SITE_SETUP_CANCEL}
        </Button>
        <Button
          color="primary"
          className="ms-3"
          onClick={() => {
            if (newDocData) {
              onDone({ ...newDocData, fileName: `${newDocData.fileName}${newDocData.fileType}` })
              toggle()
            }
          }}
        >
          {Messages.BTN_DOCUMENTS_UPDATE}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DocumentEditModal
