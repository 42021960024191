import { useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import close from "../../assets/images/icons/admin/close.svg"
import Moment from "moment"
import { DATE_FORMAT } from "../../utils/constants"

import { SiteEntity } from "../../interfaces/SiteEntity"
import { SiteInfoModalProps } from "../../interfaces/SiteInfoModalProps"
import messages from "utils/messages"

const SiteInfoModal = (props: SiteInfoModalProps) => {
  const [site, setSite] = useState<SiteEntity>()

  useEffect(() => {
    if (props.site) {
      setSite(props.site)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site])

  const handleClose = () => {
    props.close()
  }

  return (
    <Modal isOpen={props.modal} toggle={props.toggle} size={props.size} centered={true}>
      <ModalBody className="p-5">
        <h4 className="mb-5 title" style={{ fontSize: "24px" }}>
          {messages.LBL_SITE_SETUP_SITE_DETAILS}
        </h4>
        <button
          className="border-0 position-absolute bg-transparent"
          style={{ right: "20px", top: "20px", width: "25px" }}
          onClick={handleClose}
        >
          <img src={close} alt="Close" role="button" />
        </button>
        <div id="siteInfoModal" className="admin-wrapper p-0">
          <div className="overflow-auto">
            <table className="overflow-auto table table-hover site-info-text">
              <tbody>
                <tr>
                  <th scope="row">Site ID</th>
                  <td>{site?.siteUniqueId}</td>
                </tr>
                <tr>
                  <th scope="row">Site Name</th>
                  <td>{site?.siteName}</td>
                </tr>
                <tr>
                  <th scope="row">Site Description</th>
                  <td>{site?.description}</td>
                </tr>
                <tr>
                  <th scope="row">Customer ID</th>
                  <td>{site?.customerId}</td>
                </tr>
                <tr>
                  <th scope="row">Customer Name</th>
                  <td>{site?.customerName}</td>
                </tr>
                <tr>
                  <th scope="row">Site Group</th>
                  <td>{site?.siteGroup}</td>
                </tr>
                <tr>
                  <th scope="row">Commission Date</th>
                  <td>
                    {site?.commissionDate && Moment(site?.commissionDate).format(DATE_FORMAT)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Number of TeraStors</th>
                  <td>{site?.numTeraStors}</td>
                </tr>
                <tr>
                  <th scope="row">Site Power Rating</th>
                  <td>
                    {site?.numTeraStors ? `${(site?.numTeraStors * 3.6).toFixed(1)} MW` : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Site Capacity Rating</th>
                  <td>
                    {site?.numTeraStors ? `${(site?.numTeraStors * 7.2).toFixed(1)} MWh` : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Site Address</th>
                  <td>{site?.address1}</td>
                </tr>
                <tr>
                  <th scope="row">City</th>
                  <td>{site?.city}</td>
                </tr>
                <tr>
                  <th scope="row">Site Postal Code</th>
                  <td>{site?.postalCode}</td>
                </tr>
                <tr>
                  <th scope="row">Country</th>
                  <td>{site?.country}</td>
                </tr>
                <tr>
                  <th scope="row">State/Province </th>
                  <td>{site?.stateProvince}</td>
                </tr>
                <tr>
                  <th scope="row">Latitude</th>
                  <td>{site?.latitude}</td>
                </tr>
                <tr>
                  <th scope="row">Longitude</th>
                  <td>{site?.longitude}</td>
                </tr>
                <tr>
                  <th scope="row">Elevation</th>
                  <td>{site?.elevation}</td>
                </tr>
                <tr>
                  <th scope="row">Site Manager</th>
                  <td>{site?.managerName}</td>
                </tr>
                <tr>
                  <th scope="row">Phone Number</th>
                  <td>{site?.managerPhoneNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default SiteInfoModal
