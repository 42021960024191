import { useState, useEffect } from "react"
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { CSVLink } from "react-csv"

import {
  getFilters,
  getLoadUnit,
  getBatteryFilter,
  getLoadFilter,
  getSelectedTeraStors,
  getSiteEntity,
  getMultipleDownload,
  setTeraStorFilter,
  getDataMode
} from "../../../store/counter/visualizationSlice"
import { AppDispatch } from "../../../store/store"

import { ChartHeadProps } from "../../../interfaces/visualization/ChartHeadProps"
import { DropDown } from "../../../interfaces/visualization/Dropdown"

import expand from "../../../assets/images/icons/visualization/expand.svg"
import collapse from "../../../assets/images/icons/visualization/collapse.svg"
import download from "../../../assets/images/icons/visualization/download.svg"

import { VisualizationFilterEnum } from "../../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../../enum/visualization/tab/load-filter-data"
import { ExportFileTypeEnum } from "../../../enum/visualization/export-file-type"
import { DataMode } from "../../../enum/visualization/data-mode"

import Messages from "../../../utils/messages"
import { getVisuaTooltipText, getVisuaUnit, visualizationFileName } from "../../../utils/utils"
import { exportPdfFile, exportCSVFile } from "../../../utils/visualization/exporting"

const Actions = (props: ChartHeadProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const currentSiteEntity = useSelector(getSiteEntity)
  const filters = useSelector(getFilters)
  const loadUnit = useSelector(getLoadUnit)
  const batteryFilter = useSelector(getBatteryFilter)
  const loadFilter = useSelector(getLoadFilter)
  const selectedTeraStors = useSelector(getSelectedTeraStors)
  const multipleDownload = useSelector(getMultipleDownload)
  const [filterToggle, setFilterToggle] = useState(false)
  const [downloadToggle, setDownloadToggle] = useState(false)
  const dataMode = useSelector(getDataMode)

  const [dropDownData, setDropdownData] = useState<DropDown[]>([])
  const [selected, setSelected] = useState<DropDown[]>([])

  const [isAll, setIsAll] = useState(false)
  const isCurrent = loadUnit === LoadFilterEnum.Current
  const isSample = dataMode === DataMode.Sample

  const isSelected = (
    dataKey: BatterFilterEnum | LoadFilterEnum,
    dataValue: BatterFilterDataEnum | LoadFilterDataEnum
  ) => {
    return (
      selected.filter((item: DropDown) => item.dataKey === dataKey && item.dataValue === dataValue)
        .length > 0
    )
  }

  const initDropDownData = () => {
    if (filters === VisualizationFilterEnum.Battery) {
      setDropdownData([
        {
          name: `${getVisuaTooltipText(filters, BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)} ${getVisuaUnit(BatterFilterEnum.SOC)}`,
          dataKey: BatterFilterEnum.SOC,
          dataValue: BatterFilterDataEnum.SOC
        },
        {
          name: `${getVisuaTooltipText(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Maximum)} ${getVisuaUnit(BatterFilterEnum.Temperature)}`,
          dataKey: BatterFilterEnum.Temperature,
          dataValue: BatterFilterDataEnum.Maximum
        },
        {
          name: `${getVisuaTooltipText(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Average)} ${getVisuaUnit(BatterFilterEnum.Temperature)}`,
          dataKey: BatterFilterEnum.Temperature,
          dataValue: BatterFilterDataEnum.Average
        },
        {
          name: `${getVisuaTooltipText(filters, BatterFilterEnum.Temperature, BatterFilterDataEnum.Minimum)} ${getVisuaUnit(BatterFilterEnum.Temperature)}`,
          dataKey: BatterFilterEnum.Temperature,
          dataValue: BatterFilterDataEnum.Minimum
        },
        {
          name: `${getVisuaTooltipText(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Maximum)} ${getVisuaUnit(BatterFilterEnum.Voltage)}`,
          dataKey: BatterFilterEnum.Voltage,
          dataValue: BatterFilterDataEnum.Maximum
        },
        {
          name: `${getVisuaTooltipText(filters, BatterFilterEnum.Voltage, BatterFilterDataEnum.Average)} ${getVisuaUnit(BatterFilterEnum.Voltage)}`,
          dataKey: BatterFilterEnum.Voltage,
          dataValue: BatterFilterDataEnum.Average
        },
        {
          name: `Min ${BatterFilterEnum.Voltage} ${getVisuaUnit(BatterFilterEnum.Voltage)}`,
          dataKey: BatterFilterEnum.Voltage,
          dataValue: BatterFilterDataEnum.Minimum
        }
      ])
    } else {
      setDropdownData([
        ...(isCurrent
          ? [
              {
                name: `${getVisuaTooltipText(filters, loadUnit, LoadFilterDataEnum.IA)} ${getVisuaUnit(loadUnit)}`,
                dataKey: LoadFilterEnum.Current,
                dataValue: LoadFilterDataEnum.IA
              },
              {
                name: `${getVisuaTooltipText(filters, loadUnit, LoadFilterDataEnum.IB)} ${getVisuaUnit(loadUnit)}`,
                dataKey: LoadFilterEnum.Current,
                dataValue: LoadFilterDataEnum.IB
              },
              {
                name: `${getVisuaTooltipText(filters, loadUnit, LoadFilterDataEnum.IC)} ${getVisuaUnit(loadUnit)}`,
                dataKey: LoadFilterEnum.Current,
                dataValue: LoadFilterDataEnum.IC
              }
            ]
          : [
              {
                name: `${getVisuaTooltipText(filters, loadUnit, LoadFilterDataEnum.VAB)} ${getVisuaUnit(loadUnit)}`,
                dataKey: LoadFilterEnum.Voltage,
                dataValue: LoadFilterDataEnum.VAB
              },
              {
                name: `${getVisuaTooltipText(filters, loadUnit, LoadFilterDataEnum.VBC)} ${getVisuaUnit(loadUnit)}`,
                dataKey: LoadFilterEnum.Voltage,
                dataValue: LoadFilterDataEnum.VBC
              },
              {
                name: `${getVisuaTooltipText(filters, loadUnit, LoadFilterDataEnum.VCA)} ${getVisuaUnit(loadUnit)}`,
                dataKey: LoadFilterEnum.Voltage,
                dataValue: LoadFilterDataEnum.VCA
              }
            ]),
        {
          name: `${LoadFilterEnum.RealPower} Com`,
          dataKey: LoadFilterEnum.RealPower,
          dataValue: LoadFilterDataEnum.Commanded
        },
        {
          name: `${LoadFilterEnum.RealPower} Actual`,
          dataKey: LoadFilterEnum.RealPower,
          dataValue: LoadFilterDataEnum.Actual
        },
        {
          name: `${LoadFilterEnum.ReactivePower} Com`,
          dataKey: LoadFilterEnum.ReactivePower,
          dataValue: LoadFilterDataEnum.Commanded
        },
        {
          name: `${LoadFilterEnum.ReactivePower} Actual`,
          dataKey: LoadFilterEnum.ReactivePower,
          dataValue: LoadFilterDataEnum.Actual
        }
      ])
    }
  }

  const initSelectedDropdown = () => {
    if (filters === VisualizationFilterEnum.Battery) {
      const selectedArr: DropDown[] = []
      Object.entries(batteryFilter).forEach(([filter, data]) => {
        data.forEach((data: BatterFilterDataEnum) => {
          selectedArr.push({
            name: "",
            dataKey: filter as BatterFilterEnum,
            dataValue: data
          })
        })
      })
      setSelected(selectedArr)
    } else {
      const selectedArr: DropDown[] = []
      Object.entries(loadFilter).forEach(([filter, data]) => {
        data.forEach((data: LoadFilterDataEnum) => {
          if (
            filter === LoadFilterEnum.RealPower ||
            filter === LoadFilterEnum.ReactivePower ||
            (isCurrent && filter === LoadFilterEnum.Current) ||
            (!isCurrent && filter === LoadFilterEnum.Voltage)
          )
            selectedArr.push({
              name: "",
              dataKey: filter as LoadFilterEnum,
              dataValue: data
            })
        })
      })
      setSelected(selectedArr)
    }
  }

  const [exportFilename, setExportFilename] = useState("")

  useEffect(() => {
    initSelectedDropdown()
    initDropDownData()

    setExportFilename(visualizationFileName(filters, currentSiteEntity, props.tsId))
  }, [])

  useEffect(() => {
    setExportFilename(visualizationFileName(filters, currentSiteEntity, props.tsId))
  }, [currentSiteEntity, filters, props.tsId])

  useEffect(() => {
    initSelectedDropdown()
    initDropDownData()
  }, [filters, loadUnit])

  useEffect(() => {
    initSelectedDropdown()
  }, [batteryFilter, loadFilter])

  useEffect(() => {
    props.setFilters(selected)
    dispatch(setTeraStorFilter({ tsId: props.tsId, data: selected }))
    setIsAll(selected.length >= dropDownData.length)
  }, [selected])

  const handleCheckboxChange = (dropdown: DropDown) => {
    if (!isSelected(dropdown.dataKey, dropdown.dataValue)) {
      setSelected([...selected, dropdown])
    } else {
      setSelected(
        selected.filter(
          (item: DropDown) =>
            !(item.dataKey === dropdown.dataKey && item.dataValue === dropdown.dataValue)
        )
      )
    }
  }

  const handleCheckAll = () => {
    if (isAll) setSelected([])
    else setSelected(dropDownData)
    setIsAll(!isAll)
  }

  const handleDownload = async (fileType: ExportFileTypeEnum) => {
    if (!multipleDownload && !isSample) {
      props.setDownloadProcess(true, 0)
    }

    // disable download dropdown and show block div
    // TODO: this is temporary, remove this after improve chart rendering performance
    if (isSample) {
      let button: HTMLButtonElement | null = null
      let blockWrapper: HTMLElement | null = null
      if (isSample) {
        // disable download dropdown
        button = document.querySelector(`#drpDownload${props.tsId} > button`) as HTMLButtonElement
        if (button) button.disabled = true

        // show block div
        blockWrapper = document.getElementById("exportBlock")
        if (blockWrapper) {
          blockWrapper.classList.remove("d-none")
        }
      }
    }

    // wait 1 second to show download process modal before exporting file
    setTimeout(async () => {
      if (fileType === ExportFileTypeEnum.PDF) {
        await exportPdfFile(
          filters,
          exportFilename,
          selectedTeraStors,
          document.querySelector(`#badge${props.tsId}`),
          document.querySelector(`#chart${props.tsId}`),
          props.tsId
        )
      }

      // enable download dropdown and hide block div
      // TODO: this is temporary, remove this after improve chart rendering performance
      if (isSample) {
        // enable download dropdown
        const button = document.querySelector(
          `#drpDownload${props.tsId} > button`
        ) as HTMLButtonElement
        if (button) button.disabled = false

        // hide block div
        const blockWrapper = document.getElementById("exportBlock")
        if (blockWrapper) {
          blockWrapper.classList.add("d-none")
        }
      }

      if (!multipleDownload && !isSample) {
        props.setDownloadProcess(true, 100)
        props.setDownloadProcess(false, 100)

        props.setDownloadSucceed(true)
      }
    }, 1000)
  }

  /**
   * Check if opening on safari and in sample mode then disable PDF download button
   * TODO: this is temporary, remove this after improve export pdf file
   * @returns {boolean}
   */
  const isSampleOnSafari = () => {
    return isSample && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  return (
    <div
      className="d-md-flex justify-content-between rounded py-1 px-2 actions-wrapper"
      data-html2canvas-ignore="true"
    >
      <label htmlFor="selFilter" className="form-label mb-2 mb-md-0 pt-1 me-md-2">
        {Messages.LBL_VISUALIZATION_FILTER_BY}
      </label>
      <Dropdown
        isOpen={filterToggle}
        toggle={() => setFilterToggle(!filterToggle)}
        direction="down"
        className="me-md-3 mb-2 mb-md-0"
      >
        <DropdownToggle caret className="toggle-filter border-0 bg-white px-2">
          <span className="pe-md-5 me-md-5">
            {isAll ? Messages.LBL_VISUALIZATION_FILTER_ALL : "---"}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            toggle={false}
            tag="label"
            htmlFor={`chk-${props.tsId}-All${props.wrapper}`}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={`chk-${props.tsId}-All${props.wrapper}`}
                onChange={() => handleCheckAll()}
                checked={isAll}
              />
              <span className="form-check-label">{Messages.LBL_VISUALIZATION_FILTER_ALL}</span>
            </div>
          </DropdownItem>
          {dropDownData.map((item: DropDown, index: number) => (
            <DropdownItem
              key={index}
              toggle={false}
              tag="label"
              htmlFor={`chk-${props.tsId}-${item.dataKey}${item.dataValue}${props.wrapper}`}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`chk-${props.tsId}-${item.dataKey}${item.dataValue}${props.wrapper}`}
                  onChange={() => handleCheckboxChange(item)}
                  checked={isSelected(item.dataKey, item.dataValue)}
                />
                <span className="form-check-label">{item.name}</span>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <Dropdown
        isOpen={downloadToggle}
        toggle={() => setDownloadToggle(!downloadToggle)}
        direction="down"
        className={`${props.expandToggle ? "me-md-3" : ""} mb-2 mb-md-0`}
        id={`drpDownload${props.tsId}`}
      >
        <DropdownToggle caret className="border-0 bg-white px-2">
          <img src={download} width={15} alt="download" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => handleDownload(ExportFileTypeEnum.PDF)}
            id={`pdf${props.tsId}`}
            disabled={isSampleOnSafari()} // TODO: this is temporary, remove this after improve export pdf file
          >
            PDF
          </DropdownItem>
          <DropdownItem className="d-flex flex-column p-0">
            <CSVLink
              data={exportCSVFile(props.tsId, filters, props.filters, props.chartData, dataMode)}
              filename={`${exportFilename}.csv`}
              className="link-offset-2 link-underline link-underline-opacity-0 text-primary w-100 px-3 py-2"
              onClick={() => handleDownload(ExportFileTypeEnum.CSV)}
              id={`csv${props.tsId}`}
            >
              CSV
            </CSVLink>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {props.expandToggle && (
        <>
          <div className="vr me-3 d-none d-md-block"></div>
          <Button
            className="p-1 border-0 bg-white text-primary open-modal"
            onClick={() => {
              if (props.setTsId) props.setTsId(props.tsId)
              if (props.expandToggle) props.expandToggle()
            }}
          >
            <img src={!props.status ? expand : collapse} alt="collapse-expand" width={15} />
          </Button>
        </>
      )}
    </div>
  )
}

export default Actions
