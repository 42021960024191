import { useCallback } from "react"
import _ from "lodash"

import search from "../../assets/images/icons/admin/search.svg"
import { SEARCH_INPUT_THROTTLED_DELAY } from "../../utils/constants"

interface SearchInputProps {
  outerWrapId?: string
  outerWrapClass?: string

  name: string
  placeHolder: string

  onChange: (text: string) => void

  testId?: string
}
const SearchInput = (props: SearchInputProps) => {
  // Using useCallback to ensure the throttled function is not recreated on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledChangeHandler = useCallback(
    _.throttle(
      (value: string) => {
        props.onChange(value)
      },
      SEARCH_INPUT_THROTTLED_DELAY,
      { trailing: true, leading: false }
    ),
    []
  )
  return (
    <div
      className={`d-flex justify-content-between search-input-wrapper ${props.outerWrapClass || ""}`}
      id={props.outerWrapId || ""}
    >
      <img src={search} alt="map" role="button" />
      <input
        type="text"
        className="form-control"
        placeholder={props.placeHolder}
        name={props.name}
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          throttledChangeHandler(event.currentTarget.value)
        }}
        data-testid={props.testId || props.name}
      />
    </div>
  )
}

export default SearchInput
