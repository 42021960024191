import { useSelector } from "react-redux"

import {
  getFilters,
  getLoadUnit,
  getDataMode,
  getDateRange
} from "../../store/counter/visualizationSlice"

import { DropDown } from "../../interfaces/visualization/Dropdown"
import { ChartTooltipPayload } from "../../interfaces/visualization/ChartTooltipPayload"

import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"
import { VisualizationDateRangeEnum } from "../../enum/visualization/tab/daterange-tab"
import { DataMode } from "../../enum/visualization/data-mode"

import {
  _isShowDataLine,
  getVisuaTooltipText,
  getVisuaTooltipData,
  getVisuaTooltipValue,
  getVisuaUnit,
  isValidValue
} from "../../utils/utils"
import { getInfoFromTimestamp } from "../../utils/visualization/chart-data"
import Moment from "moment"

interface CustomTooltipProps {
  active?: boolean
  payload?: ChartTooltipPayload[]
  label: string
  filters: DropDown[]
}

const CustomTooltip = (props: CustomTooltipProps) => {
  const filters = useSelector(getFilters)
  const loadUnit = useSelector(getLoadUnit)
  const dataMode = useSelector(getDataMode)
  const dateRange = useSelector(getDateRange)

  if (props && props.active && props.payload && props.payload.length) {
    const data: Record<string, number> = getVisuaTooltipData(props.payload)

    const isCurrent = loadUnit === LoadFilterEnum.Current

    const isShowDataLine = (
      dataKey: BatterFilterEnum | LoadFilterEnum,
      dataValue: BatterFilterDataEnum | LoadFilterDataEnum
    ) => {
      return _isShowDataLine(props.filters, dataKey, dataValue)
    }

    enum RenderType {
      Text,
      Value
    }

    /**
     * Render tooltip text / value
     * @param {RenderType} renderType - render type text or value
     * @param {BatterFilterEnum | LoadFilterEnum} dataKey - render element data key
     * @param {BatterFilterDataEnum | LoadFilterDataEnum} dataValue - render element data value
     * @returns {React.ReactNode} - render display node
     */
    const renderElement = (
      renderType: RenderType,
      dataKey: BatterFilterEnum | LoadFilterEnum,
      dataValue: BatterFilterDataEnum | LoadFilterDataEnum
    ) => {
      const val = getVisuaTooltipValue(data, dataKey, dataValue)
      if (isValidValue(val) && isShowDataLine(dataKey, dataValue))
        return (
          <p className="my-2">
            {renderType === RenderType.Text
              ? getVisuaTooltipText(filters, dataKey, dataValue)
              : val}{" "}
            {getVisuaUnit(dataKey)}
          </p>
        )
    }

    return (
      <div className="chart-custom-tooltip d-flex flex-column bd-highlight bg-white">
        <div className="bg-primary text-center p-2 text-white rounded border-bottom border-white border-2">
          {dataMode === DataMode.Sample && Moment(Number(props.label)).utc().format("HH:mm:ss.SSS")}

          {dataMode !== DataMode.Sample &&
            getInfoFromTimestamp(props.label, false, dateRange === VisualizationDateRangeEnum.Date)}
        </div>
        <div className="px-2 bg-primary text-white rounded d-flex justify-content-between">
          {filters === VisualizationFilterEnum.Battery ? (
            <>
              <div className="me-2 pe-2 border-end border-2 border-white">
                {renderElement(RenderType.Text, BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)}
                {renderElement(
                  RenderType.Text,
                  BatterFilterEnum.Temperature,
                  BatterFilterDataEnum.Maximum
                )}
                {renderElement(
                  RenderType.Text,
                  BatterFilterEnum.Temperature,
                  BatterFilterDataEnum.Average
                )}
                {renderElement(
                  RenderType.Text,
                  BatterFilterEnum.Temperature,
                  BatterFilterDataEnum.Minimum
                )}

                {renderElement(
                  RenderType.Text,
                  BatterFilterEnum.Voltage,
                  BatterFilterDataEnum.Maximum
                )}
                {renderElement(
                  RenderType.Text,
                  BatterFilterEnum.Voltage,
                  BatterFilterDataEnum.Average
                )}
                {renderElement(
                  RenderType.Text,
                  BatterFilterEnum.Voltage,
                  BatterFilterDataEnum.Minimum
                )}
              </div>
              <div>
                {renderElement(RenderType.Value, BatterFilterEnum.SOC, BatterFilterDataEnum.SOC)}
                {renderElement(
                  RenderType.Value,
                  BatterFilterEnum.Temperature,
                  BatterFilterDataEnum.Maximum
                )}
                {renderElement(
                  RenderType.Value,
                  BatterFilterEnum.Temperature,
                  BatterFilterDataEnum.Average
                )}
                {renderElement(
                  RenderType.Value,
                  BatterFilterEnum.Temperature,
                  BatterFilterDataEnum.Minimum
                )}

                {renderElement(
                  RenderType.Value,
                  BatterFilterEnum.Voltage,
                  BatterFilterDataEnum.Maximum
                )}
                {renderElement(
                  RenderType.Value,
                  BatterFilterEnum.Voltage,
                  BatterFilterDataEnum.Average
                )}
                {renderElement(
                  RenderType.Value,
                  BatterFilterEnum.Voltage,
                  BatterFilterDataEnum.Minimum
                )}
              </div>
            </>
          ) : (
            <>
              <div className="me-2 pe-2 border-end border-2 border-white">
                {renderElement(
                  RenderType.Text,
                  loadUnit,
                  isCurrent ? LoadFilterDataEnum.IA : LoadFilterDataEnum.VAB
                )}
                {renderElement(
                  RenderType.Text,
                  loadUnit,
                  isCurrent ? LoadFilterDataEnum.IB : LoadFilterDataEnum.VBC
                )}
                {renderElement(
                  RenderType.Text,
                  loadUnit,
                  isCurrent ? LoadFilterDataEnum.IC : LoadFilterDataEnum.VCA
                )}

                {renderElement(
                  RenderType.Text,
                  LoadFilterEnum.RealPower,
                  LoadFilterDataEnum.Commanded
                )}
                {renderElement(
                  RenderType.Text,
                  LoadFilterEnum.RealPower,
                  LoadFilterDataEnum.Actual
                )}

                {renderElement(
                  RenderType.Text,
                  LoadFilterEnum.ReactivePower,
                  LoadFilterDataEnum.Commanded
                )}
                {renderElement(
                  RenderType.Text,
                  LoadFilterEnum.ReactivePower,
                  LoadFilterDataEnum.Actual
                )}
              </div>
              <div>
                {renderElement(
                  RenderType.Value,
                  loadUnit,
                  isCurrent ? LoadFilterDataEnum.IA : LoadFilterDataEnum.VAB
                )}
                {renderElement(
                  RenderType.Value,
                  loadUnit,
                  isCurrent ? LoadFilterDataEnum.IB : LoadFilterDataEnum.VBC
                )}
                {renderElement(
                  RenderType.Value,
                  loadUnit,
                  isCurrent ? LoadFilterDataEnum.IC : LoadFilterDataEnum.VCA
                )}

                {renderElement(
                  RenderType.Value,
                  LoadFilterEnum.RealPower,
                  LoadFilterDataEnum.Commanded
                )}
                {renderElement(
                  RenderType.Value,
                  LoadFilterEnum.RealPower,
                  LoadFilterDataEnum.Actual
                )}

                {renderElement(
                  RenderType.Value,
                  LoadFilterEnum.ReactivePower,
                  LoadFilterDataEnum.Commanded
                )}
                {renderElement(
                  RenderType.Value,
                  LoadFilterEnum.ReactivePower,
                  LoadFilterDataEnum.Actual
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
  return null
}

export default CustomTooltip
