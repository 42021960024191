import { Nav, NavItem } from "reactstrap"
import { Link } from "react-router-dom"

import { SITE_INFORMATION, USER_MANAGEMENT } from "../../utils/constants"

import { ChildSidebarProps } from "../../interfaces/ChildSidebarPops"

import siteInformation from "../../assets/images/icons/sidebar/admin/site_information.svg"
import siteInformationActive from "../../assets/images/icons/sidebar/admin/site_information_active.svg"
import userManagement from "../../assets/images/icons/admin/users.svg"
import userManagementActive from "../../assets/images/icons/admin/users-active.svg"
import { useSelector } from "react-redux"
import { getSidebarOpen } from "store/counter/authSlice"

const AdminSidebar = (props: ChildSidebarProps) => {
  const sideBarOpen = useSelector(getSidebarOpen)

  const isPage = (page: string) => {
    const param = props.getPath(2)
    if (!param && page === SITE_INFORMATION) return true
    if (param === page) return true
    return false
  }

  const navigation = [
    {
      title: "Site Information",
      page: SITE_INFORMATION,
      src: siteInformation,
      srcActive: siteInformationActive
    },
    {
      title: "User Management",
      page: USER_MANAGEMENT,
      src: userManagement,
      srcActive: userManagementActive
    }
  ]

  return (
    <Nav vertical className="sidebarNav" style={{ display: `${sideBarOpen ? "block" : "none"}` }}>
      {navigation.map((navi, index) => (
        <NavItem key={index}>
          <Link
            to={`/admin/` + navi.page}
            className={isPage(navi.page) ? "active nav-link py-2" : "nav-link py-2"}
          >
            <img src={isPage(navi.page) ? navi.srcActive : navi.src} alt={navi.title}></img>
            <span className="ms-3 d-inline-block">{navi.title}</span>
          </Link>
        </NavItem>
      ))}
    </Nav>
  )
}

export default AdminSidebar
