import { useState, Suspense, lazy } from "react"
import { Button } from "reactstrap"

import { USER_ROLES } from "../../utils/constants"

// loading props
import { UserEntity } from "../../interfaces/UserEntity"

// loading components
const UserManagementHandler = lazy(() => import("../../components/UserManagementHandler"))
import Loader from "../../components/Loader"
import TableTooltipColumn from "../../components/TableTooltipColumn"

import { getRoles } from "../../utils/utils"
import Messages from "../../utils/messages"

// redux
import { getCurrentUserState } from "../../store/counter/authSlice"
import { useSelector } from "react-redux"

const UserManagement = () => {
  const currentUser = useSelector(getCurrentUserState)
  const [userModal, setUserModal] = useState(false)

  const userManagementHeader = (
    <>
      <th scope="col">{Messages.LBL_USER_HEADER_USERNAME}</th>
      <th scope="col">{Messages.LBL_USER_HEADER_SITES}</th>
      <th scope="col">{Messages.LBL_USER_HEADER_EMAIL}</th>
      <th scope="col">{Messages.LBL_USER_HEADER_MOBILE}</th>
      <th scope="col">{Messages.LBL_USER_HEADER_AUTHORIZATION}</th>
      <th scope="col">{Messages.LBL_USER_HEADER_ACTIONS}</th>
    </>
  )
  const userManagementBody = (item: UserEntity) => (
    <>
      <TableTooltipColumn id={`username${item.username}`} text={item.username} maxLength={150} />
      {item.assignedSites ? (
        <TableTooltipColumn
          id={`assignedSites${item.username}`}
          text={item.assignedSites}
          maxLength={179}
        />
      ) : (
        <td></td>
      )}
      <TableTooltipColumn id={`email${item.username}`} text={item.email} maxLength={309} />
      <td>{item.phone || ""}</td>
      <td>{getRoles(item.authorities)}</td>
    </>
  )

  // ================================
  // ========== Rendering ===========
  // ================================
  return (
    <div id="userManagement" className="admin-wrapper">
      <div className="d-md-flex justify-content-between mb-3 text-center text-md-start">
        <h4 className="mt-2 text-primary fw-bold text-break text-wrap">
          {Messages.LBL_USER_MANAGEMENT_TITLE}
        </h4>
        <Button
          color="primary"
          id="btnAddUser"
          className="px-4 btn-actions"
          onClick={() => setUserModal(!userModal)}
          disabled={currentUser?.role === USER_ROLES.TENANT_SITE_ADMIN.id}
        >
          {Messages.BTN_USER_MANAGEMENT_ADD_USER}
        </Button>
      </div>

      <Suspense fallback={<Loader />}>
        <UserManagementHandler
          header={userManagementHeader}
          body={userManagementBody}
          userModal={userModal}
          setUserModal={() => setUserModal(!userModal)}
          customerUniqueId={currentUser?.customerUniqueId}
        />
      </Suspense>
    </div>
  )
}

export default UserManagement
