import { SITE_SETUP_STEP, SITE_SETUP_STEP_TITLE, USER_ROLES } from "utils/constants"

const ConfigurationNavBar = (props: {
  activeStep: string
  onTabChange: (step: string) => void
  currentUserRole?: string
}) => {
  const { activeStep, onTabChange, currentUserRole } = props
  const steps = [
    { step: SITE_SETUP_STEP.LAYOUT, title: SITE_SETUP_STEP_TITLE.LAYOUT, testId: "layoutTab" },
    {
      step: SITE_SETUP_STEP.CONFIGURATION,
      title: SITE_SETUP_STEP_TITLE.CONFIGURATION,
      testId: "configurationTab"
    },
    {
      step: SITE_SETUP_STEP.COMMISSIONING,
      title: SITE_SETUP_STEP_TITLE.COMMISIONING,
      testId: "commissioningTab"
    }
  ]

  if (currentUserRole === USER_ROLES.AESI_SUPER_ADMIN.id) {
    steps.push(
      {
        step: SITE_SETUP_STEP.REVIEW_SUBMIT,
        title: SITE_SETUP_STEP_TITLE.REVIEW_APPROVE,
        testId: "reviewApproveTab"
      },
      {
        step: SITE_SETUP_STEP.DOCUMENTS,
        title: SITE_SETUP_STEP_TITLE.DOCUMENTS,
        testId: "documentsTab"
      }
    )
  } else {
    steps.push({
      step: SITE_SETUP_STEP.REVIEW_SUBMIT,
      title: SITE_SETUP_STEP_TITLE.REVIEW_SUBMIT,
      testId: "reviewSubmitTab"
    })
  }

  return (
    <ul id="configurationNavBar" className="nav nav-tabs">
      {steps.map(({ step, title, testId }) => (
        <li
          key={step}
          className={`nav-item user-select-none ${activeStep === step ? "selected" : ""}`}
          role="button"
          data-testid={testId}
          onClick={() => onTabChange(step)}
        >
          {title}
        </li>
      ))}
      {/* Temporary remove note: Check history this line to revert */}
    </ul>
  )
}

export default ConfigurationNavBar
