const constants = {
  forgot_password: "Forgot Password?",
  FORGOT_PASSWORD_TITLE:
    "Enter the email address  and we’ll send you instructions to reset your password.",
  USERNAME_AND_PASSWORD: "Username or Email Address",
  RESET_INSTRUCTION: "Send Reset  Instructions",
  SIGN_IN_TO: "Sign in to ",
  PASSWORD: "Password",
  SIGNIN: "Sign in",
  PLEASE_ENTER_VALID_EMAIL: "Please enter valid email id",
  USERID_PASSWORD_NOT_VALID: "Username or password not valid",
  ENTER_VALID_USERNAME: "Please enter valid username",
  DEVICE_CONNECTED: "Device connected successfully",
  DEVICE_NOT_CONNECTED: "Please wait we are trying to connect the device",
  UPDATE_GROUP: "Update group",
  RENAME_GROUP: "Rename group",
  DELETE_GROUP: "Delete group",
  OLD_GROUP_NAME: "Old group name",
  NEW_GROUP_NAME: "New group name",
  UPDATE: "Update",
  CANCEL: "Cancel",
  OK: "OK",
  YES: "Yes",
  ENTER: "Enter",
  GROUP_CREATED_SUCCESSFULLY: "Group created successfully",
  GROUP_REMOVED_SUCCESSFULLY: "Group removed successfully",
  GROUP_RENAME_SUCCESSFULLY: "Group renamed successfully",
  Q_BLOCK_C: "Quarter Block C",
  Q_BLOCK_D: "Quarter Block D",
  Q_BLOCK_B: "Quarter Block B",
  Q_BLOCK_A: "Quarter Block A",
  NOTIFICATION: "Notification",
  ENERGY_MAN_SUITES: "Energy Management Suite",
  LOGOUT: "Logout",
  TOTAL_CAPACITY: "Total Capacity",
  ENERGY: "Energy",
  TERASTORS_ONLINE: "TeraStors Online",
  TERASTOR_ONLINE: "TeraStor Online",
  BATTERY: "Battery",
  REAL_POWER_CAP: "Real Power (P)",
  REACTIVE_POWER_CAP: "Reactive Power (Q)",
  APPARENT_POWER_CAP: "Apparent Power (S)",
  GROUP_ITEMS: "Group Items",
  SELECT: "Select",
  NO_TERASTOR_FOUND: "No TeraStor found",
  SHOWING: "Showing",
  NAMEPLATE_CAPACITY: "Nameplate Capacity",
  NAMEPLATE_POWER: "Nameplate Power",
  NAMEPLATE_ENERGY: "Nameplate Energy",
  TERASTOR_HASH: "TeraStor #",
  STRIP_CHART: "Strip Chart",
  LAST_UPDATE: "Last Update",
  STATUS: "Status",
  CELL_STATUS: "STATUS",
  UNKNOWN: "Unknown",
  SHUTDOWN: "Shutdown",
  STOP: "Stop",
  STARTUP: "Startup",
  ONLINE: "Online",
  MAINTENANCE: "Maintenance",
  FAULT: "Fault",
  EPO: "EPO",
  CHARGING: "CHARGING",
  STOPPING: "Stopping",
  IDLE: "Idle",
  STATUS_ZERO: 0,
  STATUS_ONE: 1,
  STATUS_TWO: 2,
  STATUS_THREE: 3,
  STATUS_FOUR: 4,
  STATUS_FIVE: 5,
  STATUS_SIX: 6,
  STATUS_SEVEN: 7,
  STATUS_EIGHT: 8,
  STATUS_NINE: 9,
  STATUS_TEN: 10,
  STATUS_BATTERY_ONLINE: 4,
  BATTERY_FOR_ALL_TERASTOR: 1,
  BATTERY_FOR_INDIVIDUAL_TERASTOR: 2,
  SHOW_REAL_POWER: 1,
  SHOW_REACTIVE_POWER: 2,
  SHOW_APPRENT_POWER: 3,
  GET_STRIP_CHART_BY_TSID: "get_strip_chart_by_tsid",
  GET_UNIQUE_ID: "get_unique_id",
  ADD_TO_GROUP: "add_to_group",
  REMOVE_FROM_GROUP: "remove_from_group",
  LIST_GROUP: "list_groups",
  CREATE_GROUP: "create_group",
  REMOVE_GROUP: "remove_group",
  GET_TERASTOR_SUMMARY: "get_terastor_summary",
  GET_QUATERBLOCK_DATA: "get_quarterblock_data",
  GET_TERASTOR_CELL_SUMMARY: "get_terastor_cell_summary",
  GET_TERASTOR_SPECS: "get_terastor_about",
  GET_PCS_DATA: "get_pcs_data",
  GET_CHILLER_DATA: "get_chiller_data",
  GET_SITE_SUMMARY: "get_site_summary",
  GET_GROUP_SUMMARY: "get_group_summary",
  GET_NAMEPLATE: "get_nameplate",
  ALL_TERASTOR: "all_terastor",
  FAILED: "failed",
  SUCCESS: "success",
  QB_A: "QB_A",
  QB_B: "QB_B",
  QB_C: "QB_C",
  QB_D: "QB_D",
  TYPE_A: "a",
  TYPE_B: "b",
  TYPE_C: "c",
  TYPE_D: "d",
  TERASTOR_OFFLINE: "TeraStor Offline",
  TERASTOR_ONFLINE: "TeraStor Online",
  SHOW_STRIP_CHART_WITH_DOTS: 1,
  SHOW_STRIP_CHART_WITHOUR_DOTS: 0,
  REMOVE_ALL_CALLBACKS: 1,
  FROM_INDIVIDUAL_CELL_BLOCKS: 1,
  FROM_QUARTER_CELL_BLOCKS: 0,
  SHOW_INDIVIDUAL_CELL: 1,
  CLOSE_INDIVIDUAL_CELL: 0,
  TS_STATE_UNKNOWN: 0,
  TS_STATE_SHUTDOWN: 1,
  TS_STATE_STOP: 2,
  TS_STATE_STARTUP: 3,
  TS_STATE_ONLINE: 4,
  TS_STATE_MAINTENANCE: 5,
  TS_STATE_FAULT: 6,
  TS_STATE_EPO: 7,
  BUS: "BUS",
  BUS_SMALL: "Bus",
  SOC: "SOC",
  SOH: "SOH",
  MINCELLV: "Min Cell V",
  MAXCELLV: "Max Cell V",
  MAXCELL: "Max Cell T",
  TERASTOR: "TeraStor",
  POWERFACTOR: "Power Factor",
  REAL_POWER: "P",
  REACTIVE_POWER: "Q",
  APPARENT_POWER: "S",
  POWER: "Power",
  FREQUENCY: "Frequency",
  PCS_STATUS: "PCS Status",
  INLET_TEMP: "Inlet Temp",
  OUTLET_TEMP: "Outlet Temp",
  FLOW_RATE: "Flow Rate",
  OUTPUT_KW: "Output KW",
  ELECTRONIC_BAY_TEMP: "Electronic Bay Temp",
  CAPACITY: "Capacity",
  FIRE_PANEL_STATUS: "Fire Panel Status",
  CHILLER_STATUS: "Chiller Status",
  PCS_AGGREGATED: "PCS Aggregated",
  PCS: "PCS",
  FAN_SPEED: "Fan Speed",
  INTL_AMBIENT_TEMP: "Intl. Ambient Temp",
  BRIDGE_TEMP_B: "Bridge Temp B ",
  BRIDGE_TEMP_A: "Bridge Temp A ",
  COOLENGT_TEMP: "Coolant Temp",
  APPLY_TS: 0,
  APPLY_TS_NORMAL: 1,
  ALL_TERASTORS: "All TeraStors",
  LOGIN: "Login",
  USERNAME_ADDRESS: "Username or Email Address",
  GROUP_ITEM_OFF: false,
  GROUP_ITEM_ON: true,
  CELL_VOLTAGE: "CELL VOLTAGE",
  MIN: "Min",
  MAX: "Max",
  AVG: "Avg",
  CELL_TEMPERATURE: "CELL TEMPERATURE",
  BMS_DETAILS: "BMS Details",
  SERIAL: "Serial",
  NUMBER: "Number",
  FIRMWARE: "Firmware",
  VERSION: "Version",
  MAC: "MAC",
  PCS_DETAILS: "PCS Details",
  POWER_RATING: "Power Rating",
  AGGREGATED: "(Aggregated)",
  CHILLER_DETAILS: "Chiller Details",
  CAPACITY_LABEL: "Capacity",
  UNIT: "unit",
  FLOW_RATE_GPM: "Flow Rate GPM",
  FIRE_PANEL_DETAILS: "Fire panel Details",
  CLICK_ON_DETAIL: "(click on each cells to view cell details)",
  CELL_DETAILS: "Cell Details",
  ABSOLUTE_ID: "Absolute ID",
  CELL_ID: "Cell ID",
  CELL_V: "Cell V",
  CELL_T: "Cell T",
  BRIDE_TEMP_A: "Bridge Temp A",
  BRIDE_TEMP_B: "Bridge Temp B",
  METER_TYPE_REAL: "1",
  METER_TYPE_REACTIVE: "2",
  METER_TYPE_APP: "3",
  VALIDATE_USER: "validate_user",
  GET_LOGIN_USER: "get_login_user",
  CONVERT_TO_CELSIUS: "Celsius",
  CONVERT_TO_FAHRENHITE: "Fahrenheit",
  GET_LOGOUT_USER: "get_logout_user",
  STATE: "State",
  REAL_POWER_UNIT: "MW",
  REACTIVE_POWER_UNIT: "MVAR",
  APPARENT_POWER_UNIT: "MVA",
  MEGAWATTHOUR_UNIT: "MWh",
  VOLT_UNIT: "V",
  MEGAWATT_UNIT: "MW",
  KILOWATT_UNIT: "KW",
  KILOWATTHOUR_UNIT: "KWh",
  KILOVOLT_AMPS_REACTIVE_UNIT: "KVAR",
  KILOVOLT_AMPS_UNIT: "KVA",
  UN_ASSIGNED_GROUP: "UNASSIGNED",
  REAL_DISCHARGE: "Discharge",
  REAL_CHARGE: "Charge",
  REACTIVE_CAPACITIVE: "Capacitive",
  REACTIVE_INDUCTIVE: "Inductive",
  GAUGE_REAL_POWER: "Real",
  GAUGE_REACTIVE_POWER: "Reactive",
  GAUGE_APPARENT_POWER: "Apparent"
}

export default constants
