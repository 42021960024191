import type { RootState } from "../store"
import { createSlice } from "@reduxjs/toolkit"
import { Auth } from "aws-amplify"
import { signinThunk } from "./authThunk"

import type { AuthenticatedUser } from "../../interfaces/AuthenticatedUser"

import { AUTHORITY_CONSTANTS, COOKIE_NAME } from "../../utils/constants"
import cookies, { setIdTokenCookie, setRefreshTokenCookie } from "../../utils/cookies"

import { ScreenSize } from "../../enum/screen-size"

export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem(COOKIE_NAME.CURRENT_USER) || "{}") as AuthenticatedUser
export const getIdToken = () => cookies.get(COOKIE_NAME.ID_TOKEN)
export const getRefreshToken = () => cookies.get(COOKIE_NAME.REFRESH_TOKEN)

export const isSigninExpired = () =>
  sessionStorage.getItem(COOKIE_NAME.SIGNIN_EXPIRED) ? true : false
export const setSigninExpired = () => {
  sessionStorage.setItem(COOKIE_NAME.SIGNIN_EXPIRED, "1")
  // trigger session storage change event
  window.dispatchEvent(new Event("storage"))
}
export const removeSigninExpired = () => sessionStorage.removeItem(COOKIE_NAME.SIGNIN_EXPIRED)

export interface AuthState {
  isLoading: boolean
  idToken: string | null
  currentUser: AuthenticatedUser | null
  refreshToken: string | null
  errorMessage: string
  errorColor: string
  sideBarOpen: boolean
  // Use for confirm password
  challenge?: string
  rateLimitExceeded: number
}

const initialState: AuthState = {
  isLoading: false,
  idToken: getIdToken(),
  currentUser: getCurrentUser(),
  refreshToken: getRefreshToken(),
  errorMessage: "",
  errorColor: "danger",
  rateLimitExceeded: cookies.get(COOKIE_NAME.RATE_LIMIT_EXCEEDED) || 0,
  sideBarOpen: window.innerWidth <= ScreenSize.iPad ? false : true // at first load sidebar always collapsed from iPad size down
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => {
      // reset state to initial state
      return initialState
    },
    logout: (state) => {
      state.currentUser = null
      state.refreshToken = null
      state.errorMessage = ""
      state.challenge = ""

      Auth.signOut()
      sessionStorage.removeItem(COOKIE_NAME.SIGNIN_EXPIRED)
      localStorage.removeItem(COOKIE_NAME.CURRENT_USER)
      cookies.remove(COOKIE_NAME.ID_TOKEN, { path: "/" })
      cookies.remove(COOKIE_NAME.REFRESH_TOKEN, { path: "/" })
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload.message
      if (action.payload.color) {
        state.errorColor = action.payload.color
      }
    },
    setSidebarOpen: (state, action) => {
      state.sideBarOpen = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signinThunk.pending, (state: AuthState) => {
      state.isLoading = true
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(signinThunk.fulfilled, (state: AuthState, action: any) => {
      state.isLoading = false
      const payload = action.payload
      if (payload.challenge === AUTHORITY_CONSTANTS.ERR_KEY_NEW_PASSWORD_REQUIRED) {
        state.challenge = payload.challenge
        if (payload.rememberMe) {
          localStorage.setItem(COOKIE_NAME.REMEMBER_ME, "1")
          localStorage.setItem(COOKIE_NAME.USERNAME, payload.username)
        } else {
          localStorage.removeItem(COOKIE_NAME.USERNAME)
          localStorage.removeItem(COOKIE_NAME.REMEMBER_ME)
        }
      } else if (payload.status) {
        state.idToken = payload.idToken
        state.currentUser = payload.currentUser
        state.refreshToken = payload.refreshToken
        state.errorMessage = ""
        state.challenge = payload.challenge
        state.rateLimitExceeded = payload.rateLimitExceeded

        // expired default is 1 day
        if (state.idToken) {
          setIdTokenCookie(state.idToken)
        }

        // expired defaults is 7 days
        if (state.refreshToken) {
          setRefreshTokenCookie(state.refreshToken)
        }

        // get current user info
        if (state.currentUser) {
          localStorage.setItem(COOKIE_NAME.CURRENT_USER, JSON.stringify(state.currentUser))
        }

        if (payload.rememberMe) {
          localStorage.setItem(COOKIE_NAME.REMEMBER_ME, "1")
          localStorage.setItem(COOKIE_NAME.USERNAME, payload.username)
        } else {
          localStorage.removeItem(COOKIE_NAME.USERNAME)
          localStorage.removeItem(COOKIE_NAME.REMEMBER_ME)
        }
      } else {
        state.currentUser = null
        state.refreshToken = null
        state.errorMessage = payload.message
        state.errorColor = "danger"
        state.rateLimitExceeded = payload.rateLimitExceeded
        cookies.remove(COOKIE_NAME.CURRENT_USER)
        cookies.remove(COOKIE_NAME.REFRESH_TOKEN)
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(signinThunk.rejected, (state: AuthState, action: any) => {
      state.isLoading = false
      state.errorMessage = action.payload?.message
      state.errorColor = "danger"
    })
  }
})

// Action creators are generated for each case reducer function
export const { logout, setErrorMessage, setSidebarOpen } = authSlice.actions
export { signinThunk as signin }

export const getCurrentUserState = (state: RootState) => state.auth.currentUser
export const getIdTokenState = (state: RootState) => state.auth.idToken
export const getRefreshTokenState = (state: RootState) => state.auth.refreshToken
export const getIsLoadingState = (state: RootState) => state.auth.isLoading
export const getSidebarOpen = (state: RootState) => state.auth.sideBarOpen
export const getChallenge = (state: RootState) => state.auth.challenge
export const getRateLimitExceeded = (state: RootState) => state.auth.rateLimitExceeded || 0

export const getUsername = () => localStorage.getItem(COOKIE_NAME.USERNAME) || ""
export const getRememberMe = () => localStorage.getItem(COOKIE_NAME.REMEMBER_ME)

export default authSlice.reducer
