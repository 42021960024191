import Moment from "moment"

import { VisualizationDateRangeEnum } from "../../enum/visualization/tab/daterange-tab"
import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { ChartDataEnum } from "../../enum/visualization/chart-data"
import { VisualizationChartDataEnum } from "../../enum/visualization/visualization-chart-data"

import { VisualizationChartData } from "../../interfaces/visualization/VisualizationChartData"
import { ChartData } from "../../interfaces/visualization/ChartData"

import { writeCodeLogEvent } from "../utils"

import {
  roundToFixed,
  celsiusToFahrenheit,
  genDateApart,
  countDatesApart,
  isValidValue
} from "../utils"
import {
  SERVER_DATE_FORMAT,
  VISUALIZATION_TIMESTAMP_REGULAR,
  DATA_CHECK_REDUCE
} from "../constants"

const _newDataValue = (val: number | null | undefined, decimal = 1) =>
  val !== null && val !== undefined ? decimalVal(val, decimal) : null
const _newData = (
  item: VisualizationChartData,
  isBattery: boolean,
  isCurrent: boolean,
  isSample: boolean
) => {
  const temp = extractFromTimestamp(item.timestamp)
  return {
    [ChartDataEnum.leftYaxis]: isBattery
      ? item.maxTemp || 0
      : isCurrent
        ? item.ia || 0
        : item.vab || 0,
    [ChartDataEnum.rightYAxis]: isBattery
      ? item.maxVoltage || 0
      : item.commandReactivePower || item.commandRealPower || 0,
    [ChartDataEnum.xAxis]: isSample ? temp.timestamp : temp.hour,
    [ChartDataEnum.date]: temp.date,

    [ChartDataEnum.terastorID]: item.terastorID,
    // Sample Mode user timestamp number
    [ChartDataEnum.timestamp]: item.timestamp,
    [ChartDataEnum.timestampNumber]: Moment.utc(item.timestamp).valueOf(),
    [ChartDataEnum.groupId]: item.groupId,
    [ChartDataEnum.groupName]: item.groupName,

    [ChartDataEnum.soc]: _newDataValue(item.soc),

    [ChartDataEnum.avgTemp]: _newDataValue(item.avgTemp),
    [ChartDataEnum.minTemp]: _newDataValue(item.minTemp),
    [ChartDataEnum.maxTemp]: _newDataValue(item.maxTemp),

    [ChartDataEnum.avgVoltage]: _newDataValue(item.avgVoltage, 3),
    [ChartDataEnum.maxVoltage]: _newDataValue(item.maxVoltage, 3),
    [ChartDataEnum.minVoltage]: _newDataValue(item.minVoltage, 3),

    [ChartDataEnum.commandReactivePower]: _newDataValue(item.commandReactivePower),
    [ChartDataEnum.actualReactivePower]: _newDataValue(item.actualReactivePower),
    [ChartDataEnum.commandRealPower]: _newDataValue(item.commandRealPower),
    [ChartDataEnum.actualRealPower]: _newDataValue(item.actualRealPower),

    [ChartDataEnum.ia]: _newDataValue(item.ia),
    [ChartDataEnum.ib]: _newDataValue(item.ib),
    [ChartDataEnum.ic]: _newDataValue(item.ic),

    [ChartDataEnum.vab]: _newDataValue(item.vab),
    [ChartDataEnum.vbc]: _newDataValue(item.vbc),
    [ChartDataEnum.vca]: _newDataValue(item.vca)
  }
}

const addNewEmptyData = (
  timestamp: string,
  isBattery: boolean,
  isCurrent: boolean,
  isSample: boolean
) =>
  _newData({ timestamp, terastorID: 0, groupId: "", groupName: "" }, isBattery, isCurrent, isSample)

export const extractFromTimestamp = (timestamp: string) => {
  const is24Hour = timestamp.includes("24:00")

  // if 24:00 moment gens date string is next date, therefore we need to set back 23:00
  const date = Moment(is24Hour ? timestamp.replace("24:00", "23:00") : timestamp)

  const hour = is24Hour ? 24 : date.toDate().getHours()
  const minute = date.toDate().getMinutes()
  const formatDate = date.format(SERVER_DATE_FORMAT)

  return {
    hour,
    minute,
    date: formatDate,
    timestamp: timestamp.split(" ")[1]
  }
}

export const decimalVal = (val: number | null | undefined, decimal?: number) =>
  Number(roundToFixed(val, decimal || 1))

export const addNewData = (
  item: VisualizationChartData,
  isBattery: boolean,
  isCurrent: boolean,
  isSample: boolean
): ChartData => _newData(item, isBattery, isCurrent, isSample)

const plusData = (
  originVal: number | null | undefined,
  newVal: number | null | undefined,
  decimal = 1
) =>
  originVal !== undefined && originVal !== null && newVal !== undefined && newVal !== null
    ? decimalVal(originVal + newVal, decimal)
    : isValidValue(originVal)
      ? decimalVal(originVal, decimal)
      : isValidValue(newVal)
        ? decimalVal(newVal, decimal)
        : null

const plusCount = (originalCount: number, newVal: number | null | undefined) =>
  originalCount + (isValidValue(newVal) ? 1 : 0)

const calVal = (val: number | null | undefined, count: number, decimal = 1) =>
  val !== undefined && val !== null ? decimalVal(count ? val / count : val, decimal) : null

interface Temp extends VisualizationChartData {
  avgVoltageCount: number
  minVoltageCount: number
  maxVoltageCount: number

  socCount: number

  avgTempCount: number
  minTempCount: number
  maxTempCount: number

  commandReactivePowerCount: number
  actualReactivePowerCount: number
  commandRealPowerCount: number
  actualRealPowerCount: number

  iaCount: number
  ibCount: number
  icCount: number

  vabCount: number
  vbcCount: number
  vcaCount: number
}

const groupDataUpdateValues = (acc: Temp, item: VisualizationChartData): Temp => {
  acc.soc = plusData(acc.soc, item.soc)

  acc.avgTemp = plusData(acc.avgTemp, celsiusToFahrenheit(item.avgTemp))
  acc.minTemp = plusData(acc.minTemp, celsiusToFahrenheit(item.minTemp))
  acc.maxTemp = plusData(acc.maxTemp, celsiusToFahrenheit(item.maxTemp))

  acc.avgVoltage = plusData(acc.avgVoltage, item.avgVoltage, 3)
  acc.minVoltage = plusData(acc.minVoltage, item.minVoltage, 3)
  acc.maxVoltage = plusData(acc.maxVoltage, item.maxVoltage, 3)

  acc.commandReactivePower = plusData(acc.commandReactivePower, item.commandReactivePower)
  acc.actualReactivePower = plusData(acc.actualReactivePower, item.actualReactivePower)
  acc.commandRealPower = plusData(acc.commandRealPower, item.commandRealPower)
  acc.actualRealPower = plusData(acc.actualRealPower, item.actualRealPower)

  acc.ia = plusData(acc.ia, item.ia)
  acc.ib = plusData(acc.ib, item.ib)
  acc.ic = plusData(acc.ic, item.ic)

  acc.vab = plusData(acc.vab, item.vab)
  acc.vbc = plusData(acc.vbc, item.vbc)
  acc.vca = plusData(acc.vca, item.vca)

  return acc
}

const groupDataUpdateCount = (acc: Temp, item: VisualizationChartData): Temp => {
  acc.socCount = plusCount(acc.socCount, item.soc)

  acc.avgTempCount = plusCount(acc.avgTempCount, celsiusToFahrenheit(item.avgTemp))
  acc.maxTempCount = plusCount(acc.maxTempCount, celsiusToFahrenheit(item.maxTemp))
  acc.minTempCount = plusCount(acc.minTempCount, celsiusToFahrenheit(item.minTemp))

  acc.avgVoltageCount = plusCount(acc.avgVoltageCount, item.avgVoltage)
  acc.maxVoltageCount = plusCount(acc.maxVoltageCount, item.maxVoltage)
  acc.minVoltageCount = plusCount(acc.minVoltageCount, item.minVoltage)

  acc.commandReactivePowerCount = plusCount(
    acc.commandReactivePowerCount,
    item.commandReactivePower
  )
  acc.actualReactivePowerCount = plusCount(acc.actualReactivePowerCount, item.actualReactivePower)
  acc.commandRealPowerCount = plusCount(acc.commandRealPowerCount, item.commandRealPower)
  acc.actualRealPowerCount = plusCount(acc.actualRealPowerCount, item.actualRealPower)

  acc.iaCount = plusCount(acc.iaCount, item.ia)
  acc.ibCount = plusCount(acc.ibCount, item.ib)
  acc.icCount = plusCount(acc.icCount, item.ic)

  acc.vabCount = plusCount(acc.vabCount, item.vab)
  acc.vbcCount = plusCount(acc.vbcCount, item.vbc)
  acc.vcaCount = plusCount(acc.vcaCount, item.vca)

  return acc
}

/**
 * Remove redundant attributes from chart data would make rendering abit faster
 * @param {VisualizationChartData} item data
 * @param {boolean} isBattery current mode is battery or load
 * @param {boolean} isCurrent current mode is current or voltage
 * @returns {VisualizationChartData}
 */
const removeReduntantAttrs = (
  item: VisualizationChartData,
  isBattery: boolean,
  isCurrent: boolean
): VisualizationChartData => {
  if (isBattery) {
    delete item.ia
    delete item.ib
    delete item.ic

    delete item.vab
    delete item.vbc
    delete item.vca

    delete item.commandReactivePower
    delete item.actualReactivePower
    delete item.commandRealPower
    delete item.actualRealPower
  } else {
    delete item.soc

    delete item.maxTemp
    delete item.avgTemp
    delete item.minTemp

    delete item.maxVoltage
    delete item.avgVoltage
    delete item.minVoltage

    if (isCurrent) {
      delete item.vab
      delete item.vbc
      delete item.vca
    } else {
      delete item.ia
      delete item.ib
      delete item.ic
    }
  }

  return item
}

export const convertAPIDataToChartData = (
  resData: VisualizationChartData[],
  viewBy: VisualizationViewByEnum,
  isBattery: boolean,
  isCurrent: boolean,
  isSample: boolean
): ChartData[] => {
  try {
    const tempData: ChartData[] = []

    if (viewBy === VisualizationViewByEnum.Detail) {
      resData.forEach((item: VisualizationChartData) => {
        // gather chart chart data
        const temp: VisualizationChartData = {
          ...item,
          avgTemp: celsiusToFahrenheit(item.avgTemp),
          minTemp: celsiusToFahrenheit(item.minTemp),
          maxTemp: celsiusToFahrenheit(item.maxTemp)
        }
        tempData.push(
          addNewData(
            removeReduntantAttrs(temp, isBattery, isCurrent),
            isBattery,
            isCurrent,
            isSample
          )
        )
      })
    } else {
      const groupedData = resData.reduce(
        (acc: Record<string, Temp>, item: VisualizationChartData) => {
          const timestamp = item.timestamp
          if (!acc[timestamp]) {
            acc[timestamp] = {
              ...addNewEmptyData(timestamp, isBattery, isCurrent, isSample),

              terastorID: item.terastorID,

              avgVoltageCount: 0,
              minVoltageCount: 0,
              maxVoltageCount: 0,

              socCount: 0,

              avgTempCount: 0,
              minTempCount: 0,
              maxTempCount: 0,

              commandReactivePowerCount: 0,
              actualReactivePowerCount: 0,
              commandRealPowerCount: 0,
              actualRealPowerCount: 0,

              iaCount: 0,
              ibCount: 0,
              icCount: 0,

              vabCount: 0,
              vbcCount: 0,
              vcaCount: 0
            }
          }

          // update values
          acc[timestamp] = groupDataUpdateValues(acc[timestamp], item)

          // update count
          acc[timestamp] = groupDataUpdateCount(acc[timestamp], item)

          return acc
        },
        {}
      )

      Object.keys(groupedData).reduce((acc: Record<string, Temp>, date: string) => {
        const item: Temp = groupedData[date]

        item.soc = calVal(item.soc, item.socCount)

        item.avgTemp = calVal(item.avgTemp, item.avgTempCount)
        item.minTemp = calVal(item.minTemp, item.minTempCount)
        item.maxTemp = calVal(item.maxTemp, item.maxTempCount)

        item.avgVoltage = calVal(item.avgVoltage, item.avgVoltageCount, 3)
        item.minVoltage = calVal(item.minVoltage, item.minVoltageCount, 3)
        item.maxVoltage = calVal(item.maxVoltage, item.maxVoltageCount, 3)

        item.commandReactivePower = calVal(
          item.commandReactivePower,
          item.commandReactivePowerCount
        )
        item.actualReactivePower = calVal(item.actualReactivePower, item.actualReactivePowerCount)
        item.commandRealPower = calVal(item.commandRealPower, item.commandRealPowerCount)
        item.actualRealPower = calVal(item.actualRealPower, item.actualRealPowerCount)

        item.ia = calVal(item.ia, item.iaCount)
        item.ib = calVal(item.ib, item.ibCount)
        item.ic = calVal(item.ic || 0, item.icCount)

        item.vab = calVal(item.vab, item.vabCount)
        item.vbc = calVal(item.vbc, item.vbcCount)
        item.vca = calVal(item.vca, item.vcaCount)

        // gather chart chart data
        tempData.push(
          addNewData(
            removeReduntantAttrs(item, isBattery, isCurrent),
            isBattery,
            isCurrent,
            isSample
          )
        )

        return acc
      }, {})
    }

    return tempData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("Code exception: handle convert api data to chart data failed =>", e.message)

    writeCodeLogEvent("handle convert api data to chart data failed", e)

    return []
  }
}

const HOURS_RANGE = {
  TWO_DAYS: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
  THREE_DAYS: [0, 8, 16],
  FOUR_DAYS: [0, 6, 12, 18],
  FIVE_DAYS: [0, 12]
}

const appendHours = (
  index: number,
  hours: number[],
  existingData: ChartData[],
  date: string,
  isBattery: boolean,
  isCurrent: boolean,
  isSample: boolean
): ChartData[] => {
  const data: ChartData[] = []

  hours.forEach((hour: number) => {
    const check: ChartData[] = existingData.filter((item: ChartData) => {
      const timestamp = extractFromTimestamp(item.timestamp)
      return timestamp.hour === hour
    })

    // due to api data 24h is 0h, we need to convert it to 24h from second date
    const timestamp = `${date} ${hour < 10 ? `0${hour}` : hour}:00:00.000`

    if (check.length > 0) {
      const temp = check[0]
      temp.date = date
      temp.timestamp = timestamp
      temp.xAxis = hour

      data.push(temp)
    } else {
      data.push(addNewEmptyData(timestamp, isBattery, isCurrent, isSample))
    }
  })
  return data
}

/**
 * Calculating average data from list data
 * @param {ChartData[]} arr list data
 * @param {ChartDataEnum} attr calculating attribute
 * @param {number} decimal
 * @returns {number | null}
 */
const calAvgValue = (arr: ChartData[], attr: ChartDataEnum, decimal = 1): number | null => {
  let totalValue: number | null = null
  let totalCount = 0
  arr.forEach((item: ChartData) => {
    const value = item[attr]
    if (typeof value !== "string" && value !== null && value !== undefined) {
      totalValue = totalValue === null ? value : totalValue + value
      totalCount++
    }
  })

  return totalValue !== null && totalCount !== 0
    ? decimalVal(totalValue / totalCount, decimal)
    : null
}

export const getHourLength = (dateCount: number) =>
  dateCount === 2 ? 1 : dateCount === 3 ? 7 : dateCount === 4 ? 5 : 11

/**
 * Update average value to hour in display array
 * @param {ChartData[]} arr list data after add full display hours
 * @param {ChartData[]} originalData api response oringindal data
 * @param {number} dateCount number of selected dates, using for calculating average value
 * @returns {ChartData[]}
 */
const updateAvgData = (
  arr: ChartData[],
  originalData: ChartData[],
  dateCount: number
): ChartData[] => {
  // get average hour range
  const hoursRange = getHourLength(dateCount)
  const resArr: ChartData[] = []

  arr.forEach((item: ChartData) => {
    // filtering current date and selected hour range
    const temp = originalData.filter(
      (oitem: ChartData) =>
        oitem.date === item.date &&
        oitem.xAxis >= item.xAxis &&
        parseInt(`${oitem.xAxis}`) <= parseInt(`${item.xAxis}`) + hoursRange
    )

    resArr.push({
      ...item,

      maxTemp: calAvgValue(temp, ChartDataEnum.maxTemp),
      avgTemp: calAvgValue(temp, ChartDataEnum.avgTemp),
      minTemp: calAvgValue(temp, ChartDataEnum.minTemp),

      maxVoltage: calAvgValue(temp, ChartDataEnum.maxVoltage, 3),
      avgVoltage: calAvgValue(temp, ChartDataEnum.avgVoltage, 3),
      minVoltage: calAvgValue(temp, ChartDataEnum.minVoltage, 3),

      ia: calAvgValue(temp, ChartDataEnum.ia),
      ib: calAvgValue(temp, ChartDataEnum.ib),
      ic: calAvgValue(temp, ChartDataEnum.ic),

      vab: calAvgValue(temp, ChartDataEnum.vab),
      vbc: calAvgValue(temp, ChartDataEnum.vbc),
      vca: calAvgValue(temp, ChartDataEnum.vca),

      commandReactivePower: calAvgValue(temp, ChartDataEnum.commandReactivePower),
      actualReactivePower: calAvgValue(temp, ChartDataEnum.actualReactivePower),

      commandRealPower: calAvgValue(temp, ChartDataEnum.commandRealPower),
      actualRealPower: calAvgValue(temp, ChartDataEnum.actualRealPower)
    })
  })

  return resArr
}

/**
 * Calculating average aggregated data from list data
 * @param {ChartData[]} arr list data
 * @param {ChartDataEnum} attr calculating attribute
 * @param {number} decimal
 * @returns {number | null}
 */
const calAvgAggregatedValue = (
  arr: VisualizationChartData[],
  attr: VisualizationChartDataEnum,
  decimal = 1
): number | null => {
  let totalValue: number | null = null
  let totalCount = 0
  arr.forEach((item: VisualizationChartData) => {
    let value = item[attr]
    if (
      [
        VisualizationChartDataEnum.maxTemp,
        VisualizationChartDataEnum.avgTemp,
        VisualizationChartDataEnum.minTemp
      ].includes(attr)
    )
      value = celsiusToFahrenheit(value)

    if (typeof value !== "string" && value !== null && value !== undefined) {
      value = decimalVal(value, decimal)
      totalValue = totalValue === null ? value : totalValue + value
      totalCount++
    }
  })

  return totalValue !== null && totalCount !== 0
    ? decimalVal(totalValue / totalCount, decimal)
    : null
}

/**
 * Update average aggregated value to hour in display array
 * @param {ChartData[]} arr list data after add full display hours
 * @param {ChartData[]} originalData api response oringindal data
 * @param {number} dateCount number of selected dates, using for calculating average value
 * @returns {ChartData[]}
 */
const updateAvgAggregatedData = (
  arr: ChartData[],
  apiData: VisualizationChartData[],
  dateCount: number
): ChartData[] => {
  // get average hour range
  const hoursRange = getHourLength(dateCount)
  const resArr: ChartData[] = []

  arr.forEach((item: ChartData) => {
    // filter data on current date
    const filter = apiData.filter((aitem: VisualizationChartData) => {
      const temp = extractFromTimestamp(aitem.timestamp)
      return (
        temp.date === item.date &&
        temp.hour >= parseInt(`${item.xAxis}`) &&
        parseInt(`${temp.hour}`) <= parseInt(`${item.xAxis}`) + hoursRange
      )
    })
    // get list tsId
    const tsIds: number[] = []
    apiData
      .map((temp: VisualizationChartData) => temp.terastorID)
      .forEach((value: number) => {
        if (!tsIds.includes(value)) {
          tsIds.push(value)
        }
      })

    const _calAvgValue = (attr: VisualizationChartDataEnum, decimal = 1): number | null => {
      let tempTotal: number | null = null
      let tempCount = 0
      tsIds.forEach((tsId: number) => {
        // filter out data of current terastor
        const filterTsId = filter.filter((temp: VisualizationChartData) => temp.terastorID === tsId)

        // calculating average value of current terastor
        const tempVal = calAvgAggregatedValue(filterTsId, attr, decimal)

        // add to total average value
        if (tempVal !== null && tempTotal !== null) {
          tempTotal += tempVal
          tempCount++
        } else if (tempVal !== null) {
          tempTotal = tempVal
          tempCount++
        }
      })

      return tempTotal !== null && tempCount !== 0
        ? decimalVal(tempTotal / tempCount, decimal)
        : null
    }

    resArr.push({
      ...item,

      maxTemp: _calAvgValue(VisualizationChartDataEnum.maxTemp),
      avgTemp: _calAvgValue(VisualizationChartDataEnum.avgTemp),
      minTemp: _calAvgValue(VisualizationChartDataEnum.minTemp),

      maxVoltage: _calAvgValue(VisualizationChartDataEnum.maxVoltage, 3),
      avgVoltage: _calAvgValue(VisualizationChartDataEnum.avgVoltage, 3),
      minVoltage: _calAvgValue(VisualizationChartDataEnum.minVoltage, 3),

      ia: _calAvgValue(VisualizationChartDataEnum.ia),
      ib: _calAvgValue(VisualizationChartDataEnum.ib),
      ic: _calAvgValue(VisualizationChartDataEnum.ic),

      vab: _calAvgValue(VisualizationChartDataEnum.vab),
      vbc: _calAvgValue(VisualizationChartDataEnum.vbc),
      vca: _calAvgValue(VisualizationChartDataEnum.vca),

      commandReactivePower: _calAvgValue(VisualizationChartDataEnum.commandReactivePower),
      actualReactivePower: _calAvgValue(VisualizationChartDataEnum.actualReactivePower),

      commandRealPower: _calAvgValue(VisualizationChartDataEnum.commandRealPower),
      actualRealPower: _calAvgValue(VisualizationChartDataEnum.actualRealPower)
    })
  })

  return resArr
}

export const appendMissingData = (
  originalData: ChartData[],
  apiData: VisualizationChartData[],
  dateRange: VisualizationDateRangeEnum,
  selectedDate: string,
  fromDate: string,
  toDate: string,
  isBattery: boolean,
  isCurrent: boolean,
  isSample: boolean,
  isAggregatedView: boolean
): ChartData[] => {
  // formart chart data
  if (dateRange === VisualizationDateRangeEnum.Hours) {
    const tempHoursData: ChartData[] = []
    Array.from({ length: 24 }, (_, i) => i).forEach((value: number) => {
      const check: ChartData[] = originalData.filter((item: ChartData) => item.xAxis === value)
      if (check.length > 0) tempHoursData.push(check[0])
      else {
        tempHoursData.push(
          addNewEmptyData(
            `${Moment(selectedDate).format(SERVER_DATE_FORMAT)} ${value < 10 ? `0${value}` : value}:00:00.000`,
            isBattery,
            isCurrent,
            isSample
          )
        )
      }
    })
    return tempHoursData
  } else {
    let tempDatesData: ChartData[] = []
    const dateCount = countDatesApart(fromDate, toDate)

    // loop through selected dates to fill missing dates
    Array.from({ length: dateCount }, (_, i) => i).forEach((value: number, index: number) => {
      // get current date (of current loop)
      const date = genDateApart(fromDate, index, SERVER_DATE_FORMAT)

      // get hours range
      const hours =
        dateCount === 2
          ? HOURS_RANGE.TWO_DAYS
          : dateCount === 3
            ? HOURS_RANGE.THREE_DAYS
            : dateCount === 4
              ? HOURS_RANGE.FOUR_DAYS
              : HOURS_RANGE.FIVE_DAYS

      // check if current date has data
      let existingData: ChartData[] = originalData.filter((item: ChartData) => item.date === date)

      if (existingData.length > 0) {
        // filtering selected hours range data
        existingData = existingData.filter((item: ChartData) =>
          hours.includes(parseInt(`${item.xAxis}`))
        )

        // remove outside range and add missing hours
        existingData = appendHours(index, hours, existingData, date, isBattery, isCurrent, isSample)

        // calculating individual hour average value from next hours
        if (isAggregatedView)
          existingData = updateAvgAggregatedData(existingData, apiData, dateCount)
        else existingData = updateAvgData(existingData, originalData, dateCount)

        // append data
        tempDatesData = [...tempDatesData, ...existingData]
      } else {
        // add new empty data
        tempDatesData = [
          ...tempDatesData,
          ...appendHours(index, hours, [], date, isBattery, isCurrent, isSample)
        ]
      }
    })

    return tempDatesData
  }
}

/**
 * Get output value from timestamp
 * @param {string} timestamp
 * @returns {string} output value
 */
export const getInfoFromTimestamp = (
  timestamp: string,
  isSample: boolean,
  isDateRange: boolean
) => {
  if (isSample) {
    return Moment(timestamp).utc().format("HH:mm:ss.SSS")
  }
  if (VISUALIZATION_TIMESTAMP_REGULAR.test(timestamp)) {
    const [datePart, timePart] = timestamp.split(" ")

    // sample mode then return hh:mm:ss.mss
    if (isSample) return timePart

    const [hours, minutes] = timePart.split(":").map(Number) // Split and convert to numbers

    // Ensure hours and minutes are two digits
    const formattedHours = hours.toString().padStart(2, "0")
    const formattedMinutes = minutes.toString().padStart(2, "0")

    // get date format
    const date = new Date(datePart)

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0") // Months are zero-based, so add 1
    const year = date.getFullYear()

    if (isDateRange) return `${day}/${month}/${year} ${formattedHours}:${formattedMinutes}`
    else return `${formattedHours}:${formattedMinutes}`
  }
}

export const reduceDataSampleMode = (
  resData: VisualizationChartData[],
  selectedTsIDs: number[]
): VisualizationChartData[] => {
  // Reduce resData: base on timestamp
  // Select 1 record every 5 minutes
  const TIME_REDUCE = 300_000 // 5 minutes
  const isGetSample = (
    previousTimestamps: Map<number, number[]>,
    data: VisualizationChartData
  ): boolean => {
    const currentTimestamp = data.timestamp ? new Date(data.timestamp).getTime() : 0
    const hasValues = DATA_CHECK_REDUCE.map((field) => {
      return data[field as keyof VisualizationChartData] !== null
    })
    let result = false
    const tsTimestamp = previousTimestamps.get(data.terastorID)
    if (!tsTimestamp) {
      // The TsID is not in request
      return false
    }
    for (let i = 0; i < tsTimestamp.length; i++) {
      // Don't support data < 1.1.1970
      // previousTimestamps[i] === 0: first record has data
      // currentTimestamp - previousTimestamps[i] >= TIME_REDUCE: 5 minutes passed
      if (
        hasValues[i] &&
        (tsTimestamp[i] === 0 || currentTimestamp - tsTimestamp[i] >= TIME_REDUCE)
      ) {
        tsTimestamp[i] = currentTimestamp
        result = true
      }
    }
    return result
  }
  if (resData.length === 0) {
    return resData
  } else {
    const previousTimestamps: Map<number, number[]> = new Map()
    selectedTsIDs.forEach((tsId) => {
      previousTimestamps.set(
        tsId,
        DATA_CHECK_REDUCE.map(() => 0)
      )
    })
    const tempResData: VisualizationChartData[] = []
    for (let i = 0; i < resData.length; i++) {
      if (isGetSample(previousTimestamps, resData[i])) {
        tempResData.push(resData[i])
      }
    }
    return tempResData
  }
}

export const calculateTicks = (data: ChartData[]): number[] => {
  const SECONDS_IN_HOUR = 3600_000
  const xAxisTicks: number[] = []
  // Inset missing hours to xAxis
  if (data.length > 2) {
    const start = Number(data[0].timestampNumber)
    const end = Number(data[data.length - 1].timestampNumber)
    const firstTick = start - (start % SECONDS_IN_HOUR) + SECONDS_IN_HOUR
    xAxisTicks.push(start)
    for (let i = firstTick; i < end; i += SECONDS_IN_HOUR) {
      xAxisTicks.push(i)
    }
    xAxisTicks.push(end)
  } else {
    xAxisTicks.push(...data.map((item) => Number(item.timestampNumber)))
  }
  return xAxisTicks
}
