import { useEffect, useState } from "react"
import { Modal, ModalBody, Button, Alert, Spinner } from "reactstrap"
import Moment from "moment"

import { ActivationKeyEntity } from "../../interfaces/ActivationKeyEntity"
import { ActivationKeyModalProps } from "../../interfaces/ActivationKeyModalProps"

import { apiGetActivationKey, apiGenerateActivationKey } from "../../services/apiActivationKey"

import { ActivationKey } from "../../enum/activation-key"

import Message from "../../utils/messages"
import { writeCodeLogEvent } from "../../utils/utils"

const ActivationKeyModal = (props: ActivationKeyModalProps) => {
  const [activationKey, setActivationKey] = useState<ActivationKeyEntity | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [error, setError] = useState("")
  const siteUniqueId = props.siteUniqueId

  const DATE_FORMAT = "MM/DD/YYYY"

  useEffect(() => {
    setError("")
    setLoading(false)
    setSubmit(false)

    if (siteUniqueId) {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteUniqueId])

  useEffect(() => {
    if (isLoading) {
      apiGetActivationKey(siteUniqueId)
        .then((res) => {
          if (res.status) {
            const data = res.data as ActivationKeyEntity[]
            if (data.length < 0) setActivationKey(null)
            else {
              const activeKey = data.filter(
                (key: ActivationKeyEntity) => key.status === ActivationKey.ACTIVE
              )
              setActivationKey(activeKey.length > 0 ? activeKey[0] : null)
            }
          } else {
            setError(res.message || "")
          }
          setLoading(false)
        })
        .catch((e) => {
          console.error("Code exception: handle get activation key =>", e.message)
          setError(Message.ERR_ACTIVATION_KEY_GET_FAILED)
          setLoading(false)

          writeCodeLogEvent("handle get activation key", e, siteUniqueId)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isSubmit) {
      apiGenerateActivationKey(siteUniqueId)
        .then((res) => {
          if (res.status) {
            const data = res.data as ActivationKeyEntity
            setActivationKey(data)
          } else {
            setError(res.message || "")
          }
          setSubmit(false)
        })
        .catch((e) => {
          console.error("Code exception: handle generate activation key =>", e.message)
          setError(Message.ERR_ACTIVATION_KEY_GENERATE_FAILED)
          setSubmit(false)

          writeCodeLogEvent("handle generate activation key", e, siteUniqueId)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit])

  const formatDate = (date: string) => (date ? Moment(date).format(DATE_FORMAT) : "-")
  const displayText = (value: string) => (
    <>
      {isLoading ? (
        <div className="text-center">
          <Spinner color="secondary" size="sm" className="spinner">
            Loading...
          </Spinner>
        </div>
      ) : (
        value
      )}
    </>
  )
  const getStatus = (status?: string) => {
    let text = ""
    switch (status) {
      case ActivationKey.ACTIVE:
        text = Message.LBL_ACTIVATION_ACTIVE
        break
      case ActivationKey.USED:
        text = Message.LBL_ACTIVATION_USED
        break
      case ActivationKey.EXPIRED:
        text = Message.LBL_ACTIVATION_EXPIRED
        break
      default:
        text = "-"
        break
    }

    return text
  }
  const getStatusClass = (status?: string) => {
    let text = "fw-bold"
    switch (status) {
      case ActivationKey.ACTIVE:
        text += " status-active"
        break
      case ActivationKey.USED:
        text += " status-used"
        break
      case ActivationKey.EXPIRED:
        text += " status-expired"
        break
      default:
        text = ""
        break
    }

    return text
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      id="activationKeyModal"
      onClosed={props.onClose}
      unmountOnClose={true}
      backdrop={"static"}
    >
      <ModalBody className="p-4 m-3">
        <h4 className="mb-4 title text-break">Activation Key ({props.siteName})</h4>
        {error && (
          <Alert color="danger" className="mb-3">
            <p className="mb-0">{error}</p>
          </Alert>
        )}
        <div id="cardWrapper" className="d-flex">
          <div className="card me-2 mb-4">
            <p className="mb-0 fw-bold px-2 py-3 card-header">Activation Key</p>
            <div className="mb-0 px-3 py-5 d-flex flex-column sub-text">
              {displayText(activationKey?.code || "-")}
            </div>
          </div>
          <div className="card me-lg-2 mb-4">
            <p className="mb-0 fw-bold px-2 py-3 card-header">Key Created</p>
            <div className="mb-0 px-3 py-5 d-flex flex-column sub-text">
              {displayText(formatDate(activationKey?.createdAt || ""))}
            </div>
          </div>
          <div className="card me-2 mb-4">
            <p className="mb-0 fw-bold px-2 py-3 card-header">Key Expiration</p>
            <div className="mb-0 px-3 py-5 d-flex flex-column sub-text">
              {displayText(formatDate(activationKey?.expiredAt || ""))}
            </div>
          </div>
          <div className="card me-lg-2 mb-4">
            <p className="mb-0 fw-bold px-2 py-3 card-header">Last Used</p>
            <div className="mb-0 px-3 py-5 d-flex flex-column sub-text">
              {displayText(activationKey?.lastUsed || "-")}
            </div>
          </div>
          <div className="card mb-4">
            <p className="mb-0 fw-bold px-2 py-3 card-header">Status</p>
            <div
              className={`${getStatusClass(activationKey?.status)} mb-0 px-3 py-5 d-flex flex-column sub-text`}
            >
              {displayText(getStatus(activationKey?.status))}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            className="me-2 fw-bold border-0 py-2 px-4"
            onClick={props.toggle}
            disabled={isSubmit}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => setSubmit(true)}
            disabled={isLoading || isSubmit}
            className="fw-bold py-2 px-4"
          >
            {isSubmit && (
              <Spinner color="light" size="sm" className="me-1">
                Loading...
              </Spinner>
            )}
            Generate new Key
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ActivationKeyModal
