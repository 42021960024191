import SearchInput from "components/form/SearchInput"
import TableHeader from "components/TableHeader"
import TableStatus from "components/TableStatus"
import TableTooltipColumn from "components/TableTooltipColumn"
import { TableStatusProps } from "interfaces/TableStatusProps"
import { useEffect, useRef, useState } from "react"
import { Button, CardGroup, Spinner, TabContent, TabPane } from "reactstrap"
import Messages from "utils/messages"

import edit from "../../assets/images/icons/admin/edit.svg"
import trash from "../../assets/images/icons/admin/trash.svg"
import { PaginationV2Props } from "interfaces/PaginationV2Props"
import { PageInfo } from "interfaces/PageInfo"
import PaginationV2 from "components/PaginationV2"
import { downloadFile, parseDateTime, stringFormat, writeCodeLogEvent } from "utils/utils"
import { AllDocumentTableProps } from "interfaces/doc/AllDocumentTableProps"
import {
  apiDeleteDoc,
  apiDownloadDoc,
  apiGetDoc,
  apiUpdateDoc
} from "services/apiDocumentManagement"
import { DocEntity, DocEntitySelection } from "interfaces/doc/DocEntity"
import { AlertModalProps } from "interfaces/AlertModalProps"
import AlertModal from "components/modal/AlertModal"
import DeleteModal from "components/modal/DeleteModal"
import { DeleteModalProps } from "interfaces/DeleteModalProps"
import { DocumentEditModalProps } from "interfaces/doc/DocumentEditModalProps"
import DocumentEditModal from "components/modal/DocumentEditModal"
import { PresignDownloadDoc } from "interfaces/doc/PresignDoc"
import { throttle } from "lodash"
import MultipleDocumentsDownloadModal from "components/modal/MultipleDocumentsDownloadModal"
import { MultipleDocsDownloadModalProps } from "interfaces/doc/MultipleDocsDownloadModalProps"
import DocumentCard from "components/cards/DocumentCard"
import { SITE_SETUP_VIEW_MODE } from "utils/constants"
import cardViewMode from "../../assets/images/icons/configuration/card.svg"
import listViewMode from "../../assets/images/icons/configuration/list.svg"
import { useSelector } from "react-redux"
import { getCategoryList } from "store/counter/docSlice"

const AllDocumentsTable = (props: AllDocumentTableProps) => {
  const DEFAULT_SORTING = "&sort=updatedAt,desc"

  const { needUpdateList, siteList, categoryId, isTenantAdmin } = props
  const categoryList = useSelector(getCategoryList)

  const [searchText, setSearchText] = useState("")
  const [allItemsChecked, setAllItemsChecked] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [documentList, setDocumentList] = useState<DocEntitySelection[]>([])
  const [selectedDocuments, setSelectedDocuments] = useState<DocEntitySelection[]>([])
  const [prepareDownload, setPrepareDownload] = useState(false)
  const [viewMode, setViewMode] = useState(SITE_SETUP_VIEW_MODE.LIST)
  const [customerId, setCustomerId] = useState<number>()
  const [categoryMap, setCategoryMap] = useState<Map<number, string>>()
  const [siteNameMap, setSiteNameMap] = useState<Map<number, string>>()

  // #region Sorting

  const headerColumns = [
    {
      alias: "fileName",
      title: Messages.LBL_DOCUMENTS_HEADER_FILE_NAME
    },
    {
      alias: "categoryId",
      title: Messages.LBL_DOCUMENTS_HEADER_DOCUMENT_CATEGORY,
      visible: !isTenantAdmin
    },
    {
      alias: "siteId",
      title: Messages.LBL_DOCUMENTS_HEADER_DOCUMENT_SITE,
      visible: isTenantAdmin
    },
    {
      alias: "fileType",
      title: Messages.LBL_DOCUMENTS_HEADER_FILE_TYPE
    },
    {
      alias: "updatedAt",
      title: Messages.LBL_DOCUMENTS_HEADER_LAST_UPDATED
    },
    {
      title: Messages.LBL_DOCUMENTS_HEADER_DESCRIPTION
    },
    {
      title: Messages.LBL_SITE_HEADER_ACTIONS
    }
  ]
  const [sortingHeader, setSortingHeader] = useState(DEFAULT_SORTING)
  // #endregion Sorting

  // #region Pagination
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const [currentPage, setCurrentPage] = useState(0)
  const paginationV2Props: PaginationV2Props = {
    setCurrentPage: (pageNumber: number) => setCurrentPage(pageNumber),
    pageInfo,
    showingCount: documentList.length,
    forcePage: currentPage
  }
  //#endregion Pagination

  // #region Table Status
  const [tableStatus, setTableStatus] = useState("loading")
  const [tableStatusMsg, setTableStatusMsg] = useState(Messages.MSG_DOCUMENTS_FETCHING)
  const tablestatusProps: TableStatusProps = {
    status: tableStatus,
    message: tableStatusMsg,
    colCount: headerColumns.length + 1
  }
  // #endregion Table Status

  // #region useEffect
  const fetchData = async () => {
    setTableStatusMsg(Messages.MSG_DOCUMENTS_FETCHING_LIST)
    setTableStatus("loading")

    if (!siteList || siteList.length === 0) {
      return
    }

    setLoading(true)
    const currentCustomerUniqueId =
      siteList && siteList.length > 0 ? siteList[0].customerUniqueId : undefined
    setCustomerId(currentCustomerUniqueId)
    const siteUniqueIds =
      siteList && siteList.length > 0 ? siteList?.map((x) => x.siteUniqueId) : []

    // list all document from all customer by passing undefined
    const res = await apiGetDoc(
      currentCustomerUniqueId,
      siteUniqueIds,
      categoryId,
      currentPage,
      sortingHeader,
      undefined,
      searchText
    )
    if (res.status) {
      const data: DocEntitySelection[] = res.data || []
      data.forEach((doc) => {
        if (selectedDocuments.some((existingDoc) => existingDoc.id === doc.id)) {
          doc.isSelected = true
        }
      })
      setDocumentList(data)
      setPageInfo(res.pageInfo)
      setLoading(false)
    } else {
      setTableStatus("error")
      setTableStatusMsg(res.message || "")
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortingHeader])

  useEffect(() => {
    if (currentPage === 0) {
      fetchData()
    } else {
      setCurrentPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, siteList, categoryId, needUpdateList])

  useEffect(() => {
    const selectedCount = documentList.filter((doc) => doc.isSelected).length || 0
    if (documentList.length > 0 && selectedCount === documentList.length) {
      setAllItemsChecked(true)
    } else {
      setAllItemsChecked(false)
    }
    if (documentList.length === 0) {
      setTableStatus("empty")
      setTableStatusMsg(Messages.MSG_EMPTY_DATA)
    } else {
      setTableStatus("")
    }
  }, [documentList])

  useEffect(() => {
    setCategoryMap(new Map(categoryList.map((category) => [category.id, category.name])))
  }, [categoryList])

  useEffect(() => {
    if (siteList && siteList.length > 0) {
      setSiteNameMap(new Map(siteList.map((site) => [site.siteUniqueId, site.siteName])))
    }
  }, [siteList])
  // #endregion useEffect

  const getCategory = (docCategoryId: number | undefined): string | undefined => {
    if (!docCategoryId) {
      return ""
    } else if (categoryMap) {
      return categoryMap.get(docCategoryId)
    } else {
      return docCategoryId.toString()
    }
  }

  const getSiteName = (siteID: number | undefined): string | undefined => {
    if (!siteID) {
      return ""
    } else if (siteNameMap) {
      return siteNameMap.get(siteID)
    } else {
      return `Site ${siteID}`
    }
  }

  // #region Item selection
  const tableAllDocumentsRef = useRef<HTMLTableElement>(null)

  const toggleAllCheckbox = (checked: boolean) => {
    setAllItemsChecked(checked)
    const updatedDocumentList: DocEntitySelection[] = documentList.map((doc) => ({
      ...doc,
      isSelected: checked
    }))
    setDocumentList(updatedDocumentList)
    // append or remove sites from stage list
    if (checked) {
      setSelectedDocuments((oldList) => {
        const combinedList = [...oldList, ...updatedDocumentList]
        return combinedList
      })
    } else {
      setSelectedDocuments((oldList) => {
        const filteredList = oldList.filter(
          (oldItem) => !updatedDocumentList.some((updatedItem) => updatedItem.id === oldItem.id)
        )
        return filteredList
      })
    }
  }

  const toggleCheckboxSelection = (docCheckEntity: DocEntitySelection, isChecked: boolean) => {
    setDocumentList((oldList) => {
      const doc = oldList.find((doc) => doc.id === docCheckEntity.id)
      if (doc) {
        doc.isSelected = isChecked
      }
      return [...oldList]
    })
    if (isChecked) {
      setSelectedDocuments((oldList) => [...oldList, docCheckEntity])
    } else {
      setSelectedDocuments((oldList) => oldList.filter((site) => site.id !== docCheckEntity.id))
    }
  }
  // #endregion Item selection

  // #region Download docs
  const downloadDocuments = async (customerId: number, docs: DocEntity[]) => {
    try {
      // Extract document IDs
      const docIds = docs.map((doc) => doc.id).filter((id): id is number => id !== undefined)

      // Call the API to get presigned URLs
      const res = await apiDownloadDoc(customerId, docIds)

      if (res.status) {
        const presignList: PresignDownloadDoc[] = res.data
        for (let i = 0; i < presignList.length; i++) {
          const presign = presignList[i]
          const doc = docs.find((doc) => doc.id === presign.id)
          if (doc) {
            downloadFile(presign.preSignedUrl, doc.fileName)
          }
        }
      } else {
        // Handle error response
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: res.errorLists || res.message,
          modal: true
        })
      }
    } catch (error) {
      console.error("Download failed", error)
      writeCodeLogEvent("[Document Center] download fail", error)
    }
  }

  const downloadSingleDocument = async (doc: DocEntity) => {
    if (customerId && doc.id) {
      await downloadDocuments(customerId, [doc])
    }
  }
  // Throttle the download function to prevent multiple clicks
  const throttledDownload = throttle(downloadSingleDocument, 500, { trailing: false })

  const downloadSelectedDocuments = async () => {
    if (customerId) {
      try {
        setPrepareDownload(true)
        // Extract document IDs
        const docIds = selectedDocuments
          .map((doc) => doc.id)
          .filter((id): id is number => id !== undefined)

        // Call the API to get presigned URLs
        const res = await apiDownloadDoc(customerId, docIds)

        if (res.status) {
          const presignList: PresignDownloadDoc[] = res.data
          for (let i = 0; i < presignList.length; i++) {
            const presign = presignList[i]
            const doc = selectedDocuments.find((doc) => doc.id === presign.id)
            if (doc) {
              presign.fileName = doc.fileName
            }
          }
          setMultipleDownload({
            ...multipleDownload,
            modal: true,
            presignLinks: presignList
          })
        } else {
          // Handle error response
          setAlertModalProps({
            ...alertModalProps,
            status: "error",
            message: res.errorLists || res.message,
            modal: true
          })
        }
      } catch (error) {
        console.error("Download failed", error)
        writeCodeLogEvent("[Document Center] download fail", error)
      } finally {
        setPrepareDownload(false)
      }
    }
  }

  const [multipleDownload, setMultipleDownload] = useState<MultipleDocsDownloadModalProps>({
    size: "md",
    modal: false,
    toggle: () => {
      setMultipleDownload({
        ...multipleDownload,
        modal: false
      })
    }
  })

  // #endregion ============= Download selected docs ==============

  // #region Edit doc

  const handleEdit = (doc: DocEntity) => {
    // handle edit
    showEditDocModal(doc)
  }

  const showEditDocModal = (doc: DocEntity) => {
    setDocumentEditModalProps((oldProps) => ({
      ...oldProps,
      modal: true,
      docData: doc,
      onDone: updateDoc
    }))
  }

  const onListLengthChanged = () => {
    if (
      pageInfo &&
      pageInfo.pageNumber !== 0 &&
      pageInfo.totalElements - pageInfo.pageSize * pageInfo.pageNumber === 1
    ) {
      setCurrentPage(pageInfo.pagePrev)
    } else {
      fetchData()
    }
  }

  const updateDoc = (newDoc: DocEntity) => {
    setAlertModalProps({
      ...alertModalProps,
      status: "loading",
      modal: true,
      message: Messages.MSG_DOCUMENTS_UPDATING
    })
    apiUpdateDoc(newDoc).then((res) => {
      if (res.status) {
        const updatedDoc: DocEntitySelection = res.data
        const index = documentList.findIndex((doc) => doc.id === updatedDoc.id)
        if (index !== -1) {
          if (documentList[index].siteId !== updatedDoc.siteId) {
            setSelectedDocuments((oldList) => oldList.filter((doc) => doc.id !== updatedDoc.id))
            onListLengthChanged()
          } else {
            const newList = [...documentList]
            updatedDoc.isSelected = newList[index].isSelected
            newList[index] = updatedDoc // Update the document if siteId is the same
            setDocumentList(newList)
          }
        }

        setAlertModalProps({
          ...alertModalProps,
          status: "success",
          modal: false
        })
      } else {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: res.errorLists || res.message,
          modal: true
        })
      }
    })
  }

  // =========================================
  // ========== edit docs modal ==========
  // =========================================
  const [documentEditModalProps, setDocumentEditModalProps] = useState<DocumentEditModalProps>({
    size: "lg",
    modal: false,
    toggle: () => {
      setDocumentEditModalProps({
        ...documentEditModalProps,
        modal: false
      })
    },
    docData: null,
    onDone: updateDoc
  })
  // #endregion Edit doc

  // #region Delete doc
  const handleDelete = (doc: DocEntity) => {
    setDeletingDoc(doc)
    setDeleteModal(true)
  }
  const [deletingDoc, setDeletingDoc] = useState<DocEntity | null>(null)
  const [deletemodal, setDeleteModal] = useState(false)
  const deleteToggle = () => setDeleteModal((prev) => !prev)
  const deleteResponse = (status: boolean) => {
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: status ? "success" : "error",
      message: status
        ? stringFormat(Messages.MSG_DOCUMENTS_DELETE_SUCCESSFULLY, [deletingDoc?.fileName || ""])
        : stringFormat(Messages.MSG_DOCUMENTS_DELETE_FAIL, [deletingDoc?.fileName || ""])
    })

    if (status && deletingDoc) {
      setSelectedDocuments((oldList) => oldList.filter((doc) => doc.id !== deletingDoc.id))
      onListLengthChanged()
    }
    setDeletingDoc(null)
  }
  const deleteDoc = async (id: number) => await apiDeleteDoc(id)
  const deleteModalProps: DeleteModalProps = {
    size: "sm",
    modal: deletemodal,
    toggle: deleteToggle,
    id: deletingDoc ? `${deletingDoc.id}` : "",
    text: stringFormat(Messages.MSG_DOCUMENTS_DELETE_CONFIRMATION, [deletingDoc?.fileName || ""]),
    request: async () => {
      const res = await deleteDoc(deletingDoc?.id || 0)
      return {
        status: res.status,
        message: res.message || Messages.ERR_COMMON_SERVER_FAILED
      }
    },
    response: deleteResponse
  }
  // #endregion ============= Delete doc ==============

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  const handleDocumentDownload = async (doc: DocEntity) => {
    await downloadSingleDocument(doc)
  }

  // #region JSX
  return (
    <>
      <div className="text-center d-md-flex justify-content-between align-items-start my-4">
        <SearchInput
          name="search"
          placeHolder="Search"
          onChange={(text: string) => {
            setSearchText(text)
          }}
        />
        <div className="d-flex flex-column align-items-end">
          {props.isTenantAdmin && (
            <div className="d-md-flex justify-content-between pt-2">
              <ul className="nav justify-content-end">
                <li
                  className={
                    viewMode === SITE_SETUP_VIEW_MODE.LIST
                      ? "nav-selection-tab-item selected"
                      : "nav-selection-tab-item"
                  }
                  role="button"
                >
                  <img
                    src={listViewMode}
                    width={20}
                    onClick={() => setViewMode(SITE_SETUP_VIEW_MODE.LIST)}
                  />
                </li>
                <li
                  className={
                    viewMode === SITE_SETUP_VIEW_MODE.CARD
                      ? "nav-selection-tab-item selected"
                      : "nav-selection-tab-item"
                  }
                  role="button"
                >
                  <img
                    src={cardViewMode}
                    width={20}
                    onClick={() => {
                      setViewMode(SITE_SETUP_VIEW_MODE.CARD)
                    }}
                  />
                </li>
              </ul>
            </div>
          )}
          {viewMode !== SITE_SETUP_VIEW_MODE.CARD &&
            (prepareDownload ? (
              <div className="py-1 px-0">
                <Spinner color="primary" size="sm" className="me-1"></Spinner>
                <span className="text-primary">{Messages.MSG_DOCUMENTS_PREPARE_DOWNLOAD}</span>
              </div>
            ) : (
              <Button
                className="flat-button pe-1"
                disabled={selectedDocuments.length === 0}
                onClick={(e) => {
                  e.preventDefault()
                  downloadSelectedDocuments()
                }}
              >
                <span>{Messages.BTN_DOCUMENTS_DOWNLOAD_SELECTED}</span>
              </Button>
            ))}
        </div>
      </div>

      <TabContent activeTab={viewMode}>
        <TabPane tabId={SITE_SETUP_VIEW_MODE.LIST}>
          <div className="overflow-auto mb-2">
            <table
              ref={tableAllDocumentsRef}
              className="overflow-auto table table-hover"
              id="tableAllDocuments"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      width={16}
                      onChange={(event) => toggleAllCheckbox(event.target.checked)}
                      checked={allItemsChecked}
                    />
                  </th>
                  {headerColumns.map(
                    (item, index) =>
                      (item.visible === undefined || item.visible) && (
                        <TableHeader
                          key={index}
                          alias={item.alias}
                          title={item.title}
                          isLoading={isLoading}
                          sortingHeader={sortingHeader}
                          visible={item.visible}
                          setSortingHeader={setSortingHeader}
                        />
                      )
                  )}
                </tr>
              </thead>
              <tbody>
                {tableStatus ? (
                  <TableStatus {...tablestatusProps} />
                ) : (
                  documentList.map((doc, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="p-0"
                          type="checkbox"
                          width={16}
                          onChange={(event) => toggleCheckboxSelection(doc, event.target.checked)}
                          checked={doc.isSelected || false}
                        />
                      </td>
                      <TableTooltipColumn
                        id={`fileName-${doc.fileId}`}
                        text={doc.fileName}
                        maxLength={550}
                      />
                      {isTenantAdmin ? (
                        <TableTooltipColumn
                          id={`site-${doc.fileId}`}
                          text={getSiteName(doc.siteId)}
                          maxLength={350}
                        />
                      ) : (
                        <TableTooltipColumn
                          id={`category-${doc.fileId}`}
                          text={getCategory(doc.categoryId)}
                          maxLength={350}
                        />
                      )}
                      <TableTooltipColumn
                        id={`fileType-${doc.fileId}`}
                        text={doc.fileType}
                        maxLength={50}
                      />
                      <TableTooltipColumn
                        id={`updatedAt-${doc.fileId}`}
                        text={doc.updatedAt ? parseDateTime(parseInt(doc.updatedAt)) : ""}
                        maxLength={175}
                      />
                      <TableTooltipColumn
                        id={`description-${doc.fileId}`}
                        text={`${doc.description ?? ""}`}
                        maxLength={550}
                      />
                      {
                        <td>
                          <div className="action-button">
                            <Button
                              color="light"
                              className="text-button"
                              onClick={() => throttledDownload(doc)}
                            >
                              {Messages.BTN_SITE_SETUP_DOWNLOAD_JSON}
                            </Button>
                            <Button
                              color="light"
                              className={`border-0 ${props.isTenantAdmin ? "d-none" : ""}`}
                              onClick={() => handleEdit(doc)}
                            >
                              <img src={edit} alt="Info" />
                            </Button>
                            <Button
                              color="light"
                              className={`border-0 ${props.isTenantAdmin ? "d-none" : ""}`}
                              onClick={() => handleDelete(doc)}
                            >
                              <img src={trash} alt="Delete" />
                            </Button>
                          </div>
                        </td>
                      }
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <PaginationV2 {...paginationV2Props} />
        </TabPane>
        <TabPane tabId={SITE_SETUP_VIEW_MODE.CARD}>
          {tableStatus ? (
            <div className="d-flex justify-content-center">
              {isLoading && <Spinner color="primary" size="sm" className="me-1" />}
              <h3 className="text-center">{tableStatusMsg}</h3>
            </div>
          ) : (
            <CardGroup>
              {documentList.map((doc, index) => (
                <div key={index}>
                  <DocumentCard doc={doc} onDownload={handleDocumentDownload} />
                </div>
              ))}
            </CardGroup>
          )}
          <PaginationV2 {...paginationV2Props} />
        </TabPane>
      </TabContent>
      {alertModalProps.modal && <AlertModal {...alertModalProps} />}
      <DeleteModal {...deleteModalProps} />
      {documentEditModalProps.modal && <DocumentEditModal {...documentEditModalProps} />}
      {multipleDownload.modal && <MultipleDocumentsDownloadModal {...multipleDownload} />}
    </>
  )
  // #endregion JSX
}

export default AllDocumentsTable
