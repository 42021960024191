import { CustomerEntity } from "interfaces/CustomerEntity"
import { ModifySite } from "interfaces/ModifySite"

export default {
  ERR_CUSTOMER_CREATE_FAILED: "Create Customer Failure. Please try again!",
  ERR_CUSTOMER_MODIFY_FAILED: "Modify Customer Failure. Please try again!",
  ERR_CUSTOMER_DELETE_FAILED: "Customer Deleted Failure. Please try again!",
  ERR_CUSTOMER_DELETE_FAILED_ACTIVE_SITES:
    "This customer still has active site(s). Deleting is not allowed!",
  ERR_CUSTOMER_GET_LIST_FAILED: "Get List Customer Failure. Please try again!",
  ERR_CUSTOMER_GET_INFO_FAILED: "Get Customer Info Failure. Please try again!",
  MSG_CUSTOMER_CREATE_SUCCESS: "The new customer master record is created.",
  MSG_CUSTOMER_DELETE_SUCCESS: "Customer Deleted Successfully!",
  MSG_CUSTOMER_MODIY_SUCCESS: "The customer master record is updated.",
  MSG_CUSTOMER_DELETE: "Are you sure you want to delete this customer Master Record",
  LBL_CUSTOMER_HEADER_ID: "Customer ID",
  LBL_CUSTOMER_HEADER_NAME: "Customer Name",
  LBL_CUSTOMER_HEADER_NUMBER_OF_SITES: "Number of Site(s)",
  LBL_CUSTOMER_HEADER_EMAIL: "Primary Contact Email",
  LBL_CUSTOMER_HEADER_ADDRESS: "Customer Address",
  LBL_CUSTOMER_HEADER_DATE: "Effective Date",
  LBL_CUSTOMER_HEADER_ACTIONS: "Actions",
  LBL_CUSTOMER_HEADER_MANAGE: "Sites/Users",
  LBL_CUSTOMER_HIDE_DELETE_CUSTOMER: "Show deleted Customers",

  LBL_CUSTOMER_MODAL_TITLE_ADD: "Create customer master record",
  LBL_CUSTOMER_MODAL_TITLE_EDIT: "Edit customer master record",
  ERR_CUSTOMER_MODAL_PHONE_INVALID: "Phone number format is invalid",

  LBL_MANAGEMENT_CUSTOMER_LIST: "Customer List",
  LBL_MANAGEMENT_ALL_SITES: "All Sites",
  LBL_MANAGEMENT_BTN_CREATE_CUSTOMER: "Create New Customer",
  LBL_MANAGEMENT_BTN_UPDATE_FW: "Stage Software",

  MSG_SITE_CREATE_SUCCESS: "The new site record is created.",
  MSG_SITE_DELETE: "Are you sure you want to delete <span class='text-primary'>{0}</span>",
  MSG_SITE_DELETE_SUCCESS: "Site Deleted Successfully!",
  MSG_SITE_MODIFY_SUCCESS: "This site’s information is successfully updated.",
  MSG_SITE_FETCHING_SITE_LIST: "Fetching the site list... Please wait",
  ERR_SITE_GET_LIST_FAILED: "Get List Site Failure. Please try again!",
  ERR_SITE_GET_INFO_FAILED: "Get Site Info Failure. Please try again!",
  ERR_SITE_GET_DETAIL_FAILED: "Could not get site detail!. Please try again",
  ERR_SITE_CREATE_FAILED: "Create Site Failure. Please try again!",
  ERR_SITE_MODIFY_FAILED: "Modify Site Failure. Please try again!",
  ERR_SITE_DELETE_FAILED: "Site Deleted Failure. Please try again!",
  LBL_SITE_HEADER_NAME: "Site Name",
  LBL_SITE_HEADER_GROUP: "Site Group",
  LBL_SITE_HEADER_ID: "Site ID",
  LBL_SITE_HEADER_CITY: "City",
  LBL_SITE_HEADER_STATE: "State/Province",
  LBL_SITE_HEADER_COUNTRY: "Country",
  LBL_SITE_HEADER_LATITUDE: "Latitude",
  LBL_SITE_HEADER_LONGTITUDE: "Longitude",
  LBL_SITE_HEADER_TERASTOR: "# TeraStors",
  LBL_SITE_HEADER_DATE: "Commission Start",
  LBL_SITE_HEADER_ACTIONS: "Actions",
  LBL_SITE_HEADER_BMS_SOFTWARE: "BMS Software",
  LBL_SITE_HEADER_EMS_SOFTWARE: "StorView Software",
  LBL_SITE_MENU_ADMIN_VIEW: "Tenant Admin View",
  LBL_SITE_MENU_KEY: "Activation Key",
  LBL_SITE_MENU_EDIT: "Edit",
  LBL_SITE_MENU_DELETE: "Delete",
  LBL_SITE_HIDE_DELETE_SITE: "Show deleted Sites",

  ERR_COGNITO_SIGNUP_ACTIVATED: "Your account has been activated. You can login now.",
  ERR_COGNITO_SIGNUP_CODE_FAILED: "Verify Signup User Failure. Please try again!",
  ERR_COGNITO_SIGNUP_FAILED: "Complete Signup User Failure. Please try again!",
  ERR_COGNITO_SIGNIN_FAILED: "Cannot sign in with your account. Please sign in again!",
  ERR_COGNITO_SIGNIN_EXPIRED: "Your sign in session has expired. Please sign in again!",
  ERR_COGNITO_SIGNIN_INVALID_USERNAME_PASSWORD: "Username or password is incorrect.",
  ERR_COGNITO_SIGNIN_TOO_MANY_REQUESTS:
    "You've exceeded the maximum number of login attempts allowed. Wait for a couple of minutes then try again!",
  ERR_COGNITO_SIGNIN_NOT_ALLOWED:
    "You're not allowed to signin. Please contact your administrator.",
  ERR_COGNITO_GET_CUSTOMERUNIQUEID_FAILED:
    "Could not get your account's customerUniqueId. Please contact your administrator.",
  ERR_MSG_USER_DISABLED: "User is disabled. Please contact your administrator.",
  ERR_MSG_FORCE_CHANGE_PASSWORD:
    "User needs to force change password. Please contact your administrator",
  ERR_MSG_PENDING_CONFIRMATION: "User needs to enable MFA. Please contact your administrator",
  ERR_MSG_RATE_LIMIT_EXCEEDED:
    "You have exceeded the maximum number of allowed failed action attempts. Wait for {0} minutes then try again!",
  MSG_USER_DELETE: "Are you sure you want to delete <span class='text-primary'>{0}</span>",
  MSG_USER_DELETE_SUCCESS: "User Deleted Successfully!",
  MSG_USER_CREATE_SUCCESS: "The new user record is created.",
  MSG_USER_SOFT_DELETE_SUCCESS: "User Of Customer Deleted Successfully!",
  MSG_USER_MODIFY_SUCCESS: "This user’s information is successfully updated.",
  ERR_USER_GET_LIST_FAILED: "Get List User Failure. Please try again!",
  ERR_USER_GET_LIST_SITE_ADMIN_FAILED: "Get List Site Admin User Failure. Please try again!",
  ERR_USER_GET_INFO_FAILED: "Get Info User Failure. Please try again!",
  ERR_USER_CREATE_FAILED: "Create User Failure. Please try again!",
  ERR_USER_MODIFY_FAILED: "Modify User Failure. Please try again!",
  ERR_USER_DELETE_FAILED: "User Deleted Failure. Please try again!",
  ERR_USER_SOFT_DELETE_FAILED: "Soft Delete User Failure. Please try again!",
  ERR_USER_UPLOAD_AVATAR_FAILED: "Upload Avatar Failure. Please try again!",
  ERR_USER_AVATAR_INVALID_FORMAT: "Avatar image should be within 1 Mb and in PNG/JPEG formats.",
  MSG_USER_PASSWORD_MESSAGE:
    "Minimum of 8 characters, must have 1 number, 1 lowercase character, 1 uppercase character & 1 of !@#$%^&*",
  MSG_USER_PASSWORD_MIN_8: "Have at least 8 characters",
  MSG_USER_PASSWORD_1_UPPER_CHARACTER: "Have at least 1 uppercase character",
  MSG_USER_PASSWORD_1_LOWER_CHARACTER: "Have at least 1 lowercase character",
  MSG_USER_PASSWORD_1_NUMBER: "Have at least 1 number",
  MSG_USER_PASSWORD_1_SPECIAL_CHARACTER: "Have at least 1 special character !@#$%^&*",
  MSG_USER_PASSWORD_NO_SPACE: "Must not contain spaces",
  MSG_USER_RESET_PASSWORD_SUCCESS:
    "Password has been successfully reset. Please click on the button bellow to back to sign in screen.",
  MSG_USER_RESET_PASSWORD_OTP_EXPIRED: "The code has expired.",
  LBL_USER_HEADER_USERNAME: "Username",
  LBL_USER_HEADER_FIRSTNAME: "First Name",
  LBL_USER_HEADER_LASTNAME: "Last Name",
  LBL_USER_HEADER_SITES: "Assigned Site(s)",
  LBL_USER_HEADER_EMAIL: "Email",
  LBL_USER_HEADER_PHONE: "Phone Number",
  LBL_USER_HEADER_ROLE: "Role",
  LBL_USER_HEADER_MOBILE: "Mobile",
  LBL_USER_HEADER_AUTHORIZATION: "Authorization",
  LBL_USER_HEADER_CREATED: "Created Date",
  LBL_USER_HEADER_ACTIONS: "Actions",

  // Monitoring
  LBL_MONITORING_SOFTWARE_STATUS: "Software Status",
  MSG_MONITORING_START_SITE_SETUP: "Start site setup.",
  BTN_MONITORING_START_SITE_SETUP: "Site Setup",
  MSG_MONITORING_SITE_SETUP_IN_PROGRESS: "Site setup is in progress.",
  BTN_MONITORING_SITE_SETUP_IN_PROGRESS: "Continue Site Setup",
  MSG_MONITORING_SITE_WAITING_DATA: "Site setup is complete. Waiting for data.",
  MSG_MONITORING_NO_SITE:
    "There is no site associated with this account. Please contact your administrator to create a site first.",
  MSG_SITE_NOT_FOUND:
    "This site isn't associated with your account. Please contact your administrator to assign the site to your account.",
  ERR_MONITORING_GET_SITE_OVERVIEW_FAILED: "Get Site Overview Failure. Please try again!",
  ERR_MONITORING_GET_SITE_SUMMARY_FAILED: "Get Site Summary Failure. Please try again!",
  ERR_MONITORING_GET_SITE_TERASTOR_FAILED: "Get List Terastor Failure. Please try again!",
  ERR_MONITORING_DEVICE_NOT_CONNECT: "Device Still Not Connected!",
  LBL_MONITORING_HEADER_TERASTOR_HASH: "TeraStor#",
  LBL_MONITORING_HEADER_BMS_SOFTWARE: "BMS Software",
  LBL_MONITORING_HEADER_STORVIEW_SOFTWARE: "Storview Software",
  LBL_MONITORING_HEADER_SOFTWARE_STATUS: "Software Status",
  LBL_MONITORING_HEADER_AVAILABLE_BMS_SOFTWARE: "Available BMS Software",
  LBL_MONITORING_HEADER_AVAILABLE_STORVIEW_SOFTWARE: "Available Storview Software",
  LBL_MONITORING_HEADER_STATUS: "Status",

  MSG_EMPTY_DATA: "There are no records to display at this moment.",
  ERR_REQUEST_TIMEOUT: "The request takes too long to respond. Please try again!",
  ERR_COMMON_SERVER_FAILED: "Server Failure. Please try again later. Thanks for your patience.",
  ERR_ACTIVATION_KEY_GENERATE_FAILED: "Generate Activation Key Failure. Please try again!",
  ERR_ACTIVATION_KEY_GET_FAILED: "Get Activation Key Failure. Please try again!",

  // Site setup
  SITE_INFORMATION: "Site Information",
  SITE_AC_VOLTAGE: "Site AC voltage",
  SITE_RATED_REAL_POWER: "Site Rated Real Power",
  SITE_RATED_REACTIVE_POWER: "Site Rated Reactive Power",
  SITE_RATED_ENERGY_CAPACITY: "Site Rated Energy Capacity",
  POWER_QUALITY_METER: "Power Quality Meter",
  POINT_OF_INTERCONNECT_PRIMARY_METER: "Point of Interconnect Primary Meter",
  POINT_OF_INTERCONNECT_SECONDARY_METER: "Point of Interconnect Secondary Meter",
  LOAD_POWER_METER: "Load Power Meter",
  SUPPLEMENTARY_POWER_METER: "Supplementary Power Meter",
  HOST_NAME: "Name",
  HOST_NAME_PLACEHOLDER: "Host name",
  IP_ADDRESS: "IP address",
  PORT_NUMBER: "Port number",
  PORT_NUMBER_PLACEHOLDER: "Port #",
  NETMASK: "Netmask",
  GATEWAY: "Gateway",
  DOMAIN_MASTER: "Domain Master",
  DATA_SERVER: "Data Server",
  TERASTOR: "TeraStor",
  STORVIEW_CUSTOMER_NETWORK_SETTINGS: "StorView Customer Network Settings",
  STORVIEW: "StorView",
  TERASTOR_NO: "TeraStor #",
  NICKNAME: "Nickname",
  PART_NO: "Part #",
  SERIAL_NO: "Serial #",
  RING_MARSHAL: "Ring Marshal",
  QUARTER_BLOCK_A: "Quarter Block A",
  QUARTER_BLOCK_B: "Quarter Block B",
  QUARTER_BLOCK_C: "Quarter Block C",
  QUARTER_BLOCK_D: "Quarter Block D",
  LBL_EMS_BOARD: "EMS Board Serial Number",
  LBL_SERIAL_NUMBER: "Serial Number",
  LBL_PART_NUMBER: "Part Number",
  EMS_BOARD: "EMS Board",
  LBL_AVAILABLE_TERASTORS: "Available TeraStors in this Site",
  LBL_BACK_SUPER_ADMIN: "Back to Super admin View",
  MSG_SAVING_SITE_CONFIGURATION: "Saving site configuration...",
  MSG_UPLOAD_SITE_CONFIGURATION_SUCCEED: "File site-config.zip uploaded to AWS S3",
  MSG_EXPORTING_SITE_CONFIGURATION: "Exporting site configuration...",
  ERR_SITE_VOLTAGE_MUST_INRANGE: "Value should be between 0 and 1500",
  ERR_SITE_POWER_MUST_INRANGE: "Value should be between 0 and 9999999",
  ERR_EXPORT_CONFIG_FAILED:
    "Export site config failed. Please try again later. Thanks for your patience.",
  ERR_PQM_HOSTNAME_MUST_BE_UNIQUE: "Hostname must be unique",
  ERR_PQM_IP_MUST_BE_UNIQUE: "IP address must be unique",
  ERR_PQM_IP_MUST_BE_V4: "IP address must be IPv4 only",
  ERR_INVALID_NETMASK: "Invalid netmask",
  ERR_CONFIGURATION_PORT_MUST_BE_IN_RANGE: "Port number must be in range 1-65535",

  LBL_ACTIVATION_ACTIVE: "Active",
  LBL_ACTIVATION_USED: "Used",
  LBL_ACTIVATION_EXPIRED: "Expired",

  LBL_SITE_SETUP_UNSAVED_CHANGE_ALERT: "Changes have not been saved. Do you want to save?",
  LBL_SITE_SETUP_EDIT_TERASTOR_TITLE: "Edit TeraStor",
  BTN_SITE_SETUP_SAVE: "Save",
  BTN_SITE_SETUP_SAVE_TO_DRAFT: "Save to draft",
  BTN_SITE_SETUP_SUBMIT: "Submit",
  BTN_SITE_SETUP_SUBMIT_APPROVAL: "Submit for approval",
  BTN_SITE_SETUP_CANCEL_SUBMISSION: "Cancel Submission",
  BTN_SITE_SETUP_CANCEL: "Cancel",
  BTN_SITE_SETUP_BACK: "Back",
  BTN_SITE_SETUP_DISCARD: "Discard",
  BTN_SITE_SETUP_APPROVE: "Approve",
  BTN_SITE_SETUP_REJECT: "Reject",
  BTN_SITE_SETUP_EDIT: "Edit",
  BTN_SITE_SETUP_EDIT_LAYOUT: "Edit Layout",
  BTN_SITE_SETUP_EDIT_SITE: "Edit Site",
  BTN_SITE_SETUP_EXPORT_JSON: "Export JSON",
  BTN_SITE_SETUP_DOWNLOAD_JSON: "Download",
  BTN_SITE_SETUP_RELOAD: "Reload",
  MSG_SITE_SETUP_REVIEW_INVALID: "Please correct configuration.",
  BTN_SITE_SETUP_VIEW_SITE_DETAILS: "View Site Details",
  LBL_SITE_SETUP_SITE_DETAILS: "Site Details",
  LBL_SITE_SETUP_UPLOAD_BACKGROUND: "Please start by uploading the Architectural drawing",
  MSG_SITE_SETUP_WARNING_APPROVE:
    "The configuration is still incomplete. Please ensure everything is valid before approval.",
  MSG_SITE_SETUP_WARNING_SUBMIT:
    "The configuration is still incomplete. Please make the necessary modifications. You can still submit if you want for the AESI representative to review.",
  MSG_SITE_SETUP_RELOAD: "Your setup is out of date. Please reload to apply the latest updates.",
  ERR_DOWNLOAD_CONFIG_ZIP_FAILED: "Unable to download site configuration. File doesn't exist!",
  ERR_SAVE_CONFIGURATION: "Error when save configuration. Please try again!",
  ERR_GET_CONFIGURATION: "Can not fetch the site configuration. Please try again!",
  MSG_EXCEED_SUCCEED_NUMBER_TERASTOR_ALERT:
    "The site layout is required to have {0} TeraStor{1} on the diagram. You currently have {2} TeraStor{3}. Please modify and submit. You can still submit if you want for the AESI representative to review.",
  MSG_EXCEED_SUCCEED_NUMBER_TERASTOR_ALERT_SUPPER_ADMIN:
    "The Site layout requires to have {0} TeraStor{1} on the diagram, you currently have {2} TeraStor{3}.  Would you like to approve this layout?",
  MSG_NO_TERASTOR_ALERT: "There are no terastors in the layout.",
  MSG_NO_SITE_DRAWING_UPLOADED: "There is no site drawing uploaded yet.",
  ERR_NICKNAME_INVALID:
    "Nickname is invalid. Only allow alphanumeric characters, spaces, underscores, colon, dash, without leading or trailing spaces.",
  ERR_PART_NUMBER_INVALID: "Part number is invalid. Only allow alphanumeric characters.",
  ERR_PART_NUMBER_REQUIRE: "Part number is required.",
  ERR_SERIAL_INVALID:
    "Serial number %BLOCK% is invalid. Only allow alphanumeric characters and start with alphabetic.",
  ERR_EMS_SERIAL_INVALID:
    "EMS board serial number is invalid. Only allow upper case alphanumeric characters and start with alphabetic.",
  ERR_AUTH_EXCEPTION: "Sorry, an unexpected error has occurred.",
  MSG_SIGNIN_VERIFY: "Verifying your login session...",
  MSG_SIGNIN_SUCCEED: "Succeed login, redirecting...",
  LBL_SITE_SETUP_FETCHING: "Fetching Site info...",
  MSG_SITE_SETUP_TS_IN_USE: "TeraStor {0} is currently selected as {1}",
  MSG_SITE_SETUP_DELETE_CONFIRM: "Do you want to process?",
  MSG_SITE_SETUP_RING_MARSHAL_MISSING:
    "Ring Marshals are missing for commissioning. Please select a TeraStor to be the Ring Marshal for each group.",
  MSG_SITE_SETUP_CONTACT_ADMIN:
    "Site {0} is ready for installation. If you need to make changes, please contact your AESI administrator.",
  ERR_SITE_SETUP_IN_PROGRESS: "[layout] Do not allow update layout when status LAYOUT_IN_PROGRESS",
  MSG_SITE_SETUP_EMS_MISSING:
    "EMS serial numbers are incorrect or missing for commissioning. Please ensure all EMS serial numbers are correct and available.",

  MSG_SIGNUP_SUCCEED_TITLE: "Congratulations",
  MSG_SIGNUP_SUCCEED_MESSAGE: "Your account has been successfully created",
  ERR_SIGNUP_ERROR_TITLE: "Something went wrong",
  ERR_SIGNUP_ERROR_MESSAGE:
    "Your account could be already activated or something wrong happened, please try again later!",
  LBL_SIGNUP_PASSWORD_NOT_MATCH: "Passwords do not match",

  ERR_FORGOT_PASSWORD_SEND_CODE_FAILED: "Send OTP Code Failure. Please try again!",
  ERR_FORGOT_PASSWORD_UPDATE_FAILED: "Reset Password Failure. Please try again!",
  LBL_FORGOT_PASSWORD_ENTER_CODE: "Please enter the one-time code that was sent to your email : ",
  LBL_FORGOT_PASSWORD_ENTER_EMAIL: "Please Enter your email to get a password reset link.",

  MSG_VISUALIZATION_SELECT_TERASTOR: "First you need to select TeraStor to generate a report",
  BTN_VISUALIZATION_SELECT_TERASTOR: "Select TeraStor",
  LBL_VISUALIZATION_FILTER: "Filters",
  LBL_VISUALIZATION_BATTERY: "Battery",
  LBL_VISUALIZATION_LOAD: "Load",
  LBL_VISUALIZATION_CURRENT: "Current",
  LBL_VISUALIZATION_TEMPERATURE: "Temperature",
  LBL_VISUALIZATION_SOC: "SOC",
  LBL_VISUALIZATION_VOLTAGE: "Voltage",
  LBL_VISUALIZATION_REAL_POWER: "Real Power",
  LBL_VISUALIZATION_COMMANDED: "Commanded",
  LBL_VISUALIZATION_ACTUAL: "Actual",
  LBL_VISUALIZATION_REACTIVE_POWER: "Reactive Power",
  LBL_VISUALIZATION_DATE_RANGE: "Date Range",
  LBL_VISUALIZATION_DATA_MODE: "Data Mode",
  LBL_VISUALIZATION_DATA_MODE_AVERAGE: "Average",
  LBL_VISUALIZATION_DATA_MODE_SAMPLE: "Sample",
  LBL_VISUALIZATION_DATE: "Date",
  LBL_VISUALIZATION_HOURS: "Hours",
  LBL_VISUALIZATION_VIEW_BY: "View By",
  LBL_VISUALIZATION_DETAIL: "Detail",
  LBL_VISUALIZATION_AGGREGATED: "Aggregated",
  LBL_VISUALIZATION_MAXIMUM: "Maximum",
  LBL_VISUALIZATION_AVERAGE: "Average",
  LBL_VISUALIZATION_MINIMUM: "Minimum",
  LBL_VISUALIZATION_SEARCH: "Search...",
  LBL_VISUALIZATION_SELECT_ALL: "Select All",
  BTN_VISUALIZATION_CLEAR: "Clear",
  BTN_VISUALIZATION_CLEAR_ALL: "Clear all",
  BTN_VISUALIZATION_CANCEL: "Cancel",
  BTN_VISUALIZATION_APPLY: "Apply",
  LBL_VISUALIZATION_MAX_TEMP: "Maximum Temperature",
  LBL_VISUALIZATION_MIN_TEMP: "Minimum Temperature",
  LBL_VISUALIZATION_MAX_VOLT: "Maximum Voltage",
  LBL_VISUALIZATION_MIN_VOLT: "Minimum Voltage",
  LBL_VISUALIZATION_FILTER_BY: "Filter by:",
  LBL_VISUALIZATION_FILTER_ALL: "All",
  LBL_VISUALIZATION_FILTER_MAXTEMP: "Max Temperature (F)",
  LBL_VISUALIZATION_FILTER_AVGTEMP: "Avg Temperature (F)",
  LBL_VISUALIZATION_FILTER_MINTEMP: "Min Temperature (F)",
  LBL_VISUALIZATION_FILTER_MAXVOLT: "Max Voltage (V)",
  LBL_VISUALIZATION_FILTER_AVGVOLT: "Avg Voltage (V)",
  LBL_VISUALIZATION_FILTER_MINVOLT: "Min Voltage (V)",
  LBL_VISUALIZATION_CHART_TEMP: "Temperature (OF)",
  LBL_VISUALIZATION_CHART_VOLT: "Voltage (V)",
  LBL_VISUALIZATION_CHART_CURR: "Current (A)",
  LBL_VISUALIZATION_BADGE_TEMP: "Temperature (A)",
  LBL_VISUALIZATION_BADGE_RPOWER: "Real Power (P)",
  LBL_VISUALIZATION_BADGE_REPOWER: "Reactive Power (Q)",
  LBL_VISUALIZATION_DETAIL_MODAL_TITLE: "Details",
  LBL_VISUALIZATION_DETAIL_MODAL_SUMMARY: "Summary",
  LBL_VISUALIZATION_SELECTED_SITE: "Selected Site:",
  ERR_VISUALIZATION_FETCHING_SITES: "Loading site list...",
  ERR_VISUALIZATION_FETCHING_TERASTOR: "Loading TeraStor list...",
  ERR_VISUALIZATION_SITES_NOTSET: "(Not set)",
  ERR_VISUALIZATION_SITES_LOADING: "Loading...",
  LBL_VISUALIZATION_SHOWING_TERASTOR: "Showing TeraStor",
  LBL_VISUALIZATION_TERASTOR: "TeraStor",
  LBL_VISUALIZATION_NO_DATA: "No data available.",
  LBL_VISUALIZATION_EXPORT_MULTIPLE: "Export all terastors",
  LBL_VISUALIZATION_EXPORT_MULTI_READY: "Your {0} files are ready to download!",
  BTN_VISUALIZATION_EXPORT_MULTI_SUCCEED: "All files have been exported",
  BTN_VISUALIZATION_EXPORT_MULTI_ALL: "Download all",
  BTN_VISUALIZATION_EXPORT_MULTI_CANCEL: "Cancel",
  BTN_VISUALIZATION_EXPORT_MULTI_CANCELING: "Canceling",
  BTN_VISUALIZATION_EXPORT_MULTI_CLOSE: "Close",
  BTN_VISUALIZATION_EXPORT_BLOCK_TITLE: "Exporting your file, please wait!",
  BTN_VISUALIZATION_EXPORT_BLOCK_DESCRIPTION:
    "Due to the overload data of sample mode, it takes more time to export your file.",
  LBL_FOOTER_NOTE: "StorView Cloud Portal",

  LBL_HEADER_WELCOME: "Welcome to StorView Cloud",

  // FW Update
  LBL_FW_UPDATE_BMS_MODAL: "BMS Software Update",
  LBL_FW_UPDATE_EMS_MODAL: "StorView Software Update",
  LBL_FW_UPDATE_SELECTED_SITES: "Selected Sites",
  LBL_FW_UPDATE_HEADER_VERSION: "Version",
  LBL_FW_UPDATE_HEADER_LAST_UPDATE: "Last Updated",
  LBL_FW_UPLOAD_FW: "Upload {0} Software",
  LBL_FW_UPDATE_BROWSE: "Browse",
  LBL_FW_UPDATE_UPLOAD: "Upload",
  LBL_FW_UPDATE_ALLOWED_TYPES: "Allowed file types: tar, tar.crypt, tar.gz, tar.gz.crypt, img.xz",
  LBL_FW_UPDATE_BROWSE_FILE: "Browse file",
  LBL_FW_UPDATE_STAGE: "Stage",
  LBL_FW_UPDATE_RELEASE_NOTES: "Release notes",
  LBL_FW_UPDATE_DONE: "Done",
  LBL_FW_UPDATE_LAST_UPDATE_ON: "Last update on :",
  LBL_FW_UPDATE_WHAT_NEW: "What's new",
  LBL_FW_UPDATE_BUG_FIXES: "Bug Fixes & stability improvements",
  LBL_FW_UPDATE_TEXTAREA_PLACEHOLDER: "Start writing ....",
  LBL_FW_UPDATE_LOADING: "Loading...",
  LBL_LOADING_SITES: "Loading assigned site list...",
  MSG_FW_UPDATE_UPLOAD_SUCCESSFULLY: "New {0} Software is successfully uploaded.",
  MSG_FW_UPDATE_STAGE_FW_SUCCESSFULLY: "New {0} Software is now available for selected sites.",
  MSG_FW_UPDATE_DELETE_FW_SUCCESSFULLY: "{0} Software {1} is deleted successfully!",
  MSG_FW_UPDATE_DELETE_FW_FAIL: "{0} Software {1} deletion failed. Please try again!",
  MSG_FW_UPDATE_DELETE_FW_CONFIRMATION:
    "Are you sure you want to delete <span class='text-primary'>{0} Software {1}</span>",
  MSG_FW_UPDATE_EDIT_NOTES: "Double-click to edit release notes",
  ERR_FW_UPDATE_STAGE_FW_FAILED: "Stage {0} Software failure. Please try again!",
  ERR_FW_UPDATE_GET_LIST_FAIL: "Get List Software Failure. Please try again!",
  ERR_FW_UPDATE_CHANGE_RELEASE_NOTES_FAIL: "Submit release notes failed. Please try again!",
  ERR_FW_UPDATE_UPLOAD_FAIL: "Upload Software failed. Please try again",
  ERR_FW_UPDATE_FILE_SIZE_EXCEEDED: "File size exceeded the limit of 1GB",

  LBL_UPLOAD_PROCESS_UPLOADING: "File uploading",
  LBL_UPLOAD_PROCESS_DOWNLOADING: "Downloading file",
  LBL_UPLOAD_PROCESS_DOWNLOAD_SUCCEED: "Download complete successfully.",

  LBL_SIGN_IN_BUTTON: "Sign in",
  LBL_SIGN_IN_EXPIRED_MESSAGE: "Your session has expired. Please sign in again to continue.",
  MSG_ELEVATION_VALID: "The valid range is from 0 to 9999",
  MSG_PHONE_NUMBER_VALID: "Phone number format is invalid",
  LBL_CUSTOMER_NAME: "Customer Name",
  LBL_SITE_NAME: "Site Name",
  LBL_ELEVATION_NAME: "Site Elevation",
  LBL_SITE_DESCRIPTION_NAME: "Site Description",
  LBL_SITE_GROUP_NAME: "Site Group",
  LBL_LATITUDE_NAME: "Latitude",
  LBL_ADDRESS_NAME: "Address",
  LBL_CITY_NAME: "City",
  LBL_LONGITUDE_NAME: "Longitude",
  LBL_STATE_PROVINCE_NAME: "State/Province",
  LBL_COUNTRY_NAME: "Country",
  LBL_POSTAL_CODE_NAME: "Postal Code",
  LBL_NUM_TERASTORS_NAME: "Number of TeraStors",
  LBL_TERASTOR_PART_NUMBER_NAME: "TeraStor Part Number",
  LBL_PO_NUMBER_NAME: "PO Number",
  LBL_COMMISSION_DATE_NAME: "Expected Commission Start Date",
  LBL_COMMISSION_DATE_PLACE_HOLDER_NAME: "Expected Commission Start Date",
  LBL_SHIPPING_DATE_NAME: "Expected Shipping Date",
  LBL_SHIPPING_DATE_PLACE_HOLDER_NAME: "Shipping Date",
  LBL_PO_DATE_NAME: "PO Date",
  LBL_PHONE_NUMBER_NAME: "Phone Number",
  LBL_SITE_MANAGER_NAME: "Site Manager Name",
  LBL_SITE_MANAGER_CREATE_TITLE: "Create site",
  LBL_SITE_MANAGER_EDIT_TITLE: "Edit site",
  LBL_SITE_MANAGER_SITE_ID: "Site ID",
  LBL_TENANT_SITE_ADMIN_NAME: "Tenant Site Admin",
  LBL_USER_NAME: "User",
  LBL_POINT_OF_CONTACT: "Point of contact",
  LBL_BUSINESS_POINT_OF_CONTACT: "Business point of contact",
  REQUIRED_QUESTION_MESSAGE: "Indicates required question",
  MSG_LATITUDE_VALID: "The valid range is from -90 to 90",
  MSG_LONGITUDE_VALID: "The valid range is from -180 to 180",

  MSG_LOADING: "Loading...",
  MSG_LOADING_USER: "Loading users...",
  MSG_LOADING_SITE_CONFIG: "Loading site configuration...",
  MSG_LOADING_SITE_MAP: "Loading site map...",

  VALIDATE_SITE_REQUIRED_FIELDS: new Map<keyof ModifySite, string>([
    ["customerName", "Customer Name is required"],
    ["siteName", "Site Name is required"],
    ["description", "Site Description is required"],
    ["address1", "Site Address is required"],
    ["city", "City is required"],
    ["stateProvince", "State/Province is required"],
    ["country", "Country is required"],
    ["postalCode", "Postal code is required"],
    ["numTeraStors", "Number of TeraStors is required"],
    ["teraStorPartNumber", "TeraStor Part Number is required"],
    ["poNumber", "PO Number is required"]
  ]),
  // Error messages for forgot password
  ERR_EMAIL_NOT_EXIST: "Email does not exist.",
  ERR_USER_NOT_EXIST: "User does not exist.",
  ERR_OTP_INCORRECT: "OTP is incorrect.",
  ERR_SECURITY_CODE_INCORRECT: "Your session has expired. Please start forget password again!",
  LABEL_CUSTOMER_NAME: "Customer name",
  LABEL_SUBSCRIPTION_TYPE: "Subscription type",
  LABEL_BUSINESS_ADDRESS: "Business Address",
  LABEL_CITY: "City",
  LABEL_STATE_PROVINCE: "State/Province",
  LABEL_ZIPCODE: "Zipcode",
  LABEL_COUNTRY: "Country",
  VALIDATE_CUSTOMER_REQUIRED_FIELDS: new Map<keyof CustomerEntity, string>([
    ["name", "Customer Name is required"],
    ["address", "Address is required"],
    ["city", "City is required"],
    ["zipCode", "Zip code is required"],
    ["stateProvince", "State/Province is required"],
    ["country", "Country is required"],
    ["subscriptionPlan", "Subscription Plan is required"]
  ]),
  ERR_COUNTRY_INVALID: "Country should contain only letters",

  // User management
  LBL_USER_MANAGEMENT_TITLE: "User Management",
  BTN_USER_MANAGEMENT_ADD_USER: "Add User",
  // Monitoring Search
  MSG_ITEMS_FOUND: "Items found",
  MSG_NO_ITEMS_FOUND: "No items found",

  // Document Center
  LBL_DOCUMENTS_All_DOCUMENTS: "All Documents",
  LBL_DOCUMENTS_UPLOAD_NEW_DOCUMENT: "Upload New Document",
  LBL_DOCUMENTS_ALLOWED_FILE_TYPE: "Allowed file type: zip only",
  LBL_DOCUMENTS_HEADER_FILE_NAME: "File name",
  LBL_DOCUMENTS_HEADER_DOCUMENT_CATEGORY: "Document Category",
  LBL_DOCUMENTS_HEADER_DOCUMENT_SITE: "Site",
  LBL_DOCUMENTS_HEADER_FILE_TYPE: "File type",
  LBL_DOCUMENTS_HEADER_LAST_UPDATED: "Last Updated",
  LBL_DOCUMENTS_HEADER_DESCRIPTION: "Description",
  MSG_DOCUMENTS_FETCHING: "Fetching the document list... Please wait",
  ERR_DOCUMENTS_UPLOAD_FAIL: "Upload document failed. Please try again",
  MSG_DOCUMENTS_UPLOAD_SUCCESSFULLY: "File is successfully uploaded to Site {0}.",
  MSG_DOCUMENTS_FETCHING_LIST: "Fetching the document list... Please wait",
  ERR_DOCUMENTS_GET_LIST_FAIL: "Get list document Failure. Please try again!",
  MSG_DOCUMENTS_DELETE_SUCCESSFULLY: "Document {0} is deleted successfully!",
  MSG_DOCUMENTS_DELETE_FAIL: "Document {0} deletion failed. Please try again!",
  MSG_DOCUMENTS_DELETE_CONFIRMATION:
    "Are you sure you want to delete this document <span class='text-primary'>{0}</span>? Any changes will not be saved",
  LBL_DOCUMENTS_EDIT_FILE: "Edit File",
  LBL_DOCUMENTS_FILE_DESCRIPTION: "File Description",
  LBL_DOCUMENTS_SITE: "Site",
  BTN_DOCUMENTS_UPDATE: "Update",
  ERR_DOCUMENTS_UPDATE_FAIL: "Update document failed. Please try again!",
  MSG_DOCUMENTS_UPDATING: "Updating document...",
  ERR_DOCUMENTS_DOWNLOAD_FAIL: "Donwload document failure. Please try again!",
  BTN_DOCUMENTS_DOWNLOAD_SELECTED: "Download Selected",
  ERR_DOCUMENTS_CATEGORY_GET_FAIL: "Get list category failure. Please try again!",
  MSG_DOCUMENTS_PREPARE_DOWNLOAD: "Preparing download...",
  LBL_LOADING_CATEGORIES: "Loading category list..."
}
