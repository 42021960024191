import { FWReleasesNoteModalProps } from "interfaces/FWReleasesNoteModalProps"
import { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Messages from "utils/messages"

import close from "../../assets/images/icons/admin/close.svg"
import { ReleaseNotes } from "interfaces/FWData"
import { RELEASE_NOTES_MAX_LENGTH } from "utils/constants"

const emptyReleaseNotes: ReleaseNotes = { whatNew: "", bugFix: "" }

const FWReleasesNoteModal = (props: FWReleasesNoteModalProps) => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNotes>(emptyReleaseNotes)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const date = new Date(props.fwData?.updatedAt || "")
  const formattedDate = props.fwData?.updatedAt
    ? `${date.getDate()} ${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()}`
    : ""

  useEffect(() => {
    if (props.fwData?.releaseNote) {
      const releaseNotes: ReleaseNotes = JSON.parse(props.fwData.releaseNote)
      if (!releaseNotes.whatNew && !releaseNotes.bugFix) {
        setIsSubmitted(false)
      } else {
        setIsSubmitted(true)
      }
      setReleaseNotes(releaseNotes)
    }
  }, [props.fwData?.releaseNote])

  const editReleaseNotes = () => {
    setIsSubmitted(false)
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      backdrop={"static"}
      id="fwReleasesNoteModal"
    >
      <ModalHeader>
        <p className="title">{Messages.LBL_FW_UPDATE_RELEASE_NOTES}</p>
        <button
          className="border-0 position-absolute bg-transparent"
          style={{ right: "31px", top: "15px", width: "25px" }}
          onClick={props.toggle}
        >
          <img src={close} alt="Close" role="button" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <p className="title-text">
              <span className="fw-500">{Messages.LBL_FW_UPDATE_HEADER_VERSION}</span>
              <span className="fw-700 emphasize ms-3">{props.fwData?.version}</span>
            </p>
            <p>
              {Messages.LBL_FW_UPDATE_LAST_UPDATE_ON}
              <span className="fw-500 ms-3">{formattedDate}</span>
            </p>
          </div>
          <p className="pt-2">{Messages.LBL_FW_UPDATE_WHAT_NEW}</p>
          {isSubmitted ? (
            <pre
              title={Messages.MSG_FW_UPDATE_EDIT_NOTES}
              onDoubleClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                editReleaseNotes()
              }}
            >
              {releaseNotes.whatNew}
            </pre>
          ) : (
            <>
              <textarea
                value={releaseNotes.whatNew}
                placeholder={Messages.LBL_FW_UPDATE_TEXTAREA_PLACEHOLDER}
                maxLength={RELEASE_NOTES_MAX_LENGTH}
                onChange={(e) => setReleaseNotes({ ...releaseNotes, whatNew: e.target.value })}
              />
              <p className="d-flex justify-content-end word-count">
                {releaseNotes.whatNew.length}/{RELEASE_NOTES_MAX_LENGTH} characters
              </p>
            </>
          )}
          <p className="pt-2">{Messages.LBL_FW_UPDATE_BUG_FIXES}</p>
          {isSubmitted ? (
            <pre
              title={Messages.MSG_FW_UPDATE_EDIT_NOTES}
              onDoubleClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                editReleaseNotes()
              }}
            >
              {releaseNotes.bugFix}
            </pre>
          ) : (
            <>
              <textarea
                value={releaseNotes.bugFix}
                placeholder={Messages.LBL_FW_UPDATE_TEXTAREA_PLACEHOLDER}
                maxLength={RELEASE_NOTES_MAX_LENGTH}
                onChange={(e) => setReleaseNotes({ ...releaseNotes, bugFix: e.target.value })}
              />
              <p className="d-flex justify-content-end word-count">
                {releaseNotes.bugFix.length}/{RELEASE_NOTES_MAX_LENGTH} characters
              </p>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button
          className="border-0"
          onClick={() => {
            props.toggle()
          }}
        >
          {Messages.BTN_SITE_SETUP_CANCEL}
        </Button>
        {isSubmitted ? (
          <Button
            color="primary"
            className="ms-3"
            onClick={() => {
              props.toggle()
            }}
          >
            {Messages.LBL_FW_UPDATE_DONE}
          </Button>
        ) : (
          <Button
            color="primary"
            className="ms-3"
            onClick={() => {
              if (props.fwData?.id) {
                props.onDone(releaseNotes, props.fwData)
                props.toggle()
              }
            }}
          >
            {Messages.BTN_SITE_SETUP_SUBMIT}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default FWReleasesNoteModal
