import "../../assets/scss/configuration/configuration.scss"
import "../../assets/scss/configuration/step-configuration.scss"
import { useEffect, useRef } from "react"
import { Button, Spinner, TabContent, TabPane } from "reactstrap"
import { useState } from "react"
import AlertModal from "../../components/modal/AlertModal"
import { AlertModalProps } from "../../interfaces/AlertModalProps"
import SiteInfoModal from "../site-management/SiteInfoModal"
import { SiteInfoModalProps } from "../../interfaces/SiteInfoModalProps"
import {
  apiGetSiteConfigurationBySiteUniqueId,
  apiModifySiteConfigurationBySiteUniqueId
} from "../../services/apiSiteConfiguration"
import { SiteConfiguration } from "../../interfaces/SiteConfiguration"
import NoteModal from "../../components/modal/NoteModal"
import { AdminNoteModalProps } from "../../interfaces/AdminNoteModalProps"
import StepLayout from "./StepLayout"
import StepReviewSubmit from "./StepReviewSubmit"
import { SITE_SETUP_STEP, USER_ROLES } from "utils/constants"
import ConfirmationModal from "components/modal/ConfirmationModal"
import {
  SaveConfigurationResponse,
  StepConfigurationProps,
  SubmitConfigurationHandle
} from "interfaces/StepConfigurationProps"
import StepConfigurationSite from "./StepConfiguration-Site"
import StepConfigurationTeraStor from "./StepConfiguration-TeraStor"
import Messages from "../../utils/messages"

import { AppDispatch } from "store/store"
import { useDispatch, useSelector } from "react-redux"
import { getSiteEntityList, setSiteEntity, setSiteEntityList } from "store/counter/monitoringSlice"
import { writeCodeLogEvent } from "../../utils/utils"
import { getCurrentUserState, getSidebarOpen } from "store/counter/authSlice"
import useNavigationBlocker from "hooks/useNavigationBlocker"
import { SiteEntity } from "interfaces/SiteEntity"
import { useLocation } from "react-router-dom"
import DocManagerSuperAdmin from "pages/document-center/DocManagerSuperAdmin"
import ConfigurationNavBar from "components/configuration/ConfigurationNavBar"

const Configuration = () => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const siteList = useSelector(getSiteEntityList)
  const currentUser = useSelector(getCurrentUserState)
  const isSidebarOpen = useSelector(getSidebarOpen)

  const stepConfigurationRef = useRef<SubmitConfigurationHandle>(null)
  const [activeStep, setActiveStep] = useState(SITE_SETUP_STEP.LAYOUT)
  const [isLoading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [siteConfiguration, setSiteConfiguration] = useState<SiteConfiguration>()
  const [lastSavedSiteConfiguration, setLastSavedSiteConfiguration] = useState<SiteConfiguration>()
  const [contentHeight, setContenHeight] = useState("auto")
  const [contentWidth, setContentWidth] = useState("auto")
  const [syncState, setSyncState] = useState(false)
  const [error, setError] = useState(Messages.LBL_SITE_SETUP_FETCHING)
  const [site, setSite] = useState<SiteEntity | null | undefined>()

  const layoutTabHasChange =
    JSON.stringify(siteConfiguration?.layout) !== JSON.stringify(lastSavedSiteConfiguration?.layout)
  const configurationTabHasChange =
    JSON.stringify(siteConfiguration?.network) !==
    JSON.stringify(lastSavedSiteConfiguration?.network)
  const hasUnsavedChanges =
    siteConfiguration !== undefined && (layoutTabHasChange || configurationTabHasChange)

  const blocker = useNavigationBlocker(hasUnsavedChanges)

  const [unsavedChangesModalProps, setUnsavedChangesModalProps] = useState<{
    isShow: boolean
    newStep: string | undefined
  }>({
    isShow: false,
    newStep: ""
  })

  const [reloadModalProps, setReloadModalProps] = useState<{
    isShow: boolean
  }>()

  const [alertModal, setAlertModal] = useState(false)
  const [alertStatus, setAlertStatus] = useState("loading")
  const alertToggle = () => setAlertModal(!alertModal)
  const alertModalProps: AlertModalProps = {
    size: "sm",
    modal: alertModal,
    toggle: alertToggle,
    status: alertStatus,
    message: error
  }

  const [siteInfoModal, setSiteInfoModal] = useState(false)
  const siteInfoModalProps: SiteInfoModalProps = {
    size: "lg",
    modal: siteInfoModal,
    toggle: () => setSiteInfoModal(!siteInfoModal),
    site: site,
    close: () => {
      setSiteInfoModal(false)
    }
  }

  const [adminNoteModal, setAdminNoteModal] = useState(false)
  const adminNoteModalProps: AdminNoteModalProps = {
    size: "md",
    modal: adminNoteModal,
    siteConfiguration: siteConfiguration,
    toggle: () => setAdminNoteModal(!adminNoteModal),
    close: () => {
      setAdminNoteModal(false)
    }
  }

  useEffect(() => {
    const onWrapperResize = () => {
      const contentWrapper = document.getElementById("contentWrapper")

      if (contentWrapper) {
        const heightOfEl = contentWrapper.offsetHeight
        setContenHeight(`${(3 * heightOfEl) / 4}px`)
        let contentWidth = contentWrapper.offsetWidth
        const sidebarWrapper = document.getElementById("sidebarArea")
        if (sidebarWrapper) {
          contentWidth = contentWrapper.offsetWidth - sidebarWrapper.offsetWidth
        }
        setContentWidth(`${contentWidth}px`)
      }
    }

    onWrapperResize()
    window.addEventListener("resize", onWrapperResize)
    return () => {
      window.removeEventListener("resize", onWrapperResize)
    }
  }, [isSidebarOpen])

  useEffect(() => {
    const alertUser = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault()
      }
    }

    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  })

  useEffect(() => {
    if (location.state?.site) {
      setSite(location.state.site)
      setLoading(true)
      setActiveStep(SITE_SETUP_STEP.LAYOUT)
      fetchSiteConfiguration(location.state.site.siteUniqueId)
    } else if (location.state) {
      setSite(null)
      setLoading(false)
    }
  }, [location.state, reload])

  const fetchSiteConfiguration = (siteUniqueId: number) => {
    apiGetSiteConfigurationBySiteUniqueId(siteUniqueId)
      .then((res) => {
        if (res.status) {
          setSiteConfiguration(res.data)
          setLastSavedSiteConfiguration(res.data)
          setSyncState(true)
        } else {
          setAlertStatus("error")
          setError(Messages.ERR_GET_CONFIGURATION)
          setAlertModal(true)
        }
      })
      .catch((e) => {
        console.error("Code exception: Fetch Site Configuration Error=>", e.message)
        setAlertStatus("error")
        setError(Messages.ERR_COMMON_SERVER_FAILED)
        setAlertModal(true)

        writeCodeLogEvent("handle fetch site configuration", e, siteUniqueId)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const saveConfigurationAsync = async (configuration: SiteConfiguration) => {
    if (configuration.siteId) {
      const res: SaveConfigurationResponse = await apiModifySiteConfigurationBySiteUniqueId(
        configuration?.siteId,
        {
          ...configuration,
          version: siteConfiguration?.version
        }
      )
      // Check the error on status
      const statusErr = res.errorLists?.find((err) => err.errorField === "version")
      if (statusErr) {
        setReloadModalProps({
          isShow: true
        })
        return undefined
      }
      if (res.status) {
        setSiteConfiguration(res.data)
        setLastSavedSiteConfiguration(res.data)
        dispatchUpdateSiteStatus(res.data)
        setSyncState(true)
      } else {
        setAlertStatus("error")
        setError(
          res.errorLists && res.errorLists.length > 0 ? res.errorLists[0].errorMsg : res.message
        )
        setAlertModal(true)
      }
      return res
    }
  }

  const dispatchUpdateSiteStatus = (configuration?: SiteConfiguration) => {
    if (configuration && configuration.status) {
      // Trigger update status sidebar menu
      const updatedListSite = siteList.map((site) => {
        if (site.siteUniqueId === configuration.siteId) {
          return {
            ...site,
            status: configuration.status
          }
        } else {
          return site
        }
      })
      dispatch(setSiteEntityList(updatedListSite))

      if (site && site.siteUniqueId === configuration.siteId) {
        dispatch(setSiteEntity({ ...site, status: configuration.status }))
      }
    }
  }

  const handleClickSiteInfo = () => {
    if (siteInfoModal === false) {
      setSiteInfoModal(true)
    }
  }

  const updateSiteConfigurationLocally = (
    configuration: SiteConfiguration,
    syncLastSave?: boolean
  ) => {
    setSiteConfiguration(configuration)
    if (syncLastSave && syncLastSave === true) {
      setLastSavedSiteConfiguration(configuration)
      dispatchUpdateSiteStatus(configuration)
    }
  }

  const onTabChange = (newStep: string) => {
    switch (activeStep) {
      case SITE_SETUP_STEP.LAYOUT:
      case SITE_SETUP_STEP.CONFIGURATION:
        if (hasUnsavedChanges) {
          setUnsavedChangesModalProps({
            isShow: true,
            newStep: newStep
          })
        } else {
          setActiveStep(newStep)
        }
        break
      default:
        setActiveStep(newStep)
        break
    }
  }

  const saveUnsavedChanges = async (newStep: string | undefined) => {
    setUnsavedChangesModalProps({
      isShow: false,
      newStep: ""
    })

    let processToNextStep = true
    if (activeStep === SITE_SETUP_STEP.CONFIGURATION) {
      // call validation and submit from child component
      processToNextStep =
        ((await stepConfigurationRef.current?.submitConfiguration()) as boolean) ?? false
    } else if (activeStep === SITE_SETUP_STEP.LAYOUT) {
      const newLayoutConfiguration: SiteConfiguration = {
        siteId: siteConfiguration?.siteId,
        layout: siteConfiguration?.layout
      }
      processToNextStep = (await saveConfigurationAsync(newLayoutConfiguration))?.status ?? false
    }

    if (processToNextStep) {
      if (newStep) {
        setActiveStep(newStep)
      } else if (blocker.state === "blocked") {
        blocker.proceed()
      }
    } else {
      // reset the blocker here
      if (blocker.state === "blocked" && blocker.reset) {
        blocker.reset()
      }
    }
  }

  const discardUnSavedChanges = (newStep: string | undefined) => {
    if (lastSavedSiteConfiguration) {
      setSiteConfiguration(lastSavedSiteConfiguration)
    }
    setSyncState(true)
    setUnsavedChangesModalProps({
      isShow: false,
      newStep: ""
    })
    if (newStep) {
      setActiveStep(newStep)
    } else if (blocker.state === "blocked") {
      blocker.proceed()
    }
  }

  if (isLoading) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center w-100 h-100"
        style={{ height: contentHeight }}
      >
        <Spinner color="primary" className="mb-3">
          {Messages.MSG_LOADING}
        </Spinner>
        <p className="fs-4">{Messages.MSG_LOADING_SITE_CONFIG}</p>
      </div>
    )
  }
  if (
    currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id &&
    site === null &&
    (siteList === null || siteList.length === 0)
  ) {
    return (
      <div id="configuration" className="w-100 h-100 p-4">
        <div className="info-wrapper d-flex flex-column align-items-center justify-content-center">
          <div className="info-content text-center d-flex flex-column align-items-center justify-content-center p-5">
            <p className="c-2 text-primary">{Messages.MSG_MONITORING_NO_SITE}</p>
          </div>
        </div>
      </div>
    )
  }

  if (!site) {
    return (
      <div id="configuration" className="w-100 h-100 p-4">
        <div className="info-wrapper d-flex flex-column align-items-center justify-content-center">
          <div className="info-content text-center d-flex flex-column align-items-center justify-content-center p-5">
            <p className="c-2 text-primary">{Messages.MSG_SITE_NOT_FOUND}</p>
          </div>
        </div>
      </div>
    )
  }

  const stepConfigurationProps: StepConfigurationProps = {
    viewOnly: false,
    configuration: siteConfiguration,
    site: site,
    setConfiguration: updateSiteConfigurationLocally,
    saveConfiguration: saveConfigurationAsync,
    contentHeight: contentHeight,
    contentWidth: contentWidth,
    syncState: syncState,
    setSyncState: setSyncState
  }

  return (
    <>
      <div
        id="configuration"
        className="p-4 overflow-x-hidden"
        style={{ width: `${contentWidth}` }}
      >
        {(unsavedChangesModalProps.isShow || blocker.state === "blocked") && (
          <ConfirmationModal
            data-testid="unsavedChangesModal"
            modal={true}
            size="md"
            cancelButtonText={Messages.BTN_SITE_SETUP_BACK}
            discardButtonText={Messages.BTN_SITE_SETUP_DISCARD}
            okButtonText={Messages.BTN_SITE_SETUP_SAVE}
            content={Messages.LBL_SITE_SETUP_UNSAVED_CHANGE_ALERT}
            toggle={() => {
              setUnsavedChangesModalProps({
                isShow: false,
                newStep: ""
              })
            }}
            onCancel={() => {
              if (blocker.state === "blocked" && blocker.reset) {
                blocker.reset()
              }
            }}
            onDiscard={() => discardUnSavedChanges(unsavedChangesModalProps.newStep)}
            onOK={async () => await saveUnsavedChanges(unsavedChangesModalProps.newStep)}
          />
        )}
        {reloadModalProps?.isShow && (
          <ConfirmationModal
            size="md"
            modal={true}
            cancelButtonText={Messages.BTN_SITE_SETUP_BACK}
            discardButtonText=""
            okButtonText={Messages.BTN_SITE_SETUP_RELOAD}
            content={Messages.MSG_SITE_SETUP_RELOAD}
            toggle={() =>
              setReloadModalProps({
                isShow: false
              })
            }
            onOK={() => {
              setReload((prevValue) => !prevValue)
            }}
          />
        )}
        {currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id && (
          <div id="siteInfo" className="text-center d-flex justify-content-between">
            <h4 className="title text-primary text-md-start">
              <span className="fw-bold">Site {site?.siteName}</span>
            </h4>
            <div id="btnWrapper">
              <Button
                data-testid="btnSiteDetails"
                color="primary"
                className="btn-actions me-0"
                onClick={handleClickSiteInfo}
              >
                <p className="site-info-text p-2 text-white">
                  {Messages.BTN_SITE_SETUP_VIEW_SITE_DETAILS}
                </p>
              </Button>
            </div>
          </div>
        )}
        <ConfigurationNavBar
          activeStep={activeStep}
          onTabChange={onTabChange}
          currentUserRole={currentUser?.role}
        />
        <div className="configuration-wrapper w-100 p-3">
          <TabContent activeTab={activeStep}>
            <TabPane tabId={SITE_SETUP_STEP.LAYOUT}>
              <StepLayout {...stepConfigurationProps} />
            </TabPane>
            <TabPane tabId={SITE_SETUP_STEP.CONFIGURATION}>
              <StepConfigurationSite {...stepConfigurationProps} ref={stepConfigurationRef} />
            </TabPane>
            <TabPane tabId={SITE_SETUP_STEP.COMMISSIONING}>
              <StepConfigurationTeraStor {...stepConfigurationProps} />
            </TabPane>
            <TabPane tabId={SITE_SETUP_STEP.REVIEW_SUBMIT}>
              <StepReviewSubmit {...stepConfigurationProps} />
            </TabPane>
            {currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id && (
              <TabPane tabId={SITE_SETUP_STEP.DOCUMENTS}>
                <DocManagerSuperAdmin site={site} />
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>
      {adminNoteModal && <NoteModal {...adminNoteModalProps} />}
      {siteInfoModal && <SiteInfoModal {...siteInfoModalProps} />}
      {alertModal && <AlertModal {...alertModalProps} />}
    </>
  )
}

export default Configuration
