import { Outlet, useRouteError, Navigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { Container } from "reactstrap"
import { useState, useEffect, lazy } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AppDispatch } from "store/store"

import "../assets/scss/admin.scss"

import { ALL_SITES_MANAGEMENT, USER_ROLES } from "../utils/constants"
import { writeCrashedLogEvent } from "../utils/utils"

import Sidebar from "./Sidebar"
import Header from "./Header"

import { CONFIGURATION_REVIEW, CUSTOMER_MANAGEMENT, AESI_ADMIN_ROUTE } from "../utils/constants"

import imgError from "../assets/images/icons/error.png"

import {
  getIdTokenState,
  getCurrentUserState,
  isSigninExpired,
  getSidebarOpen
} from "../store/counter/authSlice"

const ExpiredModal = lazy(() => import("../components/modal/ExpiredModal"))

import { apiAdminListAllSites } from "../services/apiSitesManagement"

import { setSiteEntityList, setIsFetchingSiteList } from "../store/counter/monitoringSlice"

const TenantLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError()
  const location = useLocation()
  const page = location.pathname.split("/")[2]
  const sidebarOpen = useSelector(getSidebarOpen)
  const contentWrapperClass = sidebarOpen
    ? "p-0 d-flex flex-row bd-highlight show"
    : "p-0 d-flex flex-row bd-highlight collapsed"

  const [expiredModal, setExpiredModal] = useState(false)
  useEffect(() => {
    // Signin expired
    const handleStorageChange = () => setExpiredModal(isSigninExpired())
    // Listen for changes in sessionStorage
    window.addEventListener("storage", handleStorageChange)

    // fetching list sites
    fetchingListSite()

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  const dispatch = useDispatch<AppDispatch>()
  const fetchingListSite = async () => {
    dispatch(setIsFetchingSiteList(true))
    const res = await apiAdminListAllSites()

    if (res.status && res.data && res.data.length > 0) {
      dispatch(setSiteEntityList(res.data))
    }
    dispatch(setIsFetchingSiteList(false))
  }

  useEffect(() => {
    if (error) {
      console.error("Layout exception: app crash =>", error.statusText || error.message)
      writeCrashedLogEvent("", error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const idToken = useSelector(getIdTokenState)
  const currentUser = useSelector(getCurrentUserState)
  if (!idToken || !currentUser) {
    return <Navigate to="/auth/signin" />
  } else if (currentUser.role === USER_ROLES.AESI_SUPER_ADMIN.id) {
    return <Navigate to={`${AESI_ADMIN_ROUTE}/customer-management`} />
  } else
    return (
      <>
        {/********header**********/}
        <Header />
        {/********Middle Content**********/}
        <Container id="contentWrapper" className={contentWrapperClass} fluid>
          {!error ? (
            <>
              {/********Sidebar**********/}
              {/* Hide sidebar in customer management page */}
              {page !== CUSTOMER_MANAGEMENT &&
                page !== CONFIGURATION_REVIEW &&
                page !== ALL_SITES_MANAGEMENT && <Sidebar />}
              <Outlet />
            </>
          ) : (
            <div id="errorPage" className="d-flex justify-content-center align-self-center w-100">
              <div className="text-center align-self-center">
                <img src={imgError} alt="error" width={100} className="mb-2"></img>
                <h1 className="text-danger">Oops!</h1>
                <h4 className="text-danger mb-4">Something went wrong. Please try again.</h4>
              </div>
            </div>
          )}
        </Container>
        <ExpiredModal modal={expiredModal} />
      </>
    )
}

export default TenantLayout
