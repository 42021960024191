import Cookies from "universal-cookie"
import type { AuthenticatedUser } from "../interfaces/AuthenticatedUser"
import { COOKIE_NAME } from "./constants"

const isLocalEnv = process.env.REACT_APP_ENV === "local"

const cookies = new Cookies(null, {
  path: "/",
  secure: !isLocalEnv, // https only
  sameSite: "strict"
})

const setLoggedCookie = (
  name: string,
  data: AuthenticatedUser | string | null,
  expiredTime: number
) => {
  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + expiredTime)
  cookies.set(name, data, { expires: expirationDate })
}

export const setIdTokenCookie = (idToken: string | null) => {
  const expiredTime = process.env.REACT_APP_ACCESS_TOKEN_EXPIRED
    ? parseInt(process.env.REACT_APP_ACCESS_TOKEN_EXPIRED)
    : 3600000
  setLoggedCookie(COOKIE_NAME.ID_TOKEN, idToken, expiredTime)
}

export const setRefreshTokenCookie = (refreshToken: string | null) => {
  const expiredTime = process.env.REACT_APP_REFRESH_TOKEN_EXPIRED
    ? parseInt(process.env.REACT_APP_REFRESH_TOKEN_EXPIRED)
    : 2592000000
  setLoggedCookie(COOKIE_NAME.REFRESH_TOKEN, refreshToken, expiredTime)
}

export default cookies
