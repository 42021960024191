import { InputFieldProps } from "interfaces/InputFieldProps"
import { useState } from "react"
import { Label, Input, Tooltip } from "reactstrap"

const InputField = (props: InputFieldProps) => {
  const {
    id,
    label,
    placeholder,
    value,
    onChange,
    disabled,
    tabIndex,
    labelColWidth = 3,
    totalColWidth = 12,
    col: col,
    type = "text",
    invalid,
    invalidTooltip,
    required,
    options
  } = props
  const [isTabActive, setIsTabActive] = useState(false)

  return (
    <div className={`${col ? "col" : "row"} mb-2 ${totalColWidth}`}>
      <Label for={id} className={`col-${labelColWidth} col-form-label text-light-gray`}>
        {label}
      </Label>
      <div className={`col-${totalColWidth - labelColWidth}`}>
        <Input
          onFocus={() => setIsTabActive(true)}
          onBlur={() => setIsTabActive(false)}
          onMouseEnter={() => setIsTabActive(true)}
          onMouseLeave={() => setIsTabActive(false)}
          className="form-control low-opacity-placeholder f-14"
          disabled={disabled}
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          tabIndex={tabIndex}
          itemType={type}
          onKeyDown={(event) => {
            if (type === "number") {
              validateNumberOnlyKeyDown(event)
            }
          }}
          invalid={invalid}
          required={required}
        >
          {options?.map((option, index) => (
            <option value={option.value} key={index} className="f-14">
              {option.label}
            </option>
          ))}
        </Input>
        <Tooltip placement="bottom" isOpen={invalid && isTabActive} target={id}>
          <ul className="mb-0">
            {invalidTooltip?.map((tooltip, index) => <li key={index}>{tooltip}</li>)}
          </ul>
        </Tooltip>
      </div>
    </div>
  )
}

export const validateNumberOnlyKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Allow select all key (Ctrl+A or Command+A)
  if (
    ((event.ctrlKey || event.metaKey) && event.key === "a") ||
    event.key === "v" ||
    event.key === "c"
  ) {
    return
  }

  // Allow left and right arrow keys, backspace key and tab key
  if (
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Backspace" ||
    event.key === "Tab" ||
    event.key === "Delete" ||
    event.key === "Home" ||
    event.key === "End" ||
    (event.key === "z" && event.ctrlKey)
  ) {
    return
  }

  // Allow only numbers
  if (!(event.key >= "0" && event.key <= "9")) {
    event.preventDefault()
  }
}

export default InputField
