import React, { useState } from "react"
import { Button, Modal, ModalBody, Row, Col, Spinner } from "reactstrap"
import warning from "../../assets/images/configuration/warning-light.svg"
import Messages from "../../utils/messages"
import { ModalProps } from "interfaces/ModalProps"

interface ConfirmationModalProps extends ModalProps {
  content: string
  okButtonText?: string
  cancelButtonText?: string
  discardButtonText?: string
  onOK: () => void
  onCancel?: () => void
  onDiscard?: () => void
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    modal,
    content,
    okButtonText,
    cancelButtonText,
    discardButtonText,
    onOK,
    onDiscard,
    toggle
  } = props
  const [isProcessing, setIsProcessing] = useState(false)

  if (!modal) return null

  return (
    <Modal
      className="modal-overlay"
      isOpen={modal}
      backdrop="static"
      toggle={toggle}
      centered={true}
    >
      <ModalBody className="bg-white rounded p-3 pt-5">
        <Row>
          <Col md="2">
            <img src={warning} alt="icon"></img>
          </Col>
          <Col md="10">
            <p>{content}</p>
            <br></br>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: "#F1F1F1",
            marginBottom: "-15px",
            padding: "15px"
          }}
        >
          <Col className="d-flex justify-content-end">
            <Button className="modal-btn-transparent me-3" onClick={toggle} disabled={isProcessing}>
              {cancelButtonText ? cancelButtonText : Messages.BTN_SITE_SETUP_CANCEL}
            </Button>
            {discardButtonText && (
              <Button
                className="modal-btn-transparent me-3"
                onClick={() => {
                  if (onDiscard) onDiscard()
                  toggle()
                }}
                disabled={isProcessing}
              >
                {discardButtonText}
              </Button>
            )}
            {okButtonText && (
              <Button
                className="modal-btn-color"
                onClick={() => {
                  setIsProcessing(true)
                  onOK()
                  toggle()
                }}
                disabled={isProcessing}
              >
                {isProcessing && <Spinner color="primary" size="sm" className="me-1"></Spinner>}
                {okButtonText}
              </Button>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
