import { configureStore, combineReducers } from "@reduxjs/toolkit"
import authReducer from "./counter/authSlice"
import monitoringReducer from "./counter/monitoringSlice"
import visualizationReducer from "./counter/visualizationSlice"
import docReducer from "./counter/docSlice"
import logoutMiddleware from "./counter/logoutMiddleware"

const rootReducer = combineReducers({
  auth: authReducer,
  monitoring: monitoringReducer,
  visualization: visualizationReducer,
  doc: docReducer
})
export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logoutMiddleware)
  })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"]
