import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Nav, NavItem, Spinner } from "reactstrap"

import { ChildSidebarProps } from "../../interfaces/ChildSidebarPops"
import { SiteEntity } from "../../interfaces/SiteEntity"
import Messages from "../../utils/messages"

import { Tooltip as ReactTooltip } from "react-tooltip"

import siteActive from "../../assets/images/monitoring/img/site-monitoring-active.svg"
import siteNormal from "../../assets/images/monitoring/img/site-monitoring.svg"

import { AppDispatch } from "store/store"
import { apiAdminListAllSites } from "../../services/apiSitesManagement"
import { getSidebarOpen } from "../../store/counter/authSlice"
import { getSiteStatusAsString, getSiteStatusCSS } from "utils/utils"
import {
  getSiteEntityList,
  setSiteEntityList,
  getIsFetchingSiteList,
  setIsFetchingSiteList,
  setSiteEntity
} from "../../store/counter/monitoringSlice"
import { apiMonitoringDataConnectedCheck } from "../../services/apiSiteMonitoring"
import { SITE_STATUS } from "../../utils/constants"
import { SiteEntityMonitoring } from "../../interfaces/SiteEntityMonitoring"

const ConfigurationSideBar = (props: ChildSidebarProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const siteList = useSelector(getSiteEntityList)
  const sideBarOpen = useSelector(getSidebarOpen)
  const isFetchingSiteList = useSelector(getIsFetchingSiteList)

  const [isLoading, setLoading] = useState(true)
  const [dataConnected, setDataConnected] = useState([])

  const siteUniqueId = !isNaN(+props.getPath(2)) ? parseInt(props.getPath(2)) : 0

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    setLoading(true)
    dispatch(setIsFetchingSiteList(true))
    const res = await apiAdminListAllSites()

    if (res.status && res.data) {
      // get monitoring data connected check
      const dataConnectedRes = await apiMonitoringDataConnectedCheck()
      if (dataConnectedRes.status) {
        setDataConnected(dataConnectedRes.data ?? [])
      }
      dispatch(setSiteEntityList(res.data))
    } else {
      dispatch(setSiteEntityList([]))
    }
    setLoading(false)
    dispatch(setIsFetchingSiteList(false))
  }

  useEffect(() => {
    if (!isFetchingSiteList) {
      selectDefaultSite()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingSiteList])

  const selectDefaultSite = () => {
    // Set default first site
    if (siteList && siteList.length > 0) {
      let siteIdParam = props.getPath(2)
      let siteEntity: SiteEntity | undefined = siteList[0]
      if (siteIdParam) {
        siteEntity = siteList?.find((site) => site.siteUniqueId.toString() === siteIdParam)
      } else {
        siteIdParam = siteList[0].siteUniqueId.toString()
      }
      dispatch(setSiteEntity(siteEntity))
      // Set site null when user does not have access to the site
      navigate(`/site-setup/${siteIdParam}`, { state: { site: siteEntity || null } })
    } else {
      dispatch(setSiteEntity(null))
      navigate(`/site-setup/`, { state: { site: null } })
    }
  }

  const onSiteSelected = (siteEntity: SiteEntity | undefined) => {
    if (siteEntity && siteUniqueId !== siteEntity.siteUniqueId) {
      dispatch(setSiteEntity(siteEntity))
    }
  }

  function getSiteStatusCSSWrapper(site: SiteEntityMonitoring): string | undefined {
    return `badge d-inline-block ${site.status === SITE_STATUS.READY_FOR_INSTALLATION && dataConnected[site.siteUniqueId] ? getSiteStatusCSS(SITE_STATUS.CONNECTED) : getSiteStatusCSS(site.status)}`
  }

  function getSiteStatusAsStringWrapper(site: SiteEntityMonitoring) {
    return site.status === SITE_STATUS.READY_FOR_INSTALLATION && dataConnected[site.siteUniqueId]
      ? getSiteStatusAsString(SITE_STATUS.CONNECTED)
      : getSiteStatusAsString(site.status)
  }

  const isSiteSelected = (siteUniqueId: string) => {
    const param = props.getPath(2)
    if (!param) return false
    return param.toString() === `${siteUniqueId}`
  }

  const getCutLength = (site: SiteEntity) => {
    if (
      [
        "layout-pending-approval",
        "configuration-in-progress",
        "pending-final-approval",
        "ready-for-installation",
        "connected"
      ].includes(getSiteStatusCSS(site.status) || "")
    )
      return 6
    else return 9
  }

  return (
    <Nav
      vertical
      className="sidebarNav site-setup"
      style={{ display: `${sideBarOpen ? "block" : "none"}` }}
    >
      {isLoading && (
        <div className="align-items-center text-center">
          <Spinner color="primary" className="mb-3">
            Loading...
          </Spinner>
          <p className="mb-0">{Messages.LBL_LOADING_SITES}</p>
        </div>
      )}
      {!isLoading &&
        siteList?.map((site, index) => (
          <NavItem key={index}>
            <Link
              to={`/site-setup/` + site.siteUniqueId}
              state={{
                site: site
              }}
              className={
                isSiteSelected(`${site.siteUniqueId}`)
                  ? "active nav-link py-2 justify-content-between"
                  : "nav-link py-2 justify-content-between"
              }
              onClick={() => onSiteSelected(site)}
            >
              <div className="position-relative">
                <img
                  className="mb-1"
                  src={isSiteSelected(`${site.siteUniqueId}`) ? siteActive : siteNormal}
                  alt={site.siteName}
                ></img>
                <span className={`ms-2 d-inline-block`} data-tooltip-id={`${site.siteUniqueId}`}>
                  Site{" "}
                  {site.siteName.length > getCutLength(site)
                    ? `${site.siteName.substring(0, getCutLength(site) - 2)}...`
                    : site.siteName}
                </span>
                {site.status && (
                  <span className={getSiteStatusCSSWrapper(site)}>
                    {getSiteStatusAsStringWrapper(site)}
                  </span>
                )}

                {site.siteName.length > getCutLength(site) && (
                  <ReactTooltip
                    id={`${site.siteUniqueId}`}
                    place="bottom"
                    content={site.siteName}
                  />
                )}
              </div>
            </Link>
          </NavItem>
        ))}
    </Nav>
  )
}

export default ConfigurationSideBar
