import { AdminCreateCustomer } from "../interfaces/AdminCreateCustomer"
import { AdminUpdateCustomer } from "../interfaces/AdminUpdateCustomer"
import { CustomerEntity } from "../interfaces/CustomerEntity"
import { ErrorItem } from "../interfaces/ErrorItem"
import { PageInfo } from "../interfaces/PageInfo"

import api from "../utils/axios"
import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"

export const apiAdminListCustomers = async (
  pageNumber: number,
  sortString: string,
  includeDelete = true,
  searchText: string
) => {
  try {
    const response = await api.get(
      `api/customers?page=${pageNumber}&size=${process.env.REACT_APP_PAGE_SIZE}${sortString}&includeDelete=${includeDelete}${searchText ? `&name=${searchText}` : ""}`
    )
    return {
      status: response.status === 200,
      data: response.data.data as CustomerEntity[],
      pageInfo: response.data.pageInfo as PageInfo
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list customer =>", e.message)

    writeApiLogEvent("get list customer", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_CUSTOMER_GET_LIST_FAILED)
    }
  }
}

export const apiAdminGetCustomerInfo = async (customerUniqueID: number) => {
  try {
    const response = await api.get(`api/customers/${customerUniqueID}`)
    return {
      status: response.status === 200,
      data: response.data as CustomerEntity
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get customer =>", e.message)

    writeApiLogEvent("get customer", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_CUSTOMER_GET_INFO_FAILED)
    }
  }
}

export const apiAdminModifyCustomer = async (
  body: AdminCreateCustomer | AdminUpdateCustomer,
  customerUniqueID?: number
) => {
  try {
    let response
    if (customerUniqueID) response = await api.put(`api/customers/${customerUniqueID}`, body)
    else response = await api.post(`api/customers`, body)

    const data = response.data

    return {
      status: [200, 201].includes(response.status),
      errorLists: data.errorLists ? data.errorLists.map((obj: ErrorItem) => obj.errorMsg) : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: modify customer =>", e.message)

    writeApiLogEvent("modify customer", e)

    return {
      status: false,
      message: responseErrorMessage(
        e.code,
        customerUniqueID ? Message.ERR_CUSTOMER_MODIFY_FAILED : Message.ERR_CUSTOMER_CREATE_FAILED
      )
    }
  }
}

export const apiAdminDeleteCustomer = async (customerUniqueID: number) => {
  try {
    const response = await api.delete(`api/customers/${customerUniqueID}`)

    const status = response.status === 400 ? false : true
    const data = response.data
    let message = Message.MSG_CUSTOMER_DELETE_SUCCESS
    if (!status) {
      if (data.isProvisionFailure) message = data.msg
      else message = Message.ERR_CUSTOMER_DELETE_FAILED_ACTIVE_SITES
    }

    return {
      status,
      message
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: delete customer =>", e.message)

    writeApiLogEvent("delete customer", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_CUSTOMER_DELETE_FAILED)
    }
  }
}
