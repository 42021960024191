import FlatButton from "components/button/FlatButton"
import { ExpandableWidthCardProps } from "interfaces/ExpandableWidthCardProps"

import close from "../../assets/images/icons/x-close-gray.svg"

const ExpandableWidthCard = (props: ExpandableWidthCardProps) => {
  return (
    <div className="card mb-3">
      <div className="d-flex flex-row">
        <p className="card-header ps-0">Customer {props.customerUniqueId}</p>
        <button className="px-1" onClick={() => props.removeGroupCustomer(props.customerUniqueId)}>
          <img src={close} alt="Button Image" />
        </button>
      </div>
      <div className="grid-container">
        {props.siteList.map((site) => (
          <FlatButton
            key={site.siteUniqueId}
            label={site.siteName}
            buttonAction={() => {
              props.removeSite(props.customerUniqueId, site.siteUniqueId)
            }}
            buttonImage={close}
          />
        ))}
      </div>
    </div>
  )
}

export default ExpandableWidthCard
