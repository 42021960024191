import { AxiosProgressEvent } from "axios"
import api from "../utils/axios"
import { SiteConfiguration } from "../interfaces/SiteConfiguration"
import { ErrorItem } from "../interfaces/ErrorItem"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import Message from "../utils/messages"
import { getCurrentUser } from "store/counter/authSlice"

export const apiResourceUpload = async (
  object: string,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const response = await api.put(
      `api/resources/${process.env.REACT_APP_SITE_SETUP_BUCKET}/${encodeURIComponent(object)}`,
      file,
      {
        onUploadProgress: OnUploadProgress,
        headers: {
          "Content-Type": `${file.type}`
        }
      }
    )
    return {
      status: response.status,
      message: response.statusText,
      imageUrl: response.request.responseURL
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup upload resource =>", e.message)

    writeApiLogEvent("site setup upload resource", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiResourceDownload = async (object: string) => {
  try {
    const response = await api.get(
      `api/resources/${process.env.REACT_APP_SITE_SETUP_BUCKET}/${encodeURIComponent(object)}`,
      {
        responseType: "text",
        responseEncoding: "base64"
      }
    )
    return {
      status: response.status,
      message: response.statusText,
      data: response.data,
      contentType: response.headers["content-type"]
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup download resource =>", e.message)

    writeApiLogEvent("site setup download resource", e)

    return {
      status: e.response.status,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiCreateSiteConfiguration = async (configuration: SiteConfiguration) => {
  try {
    const response = await api.post(`api/site-configuration`, configuration)
    return {
      status: response.status === 200,
      data: response.data,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup create site =>", e.message)

    writeApiLogEvent("site setup create site", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiModifySiteConfiguration = async (id: number, configuration: SiteConfiguration) => {
  try {
    const response = await api.put(`api/site-configuration/${id}`, configuration)
    return {
      status: response.status === 200,
      data: response.data,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `[${obj.errorField}] ${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup modify site =>", e.message)

    writeApiLogEvent("site setup modify site", e, id)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiGetAllSiteConfigurations = async () => {
  try {
    const response = await api.get(`api/site-configuration`)
    return {
      status: response.status === 200,
      data: response.data.data,
      pageInfo: response.data.pageInfo,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `[${obj.errorField}] ${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup get all sites =>", e.message)

    writeApiLogEvent("site setup get all sites", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiGetSiteConfiguration = async (id: number) => {
  try {
    const response = await api.get(`api/site-configuration/${id}`)
    return {
      status: response.status === 200,
      data: response.data,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `[${obj.errorField}] ${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup get site =>", e.message)

    writeApiLogEvent("site setup get site", e, id)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiGetSiteConfigurationBySiteUniqueId = async (siteUniqueId: number) => {
  try {
    const response = await api.get(`api/site-configuration/site/${siteUniqueId}`)
    return {
      status: response.status,
      data: response.data,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup get site by siteUniqueId =>", e.message)

    writeApiLogEvent("site setup get site by siteUniqueId", e, siteUniqueId)

    return {
      status: e.response.status,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiModifySiteConfigurationBySiteUniqueId = async (
  siteUniqueId: number,
  configuration: SiteConfiguration
) => {
  try {
    const response = await api.patch(`api/site-configuration/site/${siteUniqueId}`, configuration)
    return {
      status: response.status === 200,
      data: response.data,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists || []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup modify site by siteUniqueId =>", e.message)

    writeApiLogEvent("site setup modify site by siteUniqueId", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiGenerateConfig = async (siteUniqueId: number) => {
  try {
    const response = await api.post(`api/site-configuration/generate-config/${siteUniqueId}`)
    return {
      status: response.status === 200,
      message: response.statusText || response.data.msg || Message.ERR_COMMON_SERVER_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup generate config =>", e.message)

    writeApiLogEvent("site setup generate config", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_EXPORT_CONFIG_FAILED)
    }
  }
}

export const apiDownloadSiteConfigurationPackageBySiteUniqueId = async (
  siteUniqueId: number,
  customerUniqueId?: number
) => {
  try {
    const response = await api.get(`api/site-configuration/site/${siteUniqueId}/config`, {
      params: { customerId: customerUniqueId || getCurrentUser().customerUniqueId },
      responseType: "arraybuffer"
    })
    return {
      status: response.status === 200,
      data: response.data,
      message: response.statusText || response.data.msg || Message.ERR_DOWNLOAD_CONFIG_ZIP_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup download package by siteUniqueId =>", e.message)

    writeApiLogEvent("site setup download package by siteUniqueId", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOWNLOAD_CONFIG_ZIP_FAILED)
    }
  }
}

export const apiCheckSiteConfigurationPackageBySiteUniqueId = async (
  siteUniqueId: number,
  customerUniqueId?: number
) => {
  try {
    const response = await api.get(`api/site-configuration/site/${siteUniqueId}/config-check`, {
      params: { customerId: customerUniqueId || getCurrentUser().customerUniqueId }
    })
    return {
      status: response.status === 200,
      isAvailable: response.data === true,
      message: response.statusText || response.data.msg || Message.ERR_DOWNLOAD_CONFIG_ZIP_FAILED,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => `${obj.errorMsg}`)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: site setup check package by siteUniqueId =>", e.message)

    writeApiLogEvent("site setup check package by siteUniqueId", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_DOWNLOAD_CONFIG_ZIP_FAILED)
    }
  }
}
