import api from "../utils/axios"
import Message from "../utils/messages"
import { calculateFileChecksum, responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import { FWType } from "interfaces/FWUploadTableProps"
import { FWData } from "interfaces/FWData"
import axios, { AxiosProgressEvent, AxiosResponse } from "axios"
import { ErrorItem } from "interfaces/ErrorItem"

interface MultipartUploadParts {
  fileName: string
  partNumber: number
  uploadId?: string
  multipartUploads: MultipartUpload[]
  checksum?: string | null
}
interface MultipartUpload {
  url: string
  partNumber: number
  etag?: string
}

interface UploadRequestDTO {
  fileName: string
  checksum?: string | null
}

const fwRoute = "/api/firmware/"

export const apiGetFirmware = async (type: FWType) => {
  try {
    const response = await api.get(`${fwRoute}${type}`)

    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_FW_UPDATE_GET_LIST_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list fw =>", e.message)

    writeApiLogEvent("get list fw", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_GET_LIST_FAIL)
    }
  }
}

export const apiUpdateFirmware = async (type: FWType, fwData: FWData) => {
  try {
    const response = await api.put(`${fwRoute}${type}`, fwData)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_FW_UPDATE_CHANGE_RELEASE_NOTES_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: update release notes =>", e.message)

    writeApiLogEvent("update release notes", e)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_CHANGE_RELEASE_NOTES_FAIL)
    }
  }
}

export const apiDeleteFirmware = async (type: FWType, id: number) => {
  try {
    const response = await api.delete(`${fwRoute}${type}/${id}`)
    return {
      status: response.status === 204 || response.status === 200
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: delete firmware =>", e.message)

    writeApiLogEvent("delete firmware", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const apiUploadFirmware = async (
  type: FWType,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    if (file.size > 1024 * 1024 * 64) {
      // > 30MB in size
      return await apiMultiPartUpload(type, file, OnUploadProgress)
    } else {
      return await apiSinglePartUpload(type, file, OnUploadProgress)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: upload firmware =>", e.message)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const apiSinglePartUpload = async (
  type: FWType,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const checksum = await calculateFileChecksum(file)
    let response = await apiGetSingleUploadUrl(type, file.name)
    if (!response.status) {
      throw new Error(response.errorLists)
    }

    const url = response.data.url
    response = await apiSingleUpload(url, file, OnUploadProgress)
    if (!response.status) {
      throw new Error(response.message)
    }

    const requestDTO: UploadRequestDTO = {
      fileName: file.name,
      checksum: checksum
    }
    response = await apiStoreSingleUpload(type, requestDTO)

    return {
      status: response.status,
      data: response.data,
      message: Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: response.errorLists || []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: single part upload =>", e.message)
    return {
      status: false,
      message: e.message || responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const apiGetSingleUploadUrl = async (type: FWType, fileName: string) => {
  try {
    const response = await api.post(`${fwRoute}${type}/${fileName}/single-upload`)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get single upload url =>", e.message)

    writeApiLogEvent("get single upload url", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const apiSingleUpload = async (
  url: string,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const formData = new FormData()
    formData.append("file", file)
    const response = await axios.put(url, file, {
      onUploadProgress: OnUploadProgress,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 600000 // 10 minutes in milliseconds
    })
    return {
      status: response.status === 200,
      data: getEtag(response),
      message: Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: single upload to s3 =>", e.message)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const apiStoreSingleUpload = async (type: FWType, uploadRequest: UploadRequestDTO) => {
  try {
    const response = await api.post(`${fwRoute}${type}/store`, uploadRequest)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: store file =>", e.message)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const uploadPart = async (
  url: string,
  part: Blob,
  partNumber: number,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const res = await axios.put(url, part, {
      onUploadProgress: OnUploadProgress
    })
    return { partNumber: partNumber, etag: getEtag(res) }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: upload part =>", e.message)
    return ""
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const apiMultiPartUpload = async (
  type: FWType,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const partNum = parseInt(process.env.REACT_APP_UPLOAD_PARTS || "10")
    const checksum = await calculateFileChecksum(file)
    const requestDTO: MultipartUploadParts = {
      fileName: file.name,
      partNumber: partNum,
      multipartUploads: []
    }
    let response = await apiGetMultipartUploadUrl(type, requestDTO)
    if (!response.status) {
      throw new Error(response.errorLists)
    }
    const ultipartUploadParts: MultipartUploadParts = response.data
    response = await apiMultiUpload(ultipartUploadParts, file, OnUploadProgress)
    if (!response.status) {
      throw new Error(Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
    const finalMultipartRequest: MultipartUploadParts = {
      ...ultipartUploadParts,
      multipartUploads: ultipartUploadParts.multipartUploads.map((part) => {
        const etag = response.data.find(
          (item: { partNumber: number; etag: string }) => item.partNumber === part.partNumber
        )
        part.etag = etag?.etag
        return part
      }),
      checksum: checksum
    }
    response = await apiCompleteMultipartUpload(type, finalMultipartRequest)

    return {
      status: response.status,
      data: response.data,
      message: response.message ?? Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: response.errorLists
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: multipart upload =>", e.message)
    return {
      status: false,
      message: e.message || responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const apiGetMultipartUploadUrl = async (type: FWType, uploadRequest: MultipartUploadParts) => {
  try {
    const response = await api.post(`${fwRoute}${type}/multipart-upload`, uploadRequest)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get multipart urls =>", e.message)
    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

const apiMultiUpload = async (
  multipartUploadParts: MultipartUploadParts,
  file: File,
  OnUploadProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const partSize = Math.ceil(file.size / multipartUploadParts.multipartUploads.length)
    const uploadedBytes = Array.from(
      { length: multipartUploadParts.multipartUploads.length },
      () => 0
    )

    const uploadPromises = multipartUploadParts.multipartUploads.map((partUpload, i) => {
      const start = i * partSize
      const end = start + partSize
      const part = file.slice(start, end)
      return uploadPart(partUpload.url, part, partUpload.partNumber, (progressEvent) => {
        uploadedBytes[i] = progressEvent.loaded
        const overallProgressEvent = {
          ...progressEvent,
          loaded: uploadedBytes.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ),
          total: file.size
        }

        OnUploadProgress(overallProgressEvent)
      })
    })
    const response = await Promise.all(uploadPromises)

    return {
      status: true,
      data: response,
      message: "",
      errorLists: []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: multipart upload =>", e.message)
    return {
      status: false,
      data: [],
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

const apiCompleteMultipartUpload = async (type: FWType, multipartUploads: MultipartUploadParts) => {
  try {
    const response = await api.put(`${fwRoute}${type}/complete-upload`, multipartUploads)
    return {
      status: response.status === 200,
      data: response.data,
      message: Message.ERR_FW_UPDATE_UPLOAD_FAIL,
      errorLists: response.data.errorLists
        ? response.data.errorLists.map((obj: ErrorItem) => obj.errorMsg)
        : []
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: complete multipart upload =>", e.message)

    writeApiLogEvent("complete multipart upload", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_UPLOAD_FAIL)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEtag = (response: AxiosResponse<any, any>) => {
  let eTag = ""
  if (response.headers && typeof response.headers.get === "function") {
    eTag = response.headers.get("ETag")?.toString()?.replaceAll('"', "") || ""
  }
  return eTag
}
