import { memo } from "react"
import { Button, Modal, ModalBody, ModalFooter, Spinner, ModalHeader } from "reactstrap"

import { AlertModalProps } from "../../interfaces/AlertModalProps"

import error from "../../assets/images/icons/error.svg"
import success from "../../assets/images/icons/success.svg"

const AlertModal = memo((props: AlertModalProps) => {
  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      id="alertModal"
    >
      <ModalHeader toggle={props.toggle}></ModalHeader>
      <ModalBody className="p-4">
        <div className="d-flex justify-content-center align-items-center">
          {props.status === "loading" ? (
            <Spinner color="primary" />
          ) : (
            <img src={props.status === "success" ? success : error} alt="logo" width="50px" />
          )}
          <p className="mb-0 ms-3 text-break">{props.message}</p>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button
          color="outline-primary"
          onClick={() => {
            props.toggle()
            if (props.onClose) {
              props.onClose()
            }
          }}
          className="fw-bold"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
})

AlertModal.displayName = "AlertModal"

export default AlertModal
