import { MoveableManagerInterface, Renderer } from "react-moveable"

interface DeleteableProps {
  deleteable: boolean
  onDelete: () => void
}

export const Deleteable = {
  name: "deleteable",
  props: ["deleteable", "onDelete"],
  events: [],
  render(moveable: MoveableManagerInterface<DeleteableProps>, React: Renderer) {
    const rect = moveable.getRect()
    const TRANSLATE_X_PERCENTAGE = 200

    const calculateTranslatePercentage = (rotation: number): number => {
      // translate x percentage go from range 0% to -TRANSLATE_X_PERCENTAGE% and back to 0%
      // corresponding to rotation from 100 to 140 and 140 to 180 degrees
      if ((rotation > 100 && rotation < 180) || (rotation > 280 && rotation < 360)) {
        const offset = rotation > 270 ? 270 : 90
        const relativeRotation = rotation - offset
        if (relativeRotation <= 40) {
          return -(relativeRotation / 40) * TRANSLATE_X_PERCENTAGE
        } else {
          return -((90 - relativeRotation) / 40) * TRANSLATE_X_PERCENTAGE
        }
      } else if (rotation > 55 && rotation < 70) {
        return 20
      } else {
        return 0
      }
    }

    const DeleteableViewer = moveable.useCSS(
      "div",
      `
      {
          position: absolute;
          will-change: transform;
      }
      .custom-button {
          display: flex;
          width: 24px;
          height: 24px;
          padding: 4px;
          background: #4af;
          border-radius: 4px;
          appearance: none;
          border: 0;
          color: white;
          font-weight: bold;
          justify-content: center;
          align-items: center;
      }
          `
    )
    return (
      <DeleteableViewer
        key={"deleteable-viewer"}
        className={"moveable-deleteable"}
        style={{
          left: `${rect.width + 10}px`,
          top: `${0}px`,
          transform: `translate(${calculateTranslatePercentage(rect.rotation)}%, 0px)`
        }}
      >
        {moveable.props.deleteable && !moveable.isDragging() && (
          <button
            className="custom-button bi bi-trash"
            onMouseDown={() => {
              moveable.props.onDelete()
            }}
          ></button>
        )}
      </DeleteableViewer>
    )
  }
} as const
