import { FlatButtonProps } from "interfaces/FlatButtonProps"

const FlatButton = (props: FlatButtonProps) => {
  return (
    <div className="flat-button">
      <label className="truncate" title={props.label}>
        {props.label}
      </label>
      <button onClick={props.buttonAction}>
        <img src={props.buttonImage} alt="Button Image" />
      </button>
    </div>
  )
}

export default FlatButton
