import { useState, useEffect } from "react"
import { LineChart, Line } from "recharts"

import { LineOpacityEnum } from "../../enum/visualization/chart-line-opacity"
import { Color } from "../../enum/visualization/color"
import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../enum/visualization/tab/battery-filter"
import { LoadFilterEnum } from "../../enum/visualization/tab/load-filter"
import { BatterFilterDataEnum } from "../../enum/visualization/tab/battery-filter-data"
import { LoadFilterDataEnum } from "../../enum/visualization/tab/load-filter-data"

import { DropDown } from "../../interfaces/visualization/Dropdown"

interface ChartBadgeProps {
  title: string
  color: Color
  filter: VisualizationFilterEnum
  filters: DropDown[]
  dataKey: BatterFilterEnum | LoadFilterEnum
  desiredOrder: BatterFilterDataEnum[] | LoadFilterDataEnum[]
}

const ChartBadge = (props: ChartBadgeProps) => {
  const [data, setData] = useState<DropDown[]>([])
  const chartData = [
    {
      val: 3
    },
    {
      val: 3
    },
    {
      val: 3
    }
  ]

  useEffect(() => {
    const originalData = props.filters.filter(
      (selected: DropDown) => selected.dataKey === props.dataKey
    )
    const sortedData = []

    // Use a while loop to reorder the array
    let i = 0
    while (i < props.desiredOrder.length) {
      const item = props.desiredOrder[i]
      const temp = originalData.filter((selected: DropDown) => selected.dataValue === item)
      if (temp.length > 0) {
        sortedData.push(temp[0])
      }
      i++
    }

    setData(sortedData)
  }, [props])

  return (
    <div className="chart-badge shadow rounded mx-1 py-2 px-3 text-center">
      <p className="mb-2">{props.title}</p>
      {data.map((selected: DropDown, index: number) => (
        <div key={index} className="d-flex flex-row bd-highlight">
          <LineChart data={chartData} width={50} height={15} className="chart-wrapper">
            <Line
              yAxisId="right-axis"
              type="monotone"
              dataKey="val"
              stroke={props.color}
              activeDot={true}
              dot={{
                stroke: props.color,
                strokeWidth: 0.5,
                fill: props.color,
                r: 2,
                strokeDasharray: ""
              }}
              style={{
                opacity:
                  index === 0
                    ? LineOpacityEnum.Max
                    : index === 1
                      ? LineOpacityEnum.Ave
                      : LineOpacityEnum.Min
              }}
            />
          </LineChart>
          <span>{selected.dataValue}</span>
        </div>
      ))}
    </div>
  )
}

export default ChartBadge
