import { ChangeEvent, useEffect, useState } from "react"
import { Modal, ModalBody, Button, Alert, Spinner, Input, Label } from "reactstrap"
import { CommissionModalProps } from "../../interfaces/CommissionModalProps"
import edit from "../../assets/images/icons/configuration/edit.svg"
import { SiteConfiguration } from "../../interfaces/SiteConfiguration"
import { SiteCommission } from "../../interfaces/SiteCommission"

import { writeCodeLogEvent } from "../../utils/utils"

import Messages from "../../utils/messages"
import { useSelector } from "react-redux"
import { getCurrentUserState } from "../../store/counter/authSlice"
import { USER_ROLES } from "../../utils/constants"
import { ErrorItem } from "../../interfaces/ErrorItem"
import arrowRight from "../../assets/images/icons/configuration/arrow-right-bold.svg"

const CommissionModal = (props: CommissionModalProps) => {
  const [isSubmit, setSubmit] = useState(false)
  const [isUpdate, setUpdate] = useState(false)
  const [error, setError] = useState("")
  const [errorLists, setErrorLists] = useState<ErrorItem[]>([])
  const [errorListsFromServer, setErrorListsFromServer] = useState<ErrorItem[]>([])
  const [currentCommission, setCurrentCommission] = useState<SiteCommission>()
  const currentUser = useSelector(getCurrentUserState)
  const MAX_LENGTH_NICKNAME = 15
  const MAX_LENGTH_CHARACTERS = 50
  const MIN_LENGTH_PART_NUMBER = 8
  const MIN_LENGTH_SERIAL = 16
  const NICKNAME_REGEX = new RegExp("^[a-zA-Z0-9_:-]*[a-zA-Z0-9_ :-]*[a-zA-Z0-9_:-]$|^$")
  const PART_NUMBER_REGEX = new RegExp("^[a-zA-Z0-9]*$|^$")
  const PART_NUMBER_EMPTY_REGEX = new RegExp("[^]")
  const SERIAL_REGEX = new RegExp("^[a-zA-Z][a-zA-Z0-9]*$|^$")
  const SERIAL_EMS_REGEX = new RegExp("^[A-Z][A-Z0-9]*$|^$")

  useEffect(() => {
    if (currentCommission && currentCommission.id && props.commissions) {
      const originalData = props.commissions[currentCommission.id - 1]
      setUpdate(JSON.stringify(originalData) !== JSON.stringify(currentCommission))
    }
  }, [currentCommission, props.commissions])

  useEffect(() => {
    setCurrentCommission({
      ...props.selectCommission,
      ...{
        partNumber: props.selectCommission?.partNumber
          ? props.selectCommission?.partNumber
          : props.partNumber
      }
    })
  }, [props.partNumber, props.selectCommission])

  const resetError = () => {
    setErrorListsFromServer([])
    setError("")
  }

  const nextButton = () => {
    resetError()
    if (currentCommission && props.commissions) {
      const currentIndex = currentCommission.id
      if (!currentIndex) {
        setCurrentCommission(props.commissions[0])
      } else if (currentIndex < props.commissions?.length) {
        setCurrentCommission(props.commissions[currentIndex])
      }
    }
  }

  const prevButton = () => {
    resetError()
    if (currentCommission && props.commissions) {
      const currentIndex = currentCommission.id
      if (!currentIndex) {
        setCurrentCommission(props.commissions[0])
      } else if (currentIndex > 1) {
        setCurrentCommission(props.commissions[currentIndex - 2])
      }
    }
  }

  const doneButton = () => {
    props.toggle()
  }

  const saveConfiguration = (callBack: { (): void }) => {
    if (isSubmit || errorLists.length > 0) {
      return
    }
    if (isUpdate) {
      setSubmit(true)
      if (props.siteId && currentCommission) {
        let commissions: SiteCommission[] = []
        if (props.commissions) {
          commissions = props.commissions?.filter((c) => c.id !== currentCommission.id)
        }
        commissions?.push(currentCommission)

        const configuration: SiteConfiguration = {
          siteId: props.siteId,
          commission: commissions
        }
        props
          .saveConfiguration(configuration)
          .then((res) => {
            if (res) {
              if (!res.status) {
                setError(res.message)
                setErrorListsFromServer(res.errorLists || [])
              } else {
                callBack()
              }
            }
            setSubmit(false)
          })
          .catch((e) => {
            console.error("Code exception: Save Configuration Error =>", e.message)
            setError(Messages.ERR_SAVE_CONFIGURATION)
            setSubmit(false)

            writeCodeLogEvent("handle save configuration", e, props.siteId)
          })
      }
    } else {
      callBack()
    }
  }

  const clearError = (errorKey: string) => {
    setErrorLists((oldErrorList) => oldErrorList.filter((e) => e.errorField !== errorKey))
  }

  const validLength = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.currentTarget.name) {
      case "nickName":
        checkMaxLength(
          MAX_LENGTH_NICKNAME,
          event.currentTarget.value,
          event.currentTarget.name,
          Messages.NICKNAME
        )
        break
      case "partNumber":
        checkMaxLength(
          MAX_LENGTH_CHARACTERS,
          event.currentTarget.value,
          event.currentTarget.name,
          Messages.LBL_PART_NUMBER
        )
        checkMinLength(
          MIN_LENGTH_PART_NUMBER,
          event.currentTarget.value,
          event.currentTarget.name,
          Messages.LBL_PART_NUMBER
        )
        break
      case "blockASerialNumber":
        checkMaxLength(
          MAX_LENGTH_CHARACTERS,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block A"
        )
        checkMinLength(
          MIN_LENGTH_SERIAL,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block A"
        )
        break
      case "blockBSerialNumber":
        checkMaxLength(
          MAX_LENGTH_CHARACTERS,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block B"
        )
        checkMinLength(
          MIN_LENGTH_SERIAL,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block B"
        )
        break
      case "blockCSerialNumber":
        checkMaxLength(
          MAX_LENGTH_CHARACTERS,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block C"
        )
        checkMinLength(
          MIN_LENGTH_SERIAL,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block C"
        )
        break
      case "blockDSerialNumber":
        checkMaxLength(
          MAX_LENGTH_CHARACTERS,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block D"
        )
        checkMinLength(
          MIN_LENGTH_SERIAL,
          event.currentTarget.value,
          event.currentTarget.name,
          "Serial number block D"
        )
        break
      case "emsBoardSerialNumber":
        checkMaxLength(
          MAX_LENGTH_CHARACTERS,
          event.currentTarget.value,
          event.currentTarget.name,
          Messages.LBL_EMS_BOARD
        )
        checkMinLength(
          MIN_LENGTH_SERIAL,
          event.currentTarget.value,
          event.currentTarget.name,
          Messages.LBL_EMS_BOARD
        )
        break
    }
  }

  const validRegex = (
    value: string,
    regex: RegExp,
    regexConstant: string,
    messageError: string
  ) => {
    const errorKey = `${regexConstant}_regex`
    const sameError = errorLists.filter((e) => e.errorField === errorKey).length
    if (!regex.test(value)) {
      if (sameError === 0) {
        errorLists.push({
          errorField: errorKey,
          errorMsg: messageError
        })
        setErrorLists(errorLists)
      }
    } else if (sameError !== 0) {
      clearError(errorKey)
    }
  }

  const checkMaxLength = (LENGTH: number, value: string, fieldKey: string, fieldName: string) => {
    if (value !== undefined) {
      const errorKey = `${fieldKey}_max_length`
      const sameError = errorLists.filter((e) => e.errorField === errorKey).length
      if (value !== "" && value.length > LENGTH) {
        if (sameError === 0) {
          errorLists.push({
            errorField: errorKey,
            errorMsg: `${fieldName} must not exceed ${LENGTH} characters.`
          })
          setErrorLists(errorLists)
        }
      } else if (sameError !== 0) {
        clearError(errorKey)
      }
    }
  }

  const checkMinLength = (LENGTH: number, value: string, fieldKey: string, fieldName: string) => {
    if (value !== undefined) {
      const errorKey = `${fieldKey}_min_length`
      const sameError = errorLists.filter((e) => e.errorField === errorKey).length
      if (value !== "" && value.length < LENGTH) {
        if (sameError === 0) {
          errorLists.push({
            errorField: errorKey,
            errorMsg: `${fieldName} must not be fewer than ${LENGTH} characters.`
          })
          setErrorLists(errorLists)
        }
      } else if (sameError !== 0) {
        clearError(errorKey)
      }
    }
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      id="commissionModal"
      onClosed={props.onClose}
      unmountOnClose={true}
      backdrop={"static"}
    >
      <ModalBody className="p-3 pb-0 m-3">
        <div className="card-header d-flex justify-content-between pb-4">
          <h4 className="title text-break pt-2">{Messages.LBL_SITE_SETUP_EDIT_TERASTOR_TITLE}</h4>
          <div className="indicator">
            <img
              onClick={() => saveConfiguration(prevButton)}
              src={arrowRight}
              alt="prev"
              className={isSubmit || errorLists.length > 0 ? "p-2 disabled" : "p-2"}
            />
            <span className="card-header pt-2">{`${currentCommission?.id}/${props.commissions?.length}`}</span>
            <img
              onClick={() => saveConfiguration(nextButton)}
              src={arrowRight}
              alt="next"
              className={isSubmit || errorLists.length > 0 ? "p-2 disabled" : "p-2"}
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
        {errorLists.length > 0 || errorListsFromServer.length > 0 ? (
          <Alert color="danger" className="mb-3">
            <ul className="m-0 p-0">
              {errorLists.map((error, index) => (
                <li className="pt-1" key={index}>
                  {error.errorMsg}
                </li>
              ))}
              {errorListsFromServer.map((error, index) => (
                <li className="pt-1" key={index}>
                  {error.errorMsg}
                </li>
              ))}
            </ul>
          </Alert>
        ) : (
          <>
            {error && (
              <Alert color="danger" className="mb-3">
                <p className="mb-0">{Messages.ERR_SAVE_CONFIGURATION}</p>
              </Alert>
            )}
          </>
        )}

        <div id="cardWrapper" className="d-flex p-3">
          <form className="row">
            <div className="col-12">
              <p className="tera-id pe-2">TeraStor #{currentCommission?.id}</p>
              <Input
                className="tera-nick-name"
                id="nickName"
                name="nickName"
                type="text"
                tabIndex={1}
                value={currentCommission?.nickName ? currentCommission?.nickName : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.trimStart()
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    NICKNAME_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_NICKNAME_INVALID
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ nickName: event.currentTarget.value }
                  })
                }}
                placeholder="Nickname"
              />
              <img src={edit} className="p-2" width="30px" />
            </div>
            <div className="col-6">
              <Label for="partNumber">Part Number</Label>
              <Input
                id="partNumber"
                name="partNumber"
                type="text"
                tabIndex={2}
                disabled={currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id}
                value={currentCommission?.partNumber ? currentCommission.partNumber : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "")
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    PART_NUMBER_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_PART_NUMBER_INVALID
                  )
                  validRegex(
                    event.currentTarget.value,
                    PART_NUMBER_EMPTY_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_PART_NUMBER_REQUIRE
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ partNumber: event.currentTarget.value }
                  })
                }}
                placeholder="Part #"
              />
            </div>
            <div className="col-6">
              <Label for="emsBoardSerialNumber">{Messages.LBL_EMS_BOARD}</Label>
              <Input
                id="emsBoardSerialNumber"
                name="emsBoardSerialNumber"
                type="text"
                tabIndex={3}
                disabled={currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id}
                value={currentCommission?.emsBoardSerial ? currentCommission.emsBoardSerial : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "")
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    SERIAL_EMS_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_EMS_SERIAL_INVALID
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ emsBoardSerial: event.currentTarget.value }
                  })
                }}
                placeholder={Messages.LBL_SERIAL_NUMBER}
              />
            </div>
            <div className="col-6">
              <p className="card-header">Quarter Block A</p>
              <Label for="blockASerialNumber">Serial Number</Label>
              <Input
                id="blockASerialNumber"
                name="blockASerialNumber"
                type="text"
                tabIndex={3}
                disabled={currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id}
                value={currentCommission?.blockASerial ? currentCommission.blockASerial : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "")
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    SERIAL_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_SERIAL_INVALID.replace("%BLOCK%", "block A")
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ blockASerial: event.currentTarget.value }
                  })
                }}
                placeholder={Messages.LBL_SERIAL_NUMBER}
              />
            </div>
            <div className="col-6">
              <p className="card-header">Quarter Block B</p>
              <Label for="blockBSerialNumber">Serial Number</Label>
              <Input
                id="blockBSerialNumber"
                name="blockBSerialNumber"
                type="text"
                tabIndex={4}
                disabled={currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id}
                value={currentCommission?.blockBSerial ? currentCommission.blockBSerial : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "")
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    SERIAL_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_SERIAL_INVALID.replace("%BLOCK%", "block B")
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ blockBSerial: event.currentTarget.value }
                  })
                }}
                placeholder={Messages.LBL_SERIAL_NUMBER}
              />
            </div>
            <div className="col-6">
              <p className="card-header">Quarter Block C</p>
              <Label for="blockCSerialNumber">Serial Number</Label>
              <Input
                id="blockCSerialNumber"
                name="blockCSerialNumber"
                type="text"
                tabIndex={5}
                disabled={currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id}
                value={currentCommission?.blockCSerial ? currentCommission.blockCSerial : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "")
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    SERIAL_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_SERIAL_INVALID.replace("%BLOCK%", "block C")
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ blockCSerial: event.currentTarget.value }
                  })
                }}
                placeholder={Messages.LBL_SERIAL_NUMBER}
              />
            </div>
            <div className="col-6">
              <p className="card-header">Quarter Block D</p>
              <Label for="blockDSerialNumber">{Messages.LBL_SERIAL_NUMBER}</Label>
              <Input
                id="blockDSerialNumber"
                name="blockDSerialNumber"
                type="text"
                tabIndex={6}
                disabled={currentUser?.role !== USER_ROLES.AESI_SUPER_ADMIN.id}
                value={currentCommission?.blockDSerial ? currentCommission.blockDSerial : ""}
                onChange={(event) => {
                  event.currentTarget.value = event.currentTarget.value.replace(/\s/g, "")
                  validLength(event)
                  validRegex(
                    event.currentTarget.value,
                    SERIAL_REGEX,
                    event.currentTarget.name,
                    Messages.ERR_SERIAL_INVALID.replace("%BLOCK%", "block D")
                  )
                  setCurrentCommission({
                    ...currentCommission,
                    ...{ blockDSerial: event.currentTarget.value }
                  })
                }}
                placeholder={Messages.LBL_SERIAL_NUMBER}
              />
            </div>
          </form>
        </div>
        <div className="d-flex justify-content-end pt-3">
          <Button
            color="secondary"
            className="me-2 fw-bold border-0 py-2 px-4"
            onClick={props.toggle}
            disabled={isSubmit}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => saveConfiguration(doneButton)}
            disabled={isSubmit || errorLists.length > 0}
            className="fw-bold py-2 px-4"
          >
            {isSubmit && (
              <Spinner color="light" size="sm" className="me-1">
                Loading...
              </Spinner>
            )}
            Done
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CommissionModal
