import { useState, memo, useEffect } from "react"
import { Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Moment from "moment"

import {
  getFilters,
  getDateRange,
  getFromDate,
  getToDate,
  getHourDate,
  getViewBy,
  setFilters,
  setDateRange,
  setViewBy,
  setFromDate,
  setToDate,
  setHourDate,
  getFetchingListAllSitesStatus,
  getDataMode,
  setDataMode,
  getFetchingChartsStatus,
  setIsFetchingCharts,
  getLoadUnit,
  getBatteryFilter,
  getLoadFilter
} from "../../store/counter/visualizationSlice"
import { AppDispatch } from "../../store/store"

import "react-datepicker/dist/react-datepicker.css"

import info from "../../assets/images/icons/visualization/info.svg"

import DatePickerInput from "../../components/form/DatePickerInput"

import SelectTeraStorModal from "../modal/SelectTeraStorModal"
import VisualizationFilterTab from "./VisualizationFilterTab"

import Messages from "../../utils/messages"

import { VisualizationFilterEnum } from "../../enum/visualization/tab/filter-tab"
import { VisualizationViewByEnum } from "../../enum/visualization/tab/viewby-tab"
import { VisualizationDateRangeEnum } from "../../enum/visualization/tab/daterange-tab"
import { DataMode } from "../../enum/visualization/data-mode"

import { SERVER_DATE_FORMAT, VISUALIZATION_DATE_RANGE } from "../../utils/constants"
import { genDateApart, oneYearBack } from "../../utils/utils"
import { getSidebarOpen } from "store/counter/authSlice"
import Loader from "components/Loader"

const VisuallizationSidebar = () => {
  const dispatch = useDispatch<AppDispatch>()
  const filters = useSelector(getFilters)
  const dateRange = useSelector(getDateRange)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const hourDate = useSelector(getHourDate)
  const dataMode = useSelector(getDataMode)
  const viewBy = useSelector(getViewBy)
  const loadUnit = useSelector(getLoadUnit)
  const batteryFilter = useSelector(getBatteryFilter)
  const loadFilter = useSelector(getLoadFilter)
  const sideBarOpen = useSelector(getSidebarOpen)
  const isFetchingAllSites = useSelector(getFetchingListAllSitesStatus)
  const isFetchingCharts = useSelector(getFetchingChartsStatus)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (!isFetchingCharts) dispatch(setIsFetchingCharts(true))
  }, [
    dateRange,
    filters,
    loadUnit,
    fromDate,
    toDate,
    hourDate,
    viewBy,
    dataMode,
    batteryFilter,
    loadFilter
  ])

  const handleDateRangeChange = (date: string | null, isFromDate: boolean) => {
    if (date) {
      const convertFromDate = Moment(isFromDate ? date : fromDate, SERVER_DATE_FORMAT).toDate()
      const convertToDate = Moment(isFromDate ? toDate : date, SERVER_DATE_FORMAT).toDate()

      const oneDay = 24 * 60 * 60 * 1000 // Hours * minutes * seconds * milliseconds
      const diffInTime = Math.abs(convertToDate.getTime() - convertFromDate.getTime())
      const diffInDays = diffInTime / oneDay

      if (diffInDays > VISUALIZATION_DATE_RANGE) {
        if (isFromDate) {
          dispatch(setFromDate(date))
          dispatch(setToDate(genDateApart(date, VISUALIZATION_DATE_RANGE, SERVER_DATE_FORMAT)))
        } else {
          dispatch(setFromDate(genDateApart(date, -VISUALIZATION_DATE_RANGE, SERVER_DATE_FORMAT)))
          dispatch(setToDate(date))
        }
      } else {
        if (isFromDate) dispatch(setFromDate(date))
        else dispatch(setToDate(date))
      }
    }
  }

  return (
    <div
      id="visualizationSidebar"
      className="sidebar-body"
      style={{ display: `${sideBarOpen ? "block" : "none"}` }}
    >
      <div className={`sidebar-content${isFetchingCharts ? " pe-none" : ""}`}>
        <div className="rounded px-2 py-4 mb-3 block">
          <p className="d-flex justify-content-start">
            <img src={info} width={28} alt="info" className="me-2" />
            <span className="select-text">{Messages.MSG_VISUALIZATION_SELECT_TERASTOR}</span>
          </p>
          <Button
            color="primary"
            className="px-3"
            onClick={() => toggle()}
            disabled={isFetchingAllSites}
          >
            {Messages.BTN_VISUALIZATION_SELECT_TERASTOR}
          </Button>
        </div>
        <div className="rounded block">
          <div id="filterWrapper" className="px-2 py-3">
            <h5 className="fw-bold mb-4 text-primary">{Messages.LBL_VISUALIZATION_FILTER}</h5>

            <div className="d-flex justify-content-start mb-3">
              <ul className="nav tab rounded">
                <li className="nav-item rounded">
                  <Button
                    className={`nav-link ${filters === VisualizationFilterEnum.Battery ? "active" : ""}`}
                    color="link"
                    onClick={() => {
                      dispatch(setFilters(VisualizationFilterEnum.Battery))
                      dispatch(setDataMode(DataMode.Average))
                    }}
                  >
                    {Messages.LBL_VISUALIZATION_BATTERY}
                  </Button>
                </li>
                <li className="nav-item rounded">
                  <Button
                    className={`nav-link ${filters === VisualizationFilterEnum.Load ? "active" : ""}`}
                    color="link"
                    onClick={() => {
                      dispatch(setFilters(VisualizationFilterEnum.Load))
                      dispatch(setDataMode(DataMode.Average))
                    }}
                  >
                    {Messages.LBL_VISUALIZATION_LOAD}
                  </Button>
                </li>
              </ul>
            </div>

            <VisualizationFilterTab filter={filters} />
          </div>

          <hr />

          <div id="dateRangeWrapper" className="px-2 py-3">
            <h5 className="fw-bold mb-4 text-primary">{Messages.LBL_VISUALIZATION_DATE_RANGE}</h5>

            <div className="d-flex justify-content-start mb-3">
              <ul className="nav tab rounded">
                <li className="nav-item rounded">
                  <Button
                    className={`nav-link ${dateRange === VisualizationDateRangeEnum.Date ? "active" : ""}`}
                    color="link"
                    onClick={() => {
                      dispatch(setDateRange(VisualizationDateRangeEnum.Date))
                      dispatch(setDataMode(DataMode.Average))
                    }}
                  >
                    {Messages.LBL_VISUALIZATION_DATE}
                  </Button>
                </li>
                <li className="nav-item rounded">
                  <Button
                    className={`nav-link ${dateRange === VisualizationDateRangeEnum.Hours ? "active" : ""}`}
                    color="link"
                    onClick={() => {
                      dispatch(setDateRange(VisualizationDateRangeEnum.Hours))
                    }}
                  >
                    {Messages.LBL_VISUALIZATION_HOURS}
                  </Button>
                </li>
              </ul>
            </div>

            {dateRange === VisualizationDateRangeEnum.Date ? (
              <>
                <DatePickerInput
                  outerWrapClass="mb-3 row visual-datepicker-wrapper"
                  labelClass="col-sm-3 pt-1"
                  label="From:"
                  innerWrapClass="col-sm-9"
                  inputId="dateFrom"
                  name="fromDate"
                  placeHolder=""
                  date={fromDate}
                  setDate={(date: string | null) => handleDateRangeChange(date, true)}
                  required={true}
                  minDate={oneYearBack(SERVER_DATE_FORMAT)}
                  maxDate={genDateApart(toDate, -1, SERVER_DATE_FORMAT)}
                />
                <DatePickerInput
                  outerWrapClass="row visual-datepicker-wrapper"
                  labelClass="col-sm-3 pt-1"
                  label="To:"
                  innerWrapClass="col-sm-9"
                  inputId="toFrom"
                  name="toDate"
                  placeHolder=""
                  date={toDate}
                  setDate={(date: string | null) => handleDateRangeChange(date, false)}
                  required={true}
                  minDate={genDateApart(fromDate, 1, SERVER_DATE_FORMAT)}
                  maxDate={Moment(new Date()).format(SERVER_DATE_FORMAT)}
                />
              </>
            ) : (
              <DatePickerInput
                outerWrapClass="row visual-datepicker-wrapper"
                labelClass="col-xl-5 pt-1 pe-0"
                label="Select Date:"
                innerWrapClass="col-xl-7"
                inputId="selectDate"
                name="selectDate"
                placeHolder=""
                date={hourDate}
                setDate={(date: string | null) => date && dispatch(setHourDate(date))}
                required={true}
                minDate={oneYearBack(SERVER_DATE_FORMAT)}
                maxDate={Moment(new Date()).format(SERVER_DATE_FORMAT)}
              />
            )}
          </div>

          <hr />

          {dateRange === VisualizationDateRangeEnum.Hours && (
            <>
              <div id="dataModeWrapper" className="px-2 py-3">
                <h5 className="fw-bold mb-4 text-primary">
                  {Messages.LBL_VISUALIZATION_DATA_MODE}
                </h5>

                <div className="d-xl-flex justify-content-start mb-3">
                  <ul className="nav tab rounded d-flex flex-column flex-xl-row justify-content-start">
                    <li className="nav-item rounded">
                      <Button
                        className={`nav-link ${dataMode === DataMode.Average ? "active" : ""}`}
                        color="link"
                        onClick={() => {
                          dispatch(setDataMode(DataMode.Average))
                        }}
                      >
                        {Messages.LBL_VISUALIZATION_DATA_MODE_AVERAGE}
                      </Button>
                    </li>
                    <li className="nav-item rounded">
                      <Button
                        className={`nav-link ${dataMode === DataMode.Sample ? "active" : ""}`}
                        color="link"
                        onClick={() => {
                          dispatch(setDataMode(DataMode.Sample))
                        }}
                      >
                        {Messages.LBL_VISUALIZATION_DATA_MODE_SAMPLE}
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
            </>
          )}

          <div id="viewByWrapper" className="py-3 px-2">
            <h5 className="fw-bold mb-4 text-primary">{Messages.LBL_VISUALIZATION_VIEW_BY}</h5>

            <div className="d-xl-flex justify-content-start mb-3">
              <ul className="nav tab rounded d-flex flex-column flex-xl-row justify-content-start">
                <li className="nav-item rounded">
                  <Button
                    className={`nav-link ${viewBy === VisualizationViewByEnum.Detail ? "active" : ""}`}
                    color="link"
                    onClick={() => {
                      dispatch(setViewBy(VisualizationViewByEnum.Detail))
                      dispatch(setDataMode(DataMode.Average))
                    }}
                  >
                    {Messages.LBL_VISUALIZATION_DETAIL}
                  </Button>
                </li>
                <li className="nav-item rounded">
                  <Button
                    className={`nav-link ${viewBy === VisualizationViewByEnum.Aggregated ? "active" : ""}`}
                    color="link"
                    onClick={() => {
                      dispatch(setViewBy(VisualizationViewByEnum.Aggregated))
                      dispatch(setDataMode(DataMode.Average))
                    }}
                  >
                    {Messages.LBL_VISUALIZATION_AGGREGATED}
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {modal && <SelectTeraStorModal modal={modal} size="xl" toggle={() => toggle()} />}
      {sideBarOpen && isFetchingCharts && (
        <div className="transparent-layer">
          <Loader status="loading" message={true} />
        </div>
      )}
    </div>
  )
}

export default memo(VisuallizationSidebar)
