import { useState } from "react"
import { useLocation } from "react-router-dom"
import { Button } from "reactstrap"

import { isAESISuperAdminRoute } from "../../utils/utils"

import { HeaderMenuProps } from "../../interfaces/HeadMenuProps"
import { LogoProps } from "../../interfaces/LogoProps"

import HeaderMenu from "./HeaderMenu"
import HeaderActions from "./HeaderActions"
import Logo from "../Logo"

const HeaderResponsive = () => {
  const location = useLocation()
  const getPath = (index = 1) => location.pathname.split("/")[index]
  const headerMenuPops: HeaderMenuProps = {
    class: "justify-content-center"
  }
  const [headerMenu, setHeaderMenu] = useState(false)
  const toggle = () => setHeaderMenu(!headerMenu)
  const logoProps: LogoProps = {
    class: "pt-0"
  }

  if (!isAESISuperAdminRoute(getPath(2))) {
    return (
      <div className="header d-flex flex-column bd-highlight d-xl-none p-3 py-md-0">
        <div className="d-flex d-xl-none justify-content-between">
          <Button
            className="bg-white px-2 py-0 my-3"
            color="secondary"
            id="btnToggleMenu"
            onClick={toggle}
          >
            <i className="bi bi-list"></i>
          </Button>
          <HeaderActions />
        </div>
        {headerMenu && <HeaderMenu {...headerMenuPops} />}
      </div>
    )
  } else {
    return (
      <div className="header d-md-flex justify-content-between d-xl-none p-3 py-md-0">
        <Logo {...logoProps} />
        <HeaderActions />
      </div>
    )
  }
}

export default HeaderResponsive
