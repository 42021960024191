import { useEffect, useState } from "react"

import Messages from "utils/messages"
import { AlertModalProps } from "interfaces/AlertModalProps"
import AlertModal from "components/modal/AlertModal"
import AllDocumentsTable from "./AllDocumentsTable"
import { useSelector } from "react-redux"
import { getSelectedCategory, getSelectedSites } from "store/counter/docSlice"
import { SiteEntity } from "interfaces/SiteEntity"
import { DocCategory } from "interfaces/doc/DocCategory"

const DocManagerTenantAdmin = () => {
  const category = useSelector(getSelectedCategory)
  const selectedSites = useSelector(getSelectedSites)
  const [siteList, setSiteList] = useState<SiteEntity[]>([])
  const [selectedCategory, setSelectedCategory] = useState<DocCategory | undefined>()

  useEffect(() => {
    if (selectedSites && selectedSites != undefined) {
      setSiteList(selectedSites)
    }
  }, [selectedSites])

  useEffect(() => {
    setSelectedCategory(category)
  }, [category])

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  return (
    <div id="documentManager" className="admin-wrapper">
      <p className="title pt-0 mt-0">{Messages.LBL_DOCUMENTS_All_DOCUMENTS}</p>

      {/* All Documents Table snippet: */}
      <AllDocumentsTable
        isTenantAdmin={true}
        needUpdateList={false}
        siteList={siteList}
        categoryId={selectedCategory?.id}
      />
      {alertModalProps.modal && <AlertModal {...alertModalProps} />}
    </div>
  )
}

export default DocManagerTenantAdmin
