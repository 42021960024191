import { Link } from "react-router-dom"

import logo from "../assets/images/logo.svg"

import { LogoProps } from "../interfaces/LogoProps"

const Logo = (props: LogoProps) => {
  return (
    <div className={`${props.class || ""} text-center logo-wrapper`}>
      <Link to="/">
        <img src={logo} alt="logo" width={294} height={70} id="logo"></img>
      </Link>
    </div>
  )
}

export default Logo
