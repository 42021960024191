import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react"

// css
import "../../assets/scss/configuration/configuration.scss"

// icon
import undo_icon from "../../assets/images/icons/configuration/undo-layout.svg"
import redo_icon from "../../assets/images/icons/configuration/redo-layout.svg"
import zoom_in_icon from "../../assets/images/icons/configuration/zoom-in.svg"
import zoom_out_icon from "../../assets/images/icons/configuration/zoom-out.svg"

import { ElementProperties } from "../../interfaces/ElementProperties"
import { SiteMapProps } from "../../interfaces/SiteMapProps"
import SiteElement from "./SiteElement"
import Moveable, {
  BoundType,
  OnDrag,
  OnDragEnd,
  OnDragGroup,
  OnDragGroupEnd,
  OnRotate,
  OnRotateEnd,
  OnRotateGroup,
  OnRotateGroupEnd,
  OnScale,
  OnScaleEnd,
  OnScaleGroup,
  OnScaleGroupEnd
} from "react-moveable"
import Selecto from "react-selecto"
import { Container, Row, Spinner } from "reactstrap"
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import useUndo from "use-undo"
import { v4 as uuidv4 } from "uuid"

// components
import RoundImageButton from "components/button/RoundImageButton"

// Interfaces
import { SiteConfiguration } from "../../interfaces/SiteConfiguration"
import ErrorModal from "../modal/ErrorModal"
import { Deleteable } from "./MoveAbleDeleteable"
import TeraStorDetailModal from "components/modal/TeraStorDetailModal"
import { TERASTOR } from "utils/constants"
import { SaveConfigurationHandle } from "pages/configuration/StepLayout"

import { stringFormat, writeCodeLogEvent } from "../../utils/utils"
import messages from "utils/messages"
import { DeleteTeraStorModalProps } from "interfaces/DeleteTeraStorModalProps"
import DeleteTeraStorModal from "components/modal/DeleteTeraStorModal"

// #region Component constants
const TERASTOR_START_INDEX = 0
const DEFAULT_TERASTOR_SCALE = 1.0
const DEFAULT_Z_INDEX = "0"
const SELECTED_Z_INDEX = "2"
const BACKGROUND_INITIAL_SCALE = 1
const TERASTOR_WIDTH = 100 //px
const TERASTOR_HEIGHT = 200 //px
const TERASTOR_PASTE_OFFSET = 20 //px
const FULL_TERASTOR_GUIDELINE_THRESHOLD = 32 //px
// #endregion Component constants

const SiteMap = forwardRef<SaveConfigurationHandle, SiteMapProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    saveConfiguration: async () => saveConfiguration(),
    getConfiguration: () => presentSiteElements
  }))
  // eslint-disable-next-line
  const [bounds, setBounds] = useState<BoundType>({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: "css"
  })
  const selectoRef = React.useRef<Selecto>(null)
  const moveableRef = React.useRef<Moveable>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const transformWrapperRef = React.useRef<ReactZoomPanPinchRef>(null)

  const [targets, setTargets] = React.useState<Array<SVGElement | HTMLElement>>([])
  const [zoomLevel, setZoomLevel] = useState(BACKGROUND_INITIAL_SCALE)
  const [ctrlHeld, setCtrlHeld] = useState(false)
  const [lastSavedConfiguration] = useState<ElementProperties[]>([])
  const [isAutoUpdate, setAutoUpdate] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTeraStorModalOpen, setIsTeraStorModalOpen] = useState(false)
  const [error, setError] = useState<string>("")
  const [selectedTeraStorName, setSelectedTeraStorName] = useState<string>("")
  const [elementGuidelines, setElementGuidelines] = useState<Array<Element>>([])
  const [isSavingLayout, setIsSavingLayout] = useState(false)
  const [isLayoutSaved, setIsLayoutSaved] = useState(true)
  const [tsScale, setTSScale] = useState(DEFAULT_TERASTOR_SCALE)
  const [elementsClipboard, setElementsClipboard] = useState<(ElementProperties | undefined)[]>([])
  const [newDraggedElement, setNewDraggedElement] = useState<ElementProperties | undefined>()
  const [minOrigin, setMinOrigin] = useState({ x: 0, y: 0 })
  const [mapContainerRect, setMapContainerRect] = useState<{ width: number; height: number }>({
    width: 640,
    height: 480
  })

  const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent)
  const activationKeys = isMac ? "Meta" : "Control"

  const [
    siteElementState,
    { set: setSiteElements, undo: undoSiteElements, redo: redoSiteElements, canUndo, canRedo }
  ] = useUndo<ElementProperties[]>(props.configuration?.layout || [])

  const { present: presentSiteElements } = siteElementState

  // #region Component side effects handlers
  useEffect(() => {
    presentSiteElements.forEach((element) => {
      if (element.element === TERASTOR && containerRef.current && element.transform) {
        const parsedValues = parseTransformString(element.transform)
        const scale = parsedValues.scale ? parsedValues.scale[0] : 1
        if (parsedValues.translate) {
          if (parsedValues.rotate) {
            // TODO: Apply rotation to calculate if TS exeed the bound of dropzone
          } else {
            const posX = getValueInBound(
              parsedValues.translate[0],
              0,
              props.backgroundWidth - TERASTOR_WIDTH * scale
            )
            const posY = getValueInBound(
              parsedValues.translate[1],
              0,
              props.backgroundHeight - TERASTOR_HEIGHT * scale
            )
            const regex = /translate\((-?\d*\.?\d+)px,\s*(-?\d*\.?\d+)px\)/g
            const transformRule = element.transform.replace(
              regex,
              `translate(${posX}px, ${posY}px)`
            )

            element.transform = transformRule
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.backgroundWidth, props.backgroundHeight])

  useEffect(() => {
    // Convert presentSiteElements to Element, filtering out nulls
    const collection = presentSiteElements
      .map((element) => document.getElementById(element.uuid))
      .filter((element) => element !== null) as Element[]

    // Decide on guideline setting strategy based on the number of elements
    if (presentSiteElements.length < FULL_TERASTOR_GUIDELINE_THRESHOLD) {
      setElementGuidelines(collection)
    } else {
      // Optimize element guidelines for larger collections
      const optimizedCollection = optimizeElementGuidelines(collection)
      setElementGuidelines(optimizedCollection)
    }
  }, [presentSiteElements.length])

  useEffect(() => {
    if (props.viewOnly || props.syncState) {
      // Sync layout data with last configuration for re-rendering in case of view only
      // Used in review & submit mode
      if (props.configuration?.layout) {
        setSiteElements(props.configuration?.layout)
      }

      if (props.syncState && props.setSyncState) {
        props.setSyncState(false)
      }
    }
  }, [props.configuration?.layout, props.viewOnly, props.syncState])

  useEffect(() => {
    if (props.configuration?.layout && props.configuration?.layout.length > 0) {
      const parsedValues = parseTransformString(props.configuration.layout[0].transform ?? "")
      if (parsedValues.scale) {
        setTSScale(parsedValues.scale[0])
      }
    }
  }, [])

  useEffect(() => {
    const originX = (TERASTOR_WIDTH * (tsScale - 1)) / 2
    const originY = (TERASTOR_HEIGHT * (tsScale - 1)) / 2
    setMinOrigin({ x: originX, y: originY })
  }, [tsScale])

  useEffect(() => {
    if (props.contentHeight) {
      // map width = container width - element selection width - padding
      let mapRectWidth = parseInt(props.contentWidth ?? "640")
      const elementSelectionWrapper = document.getElementById("elementSelection")
      if (elementSelectionWrapper) {
        mapRectWidth = mapRectWidth - elementSelectionWrapper.offsetWidth - 20
      }

      // map height = container height - header height
      let mapRectHeight = parseInt(props.contentHeight)
      const siteInfoWrapper = document.getElementById("siteInfo")
      if (siteInfoWrapper) {
        mapRectHeight = mapRectHeight - siteInfoWrapper.offsetHeight
      }
      const navBarWrapper = document.getElementById("configurationNavBar")
      if (navBarWrapper) {
        mapRectHeight = mapRectHeight - navBarWrapper.offsetHeight
      }
      const approvalButtonWrapper = document.getElementById("approvalButton")
      if (approvalButtonWrapper) {
        mapRectHeight = mapRectHeight - approvalButtonWrapper.offsetHeight
      }
      const reviewConfigurationWrapper = document.getElementById("review-configuration")
      if (reviewConfigurationWrapper) {
        mapRectHeight = mapRectHeight - reviewConfigurationWrapper.offsetHeight
      }

      setMapContainerRect({
        width: Math.max(mapRectWidth, 640),
        height: Math.max(mapRectHeight, 480)
      })
    }
  }, [props.contentHeight, props.contentWidth])
  // #endregion Component side effects handlers

  // #region Add/Update Site Element Array
  const setLayout = useCallback(
    (updatedSiteElement: ElementProperties[]) => {
      setSiteElements(updatedSiteElement)
      props.setConfiguration({
        ...props.configuration,
        layout: updatedSiteElement
      })
      setIsLayoutSaved(false)
    },
    [props.setConfiguration, props.configuration, setSiteElements]
  )

  const renderElement = useCallback(
    (elementProperties: ElementProperties[]) => {
      const newPresentSiteElements = [...(presentSiteElements || []), ...elementProperties]
      setLayout(newPresentSiteElements)
    },
    [presentSiteElements, setLayout]
  )

  const generateElementProperties = (
    x: number,
    y: number,
    scale: number,
    rotation: number,
    elementType: string,
    id: number
  ) => {
    let transformRule = ""
    const uuid = uuidv4()
    const tsId = id
    transformRule = `translate(${x}px, ${y}px) rotate(${rotation}deg) scale(${scale}, ${scale})`
    const elementProperties: ElementProperties = {
      id: tsId,
      uuid: uuid,
      sysName: "TeraStor-" + tsId,
      posX: x,
      posY: y,
      scale: scale,
      rotation: rotation,
      transform: transformRule,
      element: elementType
    }
    return elementProperties
  }

  const addNewElement = useCallback(
    (x: number, y: number, scale: number, rotation: number, elementType: string, id: number) => {
      const elementProperties = generateElementProperties(x, y, scale, rotation, elementType, id)
      renderElement([elementProperties])

      return elementProperties
    },
    [generateElementProperties, renderElement]
  )

  function updateSiteElementState(newState: Map<string, string>, newScale: number | null = null) {
    const updatedSiteElement = presentSiteElements.map((element) => {
      const newTransform = newState.get(element.uuid)

      if (newTransform && element.transform && newTransform !== element.transform) {
        const parsedValues = parseTransformString(newTransform)
        const updatedElementProperties = {
          ...element,
          transform: newState.get(element.uuid) || element.transform,
          rotation: parsedValues.rotate ? parsedValues.rotate[0] : element.rotation,
          scale: parsedValues.scale ? parsedValues.scale[0] : element.scale,
          posX: parsedValues.translate ? parsedValues.translate[0] : element.posX,
          posY: parsedValues.translate ? parsedValues.translate[1] : element.posY
        }
        return updatedElementProperties
      } else {
        if (newScale) {
          const newTransformWithScale = element.transform?.replace(
            /scale\((-?\d*\.?\d+),\s*(-?\d*\.?\d+)\)/g,
            `scale(${newScale}, ${newScale})`
          )
          const updatedScaleElement = {
            ...element,
            transform: newTransformWithScale
          }
          return updatedScaleElement
        } else {
          return element
        }
      }
    })
    setLayout(updatedSiteElement)
  }
  // #endregion Add/Update Site Element Array

  useEffect(() => {
    if (!props.viewOnly && isAutoUpdate) {
      // TODO: Disable auto save
      // const interval = setInterval(() => {
      //   if (JSON.stringify(presentSiteElements) !== JSON.stringify(lastSavedConfiguration)) {
      //     saveConfiguration(interval)
      //     setLastSavedConfiguration(presentSiteElements)
      //   }
      // }, 15000) // 15 seconds in milliseconds
      //
      // return () => {
      //   clearInterval(interval) // Cleanup the interval on unmounting the component
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentSiteElements, lastSavedConfiguration, isAutoUpdate])

  useEffect(() => {
    setAutoUpdate(!props.viewOnly)
  }, [props.viewOnly])

  // #region Delete TS Modal
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const deleteToggle = () => setDeleteModalProps((prev) => ({ ...prev, modal: !prev.modal }))
  const deleteTeraStors = (uuidArr: string[]) => {
    deleteElementByUUID(uuidArr)
    return Promise.resolve({ status: true, message: "" })
  }
  const onDeleteModalClose = () => {
    setDeleteModalProps((prev) => ({ ...prev, msgArr: [], terastorUUIDs: [] }))

    setTargets((prevTarget) => {
      if (prevTarget) {
        prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
      }
      return []
    })
  }

  const [deleteModalProps, setDeleteModalProps] = useState<DeleteTeraStorModalProps>({
    size: "md",
    modal: false,
    toggle: deleteToggle,
    terastorUUIDs: [],
    msgArr: [],
    request: deleteTeraStors,
    response: () => {
      setDeleteSuccess(true)
    },
    onClose: onDeleteModalClose
  })

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteSuccess(false)
      saveConfiguration()
    }
  }, [deleteSuccess])

  const deleteElementByUUID = useCallback(
    (uuidsToDelete: string[]) => {
      const updatedSiteElement: ElementProperties[] = []
      let index = 1
      for (let i = 0; i < presentSiteElements.length; i++) {
        if (!uuidsToDelete.includes(presentSiteElements[i].uuid)) {
          const element: ElementProperties = {
            ...presentSiteElements[i],
            id: index,
            sysName: "TeraStor-" + index
          }
          index++
          updatedSiteElement.push(element)
        }
      }
      setLayout(updatedSiteElement)
    },
    [setLayout, presentSiteElements]
  )

  const deleteElement = useCallback(() => {
    if (targets.length > 0) {
      const uuidsToDelete: string[] = targets.map((element) => element.id)
      const tsInUseMsgArr: string[] = []
      if (props.configuration?.network) {
        const { domainMaster, dataServer } = props.configuration.network
        if (domainMaster && uuidsToDelete.includes(domainMaster.uuid)) {
          tsInUseMsgArr.push(
            stringFormat(messages.MSG_SITE_SETUP_TS_IN_USE, [
              `#${domainMaster.id}`,
              messages.DOMAIN_MASTER
            ])
          )
        }
        if (dataServer && uuidsToDelete.includes(dataServer.uuid)) {
          tsInUseMsgArr.push(
            stringFormat(messages.MSG_SITE_SETUP_TS_IN_USE, [
              `#${dataServer.id}`,
              messages.DATA_SERVER
            ])
          )
        }
      }
      if (props.configuration?.commission) {
        props.configuration.commission.forEach((commission) => {
          if (
            commission.uuid &&
            commission.isRingMarshal &&
            uuidsToDelete.includes(commission.uuid)
          ) {
            tsInUseMsgArr.push(
              stringFormat(messages.MSG_SITE_SETUP_TS_IN_USE, [
                `#${commission.id}`,
                messages.RING_MARSHAL
              ])
            )
          }
        })
      }
      if (tsInUseMsgArr.length !== 0) {
        setDeleteModalProps((prev) => ({
          ...prev,
          modal: true,
          request: deleteTeraStors,
          msgArr: tsInUseMsgArr,
          terastorUUIDs: uuidsToDelete
        }))
      } else {
        deleteElementByUUID(uuidsToDelete)
        setTargets((prevTarget) => {
          if (prevTarget) {
            prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
          }
          return []
        })
      }
    }
  }, [deleteElementByUUID, targets, props.configuration?.commission, props.configuration?.network])
  // #endregion Delete TS Modal

  // #region Hotkeys handlers
  const handleUndo = useCallback(() => {
    setTargets((prevTarget) => {
      prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
      return []
    })
    undoSiteElements()
  }, [undoSiteElements])

  const handleRedo = useCallback(() => {
    setTargets((prevTarget) => {
      prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
      return []
    })
    redoSiteElements()
  }, [redoSiteElements])

  const handleCopy = useCallback(() => {
    if (targets.length > 0) {
      const copiedElements = targets.map((target) => {
        const element = presentSiteElements.find((el) => el.uuid === target.id)
        if (element !== undefined) {
          return element
        }
      })

      setElementsClipboard(copiedElements)
    }
  }, [presentSiteElements, targets])

  const handlePaste = useCallback(() => {
    if (elementsClipboard.length > 0) {
      let containerRect: DOMRect | undefined = undefined
      let rightBound = 0
      let bottomBound = 0
      if (containerRef.current) {
        containerRect = containerRef.current.getBoundingClientRect()
        const containerStyle = getComputedStyle(containerRef.current)
        const containerWidth =
          containerRect.width -
          parseFloat(containerStyle.paddingLeft) -
          parseFloat(containerStyle.paddingRight)
        const containerHeight =
          containerRect.height -
          parseFloat(containerStyle.paddingTop) -
          parseFloat(containerStyle.paddingBottom)
        rightBound = containerWidth
        bottomBound = containerHeight
      }

      const addedElements: Array<ElementProperties> = []
      const ids = props.configuration?.layout?.map((element) => element.id) || []
      const idArray = findSmallestMissingIds(ids, elementsClipboard.length)
      elementsClipboard.forEach((element, index) => {
        if (element) {
          const x = Math.min(
            element.posX + TERASTOR_PASTE_OFFSET,
            rightBound / zoomLevel - TERASTOR_WIDTH * tsScale + minOrigin.x
          )
          const y = Math.min(
            element.posY + TERASTOR_PASTE_OFFSET,
            bottomBound / zoomLevel - TERASTOR_HEIGHT * tsScale + minOrigin.y
          )
          const newElement = generateElementProperties(
            x,
            y,
            Math.min(element.scale, tsScale),
            element.rotation,
            element.element,
            idArray[index]
          )
          addedElements.push(newElement)
        }
      })
      renderElement(addedElements)
      setElementsClipboard(addedElements)
    }
  }, [
    elementsClipboard,
    generateElementProperties,
    minOrigin.x,
    minOrigin.y,
    props.configuration?.layout,
    renderElement,
    tsScale,
    zoomLevel
  ])

  useEffect(() => {
    const addedElement = elementsClipboard
      .map((element) => {
        if (element) {
          const addedElement = document.getElementById(element.uuid) as HTMLElement | SVGElement
          addedElement.style.zIndex = SELECTED_Z_INDEX
          return addedElement
        }
      })
      .filter(
        (element): element is HTMLElement | SVGElement => element !== null && element !== undefined
      )
    setTargets((prevTarget) => {
      prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
      return addedElement
    })
  }, [elementsClipboard])

  useEffect(() => {
    if (newDraggedElement) {
      const elementInDOM = document.getElementById(newDraggedElement.uuid) as
        | HTMLElement
        | SVGElement
      if (elementInDOM) {
        elementInDOM.style.zIndex = SELECTED_Z_INDEX
        setTargets((prevTarget) => {
          prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
          return [elementInDOM]
        })
      }
    }
  }, [newDraggedElement])

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.metaKey) {
          // handle mac command key
          if (e.key === "c") {
            handleCopy()
          } else if (e.key === "v") {
            handlePaste()
          } else if (e.key === "z" && !e.shiftKey && canUndo) {
            handleUndo()
          } else if (e.key === "z" && e.shiftKey && canRedo) {
            handleRedo()
          } else {
            setCtrlHeld(true)
          }
        } else if (e.ctrlKey && !isMac) {
          if (e.key === "c") {
            handleCopy()
          } else if (e.key === "v") {
            handlePaste()
          } else if (e.key === "z" && canUndo) {
            handleUndo()
          } else if (e.key === "y" && canRedo) {
            handleRedo()
          } else {
            setCtrlHeld(true)
          }
        }
      }
    }

    const keyUpHandler = (e: KeyboardEvent) => {
      if (e.key === activationKeys) {
        setCtrlHeld(false)
      } else if (e.key === "Delete" || e.key === "Backspace") {
        deleteElement()
      }
    }

    if (!props.viewOnly) {
      window.addEventListener("keydown", keyDownHandler)
      window.addEventListener("keyup", keyUpHandler)
    }

    return () => {
      window.removeEventListener("keydown", keyDownHandler)
      window.removeEventListener("keyup", keyUpHandler)
    }
  }, [
    activationKeys,
    canRedo,
    canUndo,
    deleteElement,
    handleCopy,
    handlePaste,
    handleRedo,
    handleUndo,
    isMac,
    props.viewOnly
  ])
  // #endregion hotkeys handlers

  // #region Drag and Drop Element handlers
  const dragOverHandler = (event: React.DragEvent) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const dropHandler = (event: React.DragEvent) => {
    const elementType = props.draggedElement.element

    let x = 0,
      y = 0
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const containerScrollLeft = containerRef.current.scrollLeft
      const containerScrollTop = containerRef.current.scrollTop
      x =
        (event.clientX - containerRect.left - props.draggedElement.offsetX + containerScrollLeft) /
        zoomLevel
      y =
        (event.clientY - containerRect.top - props.draggedElement.offsetY + containerScrollTop) /
        zoomLevel

      x = getValueInBound(x, 0, containerRect.width - props.draggedElement.elementWidth * tsScale)
      y = getValueInBound(y, 0, containerRect.height - props.draggedElement.elementHeight * tsScale)
    }
    const ids = props.configuration?.layout?.map((element) => element.id) || []
    const id = findSmallestMissingIds(ids)
    const newElement = addNewElement(x, y, tsScale, 0, elementType, id[0])
    setNewDraggedElement(newElement)
  }
  // #endregion Drag and Drop Element handlers

  // #region Moveable handlers
  const handleDrag = (e: OnDrag) => {
    e.target.style.transform = e.transform
  }

  const handleDragEnd = (e: OnDragEnd) => {
    const oldTransform = presentSiteElements.find(
      (element) => element.uuid === e.target.id
    )?.transform
    if (oldTransform && oldTransform != e.target.style.transform) {
      const newSiteElementState: Map<string, string> = new Map([
        [e.target.id, e.target.style.transform]
      ])
      updateSiteElementState(newSiteElementState)
    }
  }

  const handleDragGroup = (e: OnDragGroup) => {
    e.events.forEach((ev) => {
      handleDrag(ev)
    })
  }

  const handleDragGroupEnd = (e: OnDragGroupEnd) => {
    const newSiteElementState: Map<string, string> = new Map()
    e.events.forEach((ev) => {
      const oldTransform = presentSiteElements.find(
        (element) => element.uuid === ev.target.id
      )?.transform
      if (oldTransform && oldTransform != ev.target.style.transform) {
        newSiteElementState.set(ev.target.id, ev.target.style.transform)
      }
    })
    if (newSiteElementState.size > 0) {
      updateSiteElementState(newSiteElementState)
    }
  }

  const handleRotate = (e: OnRotate) => {
    e.target.style.transform = e.transform
  }

  const handleRotateGroup = (e: OnRotateGroup) => {
    e.events.forEach((ev) => {
      handleRotate(ev)
    })
  }

  const handleRotateEnd = (e: OnRotateEnd) => {
    const newSiteElementState: Map<string, string> = new Map([
      [e.target.id, e.target.style.transform]
    ])

    updateSiteElementState(newSiteElementState)
  }

  const handleRotateGroupEnd = (e: OnRotateGroupEnd) => {
    const newSiteElementState: Map<string, string> = new Map()
    e.events.forEach((ev) => {
      newSiteElementState.set(ev.target.id, ev.target.style.transform)
    })
    updateSiteElementState(newSiteElementState)
  }

  const handleScale = (e: OnScale) => {
    e.target.style.transform = e.transform
  }

  const handleScaleGroup = (e: OnScaleGroup) => {
    e.events.forEach((ev) => {
      handleScale(ev)
    })
  }

  const handleScaleEnd = (e: OnScaleEnd) => {
    const parsedValues = parseTransformString(e.target.style.transform)
    const newSiteElementState: Map<string, string> = new Map([
      [e.target.id, e.target.style.transform]
    ])

    updateSiteElementState(newSiteElementState, parsedValues.scale ? parsedValues.scale[0] : null)
    if (parsedValues.scale) {
      setTSScale(parsedValues.scale[0])
    }
  }

  const handleScaleGroupEnd = (e: OnScaleGroupEnd) => {
    const newSiteElementState: Map<string, string> = new Map()
    let initialScaleValue = tsScale
    if (e.events.length >= 1) {
      const firstItemScale = parseTransformString(e.events[0].target.style.transform)
      if (firstItemScale.scale) {
        initialScaleValue = firstItemScale.scale[0]
      }
    }

    e.events.forEach((ev) => {
      const parsedValues = parseTransformString(ev.target.style.transform)
      if (parsedValues.scale) {
        initialScaleValue = Math.min(initialScaleValue, parsedValues.scale[0])
      }
      newSiteElementState.set(ev.target.id, ev.target.style.transform)
    })
    updateSiteElementState(newSiteElementState, initialScaleValue)
    setTSScale(initialScaleValue)
  }
  // #endregion Moveable handlers

  const changeBackground = () => {
    props.handleChangeBackground()
    props.setConfiguration({ ...props.configuration, layout: presentSiteElements })
  }

  const saveConfiguration = async (intervalAutoSaveId: NodeJS.Timer | undefined = undefined) => {
    if (props.siteUniqueId) {
      const configuration: SiteConfiguration = {
        siteId: props.siteUniqueId,
        layout: presentSiteElements
      }
      setIsSavingLayout(true)

      try {
        const res = await props.saveConfiguration(configuration)
        if (res) {
          if (!res.status) {
            if (
              Array.isArray(res.errorLists) &&
              res.errorLists.find((err) => err.errorMsg === messages.ERR_SITE_SETUP_IN_PROGRESS) &&
              intervalAutoSaveId
            ) {
              clearInterval(intervalAutoSaveId)
              setAutoUpdate(false)
            } else {
              // Do nothing since error message will be showned by Parent component
            }
          } else {
            setIsLayoutSaved(true)
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        setError(e.message)
        setIsModalOpen(true)
        console.error("Code exception: save configuration =>", e.message)

        writeCodeLogEvent("save configuration", e, props.siteUniqueId)
      } finally {
        setIsSavingLayout(false)
      }
    }
  }

  return (
    <Container className="d-flex flex-column align-items-start justify-content-start overflow-auto">
      <Row className="sticky-toolbar p-0 pb-1">
        <div className="p-0 w-auto">
          <RoundImageButton
            onClick={() => transformWrapperRef.current?.zoomIn()}
            tooltipTitle="Zoom in"
            src={zoom_in_icon}
          />
          <RoundImageButton
            onClick={() => transformWrapperRef.current?.zoomOut()}
            tooltipTitle="Zoom out"
            src={zoom_out_icon}
          />
          <RoundImageButton
            onClick={() => {
              setZoomLevel(BACKGROUND_INITIAL_SCALE)
              transformWrapperRef.current?.resetTransform()
            }}
            tooltipTitle="Reset zoom"
          >
            <i className="col bi bi-aspect-ratio-fill fs-5 rounded-circle toolbar-button"></i>
          </RoundImageButton>
        </div>
        {!props.viewOnly && (
          <div className="p-0 ps-3 w-auto">
            <RoundImageButton onClick={changeBackground} tooltipTitle="Change Background">
              <i className="bi bi-images fs-5"></i>
            </RoundImageButton>
            <RoundImageButton
              disabled={isSavingLayout}
              onClick={saveConfiguration}
              tooltipTitle="Save Layout"
            >
              {isSavingLayout ? (
                <Spinner color="secondary" size="sm" className=""></Spinner>
              ) : (
                <div>
                  {isLayoutSaved ? (
                    <i className="col bi bi-check text-success fs-5"></i>
                  ) : (
                    <i className="bi bi-floppy2-fill fs-5"> </i>
                  )}
                </div>
              )}
            </RoundImageButton>
            <RoundImageButton
              key="undo"
              onClick={() => handleUndo()}
              disabled={!canUndo}
              tooltipTitle="Undo"
              src={undo_icon}
            />
            <RoundImageButton
              key="redo"
              onClick={handleRedo}
              disabled={!canRedo}
              tooltipTitle="Redo"
              src={redo_icon}
            />
          </div>
        )}
      </Row>
      <Row
        className="m-0"
        style={{
          height: mapContainerRect.height + "px"
        }}
      >
        <TransformWrapper
          initialScale={BACKGROUND_INITIAL_SCALE}
          initialPositionX={0}
          initialPositionY={0}
          doubleClick={{
            excluded: ["target"]
          }}
          panning={{ activationKeys: [activationKeys] }}
          wheel={{ activationKeys: [activationKeys] }}
          onTransformed={(_, state) => {
            setZoomLevel(state.scale)
            setCtrlHeld(false)
          }}
          onPanning={() => {
            setCtrlHeld(true)
          }}
          onZoom={() => {
            setCtrlHeld(true)
          }}
          ref={transformWrapperRef}
        >
          {() => (
            <React.Fragment>
              <TransformComponent>
                <div
                  id="dropzone"
                  data-testid="dropzoneTest"
                  className="site-map text-white"
                  style={{
                    backgroundImage: `url(${props.backgroundUrl})`,
                    width: `${props.backgroundWidth}px`,
                    height: `${props.backgroundHeight}px`
                  }}
                  onDragOver={(event: React.DragEvent) => dragOverHandler(event)}
                  onDrop={(event: React.DragEvent) => dropHandler(event)}
                  ref={containerRef}
                >
                  {presentSiteElements.length > 0 &&
                    presentSiteElements.map((value, index) => {
                      if (value.uuid === null) {
                        value.uuid = uuidv4()
                      }
                      return (
                        value.element === TERASTOR && (
                          <SiteElement
                            key={index}
                            {...value}
                            onDoubleClick={() => {
                              setSelectedTeraStorName(value.sysName)
                              setIsTeraStorModalOpen(true)
                            }}
                          />
                        )
                      )
                    })}

                  <Moveable
                    scrollable={true}
                    scrollOptions={{
                      container: ".site-map",
                      threshold: 30,
                      checkScrollEvent: false,
                      throttleTime: 0
                    }}
                    ables={[Deleteable]}
                    props={{
                      deleteable: !props.viewOnly,
                      onDelete: deleteElement
                    }}
                    origin={false}
                    ref={moveableRef}
                    target={targets}
                    draggable={!props.viewOnly}
                    throttleDrag={1}
                    edgeDraggable={false}
                    startDragRotate={0}
                    throttleDragRotate={0}
                    scalable={!props.viewOnly}
                    keepRatio={true}
                    throttleScale={0}
                    renderDirections={["nw", "ne", "sw", "se"]}
                    snappable={true}
                    isDisplaySnapDigit={false}
                    isDisplayInnerSnapDigit={false}
                    snapGap={true}
                    snapDirections={{
                      top: true,
                      left: true,
                      bottom: true,
                      right: true,
                      center: true,
                      middle: true
                    }}
                    elementSnapDirections={{
                      left: true,
                      middle: true
                    }}
                    snapThreshold={5}
                    elementGuidelines={elementGuidelines}
                    snapContainer={".site-map"}
                    bounds={bounds}
                    rotatable={!props.viewOnly}
                    throttleRotate={0}
                    rotationPosition={"top"}
                    onRender={(e) => {
                      e.target.style.cssText += e.cssText
                    }}
                    onScroll={({ scrollContainer, direction }) => {
                      scrollContainer.scrollBy(direction[0] * 10, direction[1] * 10)
                    }}
                    onClickGroup={(e) => {
                      const current = selectoRef.current
                      if (current) {
                        current.clickTarget(e.inputEvent, e.inputTarget)
                      }
                    }}
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                    onScale={handleScale}
                    onScaleEnd={handleScaleEnd}
                    onScaleGroup={handleScaleGroup}
                    onScaleGroupEnd={handleScaleGroupEnd}
                    onDragGroup={handleDragGroup}
                    onDragGroupEnd={handleDragGroupEnd}
                    onRotate={handleRotate}
                    onRotateEnd={handleRotateEnd}
                    onRotateGroup={handleRotateGroup}
                    onRotateGroupEnd={handleRotateGroupEnd}
                  />
                </div>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
        <Selecto
          ref={selectoRef}
          dragContainer={containerRef.current}
          selectableTargets={[".terastor"]}
          hitRate={0}
          selectByClick={true}
          selectFromInside={false}
          toggleContinueSelect={["shift"]}
          ratio={0}
          keyContainer={window}
          onDragStart={(e) => {
            const moveable = moveableRef.current
            const target = e.inputEvent.target
            if (
              (moveable && moveable.isMoveableElement(target)) ||
              targets.some((t) => t === target || t.contains(target)) ||
              ctrlHeld
            ) {
              e.stop()
            }
          }}
          onSelectEnd={(e) => {
            const moveable = moveableRef.current
            if (e.isDragStart) {
              e.inputEvent.preventDefault()

              if (moveable) {
                moveable.waitToChangeTarget().then(() => {
                  moveable.dragStart(e.inputEvent)
                })
              }
            }
            e.selected.forEach((el) => {
              el.style.zIndex = SELECTED_Z_INDEX
            })

            setTargets((prevTarget) => {
              if (prevTarget) {
                prevTarget.map((target) => (target.style.zIndex = DEFAULT_Z_INDEX))
              }
              return e.selected
            })
          }}
        ></Selecto>
      </Row>

      {/* Modal goes here */}
      <div className="w-100 h-100 d-flex justify-content-center b-gray">
        <ErrorModal isOpen={isModalOpen} content={error} onClose={() => setIsModalOpen(false)} />
      </div>
      <div className="w-100 h-100 d-flex justify-content-center b-gray">
        <TeraStorDetailModal
          isOpen={isTeraStorModalOpen}
          content={selectedTeraStorName}
          onClose={() => {
            setIsTeraStorModalOpen(false)
          }}
        />
      </div>
      <DeleteTeraStorModal {...deleteModalProps} />
    </Container>
  )
})

SiteMap.displayName = "SiteMap"
export default SiteMap

interface TransformValues {
  translate: number[] | null
  rotate: number[] | null
  scale: number[] | null
}

// #region Component helper function
const parseTransformString = (transformString: string): TransformValues => {
  const translateRegex = /translate\((.*?)\)/
  const rotateRegex = /rotate\((.*?)\)/
  const scaleRegex = /scale\((.*?)\)/

  const extractTransformValues = (transformStr: string, regex: RegExp): number[] | null => {
    const match = transformStr.match(regex)
    return match ? match[1].split(",").map((value) => parseFloat(value.trim())) : null
  }

  const translateValues = extractTransformValues(transformString, translateRegex)
  const rotateValues = extractTransformValues(transformString, rotateRegex)
  const scaleValues = extractTransformValues(transformString, scaleRegex)

  return {
    translate: translateValues,
    rotate: rotateValues,
    scale: scaleValues
  }
}

function getValueInBound(x: number, minBound: number, maxBound: number): number {
  const value = Math.max(minBound, Math.min(x, maxBound))
  return value
}

function selectRandomItems(group: Element[]): Element[] {
  const shuffled = group.sort(() => 0.5 - Math.random())
  return shuffled.slice(0, Math.ceil(group.length / 3))
}

// Function to optimize element guidelines for larger collections
function optimizeElementGuidelines(collection: Element[]): Element[] {
  if (collection.length === 0) return []

  const lastItem = collection[Math.max(collection.length - 2, 0)]
  const groupSize = Math.floor(collection.length / 3)
  const [head, mid, tail] = ["head", "mid", "tail"].map((_, idx) =>
    collection.slice(idx * groupSize, (idx + 1) * groupSize)
  )

  // Select random items from each segment
  const optimizedSegments = [head, mid, tail].map(selectRandomItems)

  // Combine optimized segments with the last item
  return [...optimizedSegments.flat(), lastItem]
}

const findSmallestMissingIds = (ids: number[], count?: number): number[] => {
  let id = TERASTOR_START_INDEX
  const idArray: number[] = []
  let numberOfIds = count || 1
  while (numberOfIds > 0) {
    id++
    if (!ids.includes(id)) {
      numberOfIds--
      idArray.push(id)
    }
  }
  return idArray
}
// #endregion Component helper function
