import { RouterProvider } from "react-router-dom"
import Themeroutes from "./routes/Router"

import { Amplify, Auth } from "aws-amplify"
import awsconfig from "./aws-exports"

import { initCloudWatchRum } from "./utils/utils"
import { AppEnvEnum } from "./enum/app-env"

Amplify.configure(awsconfig)
Auth.configure({
  ...awsconfig,
  storage: localStorage
})

if (process.env.REACT_APP_ENV !== AppEnvEnum.Testing) initCloudWatchRum()

const App = () => <RouterProvider router={Themeroutes} />

export default App
