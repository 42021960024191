import { SiteEntity } from "../interfaces/SiteEntity"
import { ModifySite } from "../interfaces/ModifySite"
import api from "../utils/axios"
import { PageInfo } from "../interfaces/PageInfo"
import { ErrorItem } from "../interfaces/ErrorItem"
import { getCurrentUser } from "../store/counter/authSlice"
import { ListSite } from "../interfaces/ListSite"
import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import { FWType } from "interfaces/FWUploadTableProps"

const route = "api/sites"
export const apiAdminListSites = async (
  customerUniqueId?: number,
  pageNumber = 0,
  sortString = "",
  includeDelete = false,
  pageSize = process.env.REACT_APP_PAGE_SIZE,
  searchText?: string
) => {
  try {
    let endpoint = customerUniqueId ? `${route}?customerUniqueId=${customerUniqueId}&` : `${route}?`
    endpoint += `page=${pageNumber}&size=${pageSize}${sortString}&includeDelete=${includeDelete}${searchText ? "&customerNameOrSiteName=" + searchText : ""}`
    const response = await api.get(endpoint)

    return {
      status: response.status === 200,
      data: response.data.data as SiteEntity[],
      pageInfo: response.data.pageInfo as PageInfo
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list sites =>", e.message)

    writeApiLogEvent("get list sites", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_SITE_GET_LIST_FAILED)
    }
  }
}

export const apiAdminListAllSites = async (customerUniqueId?: number, showDeleted = false) => {
  try {
    const response = await api.post(`${route}/list`, {
      customerUniqueId: customerUniqueId || getCurrentUser().customerUniqueId,
      includeDelete: showDeleted
    })

    // this case we only need to handle 200 code, others will be treated as exceptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (response.status !== 200) throw new Error((response as any).msg)

    return {
      status: true,
      data: response.data as SiteEntity[]
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list all sites =>", e.message)

    writeApiLogEvent("get list all sites", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_SITE_GET_LIST_FAILED)
    }
  }
}

export const apiGetSiteBySiteUniqueId = async (siteUniqueId: number) => {
  try {
    const response = await api.get(`${route}/${siteUniqueId}`)

    return {
      status: response.status === 200,
      data: response.data as SiteEntity
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get site info =>", e.message)

    writeApiLogEvent("get site info", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_SITE_GET_INFO_FAILED)
    }
  }
}

export const apiAdminModifySite = async (siteUniqueId: number, body: ModifySite) => {
  try {
    let response
    if (siteUniqueId) {
      response = await api.put(`${route}/${siteUniqueId}`, body)
    } else {
      response = await api.post(`${route}`, body)
    }

    const data = response.data

    return {
      status: [200, 201].includes(response.status),
      errorLists: data.errorLists ? data.errorLists.map((obj: ErrorItem) => obj.errorMsg) : [],
      msg: data.msg || "",
      errorCode: data.errorCode || 0,
      externalErrorData: data.externalErrorData || {},
      additionalMsg: data.additionalMsg || "",
      isProvisionFailure: data.isProvisionFailure || false
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: modify site =>", e.message)

    writeApiLogEvent("modify site", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(
        e.code,
        siteUniqueId ? Message.ERR_SITE_MODIFY_FAILED : Message.ERR_SITE_CREATE_FAILED
      )
    }
  }
}

export const apiAdminDeleteSite = async (id: string, customerUniqueId: number) => {
  try {
    const response = await api.delete(`${route}/${id}?customerUniqueId=${customerUniqueId}`)

    return {
      status: [204, 200].includes(response.status),
      message: response.data.msg || ""
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: delete site =>", e.message)

    writeApiLogEvent("delete site", e, id)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_SITE_DELETE_FAILED)
    }
  }
}

export const apiGetSiteByListUniqueIds = async (body: ListSite, customerUniqueId: number) => {
  try {
    const response = await api.post(`${route}/list?customerUniqueId=${customerUniqueId}`, body)

    return {
      status: response.status === 200,
      data: response.data as SiteEntity[]
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get list site by uniqueIds =>", e.message)

    writeApiLogEvent("get list site by uniqueIds", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_SITE_GET_LIST_FAILED)
    }
  }
}

export const apiAdminStageFirmware = async (type: FWType, siteIds: number[], versionId: number) => {
  try {
    const body = { siteIds: siteIds, versionId: versionId }
    const response = await api.put(`${route}/stage/${type}`, body)

    const data = response.data

    return {
      status: [200, 201].includes(response.status),
      errorLists: data.errorLists ? data.errorLists.map((obj: ErrorItem) => obj.errorMsg) : [],
      msg: data.msg || "",
      errorCode: data.errorCode || 0,
      externalErrorData: data.externalErrorData || {},
      additionalMsg: data.additionalMsg || "",
      isProvisionFailure: data.isProvisionFailure || false
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: stage fw =>", e.message)

    writeApiLogEvent("stage fw", e, siteIds.join(", "))

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_FW_UPDATE_STAGE_FW_FAILED)
    }
  }
}
