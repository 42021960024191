import documentCardHeader from "../../assets/images/icons/document/category/document-card-header.svg"
import downloadDocument from "../../assets/images/icons/document/category/download.svg"
import { DocEntity } from "interfaces/doc/DocEntity"
import "../../assets/scss/document/category.scss"
import { Button, Card, CardBody, CardImg, CardTitle } from "reactstrap"
import { Tooltip as ReactTooltip } from "react-tooltip"

const DocumentCard = ({
  doc,
  onDownload
}: {
  doc: DocEntity
  onDownload: (doc: DocEntity) => void
}) => {
  const handleDownload = () => {
    onDownload(doc)
  }
  return (
    <Card className="document-card">
      <CardImg className="p-2" alt={doc.fileName} src={documentCardHeader} top width="50" />
      <CardBody className="pt-1">
        <CardTitle className="mb-0">
          <div className="d-flex justify-content-between">
            <p
              className="document-file-name"
              data-tooltip-id={`docFileName-${doc.id}`}
              data-tooltip-html={doc.fileName}
            >
              {doc.fileName}
            </p>
            <ReactTooltip id={`docFileName-${doc.id}`} place="top" />
            <Button onClick={handleDownload} className="document-download-button">
              <img src={downloadDocument} width={25} alt="Download" />
            </Button>
          </div>
        </CardTitle>
        <span
          data-tooltip-id={`docDescription-${doc.id}`}
          data-tooltip-html={doc.description}
          className="document-description"
        >
          {doc.description}
        </span>
        <ReactTooltip id={`docDescription-${doc.id}`} place="top" />
      </CardBody>
    </Card>
  )
}

export default DocumentCard
