import { useSelector } from "react-redux"

import { getFilters, getLoadUnit } from "../../../store/counter/visualizationSlice"

import { VisualizationFilterEnum } from "../../../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../../../enum/visualization/tab/battery-filter"
import { LoadFilterEnum } from "../../../enum/visualization/tab/load-filter"

import { getVisuaUnit } from "../../../utils/utils"
import Messages from "../../../utils/messages"
import { Color } from "../../../enum/visualization/color"

interface ViewBox {
  x: number
  y: number
  height: number
}
interface YAxisLeftLabelProps {
  viewBox: ViewBox
  fontSizeSmall: string
  fontSizeLarge: string
  displayInSmallFontSize: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YAxisLeftLabel = (props: YAxisLeftLabelProps) => {
  const { x, y, height } = props.viewBox

  const loadUnit = useSelector(getLoadUnit)
  const filters = useSelector(getFilters)
  const isBattery = filters === VisualizationFilterEnum.Battery
  const isCurrent = loadUnit === LoadFilterEnum.Current

  const Y_COORDINATE = 5

  return (
    <g transform={`translate(${x},${y})`}>
      {isBattery ? (
        <>
          <text
            x={-height / 2 + 20}
            y={Y_COORDINATE}
            transform="rotate(-90)"
            textAnchor="start"
            fill={Color.ReactivePower}
            fontSize={props.displayInSmallFontSize ? props.fontSizeSmall : props.fontSizeLarge}
          >
            {Messages.LBL_VISUALIZATION_SOC} {getVisuaUnit(BatterFilterEnum.SOC)}
          </text>
          <text
            x={-(height / 2)}
            y={Y_COORDINATE}
            transform="rotate(-90)"
            textAnchor="end"
            fill={Color.Temperature}
            fontSize={props.displayInSmallFontSize ? props.fontSizeSmall : props.fontSizeLarge}
          >
            {Messages.LBL_VISUALIZATION_TEMPERATURE} {getVisuaUnit(BatterFilterEnum.Temperature)}
          </text>
        </>
      ) : (
        <text
          x={-(height / 2 + 40)}
          y={Y_COORDINATE}
          transform="rotate(-90)"
          textAnchor="left"
          fill={Color.Voltage}
          fontSize={props.displayInSmallFontSize ? props.fontSizeSmall : props.fontSizeLarge}
        >
          {isCurrent
            ? `${Messages.LBL_VISUALIZATION_CURRENT} ${getVisuaUnit(LoadFilterEnum.Current)}`
            : `${Messages.LBL_VISUALIZATION_VOLTAGE} ${getVisuaUnit(BatterFilterEnum.Voltage)}`}
        </text>
      )}
    </g>
  )
}

export default YAxisLeftLabel
