import { useEffect, useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  Form,
  Label,
  Input,
  Row,
  Col,
  Spinner,
  Alert,
  Tooltip,
  FormText,
  FormGroup,
  FormFeedback
} from "reactstrap"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import PhoneInput, { parsePhoneNumber, Country } from "react-phone-number-input"
import { Value as E164Number, isValidPhoneNumber } from "react-phone-number-input"

import "react-phone-number-input/style.css"

import calendar from "../../assets/images/icons/admin/calendar.svg"
import { apiAdminModifySite } from "../../services/apiSitesManagement"
import { ModifySite } from "../../interfaces/ModifySite"
import { SiteModalProps } from "../../interfaces/SiteModalProps"
import Moment from "moment"

import { SERVER_DATE_FORMAT, DEFAULT_PHONE_COUNTRY_CODE, SITE_STATUS } from "../../utils/constants"
import Message from "../../utils/messages"
import { writeCodeLogEvent } from "../../utils/utils"

import AlertModal from "components/modal/AlertModal"
import { AlertModalProps } from "interfaces/AlertModalProps"
import { apiMonitoringDataConnectedCheck } from "../../services/apiSiteMonitoring"

const SiteModal = (props: SiteModalProps) => {
  const [error, setError] = useState("")
  const [errorLists, setErrorLists] = useState([])
  const [isLoadingStatus, setLoadingStatus] = useState(false)

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "error",
    message: ""
  })

  const [siteInfo, setSiteInfo] = useState<ModifySite>({
    description: "",
    address1: "",
    city: "",
    commissionDate: null,
    country: "",
    customerUniqueId: 0,
    customerName: "",
    numTeraStors: 0,
    elevation: "",
    siteName: "",
    stateProvince: "",
    teraStorPartNumber: "",
    shippingDate: null,
    poNumber: "",
    postalCode: "",
    poDate: null,
    longitude: "",
    latitude: "",
    status: null
  })
  const [phone, setPhoneNumber] = useState<E164Number | undefined>()
  const [phoneCountry, setPhoneCountry] = useState<Country>(DEFAULT_PHONE_COUNTRY_CODE)

  const [isSubmiting, setSubmit] = useState(false)
  const [requiredErrors, setRequiredErrors] = useState<Partial<Record<keyof ModifySite, string>>>(
    {}
  )

  const ELEVATION_REGEX = new RegExp(/^(?:[1-9]?\d{1,3}|0)(?:\.\d+)?$/)
  const LATITUDE_REGEX = new RegExp(/^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?))$/)
  const LONGITUDE_REGEX = new RegExp(/^([-+]?(1[0-7]\d(\.\d+)?|180(\.0+)?|([1-9]?\d|0)(\.\d+)?))$/)
  const isInvalidElevation = () =>
    siteInfo.elevation
      ? !ELEVATION_REGEX.test(`${siteInfo.elevation}`) || parseFloat(siteInfo.elevation) > 9999
      : false

  const validateModifySiteRequired = (): boolean => {
    let valid = true
    const newErrors: Partial<Record<keyof ModifySite, string>> = {}

    Message.VALIDATE_SITE_REQUIRED_FIELDS.forEach((errorMessage, key) => {
      if (!siteInfo[key]) {
        newErrors[key] = errorMessage
        valid = false
      }
    })

    setRequiredErrors(newErrors)
    return valid
  }

  useEffect(() => {
    const data = props.data
    if (data) {
      setSiteInfo({
        ...data,
        ...(!data.commissionDate && {
          commissionDate: null
        }),
        ...(!data.shippingDate && {
          shippingDate: null
        }),
        ...(!data.poDate && {
          poDate: null
        })
      })
      setPhoneNumber(data.managerPhoneNumber)
      const parsePhone = parsePhoneNumber(data.managerPhoneNumber || "")
      if (parsePhone) {
        setPhoneCountry(parsePhone.country || DEFAULT_PHONE_COUNTRY_CODE)
      } else {
        setPhoneCountry(DEFAULT_PHONE_COUNTRY_CODE)
      }
    }
    setRequiredErrors({})
    setError("")
    setErrorLists([])
  }, [props.data])

  useEffect(() => {
    if (props.data?.siteUniqueId) {
      setLoadingStatus(true)
      apiMonitoringDataConnectedCheck(props.customerUniqueId)
        .then((res) => {
          if (res.status && props.data?.siteUniqueId) {
            const isDataConnected = res.data[props.data.siteUniqueId]
            if (isDataConnected) {
              setSiteInfo((siteInfo) => {
                return {
                  ...siteInfo,
                  ...{
                    status: SITE_STATUS.CONNECTED
                  }
                }
              })
            }
          }
          setLoadingStatus(false)
        })
        .catch((e) => {
          console.error("Code exception: handle data connected check =>", e.message)
          setLoadingStatus(false)

          writeCodeLogEvent("handle data connected check", e, props.data?.siteUniqueId)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customerUniqueId, props.data?.siteUniqueId])

  const triggerCalendar = (idElement: string) => {
    const inpStartDate = document.getElementById(idElement) as HTMLInputElement
    inpStartDate.focus()
  }

  const handleDateChange = (target: string, date: Date) => {
    const convert = date ? Moment(date).format(SERVER_DATE_FORMAT) : null
    if (target === "commissionDate") {
      setSiteInfo({
        ...siteInfo,
        commissionDate: convert
      })
    } else if (target === "shippingDate") {
      setSiteInfo({
        ...siteInfo,
        shippingDate: convert
      })
    } else if (target === "poDate") {
      setSiteInfo({
        ...siteInfo,
        poDate: convert
      })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, fullValue: string) => {
    // Allow select all key (Ctrl+A or Command+A)
    if (
      ((event.ctrlKey || event.metaKey) && event.key === "a") ||
      event.key === "v" ||
      event.key === "c"
    ) {
      return
    }

    // Allow left and right arrow keys, backspace key and tab key
    if (
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Backspace" ||
      event.key === "Tab"
    ) {
      return
    }

    // Allow only numbers, one '.', and the first character to be '-'
    if (
      !(
        (event.key >= "0" && event.key <= "9") ||
        (event.key === "." && fullValue.indexOf(".") === -1 && fullValue !== "") ||
        (event.key === "-" && event.currentTarget.selectionStart === 0)
      )
    ) {
      event.preventDefault()
    }
  }

  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmit(true)

    if (
      (siteInfo.longitude && !LONGITUDE_REGEX.test(`${siteInfo.longitude}`)) ||
      (siteInfo.latitude && !LATITUDE_REGEX.test(`${siteInfo.latitude}`)) ||
      isInvalidElevation() ||
      (phone && !isValidPhoneNumber(phone)) ||
      !validateModifySiteRequired()
    ) {
      setSubmit(false)
      return false
    }

    const body: ModifySite = {
      ...siteInfo,
      customerUniqueId: props.customerUniqueId,
      managerPhoneNumber: phone ? `${phone}` : "",
      primaryContactEmail: siteInfo.primaryContactEmail === "" ? null : siteInfo.primaryContactEmail
    }
    apiAdminModifySite(props.data?.siteUniqueId || 0, body)
      .then((res) => {
        if (res.status) {
          props.toggle()
          if (props.data?.siteUniqueId) props.editCallback(props.data.siteUniqueId, body)
          else props.addCallback()
        } else if (res.isProvisionFailure) {
          setAlertModalProps({
            ...alertModalProps,
            modal: true,
            status: "error",
            message: res.msg
          })
        } else {
          setError(res.message || "")
          setErrorLists(res.errorLists || [])
        }
        setSubmit(false)
      })
      .catch((e) => {
        console.error("Code exception: handle modify site =>", e.message)
        setSubmit(false)
        setError(
          props.data?.siteUniqueId ? Message.ERR_SITE_MODIFY_FAILED : Message.ERR_SITE_CREATE_FAILED
        )

        writeCodeLogEvent("handle modify site", e, props.data?.siteUniqueId)
      })
  }

  return (
    <>
      <Modal
        isOpen={props.modal}
        toggle={props.toggle}
        size={props.size}
        centered={true}
        backdrop={"static"}
        onClosed={props.onClose}
        unmountOnClose={true}
      >
        <ModalBody className="p-4" id="siteModal">
          <h4 className="mb-2 title">
            {props.data?.siteUniqueId
              ? Message.LBL_SITE_MANAGER_EDIT_TITLE
              : Message.LBL_SITE_MANAGER_CREATE_TITLE}
          </h4>
          <hr></hr>
          <Form type="post" onSubmit={onSubmit}>
            {error && (
              <Alert color="danger" className="mb-3">
                <p className="mb-0">{error}</p>
              </Alert>
            )}
            {errorLists.length > 0 && (
              <Alert color="danger" className="mb-3">
                <ul className="mb-0">
                  {errorLists.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}
            {props.data?.siteUniqueId ? (
              <p className="text-primary fw-bold">
                {Message.LBL_SITE_MANAGER_SITE_ID} #{props.data?.siteUniqueId}
              </p>
            ) : (
              ""
            )}
            <Row className="mb-4">
              <Row>
                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="customerName">{Message.LBL_CUSTOMER_NAME} *</Label>
                      <Input
                        disabled={true}
                        id="customerName"
                        name="customerName"
                        type="text"
                        required
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, customerName: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.customerName}
                        tabIndex={1}
                        placeholder={Message.LBL_CUSTOMER_NAME}
                        data-testid="inpCustomerName"
                      />
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="siteName">{Message.LBL_SITE_NAME} *</Label>
                      <Input
                        id="siteName"
                        name="siteName"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, siteName: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.siteName}
                        tabIndex={2}
                        placeholder="Site name"
                        invalid={!!requiredErrors.siteName && !siteInfo.siteName}
                        data-testid="inpSiteName"
                      />
                      <FormFeedback>{requiredErrors.siteName}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <Label for="inpElevation">{Message.LBL_ELEVATION_NAME}</Label>
                    <Input
                      id="inpElevation"
                      name="elevation"
                      type="text"
                      onChange={(event) =>
                        setSiteInfo({ ...siteInfo, elevation: event.currentTarget.value })
                      }
                      onKeyDown={(event) => handleKeyDown(event, `${siteInfo.elevation}`)}
                      defaultValue={siteInfo.elevation}
                      tabIndex={3}
                      placeholder={Message.LBL_ELEVATION_NAME}
                      invalid={isInvalidElevation()}
                      step="any"
                      data-testid="inpElevation"
                    />
                    <Tooltip placement="bottom" isOpen={isInvalidElevation()} target="inpElevation">
                      {Message.MSG_ELEVATION_VALID}
                    </Tooltip>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="siteDiscription">{Message.LBL_SITE_DESCRIPTION_NAME} *</Label>
                      <Input
                        id="description"
                        name="description"
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, description: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.description}
                        tabIndex={4}
                        placeholder={Message.LBL_SITE_DESCRIPTION_NAME}
                        invalid={!!requiredErrors.description && !siteInfo.description}
                        data-testid="inpDescription"
                      />
                      <FormFeedback>{requiredErrors.description}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <Label for="siteGroup">{Message.LBL_SITE_GROUP_NAME}</Label>
                    <Input
                      id="siteGroup"
                      name="siteGroup"
                      onChange={(event) =>
                        setSiteInfo({ ...siteInfo, siteGroup: event.currentTarget.value })
                      }
                      defaultValue={siteInfo.siteGroup}
                      tabIndex={5}
                      placeholder={Message.LBL_SITE_GROUP_NAME}
                      data-testid="inpSiteGroup"
                    />
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <Label for="inpLatitude">{Message.LBL_LATITUDE_NAME}</Label>
                    <Input
                      id="inpLatitude"
                      name="latitude"
                      type="text"
                      onChange={(event) =>
                        setSiteInfo({ ...siteInfo, latitude: event.currentTarget.value })
                      }
                      onKeyDown={(event) => handleKeyDown(event, `${siteInfo.latitude}`)}
                      defaultValue={siteInfo.latitude}
                      tabIndex={6}
                      placeholder={Message.LBL_LATITUDE_NAME}
                      invalid={
                        siteInfo.latitude ? !LATITUDE_REGEX.test(`${siteInfo.latitude}`) : false
                      }
                      step="any"
                      data-testid="inpLatitude"
                    />
                    <Tooltip
                      placement="bottom"
                      isOpen={
                        siteInfo.latitude ? !LATITUDE_REGEX.test(`${siteInfo.latitude}`) : false
                      }
                      target="inpLatitude"
                    >
                      {Message.MSG_LATITUDE_VALID}
                    </Tooltip>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="address1">{Message.LBL_ADDRESS_NAME} *</Label>
                      <Input
                        id="address1"
                        name="address1"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, address1: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.address1}
                        tabIndex={7}
                        placeholder={Message.LBL_ADDRESS_NAME}
                        invalid={!!requiredErrors.address1 && !siteInfo.address1}
                        data-testid="inpAddress1"
                      />
                      <FormFeedback>{requiredErrors.address1}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="city">{Message.LBL_CITY_NAME} *</Label>
                      <Input
                        id="city"
                        name="city"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, city: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.city}
                        tabIndex={8}
                        placeholder={Message.LBL_CITY_NAME}
                        invalid={!!requiredErrors.city && !siteInfo.city}
                        data-testid="inpCity"
                      />
                      <FormFeedback>{requiredErrors.city}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <Label for="inpLongitude">{Message.LBL_LONGITUDE_NAME}</Label>
                    <Input
                      id="inpLongitude"
                      name="longitude"
                      type="text"
                      onChange={(event) =>
                        setSiteInfo({ ...siteInfo, longitude: event.currentTarget.value })
                      }
                      defaultValue={siteInfo.longitude}
                      onKeyDown={(event) => handleKeyDown(event, `${siteInfo.longitude}`)}
                      tabIndex={9}
                      placeholder={Message.LBL_LONGITUDE_NAME}
                      invalid={
                        siteInfo.longitude ? !LONGITUDE_REGEX.test(`${siteInfo.longitude}`) : false
                      }
                      step="any"
                      data-testid="inpLongitude"
                    />
                    <Tooltip
                      placement="bottom"
                      isOpen={
                        siteInfo.longitude ? !LONGITUDE_REGEX.test(`${siteInfo.longitude}`) : false
                      }
                      target="inpLongitude"
                    >
                      {Message.MSG_LONGITUDE_VALID}
                    </Tooltip>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="stateProvince">{Message.LBL_STATE_PROVINCE_NAME} *</Label>
                      <Input
                        id="stateProvince"
                        name="stateProvince"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, stateProvince: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.stateProvince}
                        tabIndex={10}
                        placeholder={Message.LBL_STATE_PROVINCE_NAME}
                        invalid={!!requiredErrors.stateProvince && !siteInfo.stateProvince}
                        data-testid="inpStateProvince"
                      />
                      <FormFeedback>{requiredErrors.stateProvince}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="country">{Message.LBL_COUNTRY_NAME} *</Label>
                      <Input
                        id="country"
                        name="country"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({ ...siteInfo, country: event.currentTarget.value })
                        }
                        defaultValue={siteInfo.country}
                        tabIndex={11}
                        placeholder={Message.LBL_COUNTRY_NAME}
                        invalid={!!requiredErrors.country && !siteInfo.country}
                        data-testid="inpCountry"
                      />
                      <FormFeedback>{requiredErrors.country}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <FormGroup className="mb-4">
                    <Label for="postalCode">{Message.LBL_POSTAL_CODE_NAME} *</Label>
                    <Input
                      id="postalCode"
                      name="postalCode"
                      type="text"
                      onChange={(event) =>
                        setSiteInfo({ ...siteInfo, postalCode: event.currentTarget.value })
                      }
                      defaultValue={siteInfo.postalCode}
                      tabIndex={12}
                      placeholder={Message.LBL_POSTAL_CODE_NAME}
                      invalid={!!requiredErrors.postalCode && !siteInfo.postalCode}
                      data-testid="inpPostalCode"
                    />
                    <FormFeedback>{requiredErrors.postalCode}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="numTeraStors">{Message.LBL_NUM_TERASTORS_NAME} *</Label>
                      <Input
                        id="numTeraStors"
                        name="numTeraStors"
                        type="number"
                        onChange={(event) =>
                          setSiteInfo({
                            ...siteInfo,
                            numTeraStors: parseInt(event.currentTarget.value)
                          })
                        }
                        defaultValue={siteInfo.numTeraStors || ""}
                        tabIndex={13}
                        placeholder={Message.LBL_NUM_TERASTORS_NAME}
                        min={1}
                        invalid={!!requiredErrors.numTeraStors && !siteInfo.numTeraStors}
                        disabled={
                          isLoadingStatus ||
                          (!!siteInfo.status &&
                            [SITE_STATUS.CONNECTED, SITE_STATUS.READY_FOR_INSTALLATION].includes(
                              siteInfo.status
                            ))
                        }
                        data-testid="inpNumTeraStors"
                      />
                      <FormFeedback>{requiredErrors.numTeraStors}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <FormGroup>
                      <Label for="teraStorPartNumber">
                        {Message.LBL_TERASTOR_PART_NUMBER_NAME} *
                      </Label>
                      <Input
                        id="teraStorPartNumber"
                        name="teraStorPartNumber"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({
                            ...siteInfo,
                            teraStorPartNumber: event.currentTarget.value
                          })
                        }
                        defaultValue={siteInfo.teraStorPartNumber}
                        tabIndex={14}
                        placeholder={Message.LBL_TERASTOR_PART_NUMBER_NAME}
                        invalid={
                          !!requiredErrors.teraStorPartNumber && !siteInfo.teraStorPartNumber
                        }
                        data-testid="inpTeraStorPartNumber"
                      />
                      <FormFeedback>{requiredErrors.teraStorPartNumber}</FormFeedback>
                    </FormGroup>
                  </div>
                </Col>

                <Col md="4">
                  <FormGroup className="mb-4">
                    <Label for="poNumber">{Message.LBL_PO_NUMBER_NAME} *</Label>
                    <Input
                      id="poNumber"
                      name="poNumber"
                      type="text"
                      onChange={(event) =>
                        setSiteInfo({
                          ...siteInfo,
                          ...{ poNumber: event.currentTarget.value }
                        })
                      }
                      defaultValue={siteInfo.poNumber}
                      tabIndex={15}
                      placeholder={Message.LBL_PO_NUMBER_NAME}
                      invalid={!!requiredErrors.poNumber && !siteInfo.poNumber}
                      data-testid="inpPoNumber"
                    />
                    <FormFeedback>{requiredErrors.poNumber}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="mb-4">
                    <Label for="commissionDate">{Message.LBL_COMMISSION_DATE_NAME}</Label>
                    <div className="react-datepicker-outsider">
                      <DatePicker
                        className="form-control"
                        selected={
                          siteInfo.commissionDate
                            ? Moment(siteInfo.commissionDate, SERVER_DATE_FORMAT).toDate()
                            : null
                        }
                        tabIndex={16}
                        onChange={(date: Date) => handleDateChange("commissionDate", date)}
                        name="commissionDate"
                        id="commissionDate"
                        placeholderText={Message.LBL_COMMISSION_DATE_PLACE_HOLDER_NAME}
                        data-testid="inpCommissionDate"
                        dataTestId="inpCommissionDate"
                      />
                      <img
                        src={calendar}
                        alt="calendar"
                        className="calendar"
                        onClick={() => {
                          triggerCalendar("commissionDate")
                        }}
                        role="button"
                      />
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <Label for="shippingDate">{Message.LBL_SHIPPING_DATE_NAME}</Label>
                    <div className="react-datepicker-outsider">
                      <DatePicker
                        className="form-control"
                        selected={
                          siteInfo.shippingDate
                            ? Moment(siteInfo.shippingDate, SERVER_DATE_FORMAT).toDate()
                            : null
                        }
                        tabIndex={17}
                        onChange={(date: Date) => handleDateChange("shippingDate", date)}
                        name="shippingDate"
                        id="shippingDate"
                        placeholderText={Message.LBL_SHIPPING_DATE_PLACE_HOLDER_NAME}
                        data-testid="inpShippingDate"
                      />
                      <img
                        src={calendar}
                        alt="calendar"
                        className="calendar"
                        onClick={() => {
                          triggerCalendar("shippingDate")
                        }}
                        role="button"
                      />
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="mb-4">
                    <Label for="poDate">{Message.LBL_PO_DATE_NAME}</Label>
                    <div className="react-datepicker-outsider">
                      <DatePicker
                        className="form-control"
                        selected={
                          siteInfo.poDate
                            ? Moment(siteInfo.poDate, SERVER_DATE_FORMAT).toDate()
                            : null
                        }
                        tabIndex={18}
                        onChange={(date: Date) => handleDateChange("poDate", date)}
                        name="poDate"
                        id="poDate"
                        placeholderText={Message.LBL_PO_DATE_NAME}
                        data-testid="inpPoDate"
                      />
                      <img
                        src={calendar}
                        alt="calendar"
                        className="calendar"
                        onClick={() => {
                          triggerCalendar("poDate")
                        }}
                        role="button"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col xxl="12">
                <div className="bg-secondary p-3 mb-4 rounded border">
                  <h4 className="mb-4 fw-bold">{Message.LBL_POINT_OF_CONTACT}</h4>
                  <Row>
                    <Col>
                      <Label for="poNumber">{Message.LBL_SITE_MANAGER_NAME}</Label>
                      <Input
                        id="managerName"
                        name="managerName"
                        type="text"
                        onChange={(event) =>
                          setSiteInfo({
                            ...siteInfo,
                            ...{ managerName: event.currentTarget.value }
                          })
                        }
                        defaultValue={siteInfo.managerName}
                        tabIndex={20}
                        placeholder={Message.LBL_SITE_MANAGER_NAME}
                        data-testid="inpManagerName"
                      />
                    </Col>
                    <Col>
                      <div>
                        <Label for="inpPhonenumber">{Message.LBL_PHONE_NUMBER_NAME}</Label>
                        <PhoneInput
                          id="inpPhonenumber"
                          name="phone"
                          className={
                            phone
                              ? isValidPhoneNumber(phone)
                                ? "form-control phone-wrapper"
                                : "is-invalid form-control phone-wrapper"
                              : "form-control phone-wrapper"
                          }
                          value={phone}
                          onChange={setPhoneNumber}
                          placeholder={Message.LBL_PHONE_NUMBER_NAME}
                          tabIndex={21}
                          defaultCountry={phoneCountry}
                          data-testid="inpPhonenumber"
                        />
                        {phone && !isValidPhoneNumber(phone) && (
                          <FormText className="text-danger">
                            {Message.MSG_PHONE_NUMBER_VALID}
                          </FormText>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <div className="d-lg-flex justify-content-between text-center text-lg-start">
              <p className="mb-3 fw-bold">* {Message.REQUIRED_QUESTION_MESSAGE}</p>
              <div>
                <Button
                  color="light"
                  type="button"
                  className="me-3 py-2 px-4 btn-cancel"
                  onClick={props.toggle}
                  tabIndex={1000}
                  disabled={isSubmiting}
                  data-testid="btnCancel"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="py-2 px-4"
                  type="submit"
                  tabIndex={1001}
                  disabled={isSubmiting}
                  data-testid="btnSubmit"
                >
                  {isSubmiting && (
                    <Spinner color="light" size="sm" className="me-1">
                      {Message.MSG_LOADING}
                    </Spinner>
                  )}
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <AlertModal {...alertModalProps} />
    </>
  )
}

export default SiteModal
