type AllowedFileTypes = "image/png" | "image/jpeg" | "application/pdf"
const MAX_IMAGE_SIZE_MB = 5 // Maximum image size in megabytes
const FILE_SIZE_ERROR = `The file you uploaded exceeds our maximum file size of (${MAX_IMAGE_SIZE_MB}MB). Please upload a smaller file.`
const FILE_TYPE_ERROR = "The file you is not compatible. Please upload a file that is PNG or JPEG."
const FILE_NAME_ERROR =
  "File name is invalid. File name only includes characters, numbers, _, -, ., and white spaces."

export function imageValidator(file: File): { valid: boolean; message: string } {
  if (!isValidFileType(file)) {
    return { valid: false, message: FILE_TYPE_ERROR }
  } else if (!isFileSizeValid(file)) {
    return { valid: false, message: FILE_SIZE_ERROR }
  } else if (!isFileNameValid(file.name)) {
    return { valid: false, message: FILE_NAME_ERROR }
  }
  return { valid: true, message: "" }
}

const isValidFileType = (file: File): boolean => {
  const allowedTypes: AllowedFileTypes[] = ["image/png", "image/jpeg", "application/pdf"]
  return allowedTypes.includes(file.type as AllowedFileTypes)
}

const isFileSizeValid = (file: File): boolean => {
  const fileSizeInMB = file.size / (1024 * 1024) // Convert bytes to megabytes
  return fileSizeInMB <= MAX_IMAGE_SIZE_MB
}

const isFileNameValid = (fileName: string): boolean => {
  const fileNamePartIdx = fileName.lastIndexOf(".")
  if (fileNamePartIdx === -1) {
    return false
  }
  const baseFileName = fileName.substring(0, fileNamePartIdx)

  // Allow character number, _, -, ., and white space
  const pattern = /^[a-zA-Z0-9_\-.\s]+$/
  return pattern.test(baseFileName)
}
