import { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Moment from "moment"
import { Label } from "reactstrap"

import calendar from "../../assets/images/icons/admin/calendar.svg"

import { SERVER_DATE_FORMAT } from "../../utils/constants"

interface DatePickerInputProps {
  outerWrapId?: string
  outerWrapClass?: string

  label: string
  labelClass?: string

  innerWrapClass?: string
  inputId: string
  name: string
  placeHolder: string

  dateFormat?: string
  date?: string
  setDate?: (date: string | null) => void

  minDate?: string
  maxDate?: string

  required?: boolean
}
const DatePickerInput = (props: DatePickerInputProps) => {
  const [date, setDate] = useState<string | null>(props.date || new Date().toISOString())

  useEffect(() => {
    setDate(props.date || new Date().toISOString())
  }, [props])

  const handleDateChange = (date: Date) => {
    const convert = date ? Moment(date).format(props.dateFormat || SERVER_DATE_FORMAT) : null

    if (props.required && !convert) return

    // update date value
    setDate(convert)
    props.setDate && props.setDate(convert)
  }

  // Click calendar image open calendar popup
  const triggerCalendar = () => {
    const inpStartDate = document.getElementById(props.inputId) as HTMLInputElement
    inpStartDate.focus()
  }

  return (
    <div className={props.outerWrapClass || ""} id={props.outerWrapId || ""}>
      <Label for={props.inputId} className={props.labelClass || ""}>
        {props.label}
      </Label>
      <div className={`${props.innerWrapClass || ""} react-datepicker-outsider`}>
        <DatePicker
          className="form-control"
          name={props.name}
          id={props.inputId}
          selected={date ? Moment(date, props.dateFormat || SERVER_DATE_FORMAT).toDate() : null}
          onChange={(date: Date) => handleDateChange(date)}
          placeholderText={props.placeHolder}
          minDate={props.minDate ? Moment(props.minDate, SERVER_DATE_FORMAT).toDate() : null}
          maxDate={props.maxDate ? Moment(props.maxDate, SERVER_DATE_FORMAT).toDate() : null}
        />
        <img
          src={calendar}
          alt="calendar"
          className="calendar"
          onClick={() => {
            triggerCalendar()
          }}
          role="button"
        />
      </div>
    </div>
  )
}

export default DatePickerInput
