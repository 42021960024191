import { memo, FocusEvent, useEffect, useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CloseButton } from "reactstrap"
import { SearchData, SearchObject, SearchItems } from "interfaces/monitoring/SearchProps"
import { isValidGroupName } from "store/counter/monitoringSlice"
import { SiteEntity } from "interfaces/SiteEntity"
import Messages from "utils/messages"

import search from "../../assets/images/icons/admin/search.svg"
import siteNormal from "../../assets/images/monitoring/img/site-monitoring.svg"
import bateryGroup from "../../assets/images/monitoring/img/batery-group.svg"
import batteryIdle from "../../assets/images/monitoring/img/batery-idle.svg"

const defaultSearchItems = {
  site: [],
  group: []
}

const MonitoringSearch = (props: SearchData) => {
  const { listSites, sitesOverviewData, onSiteSelected } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [items, setItems] = useState<SearchItems>(defaultSearchItems)
  const [filteredItems, setFilteredItems] = useState<SearchItems>(defaultSearchItems)
  const { MSG_ITEMS_FOUND, MSG_NO_ITEMS_FOUND } = Messages

  const clearSearch = () => {
    setDropdownOpen(false)
    setSearchTerm("")
    setFilteredItems(defaultSearchItems)
  }

  const handleSearchChange = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase()
    setSearchTerm(value)
    if (value) {
      const { site, group } = items
      const searchItems = (data: SearchObject[]) =>
        data.filter((item) => item.name.toLowerCase().includes(value))

      setFilteredItems({
        site: searchItems(site),
        group: searchItems(group)
      })
      setDropdownOpen(true)
    } else {
      clearSearch()
    }
  }

  const hasSearchData = () => {
    const { site, group } = filteredItems
    return site.length > 0 || group.length > 0
  }

  const getSearchIcon = (type: string) => {
    switch (type) {
      case "site":
        return siteNormal
      case "group":
        return bateryGroup
      default:
        return batteryIdle
    }
  }

  const handleClick = (searchObject: SearchObject) => {
    const divElement = document.getElementById(`siteLink-${searchObject.siteId}`)
    if (divElement) {
      divElement.style.scrollMarginTop = "55px"
      divElement.scrollIntoView()
    }
    onSiteSelected(searchObject.site, searchObject)
    clearSearch()
  }

  const printList = (data: SearchObject[], type: string) => {
    const icon = getSearchIcon(type)
    return data.map((item, index) => {
      const { name: itemName, site } = item
      const regex = new RegExp(`(${searchTerm})`, "gi")
      const parts = itemName.split(regex)

      return (
        <DropdownItem
          tag="li"
          key={index}
          onClick={() => handleClick(item)}
          title={`${itemName}${type === "group" ? ` (${site.siteName})` : ""}`}
        >
          <img src={icon} alt="search-icon" />{" "}
          {parts.map((part, i) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? (
              <strong key={`search-${i}`}>{part}</strong>
            ) : (
              part
            )
          )}
          {type === "group" && (
            <span className="font-s-12">
              {" "}
              {"("}
              <img src={siteNormal} alt="site-icon" />
              {site.siteName}
              {")"}
            </span>
          )}
        </DropdownItem>
      )
    })
  }

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  useEffect(() => {
    if (!dropdownOpen) {
      setSearchTerm("")
    }
  }, [dropdownOpen])

  useEffect(() => {
    const siteData: SearchObject[] = []
    const groupData: SearchObject[] = []
    listSites.forEach((site) => {
      siteData.push({
        site: site,
        siteId: site.siteUniqueId,
        name: site.siteName
      })
    })
    sitesOverviewData.forEach((groupSite) => {
      groupSite.groups.forEach((group) => {
        if (isValidGroupName(group)) {
          const groupItem = {
            site: siteData.find((data) => data.siteId === group.siteUniqueId)?.site as SiteEntity,
            name: group.groupName,
            siteId: group.siteUniqueId,
            groupId: group.groupId
          }
          groupData.push(groupItem)
        }
      })
    })
    setItems({
      site: siteData,
      group: groupData
    })
  }, [listSites, sitesOverviewData])

  return (
    <div className="mornitoring-search-wrapper mb-4">
      <div className="d-flex justify-content-between align-items-center search-container">
        <img src={search} alt="map" role="button" className="bg-transparent" />
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={searchTerm}
          onFocus={() => searchTerm && setDropdownOpen(true)}
          onChange={handleSearchChange}
        />
        {searchTerm && <CloseButton onClick={clearSearch} />}
      </div>
      {searchTerm && (
        <div className="search-result">
          <Dropdown toggle={toggle} isOpen={dropdownOpen}>
            <DropdownToggle tag="span" className="d-none" />
            <DropdownMenu tag="ul">
              <li className="total-search">
                {hasSearchData() ? (
                  <>
                    {MSG_ITEMS_FOUND}:{" "}
                    <span>
                      <b>{filteredItems.site.length}</b>
                      <img src={siteNormal} alt="site-normal" />
                    </span>
                    <span>
                      <b>{filteredItems.group.length}</b>
                      <img src={bateryGroup} alt="battery-group" />
                    </span>
                    <hr />
                  </>
                ) : (
                  <span>{MSG_NO_ITEMS_FOUND}</span>
                )}
              </li>
              {printList(filteredItems.site, "site")}
              {printList(filteredItems.group, "group")}
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
    </div>
  )
}

export default memo(MonitoringSearch)
