import { AUTHORITY_CONSTANTS } from "../utils/constants"
import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import api from "../utils/axios"
import { ForgetPasswordRequest } from "../interfaces/authorize/ForgetPasswordRequest"
import { ForgetPasswordResponse } from "../interfaces/authorize/ForgetPasswordResponse"
import { VerifyOTPRequest } from "../interfaces/authorize/VerifyOTPRequest"
import { UpdatePasswordRequest } from "../interfaces/authorize/UpdatePasswordRequest"

export const forgetPassword = async (req?: ForgetPasswordRequest) => {
  try {
    const response = await api.post(AUTHORITY_CONSTANTS.API_FORGET_PASSWORD, req)
    return {
      status: response.status === 200,
      data: response.data as ForgetPasswordResponse,
      message: responseErrorMessage(response.data.code, response.data.msg)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: forgetPassword =>", e.message)
    writeApiLogEvent("forgetPassword", e)
    throw e
  }
}

export const verifyOtp = async (req?: VerifyOTPRequest) => {
  try {
    const response = await api.post(AUTHORITY_CONSTANTS.API_VERIFY_OTP, req)
    return {
      status: response.status === 200,
      message: responseErrorMessage(response.data.code, response.data.msg)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: verifyOtp =>", e.message)

    writeApiLogEvent("verifyOtp", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}

export const updatePassword = async (req?: UpdatePasswordRequest) => {
  try {
    const response = await api.post(AUTHORITY_CONSTANTS.API_UPDATE_PASSWORD, req)
    return {
      status: response.status === 200,
      message: responseErrorMessage(response.data.code, response.data.msg)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: updatePassword =>", e.message)

    writeApiLogEvent("updatePassword", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_COMMON_SERVER_FAILED)
    }
  }
}
