import axios from "axios"
import { setSigninExpired } from "../store/counter/authSlice"
import { AUTHORITY_CONSTANTS, AXIOS_ALLOWANCE_STATUS } from "../utils/constants"
import { writeCodeLogEvent } from "./utils"
import { Auth } from "aws-amplify"

const api = axios.create({
  baseURL: `${process.env.REACT_APP_APIGATEWAY_HOST}`,
  validateStatus: function (status: number) {
    return AXIOS_ALLOWANCE_STATUS.includes(status)
  }
})

const PUBLIC_APIs = [
  AUTHORITY_CONSTANTS.API_LOGIN,
  AUTHORITY_CONSTANTS.API_FORGET_PASSWORD,
  AUTHORITY_CONSTANTS.API_VERIFY_OTP,
  AUTHORITY_CONSTANTS.API_UPDATE_PASSWORD
]
api.defaults.timeout = 60000 // 60 seconds

api.interceptors.request.use(async (config) => {
  // Ignore no authorize API
  if (config.url && PUBLIC_APIs.includes(config.url)) {
    return config
  }
  try {
    // Auto refresh ID token
    const user = await Auth.currentAuthenticatedUser()
    const idToken = user.signInUserSession.idToken.jwtToken
    config.headers["Authorization"] = "Bearer " + idToken
    return config
  } catch (e) {
    setSigninExpired()
    throw new Error("Signin session expired. Please sigin again!")
  }
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      setSigninExpired()
      writeCodeLogEvent("Signin session expired", error)
    }
    return Promise.reject(error)
  }
)

export default api
