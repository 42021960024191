import { useState, useEffect } from "react"
import { Tooltip } from "react-tooltip"

interface TableTooltipColumnProps {
  text: string | undefined
  id: string
  maxLength: number
}
const TableTooltipColumn = (props: TableTooltipColumnProps) => {
  const [showTooltip, setShowTooltip] = useState("")
  const [exceedWidth, setExceedWidth] = useState(false)
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)
  const text = props.text || ""
  const [displayText, setDisplayText] = useState(text)

  // init 2d canvas
  useEffect(() => {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    if (context) {
      context.font = "15px Poppins"
      setContext(context)
    }
  }, [])

  // update tooltip status and display text
  useEffect(() => {
    if (context) {
      const metrics = context.measureText(text)
      if (metrics.width > props.maxLength) {
        setExceedWidth(true)

        // trim exceed string
        let currentWidth = 0
        let trimmedString = ""
        for (let i = 0; i < text.length; i++) {
          const letter = text[i]
          const letterWidth = context.measureText(letter).width
          if (currentWidth + letterWidth <= props.maxLength) {
            // Add the letter to the trimmed string
            trimmedString += letter
            currentWidth += letterWidth
          } else {
            // Break the loop when maximum width is reached
            break
          }
        }

        setDisplayText(`${trimmedString}...`)
      } else {
        setDisplayText(text)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, props.maxLength, context])

  return (
    <td
      onMouseEnter={() => exceedWidth && setShowTooltip(props.id)}
      onMouseLeave={() => exceedWidth && setShowTooltip("")}
      className="table-tooltip-column"
    >
      <span id={props.id}>{displayText}</span>
      <Tooltip
        anchorSelect={`#${props.id}`}
        isOpen={showTooltip === props.id ? true : false}
        content={text}
        className="text-wrap"
      />
    </td>
  )
}

export default TableTooltipColumn
