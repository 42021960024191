import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Col, Form, Row } from "reactstrap"
import { Tooltip as ReactTooltip } from "react-tooltip"

import {
  StepConfigurationProps,
  SubmitConfigurationHandle
} from "../../interfaces/StepConfigurationProps"
import { AlertModalProps } from "../../interfaces/AlertModalProps"
import { SitePowerInformation } from "interfaces/ConfigurationSiteInformation"
import { Network } from "../../interfaces/Network"
import { NetworkConfiguration, TeraStorInfo } from "interfaces/NetworkConfiguration"

import { getCurrentUserState } from "../../store/counter/authSlice"

import { SiteConfiguration } from "interfaces/SiteConfiguration"
import AlertModal from "../../components/modal/AlertModal"
import InputField from "components/InputField"

import Messages from "../../utils/messages"
import constants from "utils/monitoring/en"
import { isEmptyString, writeCodeLogEvent } from "utils/utils"
import {
  blankNetwork,
  defaultPOIPrimaryMeter,
  SITE_STATUS,
  USER_ROLES
} from "../../utils/constants"
import { ElementProperties } from "interfaces/ElementProperties"
import { SelectOptions } from "interfaces/SelectOptions"

const StepConfigurationSite = forwardRef<SubmitConfigurationHandle, StepConfigurationProps>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      submitConfiguration: async () => {
        return await onConfigurationFormSubmit(null)
      }
    }))

    const VALUE_0_1500_REGEX = /^(1500|1[0-4][0-9]{2}|[1-9][0-9]{0,2}|0)$/
    const VALUE_0_9999999_REGEX = /^(9999999|[1-9][0-9]{0,6}|0)$/

    const defaultSitePowerInformation: SitePowerInformation = {
      acVoltage: "690",
      ratedReactivePower: props.site?.numTeraStors
        ? (2520 * props.site?.numTeraStors).toString()
        : "",
      ratedRealPower: props.site?.numTeraStors ? (3600 * props.site?.numTeraStors).toString() : "",
      ratedEnergyCapacity: props.site?.numTeraStors
        ? (7200 * props.site?.numTeraStors).toString()
        : ""
    }

    const submitRef = useRef<HTMLButtonElement>(null)
    const currentUser = useSelector(getCurrentUserState)

    const [disabled, setDisabled] = useState(false)
    const [tsOptions, setTSOptions] = useState<SelectOptions[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [sitePowerInfomation, setSitePowerInfomation] = useState<SitePowerInformation>(
      props.configuration?.network?.sitePowerInformation ?? defaultSitePowerInformation
    )

    // POINT_OF_INTERCONNECT_PRIMARY_METER
    const [powerQualityMeter1, setPowerQualityMeter1] = useState<Network>(
      props.configuration?.network?.powerQualityMeterNetworks &&
        props.configuration?.network?.powerQualityMeterNetworks[0]
        ? props.configuration?.network?.powerQualityMeterNetworks[0]
        : defaultPOIPrimaryMeter
    )

    // POINT_OF_INTERCONNECT_SECONDARY_METER
    const [powerQualityMeter2, setPowerQualityMeter2] = useState<Network>(
      props.configuration?.network?.powerQualityMeterNetworks &&
        props.configuration?.network?.powerQualityMeterNetworks[1]
        ? props.configuration?.network?.powerQualityMeterNetworks[1]
        : blankNetwork
    )

    // LOAD_POWER_METER
    const [powerQualityMeter3, setPowerQualityMeter3] = useState<Network>(
      props.configuration?.network?.powerQualityMeterNetworks &&
        props.configuration?.network?.powerQualityMeterNetworks[2]
        ? props.configuration?.network?.powerQualityMeterNetworks[2]
        : blankNetwork
    )

    // SUPPLEMENTARY_POWER_METER
    const [powerQualityMeter4, setPowerQualityMeter4] = useState<Network>(
      props.configuration?.network?.powerQualityMeterNetworks &&
        props.configuration?.network?.powerQualityMeterNetworks[3]
        ? props.configuration?.network?.powerQualityMeterNetworks[3]
        : blankNetwork
    )

    // STORVIEW_CUSTOMER_NETWORK_SETTINGS
    const [storView, setStorView] = useState<Network>(
      props.configuration?.network?.storViewNetwork ?? blankNetwork
    )

    // DOMAIN_MASTER
    const [domainMaster, setDomainMaster] = useState<TeraStorInfo | null | undefined>(
      props.configuration?.network?.domainMaster
    )
    // DATA_SERVER
    const [dataServer, setDataServer] = useState<TeraStorInfo | null | undefined>(
      props.configuration?.network?.dataServer
    )

    const powerQualityMeters = [
      powerQualityMeter1,
      powerQualityMeter2,
      powerQualityMeter3,
      powerQualityMeter4
    ]

    const [savingMsg, setSavingMsg] = useState(Messages.MSG_SAVING_SITE_CONFIGURATION)
    const [alertModal, setAlertModal] = useState(false)
    const [alertStatus, setAlertStatus] = useState("loading")
    const alertToggle = () => setAlertModal(!alertModal)
    const alertModalProps: AlertModalProps = {
      size: "sm",
      modal: alertModal,
      toggle: alertToggle,
      status: alertStatus,
      message: savingMsg
    }

    useEffect(() => {
      let userEditable = false
      if (currentUser?.role === USER_ROLES.AESI_SUPER_ADMIN.id) {
        userEditable = props.configuration?.status !== SITE_STATUS.READY_FOR_INSTALLATION
      } else {
        userEditable =
          props.configuration?.status === SITE_STATUS.LAYOUT_IN_PROGRESS ||
          props.configuration?.status === SITE_STATUS.CONFIGURATION_IN_PROGRESS ||
          props.configuration?.status === SITE_STATUS.LAYOUT_PENDING_APPROVAL
      }
      setDisabled(!userEditable)
    }, [currentUser?.role, props.configuration?.status])

    useEffect(() => {
      // TODO: Since default value is involved when creating new configuration
      // This useEffect is safe to be removed after sometime

      // using blank network data in order to maintain input controlled value
      // sync between current and last configuration on first load
      const network: NetworkConfiguration = {
        powerQualityMeterNetworks: powerQualityMeters,
        storViewNetwork: storView,
        sitePowerInformation: sitePowerInfomation,
        domainMaster: domainMaster,
        dataServer: dataServer
      }
      props.setConfiguration({ ...props.configuration, network: network }, true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const layout: ElementProperties[] = props.configuration?.layout
        ? props.configuration?.layout
        : []
      const newTSOptions: SelectOptions[] = [
        ...layout.map((element) => ({
          value: element.id,
          label: `TeraStor ${element.id ? element.id : ""}`
        }))
      ]
      setTSOptions(newTSOptions)
    }, [props.configuration?.layout])

    useEffect(() => {
      const network: NetworkConfiguration = {
        powerQualityMeterNetworks: powerQualityMeters,
        storViewNetwork: storView,
        sitePowerInformation: sitePowerInfomation,
        domainMaster: domainMaster,
        dataServer: dataServer
      }
      props.setConfiguration({ ...props.configuration, network: network })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      powerQualityMeter1,
      powerQualityMeter2,
      powerQualityMeter3,
      powerQualityMeter4,
      storView,
      sitePowerInfomation,
      domainMaster,
      dataServer
    ])

    useEffect(() => {
      if (props.viewOnly || props.syncState) {
        // sync network data with last configuration for re-rendering in case of view only
        const newSitePowerInformation =
          props.configuration?.network?.sitePowerInformation ?? defaultSitePowerInformation
        setSitePowerInfomation(newSitePowerInformation)
        let newPowerQualityMeterNetworks: Network[] | undefined = undefined
        let newPowerQualityMeter1: Network = defaultPOIPrimaryMeter
        let newPowerQualityMeter2: Network = blankNetwork
        let newPowerQualityMeter3: Network = blankNetwork
        let newPowerQualityMeter4: Network = blankNetwork
        if (
          props.configuration?.network &&
          props.configuration?.network?.powerQualityMeterNetworks
        ) {
          newPowerQualityMeter1 =
            props.configuration?.network?.powerQualityMeterNetworks[0] ?? defaultPOIPrimaryMeter
          setPowerQualityMeter1(newPowerQualityMeter1)
          newPowerQualityMeter2 =
            props.configuration?.network?.powerQualityMeterNetworks[1] ?? blankNetwork
          setPowerQualityMeter2(newPowerQualityMeter2)
          newPowerQualityMeter3 =
            props.configuration?.network?.powerQualityMeterNetworks[2] ?? blankNetwork
          setPowerQualityMeter3(newPowerQualityMeter3)
          newPowerQualityMeter4 =
            props.configuration?.network?.powerQualityMeterNetworks[3] ?? blankNetwork
          setPowerQualityMeter4(newPowerQualityMeter4)
        }
        newPowerQualityMeterNetworks = [
          newPowerQualityMeter1,
          newPowerQualityMeter2,
          newPowerQualityMeter3,
          newPowerQualityMeter4
        ]

        const newStorViewNetwork = props.configuration?.network?.storViewNetwork ?? blankNetwork
        setStorView(newStorViewNetwork)
        const newDomainMaster = props.configuration?.network?.domainMaster
        setDomainMaster(newDomainMaster)
        const newDataServer = props.configuration?.network?.dataServer
        setDataServer(newDataServer)

        if (props.syncState && props.setSyncState) {
          const network: NetworkConfiguration = {
            powerQualityMeterNetworks: newPowerQualityMeterNetworks,
            storViewNetwork: newStorViewNetwork,
            sitePowerInformation: newSitePowerInformation,
            domainMaster: newDomainMaster,
            dataServer: newDataServer
          }
          props.setConfiguration({ ...props.configuration, network: network }, true)
          props.setSyncState(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.configuration?.network, props.viewOnly, props.syncState])

    const areUniqueOrBlank = (...values: (string | undefined)[]): boolean => {
      const nonBlankValues = values.filter((value) => value !== undefined && value !== "")
      return new Set(nonBlankValues).size === nonBlankValues.length
    }

    const isItemUnique = (item: string | undefined, array: (string | undefined)[]): boolean => {
      if (item === undefined || item === "") {
        // if item is blank, it is unique
        return true
      }
      return !array.includes(item)
    }

    const validateForm = () => {
      if (
        isEmptyString(sitePowerInfomation.acVoltage) ||
        !VALUE_0_1500_REGEX.test(`${sitePowerInfomation.acVoltage}`)
      ) {
        return false
      } else if (
        isEmptyString(sitePowerInfomation.ratedRealPower) ||
        !VALUE_0_9999999_REGEX.test(`${sitePowerInfomation.ratedRealPower}`)
      ) {
        return false
      } else if (
        isEmptyString(sitePowerInfomation.ratedReactivePower) ||
        !VALUE_0_9999999_REGEX.test(`${sitePowerInfomation.ratedReactivePower}`)
      ) {
        return false
      } else if (
        isEmptyString(sitePowerInfomation.ratedEnergyCapacity) ||
        !VALUE_0_9999999_REGEX.test(`${sitePowerInfomation.ratedEnergyCapacity}`)
      ) {
        return false
      } else if (
        // PQM1 fields must be available
        isEmptyString(powerQualityMeter1.hostName) ||
        isEmptyString(powerQualityMeter1.ipAddress) ||
        isEmptyString(powerQualityMeter1.port)
      ) {
        return false
      } else if (
        // Hostnames must be unique or empty
        !areUniqueOrBlank(
          powerQualityMeter1.hostName,
          powerQualityMeter2.hostName,
          powerQualityMeter3.hostName,
          powerQualityMeter4.hostName
        )
      ) {
        return false
      } else if (
        // IP addresses must be unique or empty
        !areUniqueOrBlank(
          powerQualityMeter1.ipAddress,
          powerQualityMeter2.ipAddress,
          powerQualityMeter3.ipAddress,
          powerQualityMeter4.ipAddress,
          storView.ipAddress,
          storView.gateway
        )
      ) {
        return false
      } else if (
        // IP addresses must be IPv4
        !isValidIPv4(powerQualityMeter1.ipAddress) ||
        !isValidIPv4(powerQualityMeter2.ipAddress) ||
        !isValidIPv4(powerQualityMeter3.ipAddress) ||
        !isValidIPv4(powerQualityMeter4.ipAddress)
      ) {
        return false
      } else if (
        // Port must be in range
        !isValidPort(powerQualityMeter1.port) ||
        !isValidPort(powerQualityMeter2.port) ||
        !isValidPort(powerQualityMeter3.port) ||
        !isValidPort(powerQualityMeter4.port)
      ) {
        return false
      } else if (
        isEmptyString(storView.ipAddress) ||
        isEmptyString(storView.netmask) ||
        isEmptyString(storView.gateway) ||
        !isValidIPv4(storView.ipAddress) ||
        !isValidNetmask(storView.netmask) ||
        !isValidIPv4(storView.gateway)
      ) {
        return false
      }

      return true
    }

    const onConfigurationFormSubmit = async (e: React.SyntheticEvent<HTMLFormElement> | null) => {
      if (e) {
        e.preventDefault()
      }

      if (validateForm() === false) {
        // focus on the first invalid input field
        const invalidInput = document.querySelector("input.is-invalid") as HTMLInputElement
        if (invalidInput) {
          invalidInput.focus()
        } else {
          submitRef.current?.click()
        }
        return false
      }

      setSavingMsg(Messages.MSG_SAVING_SITE_CONFIGURATION)
      setAlertModal(true)
      return await saveConfiguration()
    }

    const saveConfiguration = async () => {
      if (props.configuration?.siteId) {
        const configuration: SiteConfiguration = {
          siteId: props.configuration?.siteId,
          network: {
            powerQualityMeterNetworks: powerQualityMeters,
            storViewNetwork: storView,
            sitePowerInformation: sitePowerInfomation,
            domainMaster: domainMaster,
            dataServer: dataServer
          }
        }
        try {
          setIsSubmitting(true)
          const res = await props.saveConfiguration(configuration)
          setIsSubmitting(false)
          if (res) {
            if (!res.status) {
              setAlertStatus("Error")
              setSavingMsg(
                res.errorLists ? res.errorLists.map((e) => e.errorMsg).join(",") : res.message
              )
              setAlertModal(true)
              return false
            }
          }
          setAlertModal(false)
          return true
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          setSavingMsg(Messages.ERR_SAVE_CONFIGURATION)
          setAlertModal(true)
          console.error("Code exception: save configuration =>", e.message)

          writeCodeLogEvent("save configuration", e, props.configuration?.siteId)
          return false
        }
      } else {
        return false
      }
    }

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      switch (event.currentTarget.id) {
        case "siteACVotage":
          setSitePowerInfomation({ ...sitePowerInfomation, acVoltage: event.currentTarget.value })
          break
        case "siteRatedRealPower":
          setSitePowerInfomation({
            ...sitePowerInfomation,
            ratedRealPower: event.currentTarget.value
          })
          break
        case "siteRatedReactivePower":
          setSitePowerInfomation({
            ...sitePowerInfomation,
            ratedReactivePower: event.currentTarget.value
          })
          break
        case "siteRatedEnergyCapacity":
          setSitePowerInfomation({
            ...sitePowerInfomation,
            ratedEnergyCapacity: event.currentTarget.value
          })
          break
        case "hostName1":
          setPowerQualityMeter1({ ...powerQualityMeter1, hostName: event.currentTarget.value })
          break
        case "ipAddress1":
          setPowerQualityMeter1({ ...powerQualityMeter1, ipAddress: event.currentTarget.value })
          break
        case "port1":
          setPowerQualityMeter1({ ...powerQualityMeter1, port: event.currentTarget.value })
          break
        case "hostName2":
          setPowerQualityMeter2({ ...powerQualityMeter2, hostName: event.currentTarget.value })
          break
        case "ipAddress2":
          setPowerQualityMeter2({ ...powerQualityMeter2, ipAddress: event.currentTarget.value })
          break
        case "port2":
          setPowerQualityMeter2({ ...powerQualityMeter2, port: event.currentTarget.value })
          break
        case "hostName3":
          setPowerQualityMeter3({ ...powerQualityMeter3, hostName: event.currentTarget.value })
          break
        case "ipAddress3":
          setPowerQualityMeter3({ ...powerQualityMeter3, ipAddress: event.currentTarget.value })
          break
        case "port3":
          setPowerQualityMeter3({ ...powerQualityMeter3, port: event.currentTarget.value })
          break
        case "hostName4":
          setPowerQualityMeter4({ ...powerQualityMeter4, hostName: event.currentTarget.value })
          break
        case "ipAddress4":
          setPowerQualityMeter4({ ...powerQualityMeter4, ipAddress: event.currentTarget.value })
          break
        case "port4":
          setPowerQualityMeter4({ ...powerQualityMeter4, port: event.currentTarget.value })
          break
        case "storViewIpAddress":
          setStorView({ ...storView, ipAddress: event.currentTarget.value })
          break
        case "storViewNetmask":
          setStorView({ ...storView, netmask: event.currentTarget.value })
          break
        case "storViewGateway":
          setStorView({ ...storView, gateway: event.currentTarget.value })
          break
        case "slcDomainMaster": {
          let newDomainMaster: TeraStorInfo | undefined = undefined
          const domainMasterTSId = parseInt(event.currentTarget.value)
          const domainMasterElement = props.configuration?.layout?.find(
            (layout) => layout.id === domainMasterTSId
          )
          if (domainMasterElement) {
            newDomainMaster = { id: domainMasterElement.id, uuid: domainMasterElement.uuid }
            setDomainMaster(newDomainMaster)
          }
          break
        }
        case "slcDataServer": {
          let newDataServer: TeraStorInfo | undefined = undefined
          const dataServerTSId = parseInt(event.currentTarget.value)
          const dataServerElement = props.configuration?.layout?.find(
            (layout) => layout.id === dataServerTSId
          )
          if (dataServerElement) {
            newDataServer = { id: dataServerElement.id, uuid: dataServerElement.uuid }
            setDataServer(newDataServer)
          }
          break
        }
      }
    }

    const renderViewOnly = () => {
      return (
        <Row>
          <div className="input-field-container">
            <h5 className="mt-3 fw-bold">{Messages.SITE_INFORMATION}</h5>
            <div className="row mt-3">
              <div className="col-12 col-md-10 col-lg-7 col-xl-5 me-md-5 mb-3">
                <InfoItem
                  id="acVoltage"
                  label={`${Messages.SITE_AC_VOLTAGE}: `}
                  value={`${sitePowerInfomation?.acVoltage} ${constants.VOLT_UNIT}`}
                  outerClass="info-item"
                />
              </div>
              <div className="col-12 col-md-10 col-lg-7 col-xl-5 me-md-5 mb-3">
                <InfoItem
                  label={`${Messages.SITE_RATED_REACTIVE_POWER}: `}
                  value={`${sitePowerInfomation?.ratedReactivePower} ${constants.KILOVOLT_AMPS_REACTIVE_UNIT}`}
                  id="ratedReactivePower"
                  outerClass="info-item"
                />
              </div>
              <div className="col-12 col-md-10 col-lg-7 col-xl-5 me-md-5 mb-3">
                <InfoItem
                  label={`${Messages.SITE_RATED_REAL_POWER}: `}
                  value={`${sitePowerInfomation?.ratedRealPower} ${constants.KILOWATT_UNIT}`}
                  id="ratedRealPower"
                  outerClass="info-item"
                />
              </div>
              <div className="col-12 col-md-10 col-lg-7 col-xl-5 me-md-5 mb-3">
                <InfoItem
                  label={`${Messages.SITE_RATED_ENERGY_CAPACITY}: `}
                  value={`${sitePowerInfomation?.ratedEnergyCapacity} ${constants.KILOWATTHOUR_UNIT}`}
                  id="ratedEnergyCapacity"
                  outerClass="info-item"
                />
              </div>
            </div>
          </div>
          <div className="input-field-container mt-1">
            <h5 className="mt-3 fw-bold">
              {Messages.POWER_QUALITY_METER}
              <ReactTooltip
                id="PowerQualityMeter"
                place="bottom"
                content={Messages.POWER_QUALITY_METER}
              />
            </h5>
            <div className="d-flex flex-wrap col-12">
              {powerQualityMeters.map((meter, index) => (
                <div className="col-12 col-md-6 col-lg-2 mt-3 short-item-lg info-item" key={index}>
                  <InfoItem
                    label={`${Messages.HOST_NAME}: `}
                    value={meter?.hostName}
                    id={`host_name_${index.toString()}`}
                  />
                  <InfoItem
                    label={`${Messages.IP_ADDRESS}: `}
                    value={meter?.ipAddress}
                    id={`ip_${index.toString()}`}
                  />
                  <InfoItem
                    label={`${Messages.PORT_NUMBER}: `}
                    value={meter?.port}
                    id={`port_${index.toString()}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="input-field-container mt-1">
            <h5 className="mt-3 fw-bold">{Messages.STORVIEW}</h5>
            <div className="d-flex flex-wrap col-12">
              <div className="col-12 col-md-6 col-lg-3 short-item-lg">
                <InfoItem
                  id="storViewIpAddress"
                  label={`${Messages.IP_ADDRESS}: `}
                  value={storView?.ipAddress}
                  outerClass="info-item"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 short-item-lg">
                <InfoItem
                  label={`${Messages.NETMASK}: `}
                  value={storView?.netmask}
                  id="storViewNetmask"
                  outerClass="info-item"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3 short-item-lg">
                <InfoItem
                  label={`${Messages.GATEWAY}: `}
                  value={storView?.gateway}
                  id="storViewGateway"
                  outerClass="info-item"
                />
              </div>
            </div>
          </div>
          <div className="col-12 row mb-3 input-field-container">
            <div className="col-12 col-md-10 col-lg-7 col-xl-5 me-md-5 mb-3 ms-0 ps-0">
              <h5 className="mt-3 fw-bold">{Messages.DOMAIN_MASTER}</h5>
              <InfoItem
                label={`${Messages.TERASTOR}: `}
                value={domainMaster ? Messages.TERASTOR + " " + domainMaster?.id.toString() : ""}
                id="domainMasterLabel"
                outerClass="info-item mb-2"
              />
            </div>
            <div className="col-12 col-md-10 col-lg-7 col-xl-5 me-md-5 mb-3 ps-0">
              <h5 className="mt-3 fw-bold">{Messages.DATA_SERVER}</h5>
              <InfoItem
                label={`${Messages.TERASTOR}: `}
                value={dataServer ? Messages.TERASTOR + " " + dataServer?.id.toString() : ""}
                id="dataServerLabel"
                outerClass="info-item mb-2"
              />
            </div>
          </div>
        </Row>
      )
    }

    const renderConfigurationForm = () => {
      return (
        <Form type="post" onSubmit={onConfigurationFormSubmit}>
          <Row>
            {/* Site Information */}
            <h5 className="mt-4 fw-bold">
              {Messages.SITE_INFORMATION}
              {<span className="text-danger">*</span>}
            </h5>

            <div className="col-12">
              <div className="row input-field-container">
                <div className="col-12 col-md-6 mb-3">
                  <InputField
                    id="siteACVotage"
                    label={`${Messages.SITE_AC_VOLTAGE} (${constants.VOLT_UNIT})`}
                    placeholder="123"
                    value={sitePowerInfomation?.acVoltage}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={1}
                    labelColWidth={6}
                    type="number"
                    invalid={
                      sitePowerInfomation?.acVoltage !== undefined
                        ? !VALUE_0_1500_REGEX.test(`${sitePowerInfomation?.acVoltage}`)
                        : false
                    }
                    invalidTooltip={[Messages.ERR_SITE_VOLTAGE_MUST_INRANGE]}
                    required={true}
                  />
                  <InputField
                    id="siteRatedRealPower"
                    label={`${Messages.SITE_RATED_REAL_POWER} (${constants.KILOWATT_UNIT})`}
                    placeholder="123"
                    value={sitePowerInfomation?.ratedRealPower}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={3}
                    labelColWidth={6}
                    type="number"
                    invalid={
                      sitePowerInfomation?.ratedRealPower !== undefined
                        ? !VALUE_0_9999999_REGEX.test(`${sitePowerInfomation?.ratedRealPower}`)
                        : false
                    }
                    invalidTooltip={[Messages.ERR_SITE_POWER_MUST_INRANGE]}
                    required={true}
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <InputField
                    id="siteRatedReactivePower"
                    label={`${Messages.SITE_RATED_REACTIVE_POWER} (${constants.KILOVOLT_AMPS_REACTIVE_UNIT})`}
                    placeholder="123"
                    value={sitePowerInfomation?.ratedReactivePower}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={2}
                    labelColWidth={6}
                    type="number"
                    invalid={
                      sitePowerInfomation?.ratedReactivePower !== undefined
                        ? !VALUE_0_9999999_REGEX.test(`${sitePowerInfomation?.ratedReactivePower}`)
                        : false
                    }
                    invalidTooltip={[Messages.ERR_SITE_POWER_MUST_INRANGE]}
                    required={true}
                  />
                  <InputField
                    id="siteRatedEnergyCapacity"
                    label={`${Messages.SITE_RATED_ENERGY_CAPACITY} (${constants.KILOWATTHOUR_UNIT})`}
                    placeholder="123"
                    value={sitePowerInfomation?.ratedEnergyCapacity}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={4}
                    labelColWidth={6}
                    type="number"
                    invalid={
                      sitePowerInfomation?.ratedEnergyCapacity !== undefined
                        ? !VALUE_0_9999999_REGEX.test(`${sitePowerInfomation?.ratedEnergyCapacity}`)
                        : false
                    }
                    invalidTooltip={[Messages.ERR_SITE_POWER_MUST_INRANGE]}
                    required={true}
                  />
                </div>
              </div>
            </div>
            {/* Power Quality Meter */}
            <h5 className="mt-4 fw-bold">{Messages.POWER_QUALITY_METER}</h5>
            {/* POINT_OF_INTERCONNECT_PRIMARY_METER */}
            <div className="col-12 col-md-6">
              <div className="input-field-container">
                <h5 className="py-2 fw-500-important">
                  {Messages.POINT_OF_INTERCONNECT_PRIMARY_METER}
                  {<span className="text-danger">*</span>}
                </h5>
                <InputField
                  id="hostName1"
                  label={Messages.HOST_NAME}
                  placeholder={Messages.HOST_NAME_PLACEHOLDER}
                  value={powerQualityMeter1?.hostName}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={5}
                  required={true}
                  invalid={
                    !isItemUnique(powerQualityMeter1.hostName, [
                      powerQualityMeter2.hostName,
                      powerQualityMeter3.hostName,
                      powerQualityMeter4.hostName
                    ])
                  }
                  invalidTooltip={[Messages.ERR_PQM_HOSTNAME_MUST_BE_UNIQUE]}
                />
                <InputField
                  id="ipAddress1"
                  label={Messages.IP_ADDRESS}
                  placeholder={Messages.IP_ADDRESS}
                  value={powerQualityMeter1?.ipAddress}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={6}
                  required={true}
                  invalid={
                    !isItemUnique(powerQualityMeter1.ipAddress, [
                      powerQualityMeter2.ipAddress,
                      powerQualityMeter3.ipAddress,
                      powerQualityMeter4.ipAddress,
                      storView.ipAddress,
                      storView.gateway
                    ]) || !isValidIPv4(powerQualityMeter1.ipAddress)
                  }
                  invalidTooltip={[
                    Messages.ERR_PQM_IP_MUST_BE_UNIQUE,
                    Messages.ERR_PQM_IP_MUST_BE_V4
                  ]}
                />
                <InputField
                  id="port1"
                  label={Messages.PORT_NUMBER}
                  placeholder={Messages.PORT_NUMBER_PLACEHOLDER}
                  value={powerQualityMeter1?.port}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={7}
                  required={true}
                  invalid={!isValidPort(powerQualityMeter1.port)}
                  invalidTooltip={[Messages.ERR_CONFIGURATION_PORT_MUST_BE_IN_RANGE]}
                />
              </div>
            </div>
            {/* POINT_OF_INTERCONNECT_SECONDARY_METER */}
            <div className="col-12 col-md-6">
              <div className="input-field-container">
                <h5 className="py-2 fw-500-important">
                  {Messages.POINT_OF_INTERCONNECT_SECONDARY_METER}
                </h5>
                <InputField
                  id="hostName2"
                  label={Messages.HOST_NAME}
                  placeholder={Messages.HOST_NAME_PLACEHOLDER}
                  value={powerQualityMeter2?.hostName}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={8}
                  invalid={
                    !isItemUnique(powerQualityMeter2.hostName, [
                      powerQualityMeter1.hostName,
                      powerQualityMeter3.hostName,
                      powerQualityMeter4.hostName
                    ])
                  }
                  invalidTooltip={[Messages.ERR_PQM_HOSTNAME_MUST_BE_UNIQUE]}
                />
                <InputField
                  id="ipAddress2"
                  label={Messages.IP_ADDRESS}
                  placeholder={Messages.IP_ADDRESS}
                  value={powerQualityMeter2?.ipAddress}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={9}
                  invalid={
                    !isItemUnique(powerQualityMeter2.ipAddress, [
                      powerQualityMeter1.ipAddress,
                      powerQualityMeter3.ipAddress,
                      powerQualityMeter4.ipAddress,
                      storView.ipAddress,
                      storView.gateway
                    ]) || !isValidIPv4(powerQualityMeter2.ipAddress)
                  }
                  invalidTooltip={[
                    Messages.ERR_PQM_IP_MUST_BE_UNIQUE,
                    Messages.ERR_PQM_IP_MUST_BE_V4
                  ]}
                />
                <InputField
                  id="port2"
                  label={Messages.PORT_NUMBER}
                  placeholder={Messages.PORT_NUMBER_PLACEHOLDER}
                  value={powerQualityMeter2?.port}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={10}
                  invalid={!isValidPort(powerQualityMeter2.port)}
                  invalidTooltip={[Messages.ERR_CONFIGURATION_PORT_MUST_BE_IN_RANGE]}
                />
              </div>
            </div>
            {/* LOAD_POWER_METER */}
            <div className="col-12 col-md-6">
              <div className="input-field-container">
                <h5 className="py-2 fw-500-important">{Messages.LOAD_POWER_METER}</h5>
                <InputField
                  id="hostName3"
                  label={Messages.HOST_NAME}
                  placeholder={Messages.HOST_NAME_PLACEHOLDER}
                  value={powerQualityMeter3?.hostName}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={11}
                  invalid={
                    !isItemUnique(powerQualityMeter3.hostName, [
                      powerQualityMeter1.hostName,
                      powerQualityMeter2.hostName,
                      powerQualityMeter4.hostName
                    ])
                  }
                  invalidTooltip={[Messages.ERR_PQM_HOSTNAME_MUST_BE_UNIQUE]}
                />
                <InputField
                  id="ipAddress3"
                  label={Messages.IP_ADDRESS}
                  placeholder={Messages.IP_ADDRESS}
                  value={powerQualityMeter3?.ipAddress}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={12}
                  invalid={
                    !isItemUnique(powerQualityMeter3.ipAddress, [
                      powerQualityMeter1.ipAddress,
                      powerQualityMeter2.ipAddress,
                      powerQualityMeter4.ipAddress,
                      storView.ipAddress,
                      storView.gateway
                    ]) || !isValidIPv4(powerQualityMeter3.ipAddress)
                  }
                  invalidTooltip={[
                    Messages.ERR_PQM_IP_MUST_BE_UNIQUE,
                    Messages.ERR_PQM_IP_MUST_BE_V4
                  ]}
                />
                <InputField
                  id="port3"
                  label={Messages.PORT_NUMBER}
                  placeholder={Messages.PORT_NUMBER_PLACEHOLDER}
                  value={powerQualityMeter3?.port}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={13}
                  invalid={!isValidPort(powerQualityMeter3.port)}
                  invalidTooltip={[Messages.ERR_CONFIGURATION_PORT_MUST_BE_IN_RANGE]}
                />
              </div>
            </div>
            {/* SUPPLEMENTARY_POWER_METER */}
            <div className="col-12 col-md-6">
              <div className="input-field-container">
                <h5 className="py-2 fw-500-important">{Messages.SUPPLEMENTARY_POWER_METER}</h5>
                <InputField
                  id="hostName4"
                  label={Messages.HOST_NAME}
                  placeholder={Messages.HOST_NAME_PLACEHOLDER}
                  value={powerQualityMeter4?.hostName}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={14}
                  invalid={
                    !isItemUnique(powerQualityMeter4.hostName, [
                      powerQualityMeter1.hostName,
                      powerQualityMeter2.hostName,
                      powerQualityMeter3.hostName
                    ])
                  }
                  invalidTooltip={[Messages.ERR_PQM_HOSTNAME_MUST_BE_UNIQUE]}
                />
                <InputField
                  id="ipAddress4"
                  label={Messages.IP_ADDRESS}
                  placeholder={Messages.IP_ADDRESS}
                  value={powerQualityMeter4?.ipAddress}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={15}
                  invalid={
                    !isItemUnique(powerQualityMeter4.ipAddress, [
                      powerQualityMeter1.ipAddress,
                      powerQualityMeter2.ipAddress,
                      powerQualityMeter3.ipAddress,
                      storView.ipAddress,
                      storView.gateway
                    ]) || !isValidIPv4(powerQualityMeter4.ipAddress)
                  }
                  invalidTooltip={[
                    Messages.ERR_PQM_IP_MUST_BE_UNIQUE,
                    Messages.ERR_PQM_IP_MUST_BE_V4
                  ]}
                />
                <InputField
                  id="port4"
                  label={Messages.PORT_NUMBER}
                  placeholder={Messages.PORT_NUMBER_PLACEHOLDER}
                  value={powerQualityMeter4?.port}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={16}
                  invalid={!isValidPort(powerQualityMeter4.port)}
                  invalidTooltip={[Messages.ERR_CONFIGURATION_PORT_MUST_BE_IN_RANGE]}
                />
              </div>
            </div>
            {/* STORVIEW_CUSTOMER_NETWORK_SETTINGS */}
            <h5 className="mt-2 fw-bold">
              {Messages.STORVIEW_CUSTOMER_NETWORK_SETTINGS}
              {<span className="text-danger">*</span>}
            </h5>
            <div className="col-12">
              <div className="input-field-container row ps-0">
                <div className="col-12 col-md-4">
                  <InputField
                    id="storViewIpAddress"
                    label={Messages.IP_ADDRESS}
                    placeholder={Messages.IP_ADDRESS}
                    value={storView?.ipAddress}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={17}
                    col={true}
                    required={true}
                    invalid={
                      !isItemUnique(storView.ipAddress, [
                        powerQualityMeter1.ipAddress,
                        powerQualityMeter2.ipAddress,
                        powerQualityMeter3.ipAddress,
                        powerQualityMeter4.ipAddress,
                        storView.gateway
                      ]) || !isValidIPv4(storView.ipAddress)
                    }
                    invalidTooltip={[
                      Messages.ERR_PQM_IP_MUST_BE_UNIQUE,
                      Messages.ERR_PQM_IP_MUST_BE_V4
                    ]}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <InputField
                    id="storViewNetmask"
                    label={Messages.NETMASK}
                    placeholder={Messages.NETMASK}
                    value={storView?.netmask}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={18}
                    col={true}
                    required={true}
                    invalid={!isValidNetmask(storView.netmask)}
                    invalidTooltip={[Messages.ERR_INVALID_NETMASK]}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <InputField
                    id="storViewGateway"
                    label={Messages.GATEWAY}
                    placeholder={Messages.GATEWAY}
                    value={storView?.gateway}
                    onChange={handleChange}
                    disabled={disabled}
                    tabIndex={19}
                    col={true}
                    required={true}
                    invalid={
                      !isItemUnique(storView.gateway, [
                        powerQualityMeter1.ipAddress,
                        powerQualityMeter2.ipAddress,
                        powerQualityMeter3.ipAddress,
                        powerQualityMeter4.ipAddress,
                        storView.ipAddress
                      ]) || !isValidIPv4(storView.gateway)
                    }
                    invalidTooltip={[
                      Messages.ERR_PQM_IP_MUST_BE_UNIQUE,
                      Messages.ERR_PQM_IP_MUST_BE_V4
                    ]}
                  />
                </div>
              </div>
            </div>

            {/* DOMAIN_MASTER */}
            <div className="col-12 col-md-6">
              <h5 className="mt-2 fw-bold">
                {Messages.DOMAIN_MASTER}
                {<span className="text-danger">*</span>}
              </h5>
              <div className="input-field-container pt-3">
                <InputField
                  id="slcDomainMaster"
                  label={Messages.TERASTOR}
                  value={domainMaster ? domainMaster?.id.toString() : ""}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={20}
                  required={props.configuration?.layout?.length !== 0}
                  type="select"
                  options={tsOptions}
                />
              </div>
            </div>
            {/* DATA_SERVER */}
            <div className="col-12 col-md-6">
              <h5 className="mt-2 fw-bold">
                {Messages.DATA_SERVER}
                {<span className="text-danger">*</span>}
              </h5>
              <div className="input-field-container pt-3">
                <InputField
                  id="slcDataServer"
                  label={Messages.TERASTOR}
                  value={dataServer ? dataServer?.id.toString() : ""}
                  onChange={handleChange}
                  disabled={disabled}
                  tabIndex={20}
                  required={props.configuration?.layout?.length !== 0}
                  type="select"
                  options={tsOptions}
                />
              </div>
            </div>
          </Row>
          {!disabled && (
            <Row>
              <Col className="pt-4 d-flex justify-content-end">
                <button
                  type="submit"
                  className="me-2 pe-4 px-4 btn-actions btn btn-primary border-0"
                  ref={submitRef}
                  disabled={isSubmitting}
                >
                  {Messages.BTN_SITE_SETUP_SAVE}
                </button>
              </Col>
            </Row>
          )}
        </Form>
      )
    }

    return (
      <>
        {props.viewOnly ? renderViewOnly() : renderConfigurationForm()}
        {alertModal && <AlertModal {...alertModalProps} />}
      </>
    )
  }
)

StepConfigurationSite.displayName = "StepConfigurationSite"
export default StepConfigurationSite

const InfoItem: React.FC<{
  label: string
  value: string | undefined
  id?: string
  outerClass?: string
}> = ({ label, value, id, outerClass }) => (
  <div className={`d-flex align-items-baseline ${outerClass ? outerClass : ""}`}>
    <span className="text-bold-gray text-nowrap">{label}</span>
    <span data-tooltip-id={id} className="ps-1 text-truncate text-light-gray">
      {value}
    </span>
    <ReactTooltip id={id} place="top" content={value} />
  </div>
)

const isValidIPv4 = (ip: string | undefined | null) => {
  if (!ip) {
    // consider empty ip as valid
    return true
  }
  const ipv4Regex = /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}$/
  return ipv4Regex.test(ip)
}

const isValidPort = (port: string | undefined | null) => {
  if (!port) {
    // consider empty port as valid
    return true
  }
  const portNumber = parseInt(port, 10)
  return portNumber >= 1 && portNumber <= 65535
}

const isValidNetmask = (netmask: string | undefined | null) => {
  if (!netmask) {
    // consider empty netmask as valid
    return true
  }
  const netmaskRegex =
    /^(255\.255\.255\.(255|254|252|248|240|224|192|128|0)|255\.255\.(255|254|252|248|240|224|192|128|0)\.0|255\.(255|254|252|248|240|224|192|128|0)\.0\.0|255\.0\.0\.0)$/
  return netmaskRegex.test(netmask)
}
