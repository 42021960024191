import { useDispatch } from "react-redux"
import { useState, useEffect, useRef } from "react"
import { Form, Input, Button, FormGroup, Label, Spinner, FormFeedback, Tooltip } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

import { setErrorMessage } from "../../store/counter/authSlice"
import { AppDispatch } from "../../store/store"

import Messages from "../../utils/messages"

import Wrapper from "./Wrapper"

import eyeopen from "../../assets/images/icons/auth/eye-open.svg"
import eyeoclosed from "../../assets/images/icons/auth/eye-closed.svg"

import { writeCodeLogEvent } from "../../utils/utils"
import { forgetPassword, updatePassword, verifyOtp } from "../../services/apiAuthorize"
import { AUTHORITY_CONSTANTS, COOKIE_NAME } from "../../utils/constants"
import cookies from "../../utils/cookies"

enum State {
  OTP,
  RESET,
  SUCCEED,
  REQUEST
}

const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [step, setStep] = useState(State.REQUEST)
  const [isSubmiting, setSubmit] = useState(false)
  const [securityCode, setSecurityCode] = useState<string>()
  const COUNTDOWN = 60
  const OTP_LENGTH = 6
  const OTP_PATTERN = /^[0-9]$/
  const OTPInputs = useRef<HTMLInputElement[]>([])

  const inputOnchange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.name === "email") {
      setEmail(event.currentTarget.value)
    } else if (event.currentTarget.name === "password") {
      setPassword(event.currentTarget.value)
    } else if (event.currentTarget.name === "confirm-password") {
      setConfirmPassword(event.currentTarget.value)
    }
  }

  const handleOTPInputChange = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    const value = event.currentTarget.value
    if (isOTPCharacter(value) || value === "") {
      const newOtpValues = [...otpValues]
      newOtpValues[index] = value
      setOtpValues(newOtpValues)

      if (value.length === 1) {
        if (index < OTP_LENGTH - 1) {
          OTPInputs.current[index + 1].focus()
        }
      }
    }
  }

  const handleOTPInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const currentElement = OTPInputs.current[index]
    if (e.key === "Tab" || e.key === "ArrowRight") {
      if (e.key === "Tab" && e.shiftKey) {
        if (index > 0) {
          OTPInputs.current[index - 1].focus()
          e.preventDefault()
        }
      } else if (index < OTP_LENGTH - 1) {
        OTPInputs.current[index + 1].focus()
        e.preventDefault()
      }
    } else if (
      e.key === "ArrowLeft" ||
      (e.key === "Backspace" && currentElement.value.length === 0)
    ) {
      if (index > 0) {
        const previousElement = OTPInputs.current[index - 1]
        previousElement.focus()
        const len = previousElement.value.length
        previousElement.setSelectionRange(len, len)
        e.preventDefault()
      }
    } else if (isOTPCharacter(e.key)) {
      const newOtpValues = [...otpValues]
      newOtpValues[index] = e.key
      setOtpValues(newOtpValues)
      currentElement.value = e.key
      if (index < OTP_LENGTH - 1) {
        OTPInputs.current[index + 1].focus()
        e.preventDefault()
      }
    }
  }

  const handlePasteOTPInputs = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData("Text").slice(0, OTP_LENGTH)
    const newOtpValues = [...otpValues]

    for (let i = 0; i < pasteData.length && i + index < otpValues.length; i++) {
      if (isOTPCharacter(pasteData[i])) {
        const currentIndex = i + index
        newOtpValues[currentIndex] = pasteData[i]
        if (OTPInputs.current[currentIndex]) {
          OTPInputs.current[currentIndex].value = pasteData[i]
          OTPInputs.current[currentIndex].focus()
        }
      }
    }

    setOtpValues(newOtpValues)
  }

  const translateMessage = (message: string | undefined) => {
    switch (message) {
      case "EMAIL_NOT_EXIST":
        return Messages.ERR_EMAIL_NOT_EXIST
      case "USER_NOT_EXIST":
        return Messages.ERR_USER_NOT_EXIST
      case "OTP_INCORRECT":
        return Messages.ERR_OTP_INCORRECT
      case "SECURITY_CODE_INCORRECT":
        return Messages.ERR_SECURITY_CODE_INCORRECT
      default:
        return Messages.ERR_COMMON_SERVER_FAILED
    }
  }

  const handleTooManyRequest = () => {
    // Lock 5 minutes
    localStorage.setItem(
      COOKIE_NAME.LOCK_TIME,
      String(new Date().getTime() + AUTHORITY_CONSTANTS.TIME_LOCK_USER)
    )
    cookies.set(COOKIE_NAME.RATE_LIMIT_EXCEEDED, AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT)
    navigate(0)
  }

  const sendRequest = () => {
    if (step === State.OTP && isSubmiting) {
      return false
    }
    dispatch(setErrorMessage({ message: "" }))
    setSubmit(true)

    forgetPassword({ email })
      .then((res) => {
        if (!res?.status) {
          dispatch(setErrorMessage({ message: translateMessage(res?.message) }))
        } else {
          setSecurityCode(res.data?.securityCode || "")
          setStep(State.OTP)
          setCowndown(COUNTDOWN)
        }
        setSubmit(false)
      })
      .catch((e) => {
        console.error("Code exception: handle forgetPassword =>", e.message)
        if (e?.response?.status == 429) {
          handleTooManyRequest()
        } else {
          dispatch(setErrorMessage({ message: Messages.ERR_FORGOT_PASSWORD_SEND_CODE_FAILED }))
          setSubmit(false)
          writeCodeLogEvent("handle forgetPassword", e)
        }
      })
  }

  const [email, setEmail] = useState("")
  const onRequestSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendRequest()
  }

  const [countDown, setCowndown] = useState(COUNTDOWN)
  const [validOTP, setValidOTP] = useState(false)
  const [otpValues, setOtpValues] = useState<string[]>(Array(OTP_LENGTH).fill(""))
  const isOTPCharacter = (value: string): boolean => OTP_PATTERN.test(value) && value.length == 1

  useEffect(() => {
    let countDownTimeout: NodeJS.Timer | undefined
    if (countDown > 0 && step === State.OTP) {
      countDownTimeout = setTimeout(() => {
        if (countDown <= 1) {
          dispatch(setErrorMessage({ message: Messages.MSG_USER_RESET_PASSWORD_OTP_EXPIRED }))
        }
        setCowndown(countDown - 1)
      }, 1000)
    }
    return () => {
      clearTimeout(countDownTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown, step])
  useEffect(() => {
    const allFilled = otpValues.every((v) => v.length > 0)
    setValidOTP(allFilled)
  }, [otpValues, validOTP])

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const passwordRegex = new RegExp(process.env.REACT_APP_PASSWORD_REGEX || "")
  const validateInput = (name: string, value: string) => {
    if (name === "password") {
      return passwordRegex.test(value) || false
    }
    if (name === "confirm-password") {
      return value === password || false
    }
  }

  const onResetSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (
      !validateInput("password", password) ||
      !validateInput("confirm-password", confirmPassword)
    ) {
      return false
    }

    setSubmit(true)
    dispatch(setErrorMessage({ message: "" }))
    updatePassword({ otpCode: otpValues.join(""), securityCode, password })
      .then((res) => {
        if (res.status) {
          setStep(State.SUCCEED)
          setSubmit(false)
        } else {
          dispatch(setErrorMessage({ message: translateMessage(res.message) }))
          setSubmit(false)
        }
      })
      .catch((e) => {
        console.error("Code exception: handle update password =>", e.message)
        dispatch(setErrorMessage({ message: Messages.ERR_FORGOT_PASSWORD_UPDATE_FAILED }))
        setSubmit(false)

        writeCodeLogEvent("handle update password", e)
      })
  }

  const onOTPSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmit(true)
    dispatch(setErrorMessage({ message: "" }))
    verifyOtp({ otpCode: otpValues.join(""), securityCode })
      .then((res) => {
        if (res.status) {
          setStep(State.RESET)
          setSubmit(false)
        } else {
          dispatch(setErrorMessage({ message: Messages.ERR_OTP_INCORRECT }))
          setSubmit(false)
        }
      })
      .catch((e) => {
        console.error("Code exception: handle verify otp =>", e.message)
        dispatch(setErrorMessage({ message: Messages.ERR_FORGOT_PASSWORD_UPDATE_FAILED }))
        setSubmit(false)

        writeCodeLogEvent("handle verify otp", e)
      })
  }

  if (step === State.SUCCEED) {
    return (
      <Wrapper id="forgotPasswordWrapper" className="text-center">
        <h4 className="mb-3 text-primary">Reset Password</h4>
        <p className="mb-5 text-primary">{Messages.MSG_USER_RESET_PASSWORD_SUCCESS}</p>
        <Link to="/auth/signin" className="btn btn-primary w-100 mb-3">
          Sign in
        </Link>
      </Wrapper>
    )
  }

  if (step === State.RESET) {
    return (
      <Wrapper id="forgotPasswordWrapper" className="text-center">
        <Form type="post" onSubmit={onResetSubmit}>
          <h4 className="mb-5 text-primary">Reset Password</h4>
          <FormGroup floating className="mb-3 rear-icon-wrapper">
            <Input
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="mb-1"
              id="inpPassword"
              defaultValue={password}
              readOnly={isSubmiting}
              required={true}
              onChange={inputOnchange}
              invalid={password ? !validateInput("password", password) : false}
            />
            <Label for="inpPassword">Password</Label>
            <Tooltip
              placement="bottom"
              isOpen={password ? !validateInput("password", password) : false}
              target="inpPassword"
            >
              <ul className="mb-0">
                <li>{Messages.MSG_USER_PASSWORD_MIN_8}</li>
                <li>{Messages.MSG_USER_PASSWORD_1_UPPER_CHARACTER}</li>
                <li>{Messages.MSG_USER_PASSWORD_1_LOWER_CHARACTER}</li>
                <li>{Messages.MSG_USER_PASSWORD_1_NUMBER}</li>
                <li>{Messages.MSG_USER_PASSWORD_1_SPECIAL_CHARACTER}</li>
                <li>{Messages.MSG_USER_PASSWORD_NO_SPACE}</li>
              </ul>
            </Tooltip>
            <img
              src={showPassword ? eyeopen : eyeoclosed}
              className="rear-icon"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            />
          </FormGroup>
          <FormGroup floating className="mb-5 rear-icon-wrapper">
            <Input
              name="confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="mb-1"
              id="inpConfirmPassword"
              defaultValue={confirmPassword}
              readOnly={isSubmiting}
              required={true}
              onChange={inputOnchange}
              invalid={
                confirmPassword ? !validateInput("confirm-password", confirmPassword) : false
              }
            />
            <Label for="inpConfirmPassword">Confirm Password</Label>
            <FormFeedback valid className="mb-1">
              {Messages.LBL_SIGNUP_PASSWORD_NOT_MATCH}
            </FormFeedback>
            <img
              src={showConfirmPassword ? eyeopen : eyeoclosed}
              className="rear-icon"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword)
              }}
            />
          </FormGroup>
          <Button color="primary" className="w-100 mb-5 mt-2" disabled={isSubmiting}>
            {isSubmiting && (
              <Spinner color="light" size="sm" className="me-1">
                Loading...
              </Spinner>
            )}
            Save
          </Button>
          <Link to="/auth/signin" id="achSignup" className="text-primary">
            Back to sign in
          </Link>
        </Form>
      </Wrapper>
    )
  }

  if (step === State.OTP) {
    return (
      <Wrapper id="forgotPasswordWrapper" className="text-center text-primary">
        <Form type="post" onSubmit={onOTPSubmit}>
          <h4 className="mb-3 text-primary">Reset Password</h4>
          <p className="mb-5 text-primary text-break ">
            {Messages.LBL_FORGOT_PASSWORD_ENTER_CODE}
            {email}
          </p>
          <div className="row mb-5" id="otpWrapper">
            {Array.from({ length: OTP_LENGTH }).map((_, index) => (
              <div className="col-2" key={index}>
                <input
                  type="text"
                  maxLength={1}
                  value={otpValues[index]}
                  ref={(el) => {
                    if (el) {
                      OTPInputs.current[index] = el
                    }
                  }}
                  onChange={(e) => handleOTPInputChange(e, index)}
                  onKeyDown={(e) => handleOTPInputKeyDown(e, index)}
                  onPaste={(e) => handlePasteOTPInputs(e, index)}
                  className="form-control"
                />
              </div>
            ))}
          </div>
          <p className="mb-2">Didn&apos;t receive code?</p>
          <p
            onClick={sendRequest}
            role="button"
            className={step === State.OTP && isSubmiting ? "cursor-not-allowed" : ""}
          >
            <b>
              {isSubmiting && (
                <Spinner color="light" size="sm" className="me-1">
                  Loading...
                </Spinner>
              )}
              Request again
            </b>
          </p>
          {!isSubmiting && (
            <h4 className="text-danger mb-3">00:{countDown < 10 ? `0${countDown}` : countDown}</h4>
          )}
          <Button
            color="primary"
            className="w-100 mb-5 mt-2"
            disabled={!validOTP || isSubmiting || countDown <= 0}
          >
            Reset Password
          </Button>
          <Link to="/auth/signin" id="achSignup" className="text-primary">
            Back to sign in
          </Link>
        </Form>
      </Wrapper>
    )
  }

  return (
    <Wrapper id="forgotPasswordWrapper" className="text-center">
      <Form type="post" onSubmit={onRequestSubmit}>
        <h4 className="mb-3 text-primary">Reset Password</h4>
        <p className="mb-5 text-primary">{Messages.LBL_FORGOT_PASSWORD_ENTER_EMAIL}</p>

        <FormGroup floating>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            className="mb-4"
            id="inpEmail"
            defaultValue={email}
            readOnly={isSubmiting}
            required={true}
            onChange={inputOnchange}
          />
          <Label for="inpEmail">Email</Label>
        </FormGroup>
        <Button color="primary" className="w-100 mb-5 mt-2" disabled={isSubmiting || !email}>
          {isSubmiting && (
            <Spinner color="light" size="sm" className="me-1">
              Loading...
            </Spinner>
          )}
          Send
        </Button>
        <Link to="/auth/signin" id="achSignup" className="text-primary">
          Back to Sign in
        </Link>
      </Form>
    </Wrapper>
  )
}

export default ForgotPassword
