import React, { useEffect, useRef, useState } from "react"
import { Button, Collapse } from "reactstrap"

import information from "assets/images/icons/information-light.svg"
import trash from "assets/images/icons/trash-fill.svg"

import arrowCollapsed from "assets/images/icons/admin/arrow-collapsed-gray.svg"
import arrowExpanded from "assets/images/icons/admin/arrow-expanded-gray.svg"

import Messages from "utils/messages"
import { FWUploadTableProps } from "interfaces/FWUploadTableProps"
import TableStatus from "components/TableStatus"
import { TableStatusProps } from "interfaces/TableStatusProps"
import { FWData } from "interfaces/FWData"
import UploadProgresModal from "components/modal/UploadProgresModal"
import { AlertModalProps } from "interfaces/AlertModalProps"
import AlertModal from "components/modal/AlertModal"
import { AxiosProgressEvent } from "axios"
import { apiUploadFirmware } from "services/apiFWUpdate"
import { stringFormat } from "utils/utils"
import { getFWTypeString } from "utils/utils"
import { ACCEPTED_FW_EXTENSIONS, MAX_FW_FILE_SIZE } from "utils/constants"
import { ProgressType } from "interfaces/UploadProgresModalProps"

interface FWSelectionData {
  isSelected: boolean
  fwData: FWData
}

enum FWTableStatus {
  DEFAULT = "default",
  LOADING = "loading",
  EMPTY = "empty",
  ERROR = "error"
}

const FWUploadTable = (props: FWUploadTableProps) => {
  const fwFileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadCollapsed, setUploadCollapsed] = useState(false)
  const [selectionFWList, setSelectionFWList] = useState<FWSelectionData[]>([])
  const [tablestatusProps, setTablestatusProps] = useState<TableStatusProps>({
    status: FWTableStatus.LOADING,
    message: Messages.LBL_FW_UPDATE_LOADING,
    colCount: 3
  })
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useEffect(() => {
    setSelectionFWList(
      props.fwList.map((fwData) => {
        const isSelected = fwData.id === props.selectedFw?.id
        return { isSelected: isSelected, fwData: fwData }
      })
    )
  }, [props.fwList, props.selectedFw])

  useEffect(() => {
    if (!props.isLoading) {
      if (selectionFWList.length === 0) {
        setTablestatusProps({
          status: FWTableStatus.EMPTY,
          message: Messages.MSG_EMPTY_DATA,
          colCount: 3
        })
      } else {
        setTablestatusProps({
          status: FWTableStatus.DEFAULT,
          message: "",
          colCount: 3
        })
      }
    }
  }, [selectionFWList, props.isLoading])

  const browseFile = () => {
    if (fwFileInputRef.current) {
      fwFileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 1. handle file validation
    // 2. set selected file
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const OnUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const progres = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setUploadProgress(progres)
    }
  }

  const handleUpload = async () => {
    if (selectedFile) {
      if (selectedFile.size > MAX_FW_FILE_SIZE) {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: Messages.ERR_FW_UPDATE_FILE_SIZE_EXCEEDED,
          modal: true
        })
        return
      }

      setIsProgressModalOpen(true)
      setUploadProgress(0)

      const res = await apiUploadFirmware(props.type, selectedFile, OnUploadProgress)

      if (res.status) {
        const newFW: FWData = res.data
        props.addNewFW(newFW)
        setAlertModalProps({
          ...alertModalProps,
          status: "success",
          message: stringFormat(Messages.MSG_FW_UPDATE_UPLOAD_SUCCESSFULLY, [props.type]),
          modal: true
        })
      } else {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: res.errorLists ?? res.message,
          modal: true
        })
      }
      // clean up
      setSelectedFile(null)
      if (fwFileInputRef.current) {
        fwFileInputRef.current.value = ""
      }
      setIsProgressModalOpen(false)
    }
  }

  const handleFWSelection = (checked: boolean, id: number) => {
    setSelectionFWList((oldSelectionList) => {
      return oldSelectionList.map((fwSelection) => {
        if (fwSelection.fwData.id !== id) {
          fwSelection.isSelected = false
        } else {
          fwSelection.isSelected = checked
        }
        return fwSelection
      })
    })
  }

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  return (
    <div>
      <p className="title pt-4">{props.title}</p>
      <table id="tableFWList" className="table table-hover mb-0">
        <thead>
          <tr>
            {<th scope="col">{Messages.LBL_FW_UPDATE_HEADER_VERSION}</th>}
            {<th scope="col">{Messages.LBL_FW_UPDATE_HEADER_LAST_UPDATE}</th>}
            {<th scope="col">{Messages.LBL_CUSTOMER_HEADER_ACTIONS}</th>}
          </tr>
        </thead>
        <tbody>
          {selectionFWList.slice(0, 2).map((fwSelection) => (
            <tr key={fwSelection.fwData.id}>
              <td>
                <div className="d-flex align-items-center">
                  <input
                    id={`checkbox-${props.type}-${fwSelection.fwData.id}`}
                    type="checkbox"
                    onChange={(event) => {
                      if (fwSelection.fwData.id) {
                        handleFWSelection(event.target.checked, fwSelection.fwData.id)
                      }
                      if (event.target.checked) {
                        props.setSelectedFW(fwSelection.fwData)
                      } else {
                        props.setSelectedFW(null)
                      }
                    }}
                    checked={fwSelection.isSelected}
                  />
                  <label
                    htmlFor={`checkbox-${props.type}-${fwSelection.fwData.id}`}
                    className="ps-2"
                  >
                    {fwSelection.fwData.version}
                  </label>
                </div>
              </td>
              <td>
                {fwSelection.fwData.createdAt
                  ? new Date(fwSelection.fwData.createdAt).toISOString().split("T")[0]
                  : "-"}
              </td>
              <td>
                <div className="action-button">
                  <Button
                    color="light"
                    className="border-0"
                    onClick={() => {
                      props.onReleaseNotesClick(fwSelection.fwData)
                    }}
                  >
                    <img src={information} alt="information" />
                  </Button>
                  <Button
                    color="light"
                    className="border-0"
                    onClick={() => {
                      props.removeFW(fwSelection.fwData)
                    }}
                  >
                    <img src={trash} alt="delete" />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {(props.isLoading || tablestatusProps.status !== FWTableStatus.DEFAULT) && (
            <TableStatus {...tablestatusProps} />
          )}
        </tbody>
      </table>
      <div className="pt-3">
        <div className="d-flex align-items-baseline">
          <h3>{stringFormat(Messages.LBL_FW_UPLOAD_FW, [getFWTypeString(props.type)])}</h3>
          <Button
            className="border-0 bg-transparent p-0"
            onClick={() => setUploadCollapsed((oldState) => !oldState)}
          >
            <img
              src={uploadCollapsed ? arrowCollapsed : arrowExpanded}
              alt="open"
              className="ps-3"
            />
          </Button>
        </div>
        <Collapse isOpen={!uploadCollapsed}>
          <div className="d-flex align-items-center">
            <input
              ref={fwFileInputRef}
              className="d-none"
              type="file"
              accept={ACCEPTED_FW_EXTENSIONS}
              onChange={handleFileChange}
            />
            <div className="d-flex align-items-center">
              <input
                type="text"
                disabled={true}
                className="low-opacity-placeholder readonly-textbox"
                value={selectedFile?.name || ""}
                title={selectedFile?.name}
                placeholder={Messages.LBL_FW_UPDATE_BROWSE_FILE}
              ></input>
              <Button color="primary" className="right-radius-button" onClick={browseFile}>
                {Messages.LBL_FW_UPDATE_BROWSE}
              </Button>
            </div>
            <Button
              color="primary"
              className="mx-3"
              onClick={handleUpload}
              disabled={selectedFile === null}
            >
              {Messages.LBL_FW_UPDATE_UPLOAD}
            </Button>
          </div>
          <h4 className="pt-1">{Messages.LBL_FW_UPDATE_ALLOWED_TYPES}</h4>
        </Collapse>
      </div>
      {isProgressModalOpen && (
        <UploadProgresModal
          isOpen={isProgressModalOpen}
          progress={uploadProgress}
          type={ProgressType.DETERMINATE}
        />
      )}
      {alertModalProps.modal && <AlertModal {...alertModalProps} />}
    </div>
  )
}

export default FWUploadTable
