import moment from "moment"
import { AwsRum, AwsRumConfig } from "aws-rum-web"

import {
  SITE_STATUS_STRING,
  SITE_STATUS_STYLE_CSS,
  USER_ROLES,
  CONFIGURATION_REVIEW,
  CUSTOMER_MANAGEMENT,
  ALL_SITES_MANAGEMENT,
  FW_ALLOWED_EXTENSIONS,
  SITE_STATUS
} from "./constants"
import Message from "./messages"

import { DropDown } from "../interfaces/visualization/Dropdown"
import { ChartTooltipPayload } from "../interfaces/visualization/ChartTooltipPayload"
import { VisualizationChartData } from "../interfaces/visualization/VisualizationChartData"

import { VisualizationFilterEnum } from "../enum/visualization/tab/filter-tab"
import { BatterFilterEnum } from "../enum/visualization/tab/battery-filter"
import { BatterFilterDataEnum } from "../enum/visualization/tab/battery-filter-data"
import { LoadFilterEnum } from "../enum/visualization/tab/load-filter"
import { LoadFilterDataEnum } from "../enum/visualization/tab/load-filter-data"
import { ChartDataEnum } from "../enum/visualization/chart-data"
import CryptoJS from "crypto-js"
import { CloudWatchRumEvent } from "../enum/cloudwatch-rum-event"
import { AppEnvEnum } from "../enum/app-env"
import { FWType } from "interfaces/FWUploadTableProps"
import { SiteEntity } from "interfaces/SiteEntity"

import { getCurrentUser } from "../store/counter/authSlice"

export const getSortingString = (sortingHeader: Record<string, string>) => {
  let sortString = ""

  if (!sortingHeader) return sortingHeader

  Object.entries(sortingHeader).forEach(([key, value]) => {
    if (value) sortString += `&sort=${key},${value}`
  })
  return sortString
}

export const getRoles = (authorities: string[] | null) => {
  if (!authorities || authorities.length === 0) return ""

  return Object.values(USER_ROLES).find((role) => role.id === authorities[0])?.name
}

export const getSiteStatusCSS = (status: string | undefined) => {
  if (status) {
    return SITE_STATUS_STYLE_CSS[status]
  } else {
    return SITE_STATUS_STYLE_CSS.START_SITE_LAYOUT
  }
}

export const getSiteStatusAsString = (status: string | undefined) => {
  if (!status) {
    return SITE_STATUS_STRING.START_SITE_LAYOUT
  } else {
    return SITE_STATUS_STRING[status]
  }
}

export const truncateText = (text: string, size = 14): string => {
  if (text.length >= size) return text.substring(0, size - 1) + "..."
  else return text
}

export const lineBreakText = (text: string | null, size = 250): string => {
  if (!text) return ""

  let chunks: string[] = []
  for (let i = 0; i < text.length; i += size) {
    chunks.push(text.slice(i, i + size))
  }
  chunks = chunks.map((x) => x + "<br/>")
  return chunks.join("")
}

export const stringFormat = (str: string, args: string[]) =>
  str.replace(/{(\d+)}/g, (_match, index) => args[index] || "")

export const responseErrorMessage = (code: string, message: string) =>
  code === "ECONNABORTED" ? Message.ERR_REQUEST_TIMEOUT : message

export const getSiteNameCutLength = (
  siteSetupStatus: string | undefined,
  longText = false,
  isSmallScreen = false
) => {
  if (longText) return isSmallScreen ? 16 : 22
  else if (
    [
      "layout-pending-approval",
      "configuration-in-progress",
      "pending-final-approval",
      "ready-for-installation",
      "connected"
    ].includes(getSiteStatusCSS(siteSetupStatus) || "")
  )
    return isSmallScreen ? 2 : 6
  else return isSmallScreen ? 6 : 9
}

export const validateNumberOnKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>,
  fullValue: string
) => {
  // Allow select all key (Ctrl+A or Command+A)
  if (
    ((event.ctrlKey || event.metaKey) && event.key === "a") ||
    event.key === "v" ||
    event.key === "c"
  ) {
    return
  }

  // Allow left and right arrow keys, backspace key and tab key
  if (
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Backspace" ||
    event.key === "Tab" ||
    event.key === "Delete"
  ) {
    return
  }

  // Allow only numbers, one '.', and the first character to be '-'
  if (
    !(
      (event.key >= "0" && event.key <= "9") ||
      (event.key === "." && fullValue.indexOf(".") === -1 && fullValue !== "") ||
      (event.key === "-" && event.currentTarget.selectionStart === 0)
    )
  ) {
    event.preventDefault()
  }
}

export const lastUpdateToolTip = (lastUpdate: number | undefined) => {
  if (!lastUpdate) return ""

  const TOOLTIP_FORMAT = "{0}<br/>{1}"
  const dateTime = parseDateTime(lastUpdate)
  const fromNow = timeAgo(lastUpdate)
  return stringFormat(TOOLTIP_FORMAT, [dateTime, fromNow])
}

export const parseDateTime = (lastUpdate: number | undefined) => {
  if (!lastUpdate) return ""

  const DATETIME_FORMAT = "{0} at {1}"
  const date = moment.unix(lastUpdate).format("MM/DD/YYYY")
  const time = moment.unix(lastUpdate).format("HH:mm:ss")
  return stringFormat(DATETIME_FORMAT, [date, time])
}

export const timeAgo = (lastUpdate: number | undefined) => {
  if (!lastUpdate) return ""

  const duration = moment.duration(moment().diff(moment.unix(lastUpdate)))
  if (duration.asMilliseconds() < 0) return ""

  const asMinutes = Math.floor(duration.asMinutes())
  const asHours = asMinutes / 60
  const minutes = Math.floor(asMinutes % 60)
  const asDays = asHours / 24
  const hours = Math.floor(asHours % 24)
  const asWeeks = asDays / 7
  const days = Math.floor(asDays % 7)
  const asMonths = asWeeks / 4.2
  const weeks = Math.floor(asWeeks % 4.2)
  const years = Math.floor(asMonths / 12)
  const months = Math.floor(asMonths % 12)

  let result = ""

  if (years) {
    result += `${years} ${years === 1 ? "yr" : "yrs"} `
    if (months) {
      result += `and ${months} ${months === 1 ? "mo" : "mos"} `
    }
    return `(${result.trim()} ago)`
  }
  if (months) {
    result += `${months} ${months === 1 ? "mo" : "mos"} `
    if (weeks) {
      result += `and ${weeks} ${weeks === 1 ? "wk" : "wks"} `
    }
    return `(${result.trim()} ago)`
  }
  if (weeks) {
    result += `${weeks} ${weeks === 1 ? "wk" : "wks"} `
    if (days) {
      result += `and ${days} ${days === 1 ? "day" : "days"} `
    }
    return `(${result.trim()} ago)`
  }
  if (days) {
    result += `${days} ${days === 1 ? "day" : "days"} `
    if (hours) {
      result += `and ${hours} ${hours === 1 ? "hr" : "hrs "} `
    }
    return `(${result.trim()} ago)`
  }
  if (hours) {
    result += `${hours} ${hours === 1 ? "hr" : "hrs "} `
    if (minutes) {
      result += `and ${minutes} ${minutes === 1 ? "min" : "mins"} `
    }
    return `(${result.trim()} ago)`
  }
  if (result === "") return "(just now)"

  return `(${result.trim()} ago)`
}

export const isAESISuperAdminRoute = (path: string) =>
  [CUSTOMER_MANAGEMENT, CONFIGURATION_REVIEW, ALL_SITES_MANAGEMENT].includes(path)

export const genDateApart = (date: Date | string, range: number, format: string) => {
  const sevenDaysFromToday = new Date(date)
  sevenDaysFromToday.setDate(sevenDaysFromToday.getDate() + range)
  return moment(sevenDaysFromToday).format(format)
}

/**
 * Get 1 year back date from today
 * @param {string} format date format
 * @returns {string} 1 year back date string
 */
export const oneYearBack = (format: string): string => {
  const today = new Date()

  // Get the current year, month, and day
  const year = today.getFullYear()
  const month = today.getMonth()
  const day = today.getDate()

  // Subtract one year
  const oneYearBack = new Date(year - 1, month, day)

  return moment(oneYearBack).format(format)
}

export const _isShowDataLine = (
  filters: DropDown[],
  dataKey: BatterFilterEnum | LoadFilterEnum,
  dataValue: BatterFilterDataEnum | LoadFilterDataEnum
) => {
  return (
    filters.filter(
      (filter: DropDown) => filter.dataKey === dataKey && filter.dataValue === dataValue
    ).length > 0
  )
}

export const countDatesApart = (startDate: string | Date, endDate: string | Date): number => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const differenceInMillis = end.getTime() - start.getTime()
  const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24)
  const count = Math.floor(differenceInDays) // or Math.ceil(differenceInDays) or Math.round(differenceInDays)

  // plus 1 for the start date
  return count + 1
}

export const getVisuaTooltipText = (
  filter: VisualizationFilterEnum,
  dataKey: BatterFilterEnum | LoadFilterEnum,
  dataValue: BatterFilterDataEnum | LoadFilterDataEnum
): string => {
  if (filter === VisualizationFilterEnum.Battery) {
    if (dataValue === BatterFilterDataEnum.SOC) return `${dataKey}`
    else if (dataValue === BatterFilterDataEnum.Maximum) return `Max ${dataKey}`
    else if (dataValue === BatterFilterDataEnum.Average) return `Avg ${dataKey}`
    else if (dataValue === BatterFilterDataEnum.Minimum) return `Min ${dataKey}`
  }

  if (filter === VisualizationFilterEnum.Load) {
    if (dataKey === LoadFilterEnum.Current || dataKey === LoadFilterEnum.Voltage)
      return `${dataKey} ${dataValue}`
    else if (dataKey === LoadFilterEnum.RealPower || dataKey === LoadFilterEnum.ReactivePower) {
      if (dataValue === LoadFilterDataEnum.Commanded) return `${dataKey} Com`
      else if (dataValue === LoadFilterDataEnum.Actual) return `${dataKey} Actual`
    }
  }

  return ""
}

export const getVisuaTooltipData = (payload: ChartTooltipPayload[]): Record<string, number> => {
  const data: Record<string, number> = {}
  payload.forEach((payload: ChartTooltipPayload) => {
    data[payload.name] = payload.value
  })

  return data
}

export const getVisuaTooltipAttributes = (
  dataKey: BatterFilterEnum | LoadFilterEnum,
  dataValue: BatterFilterDataEnum | LoadFilterDataEnum
): keyof VisualizationChartData => {
  if (dataKey === BatterFilterEnum.SOC && dataValue === BatterFilterDataEnum.SOC)
    return ChartDataEnum.soc
  else if (dataKey === BatterFilterEnum.Temperature && dataValue === BatterFilterDataEnum.Maximum)
    return ChartDataEnum.maxTemp
  else if (dataKey === BatterFilterEnum.Temperature && dataValue === BatterFilterDataEnum.Average)
    return ChartDataEnum.avgTemp
  else if (dataKey === BatterFilterEnum.Temperature && dataValue === BatterFilterDataEnum.Minimum)
    return ChartDataEnum.minTemp
  else if (dataKey === BatterFilterEnum.Voltage && dataValue === BatterFilterDataEnum.Maximum)
    return ChartDataEnum.maxVoltage
  else if (dataKey === BatterFilterEnum.Voltage && dataValue === BatterFilterDataEnum.Average)
    return ChartDataEnum.avgVoltage
  else if (dataKey === BatterFilterEnum.Voltage && dataValue === BatterFilterDataEnum.Minimum)
    return ChartDataEnum.minVoltage
  else if (dataKey === LoadFilterEnum.Current && dataValue === LoadFilterDataEnum.IA)
    return ChartDataEnum.ia
  else if (dataKey === LoadFilterEnum.Current && dataValue === LoadFilterDataEnum.IB)
    return ChartDataEnum.ib
  else if (dataKey === LoadFilterEnum.Current && dataValue === LoadFilterDataEnum.IC)
    return ChartDataEnum.ic
  else if (dataKey === LoadFilterEnum.Voltage && dataValue === LoadFilterDataEnum.VAB)
    return ChartDataEnum.vab
  else if (dataKey === LoadFilterEnum.Voltage && dataValue === LoadFilterDataEnum.VBC)
    return ChartDataEnum.vbc
  else if (dataKey === LoadFilterEnum.Voltage && dataValue === LoadFilterDataEnum.VCA)
    return ChartDataEnum.vca
  else if (dataKey === LoadFilterEnum.RealPower && dataValue === LoadFilterDataEnum.Commanded)
    return ChartDataEnum.commandRealPower
  else if (dataKey === LoadFilterEnum.RealPower && dataValue === LoadFilterDataEnum.Actual)
    return ChartDataEnum.actualRealPower
  else if (dataKey === LoadFilterEnum.ReactivePower && dataValue === LoadFilterDataEnum.Commanded)
    return ChartDataEnum.commandReactivePower

  // ReactivePower Actual
  return ChartDataEnum.actualReactivePower
}
export const getVisuaTooltipValue = (
  data: Record<string, number>,
  dataKey: BatterFilterEnum | LoadFilterEnum,
  dataValue: BatterFilterDataEnum | LoadFilterDataEnum
) => {
  const key = getVisuaTooltipAttributes(dataKey, dataValue)

  return data[key]
}
export const getVisuaUnit = (dataKey: BatterFilterEnum | LoadFilterEnum) => {
  if (dataKey === BatterFilterEnum.Temperature) return "(℉)"
  else if (dataKey === BatterFilterEnum.SOC) return "(%)"
  else if (dataKey === LoadFilterEnum.Current) return "(A)"
  else if ([BatterFilterEnum.Voltage, LoadFilterEnum.Voltage].includes(dataKey)) return "(V)"
  else if (dataKey === LoadFilterEnum.RealPower) return "(kW)"
  else if (dataKey === LoadFilterEnum.ReactivePower) return "(kVAR)"

  return ""
}

/**
 * Rounds a number to a fixed number of decimal places.
 *
 * @param num - The number to round.
 * @param decimalPlaces - The number of decimal places to round to.
 * @returns The rounded number as a string with the specified number of decimal places.
 */
export const roundToFixed = (num: number | string | undefined | null, decimalPlaces: number) => {
  if (num === undefined || num === null) {
    return num
  }
  // parse to number if value is string
  num = typeof num === "string" ? parseFloat(num) : num
  const multiplier = Math.pow(10, decimalPlaces)
  return (Math.round(num * multiplier) / multiplier).toFixed(decimalPlaces)
}

/**
 * Convert celsius to fahrenheit
 * @param {number|null|undefined} celsius - celsius value
 * @returns {number} - converted fahrenheit value
 */
export function celsiusToFahrenheit(celsius: string | number | null | undefined) {
  return typeof celsius !== "string" && celsius !== undefined && celsius !== null
    ? (celsius * 9) / 5 + 32
    : null
}

export const calculateFileChecksum = async (file: File): Promise<string> => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)
  const wordArray = CryptoJS.lib.WordArray.create(reader.result as ArrayBuffer)
  return CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex)
}

export const getFWNameWithoutExtension = (fileName: string) => {
  return fileName.replace(new RegExp(`(${FW_ALLOWED_EXTENSIONS.join("|")})$`), "")
}

/**
 * Converts a number or string to a number, or returns a dash if the value is null or undefined.
 *
 * @param value - The value to convert or check.
 * @returns The converted number or a dash if the value is null or undefined.
 */
export const numberOrDash = (value: number | string | null | undefined) => {
  return value !== null && value !== undefined ? value : "-"
}

/**
 * check if value is not null neither undefined
 * @param {number | null | undefined} val - value to check
 * @returns {boolean} - true if value is not null neither undefined, false otherwise
 */
export const isValidValue = (val: number | null | undefined) => val !== undefined && val !== null

/**
 * Init cloud watch rum
 * @returns {AwsRum} - cloud watch rum object
 */
export const initCloudWatchRum = (): AwsRum => {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: process.env.REACT_APP_RUM_POOL_ID || "",
    endpoint: process.env.REACT_APP_RUM_ENDPOINT || "",
    telemetries: [
      "performance",
      "errors",
      [
        "http",
        {
          recordAllRequests: true
        }
      ]
    ],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true
  }

  return new AwsRum(
    process.env.REACT_APP_RUM_APPLICATION_ID || "",
    process.env.REACT_APP_RUM_APPLICATION_VERSION || "",
    process.env.REACT_APP_RUM_APPLICATION_REGION || "",
    config
  )
}

/**
 * Write cloud watch rum event
 * @param {CloudWatchRumEvent} event - logging type of event
 * @param {string} title - error title
 * @param {any} err - error object
 * @returns {void}
 */
export const writeCloudWatchRumLogEvent = (
  preTitle: string,
  title: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any & { notNull: true },
  event = CloudWatchRumEvent.CodeException,
  siteUniqueId?: number | string
) => {
  try {
    // ignore in testing mode
    if (process.env.REACT_APP_ENV === AppEnvEnum.Testing) return

    const awsRum: AwsRum = initCloudWatchRum()

    // get current user
    const user = getCurrentUser()

    // Add custom event
    awsRum.recordEvent(event, {
      message: `${preTitle} [username=${user.username}][customerUniqueId=${user.customerUniqueId}][siteId=${siteUniqueId}]: ${title} => ${err.message}`,
      err: err.response ? err.response.data : err.stack
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    console.error("Code exception: handle record cloud watch rum event", err.message)
  }
}

/**
 * Write api cloud watch event
 * @param {string} title error title
 * @param {any} err error object
 * @returns {any}
 */
export const writeApiLogEvent = (
  title: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any & { notNull: true },
  siteUniqueId?: number | string
) =>
  writeCloudWatchRumLogEvent(
    "API exception",
    title,
    err,
    CloudWatchRumEvent.ApiException,
    siteUniqueId
  )

/**
 * Write code cloud watch event
 * @param {string} title error title
 * @param {any} err error object
 * @returns {any}
 */
export const writeCodeLogEvent = (
  title: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any & { notNull: true },
  siteUniqueId?: number | string
) =>
  writeCloudWatchRumLogEvent(
    "Code exception",
    title,
    err,
    CloudWatchRumEvent.CodeException,
    siteUniqueId
  )

/**
 * Write app crashed cloud watch event
 * @param {string} title error title
 * @param {any} err error object
 * @returns {any}
 */
export const writeCrashedLogEvent = (
  title: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any & { notNull: true }
) => writeCloudWatchRumLogEvent("App crashed", title, err, CloudWatchRumEvent.AppCrashed)

export function getFWTypeString(type: FWType | null | undefined) {
  return type === FWType.EMS ? "StorView" : FWType.BMS || ""
}

/**
 * Checks if a string is an empty string.
 *
 * @param {string | null | undefined} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is null, undefined, or an empty string. Otherwise, returns false.
 */
export const isEmptyString = (str: string | null | undefined): boolean =>
  str === null || str === undefined || str === ""

/**
 * Visualization export file name
 * @param {VisualizationFilterEnum} filters chart mode
 * @param {SiteEntity} siteEntity? selected site
 * @param {number} tsId? terastor id
 * @returns {string}
 */
export const visualizationFileName = (
  filters: VisualizationFilterEnum,
  siteEntity: SiteEntity | null,
  tsId?: number
): string => {
  const siteName: string = siteEntity ? siteEntity.siteName : ""
  const siteUniqueId: string | number = siteEntity ? siteEntity.siteUniqueId : ""

  return `svc-${filters}-${siteName}-${siteUniqueId}${tsId ? `-ts${tsId}` : ""}`
}

/**
 * Convert tier alias to tier string
 * @param {string} tier tier alias
 * @returns {string} tier string
 */
export const convertTierString = (tier: string) => {
  // Split the input string by underscore
  const parts = tier.toLowerCase().split("_")

  // Capitalize the first letter of the first word
  parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1)

  // Join the parts with a space
  return parts.join(" ")
}

/**
 * Get customer start date
 * @returns {string}
 */
export const getStartDate = () => {
  const toDay = new Date()
  const date = toDay.getDate()
  const month = toDay.getMonth() + 1
  const year = toDay.getFullYear()

  return `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}T00:00:00Z`
}

/**
 * Get enable activation
 * @returns {boolean}
 */
export const getEnableActivationCode = (siteUniqueId: number, sites: SiteEntity[]) => {
  return sites.some(
    (site) =>
      site.siteUniqueId === siteUniqueId &&
      (site.status === SITE_STATUS.READY_FOR_INSTALLATION || site.status === SITE_STATUS.CONNECTED)
  )
}

/**
 * Removes the file extension from a given file name.
 *
 * @param fileName - The name of the file from which to remove the extension.
 * @returns The file name without its extension.
 */
export const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName.split(".").slice(0, -1).join(".")
}

/**
 * Fetches a file from the given URL and returns its content as a Blob.
 *
 * @param {string} fileUrl - The URL of the file to fetch.
 * @returns {Promise<Blob>} A promise that resolves to the file content as a Blob.
 * @throws {Error} If the network response is not ok.
 * @throws {Error} If there is an issue with fetching the file.
 */
export const fetchFile = async (fileUrl: string) => {
  try {
    // Fetch the file content from the fileUrl
    const response = await fetch(fileUrl)
    if (!response.ok) {
      throw new Error("Network response was not ok")
    }
    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error("fetch file failed", error)
    writeCodeLogEvent("fetch file fail", error)
  }
}

export const downloadFile = async (fileUrl: string, fileName: string) => {
  try {
    const link = document.createElement("a")
    link.href = fileUrl
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()

    // Cleanup
    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
  } catch (error) {
    console.error("downloadFile failed", error)
    writeCodeLogEvent("[Document Center] downloadFile fail", error)
  }
}
