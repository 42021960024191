import { Suspense, lazy } from "react"
const SiteManagement = lazy(() => import("../site-management/SiteManagement"))
import Loader from "../../components/Loader"

const SiteInformation = () => {
  return (
    <div id="siteInformation" className="admin-wrapper">
      <div className="d-md-flex justify-content-between mb-3 text-center text-md-start">
        <h4 className="mt-2 text-primary fw-bold">All sites</h4>
      </div>

      <Suspense fallback={<Loader />}>
        <SiteManagement
          readonly={true}
          notShowCommissionStartDate={true}
          wrapperClass="site-information"
        />
      </Suspense>
    </div>
  )
}

export default SiteInformation
