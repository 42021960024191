import { memo } from "react"
import { Modal, ModalBody } from "reactstrap"

import { ModalProps } from "../../interfaces/ModalProps"

import ChartWrapper from "../../pages/visualization/ChartWrapper"

interface ChartModalProps extends ModalProps {
  tsId?: number

  setDownloadProcess: (isOpen: boolean, progress: number) => void
  setDownloadSucceed: (modal: boolean) => void
}

const ChartModal = memo((props: ChartModalProps) => {
  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      onClosed={props.onClose}
      unmountOnClose={true}
      id="chartModal"
    >
      <ModalBody className="p-0">
        <ChartWrapper
          expandToggle={() => props.toggle()}
          status={true}
          openInModal={true}
          tsId={props.tsId}
          setDownloadProcess={(isOpen: boolean, progress: number) =>
            props.setDownloadProcess(isOpen, progress)
          }
          setDownloadSucceed={(modal: boolean) => props.setDownloadSucceed(modal)}
          wrapper="Modal"
        />
      </ModalBody>
    </Modal>
  )
})

ChartModal.displayName = "ChartModal"

export default ChartModal
