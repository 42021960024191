import React from "react"
import { ElementProperties } from "../../interfaces/ElementProperties"
import "../../assets/scss/configuration/configuration.scss"

const SiteElement: React.FC<ElementProperties> = (props: ElementProperties) => {
  const targetRef = React.useRef<HTMLDivElement>(null)

  const element = props.element.split("-")[0].toLowerCase()

  return (
    <div
      id={`${props.uuid}`}
      className={`target ${element}`}
      ref={targetRef}
      onDoubleClick={props.onDoubleClick}
      style={{
        transform: `${props.transform}`
      }}
    >
      {props.sysName}
    </div>
  )
}

export default SiteElement
