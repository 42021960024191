import { useBlocker } from "react-router-dom"

const useNavigationBlocker = (shouldBlock: boolean) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return currentLocation != nextLocation && shouldBlock
  })

  return blocker
}

export default useNavigationBlocker
