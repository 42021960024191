import React from "react"
import "../../assets/scss/configuration/configuration.scss"
import ElementCard from "./ElementCard"
import { ElementCardProps } from "../../interfaces/ElementCardProps"
import { ElementSelectionProps } from "../../interfaces/ElementSelectionProps"
import { TERASTOR } from "../../utils/constants"

const displayCards: string[] = [TERASTOR]

const ElementSelection: React.FC<ElementSelectionProps> = (props: ElementSelectionProps) => {
  const cardMouseDrag = (event: React.MouseEvent, element: string) => {
    props.elementDragged(event, element)
  }

  return (
    <div
      id="elementSelection"
      data-testid="elementSelectionTest"
      className={"element-selection text-black px-4 d-flex flex-column justify-content-between"}
    >
      <div className="pt-4">
        <p className="fw-bold text-center">Drag & Drop</p>
        {displayCards.map((card: string, i: number) => {
          const cardProps: ElementCardProps = {
            name: card,
            handleMouseMove: (event: React.MouseEvent) => {
              cardMouseDrag(event, card)
            }
          }
          return <ElementCard key={i} {...cardProps} />
        })}
      </div>
      <p className="fw-bold text-center">
        {props.placedElements} out of {props.totalElements} placed
      </p>
    </div>
  )
}

export default ElementSelection
