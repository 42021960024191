import React from "react"
import { Modal, ModalBody, Row, Col } from "reactstrap"
import "../../assets/scss/configuration/configuration.scss"
import TeraStorLarge from "../../assets/images/configuration/terastor-large.svg"
import close from "../../assets/images/icons/admin/close.svg"

const TeraStorDetailModal: React.FC<{ isOpen: boolean; content: string; onClose: () => void }> = ({
  isOpen,
  content,
  onClose
}) => {
  if (!isOpen) return null

  return (
    <Modal
      className="modal-overlay"
      size="lg"
      backdrop={false}
      isOpen={isOpen}
      toggle={onClose}
      centered={true}
    >
      <ModalBody className="bg-white rounded p-3">
        <Row
          style={{
            backgroundColor: "white",
            padding: "15px"
          }}
        >
          <Col className="d-flex justify-content-start">
            <p className="fs-4">{content}</p>
          </Col>
          <Col className="d-flex justify-content-end">
            <img
              src={close}
              alt="Close"
              style={{ right: "20px", top: "20px", width: "42px", cursor: "pointer" }}
              className="border-0 position-absolute"
              onClick={onClose}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-center p-3 pt-1 pb-1">
          <div
            className="p-4"
            style={{
              border: "1px solid",
              borderColor: "#0000001c",
              borderRadius: "14px"
            }}
          >
            <img
              draggable="false"
              src={TeraStorLarge}
              alt="terastor"
              style={{ height: "100%", width: "100%" }}
            ></img>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default TeraStorDetailModal
