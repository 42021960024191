import { useState, useEffect, useRef } from "react"
import { Button, Modal, ModalBody, ModalFooter, Spinner, ModalHeader } from "reactstrap"

import JSZip from "jszip"

import download from "../../assets/images/icons/visualization/download.svg"
import success from "../../assets/images/icons/success.svg"

import Messages from "../../utils/messages"
import { fetchFile, stringFormat } from "../../utils/utils"

import { MultipleDocsDownloadModalProps } from "interfaces/doc/MultipleDocsDownloadModalProps"
import { PresignDownloadDoc } from "interfaces/doc/PresignDoc"
import { Tooltip as ReactTooltip } from "react-tooltip"

const MultipleDocumentsDownloadModal = (props: MultipleDocsDownloadModalProps) => {
  const { presignLinks } = props

  const [downloading, setDownloading] = useState(false)
  const [succeed, setSucceed] = useState(false)
  const [canceling, setCanceling] = useState(false)
  const cancelingRef = useRef(canceling)

  useEffect(() => {
    cancelingRef.current = canceling
  }, [canceling])

  /**
   * Generate zip file
   * @returns {void}
   */
  const createZipFile = async () => {
    const zip = new JSZip()
    if (!presignLinks) {
      setSucceed(true)
      setDownloading(false)
      return
    }

    const fileNameSet = new Set<string>()
    // loop data
    for (let i = 0; i < presignLinks.length; i++) {
      const link = presignLinks[i]

      if (cancelingRef.current) break

      // show spinner
      document.querySelector(`#item-${link.id} .download`)?.classList.add("d-none")
      document.querySelector(`#item-${link.id} .spinner`)?.classList.remove("d-none")

      // Create PDF blob
      const fileName = link.fileName ?? `file-${i}`
      const fileBlob = await fetchFile(link.preSignedUrl)

      // Add PDF blob to zip file
      if (fileBlob) {
        // Extract file name without extension and extension once
        const extIndex = fileName.lastIndexOf(".")
        const fileNameNoExtension = extIndex > 0 ? fileName.substring(0, extIndex) : fileName
        const ext = extIndex > 0 ? fileName.substring(extIndex) : ""

        // Check for duplicates and add postfix if necessary
        let postfix = 1
        let uniqueFileName = fileName

        while (fileNameSet.has(uniqueFileName)) {
          uniqueFileName = `${fileNameNoExtension} (${postfix})${ext}`
          postfix++
        }

        zip.file(uniqueFileName, fileBlob)
        fileNameSet.add(uniqueFileName)
      }

      // show succeed image
      document.querySelector(`#item-${link.id} .spinner`)?.classList.add("d-none")
      document.querySelector(`#item-${link.id} .succeed`)?.classList.remove("d-none")
    }

    // create zip file
    if (!cancelingRef.current) {
      // Generate the zip file as a blob
      const zipBlob = await zip.generateAsync({ type: "blob" })

      // Trigger download of the zip file
      const link = document.createElement("a")
      link.href = URL.createObjectURL(zipBlob)
      link.download = `site-documents.zip`
      link.click()

      // set status
      setSucceed(true)
      setDownloading(false)
    } else props.toggle()
  }

  useEffect(() => {
    if (downloading) createZipFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloading, canceling])

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      backdrop={"static"}
      id="multipleDocsDownloadModal"
      scrollable={true}
    >
      <ModalHeader className="d-flex justify-content-center text-primary fw-bold">
        {succeed
          ? Messages.BTN_VISUALIZATION_EXPORT_MULTI_SUCCEED
          : stringFormat(Messages.LBL_VISUALIZATION_EXPORT_MULTI_READY, [""])}
      </ModalHeader>
      <ModalBody className="p-4 me-1 overflow-x-hidden">
        <ul className="mb-0 ps-0">
          {presignLinks &&
            presignLinks.map((link: PresignDownloadDoc, index: number) => (
              <li
                className={`d-flex justify-content-between mb-2 pb-2 ${index < presignLinks.length - 1 ? "border-bottom" : ""}`}
                key={index}
                id={`item-${link.id}`}
              >
                <span
                  className="text-truncate"
                  data-toggle="tooltip"
                  data-tooltip-id={`docFileName-${link.id}`}
                  data-tooltip-content={link.fileName}
                >
                  {link.fileName}
                </span>
                <ReactTooltip id={`docFileName-${link.id}`} place="bottom" />
                <img src={download} width={15} alt="download" className="status download" />
                <img src={success} width={15} alt="download" className="status succeed d-none" />
                <Spinner size="sm" color="primary" className="ps-1 status spinner d-none" />
              </li>
            ))}
        </ul>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {downloading || canceling ? (
          <Button
            color="danger"
            className="fw-bold"
            onClick={() => setCanceling(true)}
            disabled={canceling}
          >
            {canceling
              ? Messages.BTN_VISUALIZATION_EXPORT_MULTI_CANCELING
              : Messages.BTN_VISUALIZATION_EXPORT_MULTI_CANCEL}
            <Spinner size="sm" color="light" className="ms-2" />
          </Button>
        ) : succeed ? (
          <Button color="outline-primary" className="fw-bold" onClick={() => props.toggle()}>
            {Messages.BTN_VISUALIZATION_EXPORT_MULTI_CLOSE}
          </Button>
        ) : (
          <>
            <Button color="primary" className="fw-bold" onClick={() => setDownloading(true)}>
              {Messages.BTN_VISUALIZATION_EXPORT_MULTI_ALL}
            </Button>
            <Button color="outline-primary" className="fw-bold" onClick={() => props.toggle()}>
              {Messages.BTN_VISUALIZATION_EXPORT_MULTI_CANCEL}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default MultipleDocumentsDownloadModal
