import type { RootState } from "../store"
import { createSlice } from "@reduxjs/toolkit"

import { DocCategory } from "interfaces/doc/DocCategory"
import { fetchCategoryThunk } from "./docThunk"
import { SiteEntity } from "interfaces/SiteEntity"

export interface DocState {
  isLoading: boolean
  categoryList: DocCategory[]
  selectedCategory?: DocCategory
  selectedSites?: SiteEntity[]
  errorMessage?: string
}

const initialState: DocState = {
  isLoading: false,
  categoryList: [],
  selectedCategory: undefined,
  selectedSites: [],
  errorMessage: undefined
}

export const docSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {
    setcategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload
    },
    setSelectedSites: (state, action) => {
      state.selectedSites = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryThunk.pending, (state: DocState) => {
      state.isLoading = true
      state.categoryList = []
      state.selectedSites = []
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(fetchCategoryThunk.fulfilled, (state: DocState, action: any) => {
      const payload = action.payload
      state.isLoading = false
      if (payload.status) {
        state.categoryList = payload.data
      } else {
        state.errorMessage = payload.message
      }
    })
  }
})

// Action creators are generated for each case reducer function
export { fetchCategoryThunk as fetchDocCategory }
export const getCategoryList = (state: RootState) => state.doc.categoryList
export const getSelectedCategory = (state: RootState) => state.doc.selectedCategory
export const getSelectedSites = (state: RootState) => state.doc.selectedSites

export const { setSelectedCategory, setSelectedSites } = docSlice.actions

export default docSlice.reducer
