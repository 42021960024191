import api from "../utils/axios"

import { ReportApiBody } from "../interfaces/visualization/ReportApiBody"
import { VisualizationFilterEnum } from "../enum/visualization/tab/filter-tab"

import Message from "../utils/messages"
import { responseErrorMessage, writeApiLogEvent } from "../utils/utils"
import { getCurrentUser } from "../store/counter/authSlice"

const route = "api/monitoring"

export const apiMonitoringGetSitesOverview = async (
  customerUniqueId: number,
  requestBody: { siteUniqueIds: number[] }
) => {
  try {
    const response = await api.post(`${route}/overview?customerId=${customerUniqueId}`, requestBody)
    return {
      status: response.status === 200,
      data: response.data,
      message: response.status === 200 ? "" : Message.ERR_MONITORING_GET_SITE_OVERVIEW_FAILED
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get monitoring site overview =>", e.message)

    writeApiLogEvent("get monitoring site overview", e, requestBody.siteUniqueIds.join(", "))

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_MONITORING_GET_SITE_OVERVIEW_FAILED)
    }
  }
}

export const apiMonitoringGetSiteSummary = async (
  siteUniqueId?: number,
  customerUniqueId?: number
) => {
  try {
    const response = await api.get(`${route}/site/summary/${siteUniqueId}`, {
      params: { customerId: customerUniqueId || getCurrentUser().customerUniqueId }
    })

    return {
      status: response.status === 200,
      message: response.status === 200 ? "" : Message.ERR_MONITORING_DEVICE_NOT_CONNECT,
      data: response.data
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get monitoring site summary =>", e.message)

    writeApiLogEvent("get monitoring site summary", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_MONITORING_GET_SITE_SUMMARY_FAILED)
    }
  }
}

export const apiMonitoringGetTeraStorsData = async (
  siteUniqueId: number,
  showSoftwareStatus: boolean
) => {
  try {
    const response = await api.get(
      `${route}/site/data/${siteUniqueId}?showSoftwareStatus=${showSoftwareStatus}`
    )

    return {
      status: response.status === 200,
      message: response.status === 200 ? "" : Message.ERR_MONITORING_DEVICE_NOT_CONNECT,
      data: response.data
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get monitoring list terastor =>", e.message)

    writeApiLogEvent("get monitoring list terastor", e, siteUniqueId)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.ERR_MONITORING_GET_SITE_TERASTOR_FAILED)
    }
  }
}

export const apiMonitoringDataConnectedCheck = async (customerUniqueId?: number) => {
  try {
    const response = await api.get(`${route}/data-check`, {
      params: { customerId: customerUniqueId || getCurrentUser().customerUniqueId }
    })

    return {
      status: response.status === 200,
      message: response.status === 200 ? "" : Message.MSG_EMPTY_DATA,
      data: response.data
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get monitoring site summary =>", e.message)

    writeApiLogEvent("get monitoring site summary", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.MSG_EMPTY_DATA)
    }
  }
}

export const apiMonitoringReport = async (
  reportType: VisualizationFilterEnum,
  siteId: number,
  body: ReportApiBody
) => {
  try {
    const response = await api.post(
      `${route}/report/graph/${reportType}/${siteId}?customerId=${getCurrentUser().customerUniqueId}`,
      body
    )

    // this case we only need to handle 200 code, others will be treated as exceptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (response.status !== 200) throw new Error((response as any).msg)

    return {
      status: true,
      data: response.data
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("API exception: get monitoring site report =>", e.message)

    writeApiLogEvent("get monitoring site report", e)

    return {
      status: false,
      message: responseErrorMessage(e.code, Message.MSG_EMPTY_DATA)
    }
  }
}
