import { Middleware } from "redux"

const logoutMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === "auth/logout") {
    // Immediately handle the logout action
    next(action)

    // Then dispatch the reset action from another slice
    store.dispatch({ type: "monitoringSlice/reset" })
    store.dispatch({ type: "visualization/reset" })
  } else {
    // For all other actions, just continue
    next(action)
  }
}

export default logoutMiddleware
