import { Auth } from "aws-amplify"

import { SignupBody } from "../interfaces/SignupBody"
import { SigninBody } from "../interfaces/SigninBody"
import type { AuthenticatedUser } from "../interfaces/AuthenticatedUser"

import { AUTHORITY_CONSTANTS, COOKIE_NAME, USER_ROLES } from "../utils/constants"
import Message from "../utils/messages"
import { writeCodeLogEvent } from "../utils/utils"
import api from "../utils/axios"
import cookies from "../utils/cookies"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUserInfo = async (user: any, body: SignupBody) => {
  try {
    // update password
    await Auth.completeNewPassword(user, body.password)

    // update user attributes
    await Auth.updateUserAttributes(user, {
      given_name: body.firstName,
      family_name: body.lastName
    })

    return {
      status: true
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error("Failed to call cognito API update user password and attributes:", e.message)

    writeCodeLogEvent(`Failed to call cognito API update user password and attributes`, e)

    return {
      status: false,
      message: Message.ERR_COGNITO_SIGNUP_FAILED
    }
  }
}

export const signin = async (body?: SigninBody) => {
  let user
  if (!body) {
    // Get user info from storage
    user = await Auth.currentAuthenticatedUser()
  } else {
    const checkLogin = await api.post(AUTHORITY_CONSTANTS.API_LOGIN, body)
    if (checkLogin.status == 429) {
      return {
        status: false,
        message: AUTHORITY_CONSTANTS.ERR_KEY_TOO_MANY_REQUESTS,
        rateLimitExceeded: AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT
      }
    }

    if (checkLogin.status !== 200) {
      const rateLimitExceeded: number = checkLogin.data.rateLimitExceeded
      cookies.set(COOKIE_NAME.RATE_LIMIT_EXCEEDED, rateLimitExceeded)
      if (rateLimitExceeded >= AUTHORITY_CONSTANTS.MAX_LOGIN_ATTEMPT) {
        localStorage.setItem(
          COOKIE_NAME.LOCK_TIME,
          String(new Date().getTime() + AUTHORITY_CONSTANTS.TIME_LOCK_USER)
        )
      }
      return {
        status: false,
        message: checkLogin.data.msg,
        rateLimitExceeded: rateLimitExceeded
      }
    } else {
      cookies.set(COOKIE_NAME.RATE_LIMIT_EXCEEDED, 0)
      if (checkLogin.data.challenge === AUTHORITY_CONSTANTS.ERR_KEY_NEW_PASSWORD_REQUIRED) {
        // Prepare data, Go to sign up page
        return {
          status: true,
          challenge: checkLogin.data.challenge
        }
      }
    }
    user = await Auth.signIn(body.username, body.password)
  }
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { refreshToken, idToken } = user.signInUserSession

  // has not role or not aesi super admin, tenant cloud admin and tenant site admin the not allow to signin
  const cognitoGroups = idToken.payload["cognito:groups"]
  if (!cognitoGroups) {
    throw new Error(Message.ERR_COGNITO_SIGNIN_NOT_ALLOWED)
  }
  const role = cognitoGroups[0]
  if (
    ![
      USER_ROLES.AESI_SUPER_ADMIN.id,
      USER_ROLES.TENANT_CLOUD_ADMIN.id,
      USER_ROLES.TENANT_SITE_ADMIN.id
    ].includes(role)
  ) {
    throw new Error(Message.ERR_COGNITO_SIGNIN_NOT_ALLOWED)
  }

  // if user login is tenant cloud admin / tenant site admin and has no customerUniqueId then is not allowed to login
  const customerUniqueId = user.attributes["custom:tenant"]
  if (role !== USER_ROLES.AESI_SUPER_ADMIN.id && !customerUniqueId) {
    throw new Error(Message.ERR_COGNITO_GET_CUSTOMERUNIQUEID_FAILED)
  }

  const currentUser: AuthenticatedUser = {
    firstName: user.attributes.given_name,
    lastName: user.attributes.family_name,
    role,
    customerUniqueId,
    username: user.username,
    email: user.attributes.email,
    avatar: user.attributes.picture
  }
  localStorage.setItem(COOKIE_NAME.CURRENT_USER, JSON.stringify(currentUser || {}))
  cookies.set(COOKIE_NAME.REFRESH_TOKEN, refreshToken)
  cookies.set(COOKIE_NAME.ID_TOKEN, idToken)
  cookies.set(COOKIE_NAME.RATE_LIMIT_EXCEEDED, 0)
  return {
    status: true,
    idToken: idToken.jwtToken,
    currentUser,
    refreshToken: refreshToken.token,
    rateLimitExceeded: 0
  }
}
