import { Navigate, createBrowserRouter } from "react-router-dom"

/****Layouts*****/
import TenantLayout from "../layouts/TenantLayout"
import AESILayout from "../layouts/AESILayout"
import AuthLayout from "../layouts/AuthLayout"

/***** Pages ****/

import Monitoring from "../pages/Monitoring"
import Visualization from "../pages/visualization/Visualization"
import Admin from "../pages/Admin"
import CustomerManagement from "../pages/CustomerManagement"
import Auth from "../pages/Auth"
import Configuration from "../pages/configuration/Configuration"
import NotFound from "../pages/auth/NotFound"
import ReviewConfiguration from "../pages/admin/ReviewConfiguration"

import { AESI_ADMIN_ROUTE, CONFIGURATION_REVIEW, ALL_SITES_MANAGEMENT } from "../utils/constants"
import AllSitesManagement from "pages/site-management/AllSitesManagement"
import DocManagerTenantAdmin from "pages/document-center/DocManagerTenantAdmin"

const AESI_CUSTOMER_MANAGEMENT_ROUTE = `${AESI_ADMIN_ROUTE}/customer-management`

/*****Routes******/
const ThemeRoutes = createBrowserRouter([
  {
    path: "/",
    element: <TenantLayout />,
    errorElement: <TenantLayout />,
    children: [
      { path: "/", element: <Navigate to="/monitoring" /> },
      { path: "/monitoring/:siteUniqueId?/all?/:tsId?", element: <Monitoring /> },
      { path: "/visualization", element: <Visualization /> },
      { path: "/admin/:page?", element: <Admin /> },
      { path: "/document", element: <DocManagerTenantAdmin /> },
      { path: "/site-setup/:siteId?", element: <Configuration /> }
    ]
  },
  {
    path: AESI_ADMIN_ROUTE,
    element: <AESILayout />,
    errorElement: <AESILayout />,
    children: [
      { path: AESI_ADMIN_ROUTE, element: <Navigate to={AESI_CUSTOMER_MANAGEMENT_ROUTE} /> },
      {
        path: `${AESI_CUSTOMER_MANAGEMENT_ROUTE}/:customerUniqueId?`,
        element: <CustomerManagement />
      },
      {
        path: `${AESI_ADMIN_ROUTE}/${CONFIGURATION_REVIEW}/:siteId?`,
        element: <ReviewConfiguration />
      },
      {
        path: `${AESI_ADMIN_ROUTE}/${ALL_SITES_MANAGEMENT}`,
        element: <AllSitesManagement />
      }
    ]
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    errorElement: <AuthLayout />,
    children: [
      { path: "/auth", element: <Navigate to="/auth/signin" /> },
      { path: "/auth/signin", element: <Auth /> },
      { path: "/auth/forgot-password", element: <Auth /> },
      { path: "/auth/signup/:username/:code", element: <Auth /> },
      // Catch-all route for invalid URLs under /auth
      { path: "*", element: <NotFound /> }
    ]
  }
])

export default ThemeRoutes
