import { useState, useEffect, memo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Modal, ModalBody, ModalFooter, Spinner, ModalHeader } from "reactstrap"

import JSZip from "jszip"

import { MultipleDownloadModalProps } from "../../interfaces/MultipleDownloadModalProps"
import { SiteTeraStorData } from "../../interfaces/monitoring/maxnerva/SiteTeraStorData"

import { ExportFileTypeEnum } from "../../enum/visualization/export-file-type"

import download from "../../assets/images/icons/visualization/download.svg"
import success from "../../assets/images/icons/success.svg"

import {
  getSelectedTeraStors,
  setMultipleDownload,
  getFilters,
  getSiteEntity,
  getChartData,
  getTeraStorFilters,
  getDataMode
} from "../../store/counter/visualizationSlice"

import Messages from "../../utils/messages"
import { stringFormat, visualizationFileName } from "../../utils/utils"
import { exportPdfFile, exportCSVFile } from "../../utils/visualization/exporting"

import { AppDispatch } from "../../store/store"

const MultipleDownloadModal = memo((props: MultipleDownloadModalProps) => {
  const chartData = useSelector(getChartData)
  const teraStorFilters = useSelector(getTeraStorFilters)
  const currentSiteEntity = useSelector(getSiteEntity)
  const filters = useSelector(getFilters)
  const selectedTeraStors = useSelector(getSelectedTeraStors)
  const dataMode = useSelector(getDataMode)
  const dispatch = useDispatch<AppDispatch>()

  const [downloading, setDownloading] = useState(false)
  const [succeed, setSucceed] = useState(false)

  useEffect(() => {
    dispatch(setMultipleDownload(true))

    // reset multiple download state on dismounting
    return () => {
      dispatch(setMultipleDownload(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Generate zip file
   * @returns {void}
   */
  const createZipFile = async () => {
    const zip = new JSZip()

    // loop data
    for (const teraStor of selectedTeraStors) {
      const _fileName = visualizationFileName(filters, currentSiteEntity, teraStor.tsId)

      if (!document.querySelector("#multipleDownloadModal")) break

      // show spinner
      document.querySelector(`#item-${teraStor.tsId} .download`)?.classList.add("d-none")
      document.querySelector(`#item-${teraStor.tsId} .spinner`)?.classList.remove("d-none")

      if (props.fileType === ExportFileTypeEnum.PDF) {
        // Create PDF blob
        const pdfFileName = `${_fileName}.pdf`
        const pdfBlob = await exportPdfFile(
          filters,
          pdfFileName,
          selectedTeraStors,
          document.querySelector(`#badge${teraStor.tsId}`),
          document.querySelector(`#chart${teraStor.tsId}`),
          teraStor.tsId,
          true
        )

        // Add PDF blob to zip file
        if (pdfBlob) zip.file(pdfFileName, pdfBlob)
      } else {
        // create csv blob
        const csvBlob = createCsvBlob(
          exportCSVFile(
            teraStor.tsId,
            filters,
            teraStorFilters[teraStor.tsId],
            chartData[teraStor.tsId],
            dataMode
          )
        )
        zip.file(`${_fileName}.csv`, csvBlob)
      }

      // show succeed image
      document.querySelector(`#item-${teraStor.tsId} .spinner`)?.classList.add("d-none")
      document.querySelector(`#item-${teraStor.tsId} .succeed`)?.classList.remove("d-none")
    }

    // create zip file
    if (document.querySelector("#multipleDownloadModal")) {
      // Generate the zip file as a blob
      const zipBlob = await zip.generateAsync({ type: "blob" })

      // Trigger download of the zip file
      const link = document.createElement("a")
      link.href = URL.createObjectURL(zipBlob)
      link.download = `${visualizationFileName(filters, currentSiteEntity)}-${props.fileType?.toLocaleLowerCase()}.zip`
      link.click()

      // set status
      dispatch(setMultipleDownload(false))
      setSucceed(true)
      setDownloading(false)
    } else props.toggle()
  }

  useEffect(() => {
    if (downloading) {
      setTimeout(() => createZipFile(), 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloading])

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      backdrop={"static"}
      id="multipleDownloadModal"
      scrollable={true}
    >
      <ModalHeader className="d-flex justify-content-center text-primary fw-bold">
        {succeed
          ? Messages.BTN_VISUALIZATION_EXPORT_MULTI_SUCCEED
          : stringFormat(Messages.LBL_VISUALIZATION_EXPORT_MULTI_READY, [props.fileType || ""])}
      </ModalHeader>
      <ModalBody className="p-4">
        <ul className="mb-0 ps-0">
          {selectedTeraStors.map((teraStor: SiteTeraStorData, index: number) => (
            <li
              className={`d-flex justify-content-between mb-2 pb-2 ${index < selectedTeraStors.length - 1 ? "border-bottom" : ""}`}
              key={index}
              id={`item-${teraStor.tsId}`}
            >
              <span>
                {visualizationFileName(filters, currentSiteEntity, teraStor.tsId)}.
                {props.fileType?.toLocaleLowerCase()}
              </span>
              <img src={download} width={15} alt="download" className="status download" />
              <img src={success} width={15} alt="download" className="status succeed d-none" />
              <Spinner size="sm" color="primary" className="status spinner d-none" />
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {downloading ? (
          <Button color="danger" className="fw-bold" onClick={() => props.toggle()}>
            {Messages.BTN_VISUALIZATION_EXPORT_MULTI_CANCEL}
            <Spinner size="sm" color="light" className="ms-2" />
          </Button>
        ) : succeed ? (
          <Button color="outline-primary" className="fw-bold" onClick={() => props.toggle()}>
            {Messages.BTN_VISUALIZATION_EXPORT_MULTI_CLOSE}
          </Button>
        ) : (
          <>
            <Button color="primary" className="fw-bold" onClick={() => setDownloading(true)}>
              {Messages.BTN_VISUALIZATION_EXPORT_MULTI_ALL}
            </Button>
            <Button color="outline-primary" className="fw-bold" onClick={() => props.toggle()}>
              {Messages.BTN_VISUALIZATION_EXPORT_MULTI_CANCEL}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  )
})

MultipleDownloadModal.displayName = "MultipleDownloadModal"

export default MultipleDownloadModal

/**
 * Create CSV blob
 * @param {string[][]} data csv dât
 * @returns {blob} csv blob
 */
const createCsvBlob = (data: string[][]) => {
  const csvContent = data.map((row: string[]) => row.join(",")).join("\n")
  return new Blob([csvContent], { type: "text/csv" })
}
