import React from "react"
import { Spinner } from "reactstrap"
import Messages from "utils/messages"

interface ActionButtonProps {
  className: string
  onClick: () => void
  text: string | undefined
  spinner?: boolean
  disabled?: boolean
}

const ActionButton = (props: ActionButtonProps) => {
  const { className, onClick, text, spinner = false, disabled = false } = props
  return (
    <button
      className={`me-0 btn-actions btn border-0 ${className} `}
      onClick={onClick}
      disabled={disabled}
    >
      {spinner && (
        <Spinner color="light" size="sm" className="me-1">
          {Messages.MSG_LOADING}
        </Spinner>
      )}
      {text}
    </button>
  )
}

export default ActionButton
