import { TableHeaderProps } from "../interfaces/TableHeaderPops"

import sortasc from "../assets/images/icons/admin/sorting-asc.svg"
import sortdesc from "../assets/images/icons/admin/sorting-desc.svg"

const SORT_ASC = "asc"
const SORT_DESC = "desc"

const TableHeader = (props: TableHeaderProps) => {
  let sortingHeader = `${props.sortingHeader}`
  const alias = props.alias ?? ""
  const sortable = alias !== ""
  const SORT_PREFIX = "&sort="
  const visible = props.visible ?? true

  // This code allow sorting multiple columns
  // In order to enable multiple sorting columns, change this:
  // sortingHeader = `${sortingHeader}${SORT_PREFIX}${field},${type}`
  const handleSorting = (field: string, type: string) => {
    if (!props.isLoading && sortable) {
      // not exist then append
      if (!sortingHeader.includes(field)) {
        sortingHeader = `${SORT_PREFIX}${field},${type}`
      } else {
        // reset sort value
        if (!type) {
          sortingHeader = sortingHeader
            .split(SORT_PREFIX)
            .filter((item) => !item.includes(`${field},`))
            .join(SORT_PREFIX)
        } else {
          sortingHeader = sortingHeader
            .split(SORT_PREFIX)
            .map((item) => (item.includes(`${field},`) ? (item = `${field},${type}`) : item))
            .join(SORT_PREFIX) // switch type then update
        }
      }
      props.setSortingHeader(sortingHeader)
    }
  }
  const getCurrentSortValue = (field: string) => {
    // empty then return empty
    if (!sortingHeader || !sortable) return ""

    // check if current field is already sorted
    let filter = sortingHeader.split(SORT_PREFIX).filter((item) => item.includes(`${field},`))
    if (filter.length === 0) return ""

    // return current sort value
    filter = filter[0].split(",")
    return filter.length > 0 ? filter[1] : ""
  }
  const getNextSortValue = (field: string) => {
    if (!sortable) return ""

    const current = getCurrentSortValue(field)
    if (current === SORT_ASC) return SORT_DESC
    else if (current === SORT_DESC) return ""
    else return SORT_ASC
  }

  return (
    <th
      scope="col"
      onClick={() => handleSorting(alias, getNextSortValue(alias))}
      className={`${getCurrentSortValue(alias) ? "active" : ""} ${visible ? "" : "d-none"}`}
      data-testid={`sort${alias}`}
    >
      {props.title}
      {sortable && (
        <img
          src={
            !getCurrentSortValue(alias) || getCurrentSortValue(alias) === SORT_ASC
              ? sortasc
              : sortdesc
          }
          alt="sorting"
          className={getCurrentSortValue(alias) ? "sorting ms-2 active" : "sorting ms-2"}
        />
      )}
    </th>
  )
}

export default TableHeader
