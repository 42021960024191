import { NavItem } from "reactstrap"
import siteActive from "../../assets/images/monitoring/img/site-monitoring-active.svg"
import siteNormal from "../../assets/images/monitoring/img/site-monitoring.svg"
import arrowOpen from "../../assets/images/icons/admin/arrow-open.svg"
import arrowClosed from "../../assets/images/icons/admin/arrow-closed.svg"
import { SiteEntity } from "interfaces/SiteEntity"
import { getSiteStatusCSS, getSiteStatusAsString, getSiteNameCutLength } from "utils/utils"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { useSelector } from "react-redux"
import { getSiteEntityList } from "store/counter/monitoringSlice"
import { useEffect, useState } from "react"
import { SITE_STATUS } from "../../utils/constants"
import { ScreenSize } from "enum/screen-size"

interface SiteNavItemProps {
  site: SiteEntity
  isSelected: boolean
  onSiteSelected: (site: SiteEntity) => void
  isOpen: boolean
}

const SiteNavItem: React.FC<SiteNavItemProps> = (props: SiteNavItemProps) => {
  const { site, isSelected, onSiteSelected, isOpen } = props
  const listSites = useSelector(getSiteEntityList)
  const [status, setStatus] = useState<string | undefined>("")
  const [dataConnected, setDataConnected] = useState(false)

  const showSiteSetupStaus = status !== SITE_STATUS.READY_FOR_INSTALLATION

  useEffect(() => {
    setStatus(
      listSites?.find((status) => status.siteUniqueId === props?.site?.siteUniqueId)?.status
    )
    setDataConnected(
      listSites?.find((status) => status.siteUniqueId === props?.site?.siteUniqueId)
        ?.dataConnected || false
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, listSites])

  const [cutLength, setCutLength] = useState(9)

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      const isSmallScreen = windowWidth < ScreenSize.xlBreakpoint
      setCutLength(getSiteNameCutLength(status, !showSiteSetupStaus, isSmallScreen))
    }

    window.addEventListener("resize", handleResize)
    setCutLength(getSiteNameCutLength(status, !showSiteSetupStaus))

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize)
  }, [status, showSiteSetupStaus])

  return (
    <NavItem className="mb-3" tag="div">
      <div
        id={`siteLink-${site.siteUniqueId}`}
        className={`nav-link p-2 d-flex justify-content-between flex-nowrap
          ${isSelected ? "active" : ""}
        `}
        onClick={() => {
          onSiteSelected(site)
        }}
      >
        <div className="d-flex flex-nowrap">
          <img
            className="mb-1"
            src={isSelected ? siteActive : siteNormal}
            alt={`${site.siteUniqueId || "site"}`}
          ></img>
          <span className="ms-2 mt-1 text-nowrap" data-tooltip-id={`${site.siteUniqueId}`}>
            Site{" "}
            {site.siteName.length > cutLength
              ? `${site.siteName.substring(0, cutLength - 2)}...`
              : site.siteName}
          </span>
          {site.siteName.length > cutLength && (
            <ReactTooltip id={`${site.siteUniqueId}`} place="bottom" content={site.siteName} />
          )}
        </div>
        <div className="site-setup d-flex justify-content-between">
          {listSites && showSiteSetupStaus && (
            <span className={`badge d-inline-block pt-1 ${getSiteStatusCSS(status)}`}>
              {getSiteStatusAsString(status)}
            </span>
          )}
          {(!showSiteSetupStaus || dataConnected) && (
            <img
              width={8}
              src={isOpen && isSelected === true ? arrowOpen : arrowClosed}
              alt="open"
              className="ms-1"
            />
          )}
        </div>
      </div>
    </NavItem>
  )
}

export default SiteNavItem
