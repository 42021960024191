import { Network } from "interfaces/Network"

const SITE_INFORMATION = "site-information"
const USER_MANAGEMENT = "user-management"
const SYSTEM_UPDATES = "system-updates"
const CUSTOMER_MANAGEMENT = "customer-management"
const ALL_SITES_MANAGEMENT = "all-sites-management"
const CONFIGURATION_REVIEW = "site-setup-review"
const SIGNIN = "signin"
const FORGOT_PASSWORD = "forgot-password"
const SIGNUP = "signup"
const TERASTOR = "terastor"
const CONFIGURATION = "configuration"

const COOKIE_NAME = {
  CURRENT_USER: "currentUser",
  REFRESH_TOKEN: "refreshToken",
  ID_TOKEN: "idToken",
  USERNAME: "username",
  REMEMBER_ME: "rememberMe",
  SIGNIN_EXPIRED: "signinExpired",
  RATE_LIMIT_EXCEEDED: "rateLimitExceeded",
  SESSION_TOKEN: "sessionToken",
  LOCK_TIME: "lockTime"
}

const AUTHORITY_CONSTANTS = {
  MAX_LOGIN_ATTEMPT: 5,
  CAPTCHA_LOGIN_ATTEMPT: 3,
  TIME_LOCK_USER: 300_000, // 5 minutes
  API_LOGIN: "api/noauth/login",
  API_FORGET_PASSWORD: "api/noauth/forgot-password",
  API_VERIFY_OTP: "api/noauth/verify-otp",
  API_UPDATE_PASSWORD: "api/noauth/reset-password",
  ERR_KEY_TOO_MANY_REQUESTS: "TOO_MANY_REQUESTS",
  ERR_KEY_PASSWORD_INCORRECT: "PASSWORD_INCORRECT",
  ERR_KEY_NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  ERR_KEY_USER_DISABLED: "USER_DISABLED",
  ERR_KEY_FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
  ERR_KEY_PENDING_CONFIRMATION: "PENDING_CONFIRMATION",
  ERR_MSG_PASSWORD_INCORRECT: "PASSWORD_INCORRECT"
}

const USER_ROLES: { [key: string]: { id: string; name: string } } = {
  AESI_SUPER_ADMIN: {
    id: "AESI_SUPER_ADMIN",
    name: "AESI Super Admin"
  },
  TENANT_CLOUD_ADMIN: {
    id: "TENANT_CLOUD_ADMIN",
    name: "Tenant Cloud Admin"
  },
  TENANT_SITE_ADMIN: {
    id: "TENANT_SITE_ADMIN",
    name: "Tenant Site Admin"
  }
}

const SITE_STATUS = {
  START_SITE_LAYOUT: "START_SITE_LAYOUT",
  LAYOUT_IN_PROGRESS: "LAYOUT_IN_PROGRESS",
  LAYOUT_PENDING_APPROVAL: "LAYOUT_PENDING_APPROVAL",
  LAYOUT_APPROVED: "LAYOUT_APPROVED",
  CONFIGURATION_IN_PROGRESS: "CONFIGURATION_IN_PROGRESS",
  PENDING_FINAL_APPROVAL: "PENDING_FINAL_APPROVAL",
  READY_FOR_INSTALLATION: "READY_FOR_INSTALLATION",
  CONNECTED: "CONNECTED"
}

const SITE_STATUS_STYLE_CSS: { [key: string]: string } = {
  START_SITE_LAYOUT: "start-site-layout",
  LAYOUT_IN_PROGRESS: "layout-in-progress",
  LAYOUT_PENDING_APPROVAL: "layout-pending-approval",
  LAYOUT_APPROVED: "layout-approved",
  CONFIGURATION_IN_PROGRESS: "configuration-in-progress",
  PENDING_FINAL_APPROVAL: "pending-final-approval",
  READY_FOR_INSTALLATION: "ready-for-installation",
  CONNECTED: "connected"
}

const SITE_STATUS_STRING: { [key: string]: string } = {
  START_SITE_LAYOUT: "Site Created",
  LAYOUT_IN_PROGRESS: "Layout in progress",
  LAYOUT_PENDING_APPROVAL: "Layout pending approval",
  LAYOUT_APPROVED: "Layout approved",
  CONFIGURATION_IN_PROGRESS: "Configuration in progress",
  PENDING_FINAL_APPROVAL: "Pending final approval",
  READY_FOR_INSTALLATION: "Ready for installation",
  CONNECTED: "Connected"
}

const USER_ROLES_DEFAULT_URL: { [key: string]: string } = {
  AESI_SUPER_ADMIN: "/aesi-admin/customer-management",
  TENANT_CLOUD_ADMIN: "/monitoring",
  TENANT_SITE_ADMIN: "/monitoring"
}

const SITE_SETUP_STEP = {
  LAYOUT: "layout",
  CONFIGURATION: "configuration",
  COMMISSIONING: "commissioning",
  REVIEW_SUBMIT: "review_submit",
  DOCUMENTS: "documents"
}
const SITE_SETUP_STEP_TITLE = {
  LAYOUT: "Site Layout",
  CONFIGURATION: "Configuration",
  COMMISIONING: "Commissioning",
  REVIEW_SUBMIT: "Review & Submit",
  REVIEW_APPROVE: "Review & Approve",
  DOCUMENTS: "Documents"
}

const SITE_SETUP_VIEW_MODE = {
  LIST: "LIST",
  CARD: "CARD"
}

enum TERASTOR_STATUS {
  TS_STATE_UNKNOWN = 0,
  TS_STATE_SHUTDOWN = 1,
  TS_STATE_STOP = 2,
  TS_STATE_STARTUP = 3,
  TS_STATE_ONLINE = 4,
  TS_STATE_MAINTENANCE = 5,
  TS_STATE_FAULT = 6,
  TS_STATE_EPO = 7
}

const TERASTOR_STATUS_TEXT = {
  UNKNOWN: "Unknown",
  SHUTDOWN: "Shutdown",
  STOP: "Stop",
  STARTUP: "Startup",
  ONLINE: "Online",
  MAINTENANCE: "Maintenance",
  FAULT: "Fault",
  EPO: "EPO",
  CHARGING: "CHARGING",
  STOPPING: "Stopping",
  IDLE: "Idle"
}

enum SOFTWARE_STATUS {
  STAGED = "Staged",
  INSTALLED = "Installed",
  FAILED = "Failed"
}

const DATE_FORMAT = "YYYY - MM - DD"

const SERVER_DATE_FORMAT = "YYYY-MM-DD"

const DEFAULT_PHONE_COUNTRY_CODE = "US"

const AESI_ADMIN_ROUTE = "/aesi-admin"

const AXIOS_ALLOWANCE_STATUS = [200, 201, 400, 204]

const VISUALIZATION_DATE_FORMAT = "DD/MM/YYYY"
const VISUALIZATION_DATE_RANGE = 6 // maximum is 7 days apart, plus 6 days from the current date
const FW_ALLOWED_EXTENSIONS = [".tar.gz.crypt", ".tar.gz", ".xz"]
const ACCEPTED_FW_EXTENSIONS = ".tar,.gz,.crypt,.xz"
const ACCEPTED_DOC_EXTENSIONS = "*.*"

const SEARCH_INPUT_THROTTLED_DELAY = 1000 // 1000ms = 1 second
const RELEASE_NOTES_MAX_LENGTH = 1000 // characters
const MONITORING_STROKE_COLOR = "#15376f"
const UNASSIGNED_GROUP = "Unassigned"

const defaultPOIPrimaryMeter: Network = {
  hostName: "Pol PQM",
  ipAddress: "10.22.50.103",
  port: "502"
}

const blankNetwork: Network = {
  hostName: "",
  ipAddress: "",
  port: "",
  netmask: "",
  gateway: ""
}

const DATA_CHECK_REDUCE = [
  "avgVoltage",
  "minVoltage",
  "maxVoltage",
  "avgTemp",
  "minTemp",
  "maxTemp",
  "commandReactivePower",
  "actualReactivePower",
  "commandRealPower",
  "actualRealPower",
  "ia",
  "ib",
  "ic",
  "vab",
  "vbc",
  "vca"
]

enum XAXIS_TYPE {
  CATEGORY = "category",
  NUMBER = "number"
}

const VISUALIZATION_TIMESTAMP_REGULAR =
  /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) ([01]\d|2[0-3]):([0-5]\d):([0-5]\d)\.\d{3}$/

const MAX_FW_FILE_SIZE = 1000000000 // 1GB

const COMMISION_PAGE_SIZE = 10

export {
  SITE_INFORMATION,
  USER_MANAGEMENT,
  SYSTEM_UPDATES,
  CUSTOMER_MANAGEMENT,
  ALL_SITES_MANAGEMENT,
  CONFIGURATION_REVIEW,
  SIGNIN,
  FORGOT_PASSWORD,
  SIGNUP,
  TERASTOR,
  COOKIE_NAME,
  USER_ROLES,
  USER_ROLES_DEFAULT_URL,
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
  DEFAULT_PHONE_COUNTRY_CODE,
  CONFIGURATION,
  SITE_STATUS,
  SITE_STATUS_STRING,
  SITE_STATUS_STYLE_CSS,
  AESI_ADMIN_ROUTE,
  SITE_SETUP_STEP,
  AXIOS_ALLOWANCE_STATUS,
  SITE_SETUP_STEP_TITLE,
  SITE_SETUP_VIEW_MODE,
  VISUALIZATION_DATE_RANGE,
  VISUALIZATION_DATE_FORMAT,
  FW_ALLOWED_EXTENSIONS,
  ACCEPTED_FW_EXTENSIONS,
  SEARCH_INPUT_THROTTLED_DELAY,
  RELEASE_NOTES_MAX_LENGTH,
  MONITORING_STROKE_COLOR,
  UNASSIGNED_GROUP,
  AUTHORITY_CONSTANTS,
  TERASTOR_STATUS,
  TERASTOR_STATUS_TEXT,
  SOFTWARE_STATUS,
  defaultPOIPrimaryMeter,
  blankNetwork,
  DATA_CHECK_REDUCE,
  ACCEPTED_DOC_EXTENSIONS,
  VISUALIZATION_TIMESTAMP_REGULAR,
  MAX_FW_FILE_SIZE,
  XAXIS_TYPE,
  COMMISION_PAGE_SIZE
}
