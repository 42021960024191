import React from "react"
import { ElementCardProps } from "../../interfaces/ElementCardProps"
import TeraStor from "../../assets/images/configuration/terastor.svg"
import { TERASTOR } from "../../utils/constants"

const ElementCard: React.FC<ElementCardProps> = (props: ElementCardProps) => {
  const handleMouseDrag = (event: React.MouseEvent) => {
    props.handleMouseMove(event)
  }

  const cardProps = {
    imgSource: TERASTOR,
    cardWidth: 100,
    cardHeight: 100
  }

  switch (props.name) {
    case TERASTOR:
      cardProps.imgSource = TeraStor
      cardProps.cardHeight = 200
      cardProps.cardWidth = 100
      break
  }

  return (
    <div className="pt-1 cursor-pointer" draggable onDragStart={(event) => handleMouseDrag(event)}>
      <img
        src={cardProps.imgSource}
        width={cardProps.cardWidth}
        height={cardProps.cardHeight}
        alt={props.name}
      ></img>
    </div>
  )
}

export default ElementCard
