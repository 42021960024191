import { Suspense, lazy } from "react"
import { useLocation } from "react-router-dom"

import Signin from "./auth/Signin"
import ForgotPassword from "./auth/ForgotPassword"
const Signup = lazy(() => import("./auth/Signup"))
import Loader from "../components/Loader"

const AuthWrapper = () => {
  const location = useLocation()
  const page = location.pathname.split("/")[2]

  if (page === "signin") return <Signin />
  else if (page === "forgot-password" || page === "reset-password") return <ForgotPassword />
  else if (page === "signup")
    return (
      <Suspense fallback={<Loader />}>
        <Signup />
      </Suspense>
    )
  else throw new Error("Invalid path")
}

export default AuthWrapper
