import HeaderDesktop from "../components/header/HeaderDesktop"
import HeaderResponsive from "../components/header/HeaderResponsive"

const Header = () => {
  return (
    <div id="headerWrapper">
      <HeaderDesktop />
      <HeaderResponsive />
    </div>
  )
}

export default Header
