import type { RootState } from "../store"
import { createSlice } from "@reduxjs/toolkit"

import { SiteTeraStorData } from "interfaces/monitoring/maxnerva/SiteTeraStorData"
import type { MonitoringState } from "../../interfaces/MonitoringState"

import { summaryDataThunk, teraStorDataThunk, sitesOverviewThunk } from "./monitoringThunk"
import { TeraStorGroup, TsDataOverview } from "interfaces/monitoring/maxnerva/SiteOverviewData"
import { UNASSIGNED_GROUP } from "utils/constants"

export const initialState: MonitoringState = {
  currentSiteEntity: undefined,
  siteEntityList: [],
  isFetchingSiteList: true,
  sitesOverviewData: [],
  isFetchingSummary: false,
  fetchingSummaryError: "",
  siteSummaryData: null,
  isFetchingTeraStor: true,
  fetchingTeraStorError: "",
  siteTeraStorsData: [],
  showSoftwareStatus: false
}

const monitoringSlice = createSlice({
  name: "monitoringSlice",
  initialState,
  reducers: {
    reset: () => {
      // reset state to initial state
      return initialState
    },
    setSiteEntity: (state, action) => {
      state.currentSiteEntity = action.payload
      state.isFetchingSummary = false
      state.fetchingSummaryError = ""
      state.siteTeraStorsData = []
      state.isFetchingTeraStor = false
      state.fetchingTeraStorError = ""
      state.siteSummaryData = null
    },
    setSiteEntityList: (state, action) => {
      state.siteEntityList = action.payload
    },
    setIsFetchingSiteList: (state, action) => {
      state.isFetchingSiteList = action.payload
    },
    setShowSoftwareStatus: (state, action) => {
      state.showSoftwareStatus = action.payload
    },
    setIsFetchingSummary: (state, action) => {
      state.isFetchingSummary = action.payload
    }
  },
  extraReducers: (builder) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(summaryDataThunk.fulfilled, (state: MonitoringState, action: any) => {
      const payload = action.payload
      state.isFetchingSummary = false
      if (payload.status) {
        state.siteSummaryData = payload.data
        state.fetchingSummaryError = ""
      } else {
        state.siteSummaryData = null
        state.fetchingSummaryError = payload.message
      }
    })

    builder.addCase(teraStorDataThunk.pending, (state: MonitoringState) => {
      state.fetchingTeraStorError = ""
      state.isFetchingTeraStor = true
      state.siteTeraStorsData = []
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(teraStorDataThunk.fulfilled, (state: MonitoringState, action: any) => {
      const payload = action.payload
      state.isFetchingTeraStor = false
      if (payload.status) {
        state.siteTeraStorsData = payload.data
      } else {
        state.siteTeraStorsData = []
        state.fetchingTeraStorError = payload.message
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(sitesOverviewThunk.fulfilled, (state: MonitoringState, action: any) => {
      const payload = action.payload
      if (payload.status) {
        state.sitesOverviewData = payload.data
      } else {
        state.sitesOverviewData = []
      }
    })
  }
})

export const {
  setSiteEntity,
  setSiteEntityList,
  setIsFetchingSiteList,
  setShowSoftwareStatus,
  setIsFetchingSummary
} = monitoringSlice.actions
export {
  summaryDataThunk as fetchSummaryData,
  teraStorDataThunk as fechTeraStorData,
  sitesOverviewThunk as fetchSitesOverviewData
}

export const getGroupedTeraStorData = (
  teraStorGroup: TeraStorGroup[],
  teraStorList: SiteTeraStorData[]
) => {
  const groupedData: Record<string, SiteTeraStorData[]> = {}
  teraStorGroup.forEach((item) => {
    if (isValidGroupName(item)) {
      const groupname = item.groupName
      if (!groupedData[groupname]) {
        groupedData[groupname] = []
      }
      const tsData = teraStorList?.filter((ts) => ts.groupID === item.groupId)
      if (tsData.length > 0) {
        groupedData[groupname].push(...tsData)
      } else if (groupname === UNASSIGNED_GROUP) {
        // don't show unassigned group if there is no teraStor in it
        delete groupedData[UNASSIGNED_GROUP]
      }
    }
  })
  return groupedData
}

export const getOverviewGroupedData = (teraStorGroup: TeraStorGroup[]) => {
  const groupedData: Record<string, TsDataOverview[]> = {}
  teraStorGroup.forEach((item) => {
    if (isValidGroupName(item)) {
      const groupname = item.groupName
      if (!groupedData[groupname]) {
        groupedData[groupname] = []
      }
      if (item.tsData.length > 0) {
        groupedData[groupname] = item.tsData
      }
    }
  })

  return groupedData
}

export const isValidGroupName = (tsGroup: TeraStorGroup) => {
  const JUNK_GROUP_NAME = "UN_ASSIGNED"
  if (
    tsGroup.groupId !== -1 &&
    tsGroup.groupName !== null &&
    tsGroup.groupName !== "" &&
    tsGroup.groupName !== JUNK_GROUP_NAME &&
    !(tsGroup.groupName === UNASSIGNED_GROUP && tsGroup.tsData.length === 0)
  ) {
    return true
  }

  return false
}

export const getSiteEntity = (state: RootState) => state.monitoring.currentSiteEntity
export const getSiteEntityList = (state: RootState) => state.monitoring.siteEntityList
export const getIsFetchingSiteList = (state: RootState) => state.monitoring.isFetchingSiteList
export const getIsFetchingSummary = (state: RootState) => state.monitoring.isFetchingSummary
export const getFetchingSummaryError = (state: RootState) => state.monitoring.fetchingSummaryError
export const getSummaryData = (state: RootState) => state.monitoring.siteSummaryData
export const getFetchingTeraStorStatus = (state: RootState) => state.monitoring.isFetchingTeraStor
export const getFetchingTeraStorError = (state: RootState) => state.monitoring.fetchingTeraStorError
export const getTeraStorData = (state: RootState) => state.monitoring.siteTeraStorsData
export const getSitesOverviewData = (state: RootState) => state.monitoring.sitesOverviewData
export const getShowSoftwareStatus = (state: RootState) => state.monitoring.showSoftwareStatus

export default monitoringSlice.reducer
