import { useState, useEffect, useRef } from "react"
import { Button } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

import "react-datepicker/dist/react-datepicker.css"

import { apiAdminListSites, apiAdminDeleteSite } from "../../services/apiSitesManagement"

import info from "../../assets/images/icons/admin/info_blue.svg"
import infoDisabled from "../../assets/images/icons/admin/info-disabled.svg"
import eye from "../../assets/images/icons/admin/eye.svg"
import eyeDisabled from "../../assets/images/icons/admin/eye-disabled.svg"
import reviewRed from "../../assets/images/icons/admin/review-red.svg"
import menu from "../../assets/images/icons/admin/menu.svg"
import menuDisabled from "../../assets/images/icons/admin/menu-disabled.svg"

import { SITE_STATUS } from "../../utils/constants"
import { AESI_ADMIN_ROUTE, ALL_SITES_MANAGEMENT } from "../../utils/constants"

// props
import { PageInfo } from "../../interfaces/PageInfo"
import { SiteEntity } from "../../interfaces/SiteEntity"
import { ModifySite } from "../../interfaces/ModifySite"
import { AlertModalProps } from "../../interfaces/AlertModalProps"
import { SiteModalProps } from "../../interfaces/SiteModalProps"
import { SiteInfoModalProps } from "../../interfaces/SiteInfoModalProps"
import { TableStatusProps } from "../../interfaces/TableStatusProps"
import { PaginationV2Props } from "../../interfaces/PaginationV2Props"
import { DeleteModalProps } from "../../interfaces/DeleteModalProps"
import { ActivationKeyModalProps } from "../../interfaces/ActivationKeyModalProps"
import { UpdateFWModalProps } from "interfaces/UpdateFWModalProps"

// components
import AlertModal from "../../components/modal/AlertModal"
import SiteModal from "../site-management/SiteModal"
import SiteInfoModal from "./SiteInfoModal"
import ActivationKeyModal from "./ActivationKeyModal"
import TableStatus from "../../components/TableStatus"
import PaginationV2 from "../../components/PaginationV2"
import DeleteModal from "../../components/modal/DeleteModal"
import TableHeader from "../../components/TableHeader"
import TableTooltipColumn from "../../components/TableTooltipColumn"

import Message from "../../utils/messages"
import { getEnableActivationCode, stringFormat, writeCodeLogEvent } from "../../utils/utils"
import UpdateFWModal from "./UpdateFWModal"
import AdminTabNavigator from "components/AdminTabNavigator"
import SearchInput from "components/form/SearchInput"

const AllSitesManagement = () => {
  const [isLoading, setLoading] = useState(false)
  const [selectedSite, setSelectedSite] = useState<SiteEntity>()
  const [includeDelete, setIncludeDelete] = useState(false)
  const [listSites, setListSites] = useState<SiteEntity[]>([])
  // state for FW update
  const [sitesToUpdateFW, setSitesToUpdateFW] = useState<SiteEntity[]>([])
  const [allSitesChecked, setAllSitesChecked] = useState(false)
  // search text
  const [searchText, setSearchText] = useState("")

  const [columnsCount, setColumnsCount] = useState(0)

  // ================================
  // ========== Alert modal =========
  // ================================
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    size: "sm",
    modal: false,
    toggle: () => {
      setAlertModalProps({
        ...alertModalProps,
        modal: false
      })
    },
    status: "success",
    message: ""
  })

  // ================================
  // =========== Sorting ============
  // ================================
  const headerColumns = [
    {
      alias: "customerName",
      title: Message.LBL_CUSTOMER_HEADER_NAME
    },
    {
      alias: "customerUniqueId",
      title: Message.LBL_CUSTOMER_HEADER_ID
    },
    {
      alias: "siteName",
      title: Message.LBL_SITE_HEADER_NAME
    },
    {
      alias: "siteGroup",
      title: Message.LBL_SITE_HEADER_GROUP
    },
    {
      alias: "siteUniqueId",
      title: Message.LBL_SITE_HEADER_ID
    },
    {
      alias: "city",
      title: Message.LBL_SITE_HEADER_CITY
    },
    {
      alias: "stateProvince",
      title: Message.LBL_SITE_HEADER_STATE
    },
    {
      alias: "country",
      title: Message.LBL_SITE_HEADER_COUNTRY
    }
  ]
  const [sortingHeader, setSortingHeader] = useState("")

  // ================================
  // ========= Modify modal ==========
  // ================================
  const [modal, setModal] = useState(false)
  const emptySite: SiteEntity = {
    address1: "",
    city: "",
    country: "",
    customerUniqueId: 0,
    customerName: "",
    customerId: "",
    siteUniqueId: 0,
    description: "",
    longitude: "",
    latitude: "",
    numTeraStors: 0,
    primaryContactEmail: "",
    siteName: "",
    stateProvince: "",
    teraStorPartNumber: "",
    poNumber: "",
    postalCode: "",
    elevation: ""
  }
  const [editEntity, setEditEntity] = useState<SiteEntity | null>(null)
  const toggle = () => setModal(!modal)
  useEffect(() => {
    if (editEntity) {
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEntity])
  const showEditModal = (id?: number) => {
    if (id) {
      const entity: SiteEntity[] = listSites.filter((entity) => entity.siteUniqueId === id)
      if (!entity || entity.length === 0) {
        setAlertModalProps({
          ...alertModalProps,
          status: "error",
          message: Message.ERR_SITE_GET_DETAIL_FAILED
        })
        return
      }

      setEditEntity(entity[0])
    } else {
      setEditEntity(emptySite)
    }
  }
  const updateListData = (arr: SiteEntity[], body: ModifySite | null, siteUniqueId: number) => {
    if (!body) return arr
    return arr.map((item) => {
      if (item.siteUniqueId === siteUniqueId) {
        item.description = body.description
        item.siteName = body.siteName
        item.siteGroup = body.siteGroup
        item.address1 = body.address1
        item.city = body.city
        item.stateProvince = body.stateProvince
        item.country = body.country
        item.numTeraStors = body.numTeraStors
        item.primaryContactEmail = body.primaryContactEmail
        item.teraStorPartNumber = body.teraStorPartNumber
        item.longitude = body.longitude
        item.latitude = body.latitude
        item.poNumber = body.poNumber
        item.commissionDate = body.commissionDate
        item.shippingDate = body.shippingDate
        item.poDate = body.poDate
        item.elevation = body.elevation
        item.postalCode = body.postalCode
        item.managerName = body.managerName
        item.managerPhoneNumber = body.managerPhoneNumber
      }
      return item
    })
  }
  const addNewCustomerToListData = () => {
    // show success dialog
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: "success",
      message: Message.MSG_SITE_CREATE_SUCCESS
    })

    // refresh list data
    fetchData()
  }
  const updateCustomerToListData = (siteUniqueId: number, body: ModifySite) => {
    // show success dialog
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: "success",
      message: Message.MSG_SITE_MODIFY_SUCCESS
    })

    // refresh list data
    setListSites(updateListData(listSites, body, siteUniqueId))
  }
  const siteModalProps: SiteModalProps = {
    size: "xl",
    modal,
    toggle,
    onClose: () => {
      setEditEntity(null)
    },
    addCallback: addNewCustomerToListData,
    editCallback: updateCustomerToListData,
    data: editEntity,
    customerUniqueId: editEntity?.customerUniqueId || 0
  }

  // ================================
  // ========= Info modal ==========
  // ================================
  const [siteInfoModal, setSiteInfoModal] = useState(false)
  const siteInfoModalProps: SiteInfoModalProps = {
    size: "lg",
    modal: siteInfoModal,
    toggle: () => setSiteInfoModal(!siteInfoModal),
    site: selectedSite,
    customerUniqueId: selectedSite?.customerUniqueId,
    close: () => {
      setSiteInfoModal(false)
    }
  }
  const handleClickInfo = (site: SiteEntity) => {
    if (siteInfoModal === false) {
      setSiteInfoModal(true)
      setSelectedSite(site)
    }
  }

  // ================================
  // ========= Loading data ========
  // ================================
  const [currentPage, setCurrentPage] = useState(0)
  const [tableStatus, setTableStatus] = useState("loading")
  const [tableStatusMsg, setTableStatusMsg] = useState(Message.MSG_SITE_FETCHING_SITE_LIST)
  const tablestatusProps: TableStatusProps = {
    status: tableStatus,
    message: tableStatusMsg,
    colCount: columnsCount
  }
  const fetchData = () => {
    setTableStatusMsg(Message.MSG_SITE_FETCHING_SITE_LIST)
    setTableStatus("loading")
    setLoading(true)

    // list all site from all customer by passing undefined
    apiAdminListSites(undefined, currentPage, sortingHeader, includeDelete, undefined, searchText)
      .then((res) => {
        if (res.status) {
          const data = res.data || []
          data.forEach((site) => {
            if (
              sitesToUpdateFW.some(
                (existingSite) => existingSite.siteUniqueId === site.siteUniqueId
              )
            ) {
              site.isSelected = true
            }
          })
          setListSites(data)
          setPageInfo(res.pageInfo)

          if (data.length <= 0) {
            setTableStatus("empty")
            setTableStatusMsg(Message.MSG_EMPTY_DATA)
          } else {
            setTableStatus("")
            setLoading(false)
          }
        } else {
          setTableStatus("error")
          setTableStatusMsg(res.message || "")
        }
      })
      .catch((e) => {
        console.error("Code exception: handle list site =>", e.message)
        setTableStatus("error")
        setTableStatusMsg(Message.ERR_SITE_GET_LIST_FAILED)

        writeCodeLogEvent("handle list site", e)
      })
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortingHeader])

  useEffect(() => {
    if (currentPage === 0) {
      fetchData()
    } else {
      setCurrentPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeDelete, searchText])

  useEffect(() => {
    const selectedCount = listSites.filter((site) => site.isSelected).length || 0
    if (listSites.length > 0 && selectedCount === listSites.length) {
      setAllSitesChecked(true)
    } else {
      setAllSitesChecked(false)
    }
  }, [listSites])

  const tableAllSitesRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (tableAllSitesRef.current) {
      const table = tableAllSitesRef.current
      const columns = table.querySelectorAll("th").length
      setColumnsCount(columns)
    }
  }, [])

  // ================================
  // ========== Pagination ==========
  // ================================
  const [pageInfo, setPageInfo] = useState<PageInfo>()
  const paginationV2Props: PaginationV2Props = {
    setCurrentPage: (pageNumber: number) => setCurrentPage(pageNumber),
    pageInfo,
    showingCount: listSites.length,
    forcePage: currentPage
  }

  // ================================
  // ========= Delete user ==========
  // ================================
  interface DeleteSite {
    id: number
    name: string
    customerUniqueId: number
  }
  const [deleteSite, setDeleteSite] = useState<DeleteSite | null>(null)
  const [deletemodal, setDeleteModal] = useState(false)
  const deleteToggle = () => setDeleteModal(!deletemodal)
  const deleteResponse = (status: boolean) => {
    setAlertModalProps({
      ...alertModalProps,
      modal: true,
      status: status ? "success" : "error",
      message: status ? Message.MSG_SITE_DELETE_SUCCESS : Message.ERR_SITE_DELETE_FAILED
    })

    // fetch data
    if (status) fetchData()
  }
  const apiDeleteSite = async (id: string) =>
    await apiAdminDeleteSite(id, deleteSite?.customerUniqueId || 0)
  const deleteModalProps: DeleteModalProps = {
    size: "sm",
    modal: deletemodal,
    toggle: deleteToggle,
    id: deleteSite ? `${deleteSite.id}` : "",
    text: stringFormat(Message.MSG_SITE_DELETE, [deleteSite ? deleteSite.name : ""]),
    request: apiDeleteSite,
    response: deleteResponse,
    onClose: () => {
      setDeleteSite(null)
    }
  }
  useEffect(() => {
    if (deleteSite) {
      deleteToggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSite])

  // ================================
  // ====== Approval indicator ======
  // ================================
  const requireApprovalArray = [
    SITE_STATUS.LAYOUT_PENDING_APPROVAL,
    SITE_STATUS.PENDING_FINAL_APPROVAL
  ]
  const requireApproval = (status: string) => {
    return requireApprovalArray.includes(status)
  }

  // ================================
  // ======== Dropdown menu =========
  // ================================
  interface ActiveRow {
    siteUniqueId: number
    siteName: string
    x?: number
    y?: number
  }
  const [activeRow, setActiveRow] = useState<ActiveRow>({
    siteUniqueId: 0,
    siteName: ""
  })
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLImageElement>,
    siteUniqueId: number,
    siteName: string
  ) => {
    const wrapperRect = document.getElementById("allSiteManagement")?.getBoundingClientRect()
    const rect = (e.target as HTMLElement).getBoundingClientRect()

    if (activeRow.siteUniqueId !== siteUniqueId) {
      setActiveRow({
        siteUniqueId,
        siteName,
        x: rect.left - 210,
        y: rect.top - (wrapperRect?.top || 0) + 40
      })
    } else
      setActiveRow({
        siteUniqueId: 0,
        siteName: ""
      })
  }
  const navigate = useNavigate()
  const dropdownActions = (action: string) => {
    const filter = listSites.filter((site) => site.siteUniqueId === activeRow.siteUniqueId)
    if (filter.length > 0) {
      const site = filter[0]
      switch (action) {
        case "edit":
          showEditModal(activeRow.siteUniqueId)
          break
        case "goToSiteReview":
          navigate(`${AESI_ADMIN_ROUTE}/site-setup-review/${activeRow.siteUniqueId}`, {
            state: { from: location.pathname, site: site }
          })
          break
        case "deleteSite":
          setDeleteSite({
            id: site.siteUniqueId,
            name: site.siteName,
            customerUniqueId: site.customerUniqueId
          })
          break
        case "showActivationKey":
          {
            const enable = getEnableActivationCode(activeRow.siteUniqueId, listSites)
            setActivationKeyModal(enable)
            if (enable) {
              setActivationKeyData(activeRow)
            }
          }
          break
        default:
          break
      }
    }
    setActiveRow({
      siteUniqueId: 0,
      siteName: ""
    })
  }

  const buttonRef = useRef<HTMLImageElement>(null)
  const menuImgClass = "menu-img"
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement
      if (target && ![menuImgClass, "list-group-item"].includes(target.className)) {
        setActiveRow({
          siteUniqueId: 0,
          siteName: ""
        })
      }
    }
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  // ================================
  // ===== Activation key modal =====
  // ================================
  const [activationKeyModal, setActivationKeyModal] = useState(false)
  interface ActivationKeyData {
    siteUniqueId: number
    siteName: string
  }
  const [activationKeyData, setActivationKeyData] = useState<ActivationKeyData | null>(null)
  const activationKeyModalProps: ActivationKeyModalProps = {
    size: "xl",
    modal: activationKeyModal,
    toggle: () => setActivationKeyModal(!activationKeyModal),
    siteUniqueId: activationKeyData?.siteUniqueId || 0,
    siteName: activationKeyData?.siteName || "",
    onClose: () => {
      setActivationKeyData(null)
    }
  }

  // ================================
  // ===== Update firmware modal =====
  // ================================
  const [updateFWModal, setUpdateFWModal] = useState(false)
  const updateFWModalProps: UpdateFWModalProps = {
    size: "lg",
    modal: updateFWModal,
    toggle: () => {
      setUpdateFWModal((prevState) => !prevState)
      toggleAllCheckbox(false)
      setSitesToUpdateFW([])
    },
    listSite: sitesToUpdateFW
  }

  const toggleAllCheckbox = (checked: boolean) => {
    setAllSitesChecked(checked)
    const updatedListSite: SiteEntity[] = listSites.map((site) => ({
      ...site,
      isSelected: checked
    }))
    setListSites(updatedListSite)

    // append or remove sites from stage list
    if (checked) {
      setSitesToUpdateFW((oldList) => {
        const combinedList = [...oldList, ...updatedListSite.filter((site) => !site.deleted)]
        return combinedList
      })
    } else {
      setSitesToUpdateFW((oldList) => {
        const filteredList = oldList.filter(
          (oldItem) =>
            !updatedListSite.some(
              (updatedItem) => updatedItem.siteUniqueId === oldItem.siteUniqueId
            )
        )
        return filteredList
      })
    }
  }

  const toggleCheckboxSelection = (siteCheckEntity: SiteEntity, isChecked: boolean) => {
    setListSites((oldList) => {
      const site = oldList.find((site) => site.siteUniqueId === siteCheckEntity.siteUniqueId)
      if (site) {
        site.isSelected = isChecked
      }
      return [...oldList]
    })
    if (isChecked) {
      setSitesToUpdateFW((oldList) => [...oldList, siteCheckEntity])
    } else {
      setSitesToUpdateFW((oldList) =>
        oldList.filter((site) => site.siteUniqueId !== siteCheckEntity.siteUniqueId)
      )
    }
  }

  return (
    <div id="allSiteManagement" className={`admin-wrapper`}>
      <div
        className="list-group rounded"
        id="menuWrapper"
        style={{
          top: activeRow.y + "px",
          left: activeRow.x + "px",
          display: activeRow.siteUniqueId ? "block" : "none"
        }}
      >
        <div className="list-group-item" onClick={() => dropdownActions("goToSiteReview")}>
          {Message.LBL_SITE_MENU_ADMIN_VIEW}
        </div>
        <div
          className={`${getEnableActivationCode(activeRow.siteUniqueId, listSites) ? "list-group-item" : "list-group-item disabled"}`}
          onClick={() => dropdownActions("showActivationKey")}
        >
          {Message.LBL_SITE_MENU_KEY}
        </div>
        <div className="list-group-item" onClick={() => dropdownActions("edit")}>
          {Message.LBL_SITE_MENU_EDIT}
        </div>
        <div className="list-group-item text-danger" onClick={() => dropdownActions("deleteSite")}>
          {Message.LBL_SITE_MENU_DELETE}
        </div>
      </div>
      <AdminTabNavigator
        selectedTab={ALL_SITES_MANAGEMENT}
        actionButtonClick={() => setUpdateFWModal(true)}
        actionButtonDisabled={sitesToUpdateFW.length === 0}
        actionButtonLabel={Message.LBL_MANAGEMENT_BTN_UPDATE_FW}
      />
      <div className="text-center d-md-flex justify-content-between my-4">
        <SearchInput
          name="search"
          placeHolder="Search"
          onChange={(text: string) => {
            setSearchText(text)
          }}
        />
        <div role="button">
          <label
            className="form-check-label me-2 text-primary fw-bold"
            htmlFor="chkIncludeAllDeleteSite"
          >
            {Message.LBL_SITE_HIDE_DELETE_SITE}
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="chkIncludeAllDeleteSite"
            checked={includeDelete}
            onChange={() => setIncludeDelete((oldState) => !oldState)}
          />
        </div>
      </div>
      <div className="overflow-auto mb-5">
        <table
          ref={tableAllSitesRef}
          className="overflow-auto table table-hover mb-0"
          id="tableAllSites"
        >
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  width={16}
                  onChange={(event) => toggleAllCheckbox(event.target.checked)}
                  checked={allSitesChecked}
                />
              </th>
              {headerColumns.map((item, index) => (
                <TableHeader
                  key={index}
                  alias={item.alias}
                  title={item.title}
                  isLoading={isLoading}
                  sortingHeader={sortingHeader}
                  setSortingHeader={setSortingHeader}
                />
              ))}
              {<th scope="col">{Message.LBL_SITE_HEADER_BMS_SOFTWARE}</th>}
              {<th scope="col">{Message.LBL_SITE_HEADER_EMS_SOFTWARE}</th>}
              <TableHeader
                key={headerColumns.length + 3}
                alias="numTeraStors"
                title={Message.LBL_SITE_HEADER_TERASTOR}
                isLoading={isLoading}
                sortingHeader={sortingHeader}
                setSortingHeader={setSortingHeader}
              />
              {<th scope="col">{Message.LBL_SITE_HEADER_ACTIONS}</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading && tableStatus && <TableStatus {...tablestatusProps} />}
            {!isLoading &&
              listSites.map((site, index) => (
                <tr key={index} className={site.deleted ? "disabled-row" : ""}>
                  <td>
                    <input
                      className="p-0"
                      type="checkbox"
                      width={16}
                      onChange={(event) => toggleCheckboxSelection(site, event.target.checked)}
                      checked={(!site.deleted && site.isSelected) || false}
                      disabled={site.deleted}
                    />
                  </td>
                  <TableTooltipColumn
                    id={`customerName${site.siteUniqueId}`}
                    text={site.customerName}
                    maxLength={195}
                  />
                  <TableTooltipColumn
                    id={`customerID${site.siteUniqueId}`}
                    text={site.customerUniqueId.toString()}
                    maxLength={195}
                  />
                  <TableTooltipColumn
                    id={`siteName${site.siteUniqueId}`}
                    text={site.siteName}
                    maxLength={195}
                  />
                  <TableTooltipColumn
                    id={`siteGroup${site.siteUniqueId}`}
                    text={site.siteGroup || ""}
                    maxLength={195}
                  />
                  <TableTooltipColumn
                    id={`siteId${site.siteUniqueId}`}
                    text={`${site.siteUniqueId}`}
                    maxLength={75}
                  />
                  <TableTooltipColumn
                    id={`siteCity${site.siteUniqueId}`}
                    text={site.city}
                    maxLength={75}
                  />
                  <TableTooltipColumn
                    id={`stateProvince${site.siteUniqueId}`}
                    text={site.stateProvince}
                    maxLength={103}
                  />
                  <TableTooltipColumn
                    id={`country${site.siteUniqueId}`}
                    text={site.country}
                    maxLength={75}
                  />
                  <td>{site.bmsVersion}</td>
                  <td>{site.emsVersion}</td>
                  <td>{site.numTeraStors || 0}</td>
                  {
                    <td className="action-button">
                      <Button color="light" className="border-0" disabled={site.deleted}>
                        <Link
                          to={
                            site.deleted
                              ? "#"
                              : `${AESI_ADMIN_ROUTE}/site-setup-review/${site.siteUniqueId}`
                          }
                          state={{ from: location.pathname, site: site }}
                          role="button"
                          className="position-relative"
                        >
                          <img src={site.deleted ? eyeDisabled : eye} alt="Eye" />
                          {requireApproval(site.status || "") && (
                            <img
                              src={reviewRed}
                              alt="Review"
                              className="position-absolute review-badge"
                            />
                          )}
                        </Link>
                      </Button>
                      <Button
                        color="light"
                        className="border-0"
                        onClick={() => !site.deleted && handleClickInfo(site)}
                        disabled={site.deleted}
                      >
                        <img src={site.deleted ? infoDisabled : info} alt="Info" />
                      </Button>
                      <Button
                        color="light"
                        className="border-0"
                        onClick={(event) =>
                          !site.deleted && handleClick(event, site.siteUniqueId, site.siteName)
                        }
                      >
                        <img
                          src={site.deleted ? menuDisabled : menu}
                          alt="Menu"
                          ref={buttonRef}
                          className={menuImgClass}
                          onClick={(event) =>
                            !site.deleted && handleClick(event, site.siteUniqueId, site.siteName)
                          }
                        />
                      </Button>
                    </td>
                  }
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaginationV2 {...paginationV2Props} />

      <>
        <SiteModal {...siteModalProps} />
        {siteInfoModal && <SiteInfoModal {...siteInfoModalProps} />}
        {activationKeyModal && <ActivationKeyModal {...activationKeyModalProps} />}
        {updateFWModal && <UpdateFWModal {...updateFWModalProps} />}
        <AlertModal {...alertModalProps} />
        <DeleteModal {...deleteModalProps} />
      </>
    </div>
  )
}

export default AllSitesManagement
