import { useLocation } from "react-router-dom"

import { USER_MANAGEMENT, SYSTEM_UPDATES } from "../utils/constants"

import SiteInformation from "./admin/SiteInformation"
import UserManagement from "./admin/UserManagement"
import SystemUpdates from "./admin/SystemUpdates"

const Admin = () => {
  const location = useLocation()
  const page = location.pathname.split("/")[2]

  if (page === USER_MANAGEMENT) return <UserManagement />
  else if (page === SYSTEM_UPDATES) return <SystemUpdates />
  else return <SiteInformation />
}

export default Admin
